<template>
    <v-card flat>
         <!-- <v-form @submit.prevent="create" ref="form"> -->
             <v-card-title>
                 Delete Request
                 <v-btn flat icon="mdi mdi-close" class="mt-1 float-right" density="compact" size="small"
                     @click="$emit('closeDialog')" />
             </v-card-title>
 
             <v-card-text>
                 Are you sure you want to delete this Request?
             </v-card-text>
 
             <v-card-actions style="display: flex; justify-content: center;">
                <v-btn @click="$emit('closeDialog')"  style="background-color:red;color:#fff;width:80px;" flat >Cancel</v-btn>

                 <v-btn @click="deleteItem()"  style="background-color:#1258AB;color:#fff;width:80px;" flat type="submit" 
                     color="#1258AB" >Delete</v-btn>
             </v-card-actions>
         <!-- </v-form> -->
     </v-card>
 </template>
 
 <script>
 export default {
     components: {
     },
     created(){
         console.log(this.item , 'ifYouNeed')
     },
     methods:{
         deleteItem(){
             this.$emit('delete')
         }
     }
 }
 </script>
 
 <style>
 
 </style>