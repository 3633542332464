<template>
  <div class="reportContainer">
    <ReportTools
      @Filter-clicked="handleFilterClick"
      @switch-group-change="handleSwitchChange"
    />
    <ReportTable
      :filterData="filterData"
      :switchData="switchData"
      @workspace-clicked="handleWorkspaceClick"
    />
    <!-- <div style="display: flex; gap: 35px; margin-top: 5px">
      <div style="display: flex; gap: 15px">
        <div style="width: 20px; height: 20px; background: #fd5151"></div>
        <div class="instruction">Critical Issues</div>
      </div>

      <div style="display: flex; gap: 15px">
        <div style="width: 20px; height: 20px; background: #f88d0f"></div>
        <div class="instruction">More than 2 days</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import ReportTools from "./ReportTools.vue";
import ReportTable from "./ReportTable.vue";
export default {
  props: ["reportData"],
  components: {
    ReportTools,
    ReportTable,
  },
  data() {
    return {
      filterData: null,
      group: null,
      switchData: null,
    };
  },
  methods: {
    handleSwitchChange(data) {
      const switchData = data.type;
      this.switchData = switchData;
    },
    handleWorkspaceClick(payload) {
      const projectId = payload.projectId;
      const accountId = payload.accountId;
      this.$emit("Report-Data", {
        projectId: projectId,
        accountId: accountId,
      });
    },
    handleFilterClick(payload) {
      console.log(payload, "kishorehi1");
      const filterData = payload;
      this.filterData = filterData;
      this.$emit("filter-Data", {
        filterData: filterData,
      });
    },
  },
};
</script>

<style scoped>
.reportContainer {
  /* height: 77vh; */
  padding: 18px 18px;
  border-radius: 4px;
  border: 1px solid #c6c6c6;
  background: #fff;
}
.instruction {
  font-size: 14px;
  font-weight: 400;
}
</style>
