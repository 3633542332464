<template>
  <div class="mb-3 mr-3 iconDiv">
    <span class="prime-icon-outlet" @click="addDiolog()">
      <i class="pi pi-plus" />
      <v-tooltip activator="parent" location="bottom">Add</v-tooltip>
    </span>
  </div>

  <v-table>
    <thead>
      <tr class="tRow">
        <th class="text-left">
          Project
        </th>
        <th class="text-left">
          Account
        </th>
        <th class="text-left">
          Skill
        </th>
        <th class="text-left">
          Category
        </th>
        <th class="text-left">
          Experience
        </th>
        <th class="text-left">
          Actions
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        @click="rowActions('view', item)"
        v-for="item in skillData"
        :key="item"
      >
        <td>{{ item.project_name }}</td>
        <td>{{ item.accounts_name }}</td>
        <td>{{ item.risk_name }}</td>
        <td>{{ item.category_name }}</td>
        <td>{{ item.experience }}</td>
        <td>
          <v-icon @click="rowActions('view', item)">mdi-eye-outline</v-icon>
          <v-icon class="ml-1" @click.stop="rowActions('edit', item)"
            >mdi-file-edit-outline</v-icon
          >
        </td>
      </tr>
    </tbody>
  </v-table>
  <FilterDrawer ref="FilterDrawer" />
  <v-dialog persistent v-model="dialog" width="600px">
    <AddSkill
      :viewType="viewType"
      @closeDialog="setFalse()"
      @reload="getSkillData(this.userDetails.id)"
      :id="subCategoryId"
      ref="AddSubCategory"
    />
  </v-dialog>
</template>

<script>
import FilterDrawer from "@/components/FilterDrawer.vue";
import UserSkillService from "@/api/service/UserSkill/UserSkillService";
import AddSkill from "./AddSkill.vue";
import commonService from "@/common.service";
export default {
  components: {
    AddSkill,
    FilterDrawer,
  },
  data() {
    return {
      dialog: false,
      viewType: false,
      subCategoryId: null,
      skillData: [],
      userDetails: JSON.parse(localStorage.getItem("user_details")),
    };
  },
  created() {
    this.getWorkTimings();
    if (this.userDetails.id) {
      this.getSkillData(this.userDetails.id);
    }
  },
  methods: {
    async getSkillData(id) {
      let res = await UserSkillService.getUserSkill({ id: id });
      this.skillData = res;
    },
    setFalse() {
      this.dialog = false;
    },

    rowActions(type, event) {
      if (type === "view") {
        this.dialog = true;
        this.viewType = type;
        this.subCategoryId = event.id;
      }
      if (type === "edit") {
        this.dialog = true;
        this.viewType = type;
        this.subCategoryId = event.id;
      }
      if (type == "select") {
        this.dialog = true;
        this.viewType = "view";
        this.subCategoryId = event.id;
      }
    },

    async getWorkTimings() {},

    addDiolog() {
      this.dialog = true;
      this.viewType = false;
      this.subCategoryId = null;
    },

    addFilter() {
      this.$refs.FilterDrawer.dialog = true;
      this.$refs.FilterDrawer.size = commonService.largeDialog();
    },
  },
};
</script>

<style scoped>
.tRow {
  background-color: #16a9f623;
}
.iconDiv {
  display: flex;
  justify-content: end;
}
.v-table > .v-table__wrapper > table > tbody > tr > td,
.v-table > .v-table__wrapper > table > tbody > tr > th,
.v-table > .v-table__wrapper > table > tfoot > tr > td,
.v-table > .v-table__wrapper > table > tfoot > tr > th,
.v-table > .v-table__wrapper > table > thead > tr > td,
.v-table > .v-table__wrapper > table > thead > tr > th {
  padding: 3px 3px;
  transition: height cubic-bezier(0.4, 0, 0.2, 1);
}
.prime-icon-outlet {
  width: 30px;
  height: 30px;
  padding: 10px;
  color: #7b7b7b;
  font-weight: 800;
  border-radius: 4px;
  border: 0px solid #d1cece;
  cursor: pointer;
  margin-left: 3%;
}

.prime-icon-outlet:hover {
  background-color: #f3f9ff;
  color: #000000;
  font-weight: 800;
}
</style>
