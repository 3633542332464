<template>
  <v-layout>

    <table fixed-header class="table">
      <thead>
        <tr class="mainHead">
          <th class="heads" v-for="header in headers" :key="header.key">
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(workspace, workspaceIndex) in displayData" :key="workspaceIndex">
          <tr>
            <th class="fixedHeader1" :colspan="headers.length + 1" style="text-align: justify">
              <div class="my-3">
                <span class="headd">
                  {{ workspace.workSpaceName }}
                </span>
              </div>
            </th>
          </tr>
          <template v-for="(user, userIndex) in workspace.userData" :key="`user-${userIndex}`">
            <!-- {{ account }} -->
            <tr :class="user.userName == ''
              ? 'fixedheader'
              : user.pin == true
                ? 'fixedHeader3'
                : 'bodyRow'
              " :style="{
                fontWeight:
                  expandedRows.includes(userIndex) &&
                    workspaceIndex == selectedWorkSpace
                    ? 'bold'
                    : 'normal',
                background: user.userName ? ' #f3f3f3' : '#ffefef',
              }" class="hidden">
              <td>
                <v-icon v-if="!user.pin" @click="pinTheData(workspace, user)" size="large"
                  class="iconHover">mdi-pin-outline</v-icon>
                <v-icon class="iconHover" v-if="user.pin" @click="pinTheData(workspace, user)"
                  size="large">mdi-pin-off-outline</v-icon>
              </td>
              <td style="text-align: left">
                <span :style="{
                  fontWeight: user.userName ? 'normal' : 'bold',
                  color: user.userName ? 'black' : 'red',
                }">
                  <strong>{{
                    user.userName ? user.userName : "Un Assigned"
                  }}</strong>
                  <br />
                  <span v-if="user && user.userName">{{ user && user.doj ? `${user.doj}` : "No DOJ"
                    }}{{
                      user && user.role_name ? `-${user.role_name}` : "No Role"
                    }}{{
                      user && user.work_timing_name
                        ? `-${user.work_timing_name}`
                        : "Offline"
                    }}</span>
                </span>
              </td>
              <td v-for="header in headers2" :key="header.key" style="text-align: left">
                <!-- {{ header.key }} -->
                <span @click.stop="
                  getIssueData(header, user, workspace, null, 'outside')
                  " :style="{
                    fontWeight: user.userName ? 'normal' : 'bold',
                    color: user.userName ? 'black' : 'red',
                  }" class="cursorPointer">{{ user[header.key] }}</span>
              </td>
              <v-icon v-if="
                expandedRows.includes(userIndex) &&
                workspaceIndex == selectedWorkSpace
              " @click="toggleDetails(userIndex, workspaceIndex)"
                style="position: absolute; right: 40px; top: 15px">mdi-chevron-up-circle-outline</v-icon>
              <v-icon v-else @click="toggleDetails(userIndex, workspaceIndex)"
                style="position: absolute; right: 40px; top: 15px">mdi-chevron-down-circle-outline</v-icon>
            </tr>
            <template v-if="
              expandedRows.includes(userIndex) &&
              workspaceIndex == selectedWorkSpace
            ">
              <tr class="bodyRow extraRow" v-for="(account, index) in user.accountData" :key="index">
                <td v-for="header in exheaders" :key="header.key" :colspan="header.key === 'user' ? 2 : 1"
                  style="text-align: left">
                  <span v-if="header.label === 'User'" :style="{
                    fontWeight: account[header.key] ? 'normal' : 'bold',
                    color: account[header.key] ? 'black' : 'red',
                  }">
                    {{
                      account[header.key] ? account[header.key] : " Un Assigned"
                    }}
                  </span>
                  <span v-else @click.stop="
                    getIssueData(header, user, workspace, account, 'inside')
                    " class="cursorPointer">{{ account[header.key] }}</span>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>

    <v-navigation-drawer :width="600" v-model="drawer" style="margin-top: 70px" location="right" permanent>
      <workSpaceView :filter="filterData" :WorkSpaceName="WorkSpaceName" :issueData="issueData"
        @closedrawer="closeDrawer" />
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import workSpaceView from "./workSpaceView.vue";
import LiveBoardDataService from "./LiveBoardDataService";
export default {
  props: ["tableData", "allData", "shift"],
  components: { workSpaceView },
  data() {
    return {
      drawer: false,
      selectedWorkSpace: "",
      bckupTableData: null,
      pinData: null,
      expandedRows: [],
      expandedData: [],
      filterData: null,
      WorkSpaceName: null,
      displayData: [],
      headers: [],
      headers2: [],
      exheaders: [],
      issueData: [],
    };
  },
  created() {
      
    let $pinData = localStorage.getItem("EMP_PIN_DATA");
    this.pinData = $pinData ? JSON.parse($pinData) : [];
    this.assignTheData();
    this.headers = LiveBoardDataService.getHeaderData("employee", "headers");
    this.headers2 = LiveBoardDataService.getHeaderData("employee", "headers2");
    this.exheaders = LiveBoardDataService.getHeaderData(
      "employee",
      "exheaders"
    );
    if (this.shift) {
        this.$emit('ShiftData')
        }
  },
  watch: {
    shift() {
      if (this.shift) {
      this.assignTheData();
        this.$emit('ShiftData')
      }
    },
    fixedIndex() {
      this.changeWorkSpaceIndex()
    },
  },
  methods: {
    closeDrawer($drawer) {
      this.drawer = $drawer;
    },
    assignTheData() {
      this.displayData = JSON.parse(JSON.stringify(this.tableData));
      if (this.tableData.length > 0) {
        this.getPinTheUserData();
      }
    },
    pinTheData($workspace, $user) {
      let index = this.pinData.findIndex(
        (k) => k.workSpaceId == $workspace.workSpaceId
      );
      if (index > -1) {
        let userIndex = this.pinData[index].user.findIndex(
          (u) => u == $user.userId
        );
        if (userIndex > -1) {
          this.pinData[index].user.splice(userIndex, 1);
        } else {
          this.pinData[index].user.push($user.userId);
        }
      } else {
        let obj = {};
        obj.workSpaceId = $workspace.workSpaceId;
        obj.user = [$user.userId];
        this.pinData.push(obj);
      }
      localStorage.setItem("EMP_PIN_DATA", JSON.stringify(this.pinData));
      this.getPinTheUserData();
    },
    getPinTheUserData() {
      this.displayData = JSON.parse(JSON.stringify(this.tableData));
      for (let item of this.displayData) {
        let index = this.pinData.findIndex(
          (k) => k.workSpaceId == item.workSpaceId
        );
        if (index > -1) {
          item.userData = this.getSeparateUser(
            item.userData,
            this.pinData[index].user
          );
        }
      }
    },
    getSeparateUser(userData, userId) {
      let pinUser = [];
      let unPinUser = [];
      let unDefined = [];
      for (let user of userData) {
        if (userId.includes(user.userId)) {
          user.pin = true;
          pinUser.push(user);
        } else if (user.userName == "") {
          unDefined.push(user);
        } else {
          user.pin = false;
          unPinUser.push(user);
        }
      }
      let opt1 = pinUser.concat(unPinUser);
      let opt = unDefined.concat(opt1);
      return opt;
    },
    async getIssueData($header, $user, $workspace, $account, type) {
      this.issueData = await LiveBoardDataService.getIssueData2(
        $header,
        $user,
        $workspace,
        $account,
        type == 'inside' && $user && ($user.userId || $user.user_id) ? false : type == 'outside' && $user && ($user.userId || $user.user_id) ? false : true
      );
      this.drawer = true;
    },
    async toggleDetails(accountIndex, workspaceIndex) {
      if (this.expandedRows.includes(accountIndex)) {
        let index = this.expandedRows.findIndex((k) => k == accountIndex);
        this.expandedRows.splice(index, 1);
      } else {
        this.expandedRows.push(accountIndex);
        this.selectedWorkSpace = workspaceIndex;
      }
    },
    combineAt(n, a, b) {
      return a.reduce((ac, cv, ci) => {
        if (n === ci) {
          // Insert elements from array 'b' at position 'n'
          ac.push(...b, cv);
        } else {
          // Insert element from array 'a'
          ac.push(cv);
        }
        return ac;
      }, []);
    },

    merge(a1, a2, i) {
      return [...a1.slice(0, i), ...a2, ...a1.slice(i)];
    },
  },
};
</script>

<style scoped>
.issue-view-container {
  box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
}
.table {
  border-spacing: 0 5px;
  width: 100%;
  overflow: auto;
  padding-bottom: 5%;
}

thead {
  position: sticky;
  top: 0;
  /* background: #fff; */
  z-index: 1;
}

thead>tr {
  height: 40px;
}

thead>tr>.heads {
  border-bottom: 2px solid #0cb99a;
  border-top: 2px solid #0cb99a;
}

thead>tr>.heads:nth-child(1) {
  border-left: 2px solid #0cb99a;
  border-radius: 4px 0 0 4px;
}

thead>tr>.heads:last-child {
  border-right: 2px solid #0cb99a;
  border-radius: 0 4px 4px 0;
}

tbody>tr>td:nth-child(1) {
  border-radius: 4px 0 0 4px;
}

tbody>tr>td:last-child {
  border-radius: 0 4px 4px 0;
}

tbody>tr {
  padding: 50px 0;
  height: 50px;
  text-align: center;
}

.mainHead {
  background-color: #edf5f4;
  border: 2px solid #0cb99a;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 15px;
  text-align: center;
  text-align: left;
}

.headd {
  background-color: #fdf4f4;
  border: 1.5px solid #ffa1a1;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.bodyRow {
  position: relative;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
}

.extraRow {
  background-color: #fef7e5;
}
.SearchInput {
  border-left: 1px solid #0cb99a;
  border-top: 1px solid #0cb99a;
  border-bottom: 1px solid #0cb99a;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
.iconHover {
  visibility: hidden;
}
.hidden:hover .v-icon {
  visibility: visible;
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointer:hover {
  text-decoration: underline;
}
.fixedheader {
  position: sticky;
  top: 90px;
  z-index: 2;
  background: #fff;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
}
.fixedHeader1 {
  position: sticky;
  top: 40px;
  z-index: 2;
  background: #fff;
}
.fixedHeader3 {
  position: sticky;
  top: 140.5px;
  z-index: 2;
  background: #fff;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
}
</style>
