<template>
  <v-card width="600px"  style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form" v-model="value">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b> WorkSpace Setting</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>


      <v-card-text>
        <v-row class="mt-3 py-0 px-2">

          <v-col cols="12" class="px-0 py-0" style="display: grid">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" class="py-0 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Work Space
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <!-- <AutoComplete :items="workData" :placeholder="'work space'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="formData.work_space_id" :rules="workRule" @update:modelValue="workSpaceChanged" /> -->
              
            <AutoComplete :items="workData" :placeholder="'work space'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="formData.work_space_id" @update:modelValue="workSpaceChanged($event)" :rules="workRule" />
          </v-col>
        </v-row>


        <v-row class="px-2 py-0">
          <v-col cols="6" class="py-0 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Account
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="accountData" :placeholder="'Account'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="formData.account_id" :rules="accountRule" />
          </v-col>




          <v-col cols="6" class="py-0 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Project
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="projectData" :placeholder="'Project'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="formData.project_id" :rules="projectRule" @update:modelValue="selectProjects($event)"  />
          </v-col>

        </v-row>

        <v-row class=" py-0 px-2">

          <v-col cols="6" class="py-0 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Task Type
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="issueTypeData" :placeholder="'Task Type'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="defaultData.issue_type_id" :rules="issueTypeRule" />
          </v-col>
          <v-col cols="6" class="py-0 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Category
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="categoryData" :placeholder="'Category'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="defaultData.category_id" @update:modelValue="selectCategory()" :rules="categoryRule" />
          </v-col>

        </v-row>

        <v-row class="py-0 px-2">


          <v-col cols="6" class="py-0 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Category
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="subCategoryData" :placeholder="'Sub Category'" :item-title="'name'"
              :disabled="showReadOnly" :item-value="'id'" v-model="defaultData.sub_category_id"
              :rules="subCategoryRule" />
          </v-col>

          <v-col cols="6" class="py-0 dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Sub Task
              <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :items="taskData" :placeholder="'Sub Task'" :item-title="'name'" :disabled="showReadOnly"
              :item-value="'id'" v-model="defaultData.task_id" :rules="taskRule" />
          </v-col>

        </v-row>

        <v-row 
         justify="end" class="pr-0 mb-2 dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="close()">Cancel</v-btn>
          <v-btn class=" mr-0 save" type="submit">Save</v-btn>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import AccountService from "@/api/service/Account/AccountService";
import ProjectService from "@/api/service/Project/ProjectService";
import TaskService from "@/api/service/Task/TaskService";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import CategoryService from "@/api/service/Category/CategoryService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
//  import TextArea from "@/components/ui/TextArea.vue";
import WsSettingService from "@/api/service/WsSetting/WsSettingService";
import commonService from "@/common.service";

export default {
  props: ["id", "viewType"],
  components: {
    // TextFieldComponent,
    AutoComplete,
    // TextArea,
  },

  data() {
    return {
      workRule: [(value) => !!value || "Work Space is Required"],
      projectRule: [(value) => !!value || "Project is Required"],
      accountRule: [(value) => !!value || "Account is Required"],
      issueTypeRule: [(value) => !!value || "IssueType is Required"],
      categoryRule: [(value) => !!value || "Category is Required"],
      subCategoryRule: [(value) => value || "Sub-Category is Required"],
      taskRule: [(value) => !!value || "Task is Required"],
      dialog: false,
      add: false,
      type: "null",
      showReadOnly: false,
      // id:null,
      size: {},
      formData: {},
      defaultData: {
        category_id: null,
        sub_category_id: null,
        issue_type_id: null,
        task_id: null
      },
      projectData: [],
      accountData: [],
      workData: [],
      taskData: [],
      categoryData: [],
      subCategoryData: [],
      issueTypeData: [],
      // workSpaceId:""
    };
  },


  created() {
    // let workSpaceId = localStorage.getItem('workSpaceId')
    this.getWorkSpace();
    // this.getCategoryData();
    this.getIssueTypeData();
    this.getTaskData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },
  methods: {
    close() {
      this.$emit("closeDialog");
    },
    selectCategory() {
      if (this.defaultData.category_id) {
        this.defaultData.sub_category_id=null
        this.getSubCategoryData();
      }
    },
    selectProjects($event){
      if($event){
      this.categoryData =[]
      this.subCategoryData =[]
      this.defaultData.category_id =null
      this.defaultData.sub_category_id =null
      let selectProjectData = this.projectData.filter(k=>k.id==this.formData.project_id)
      this.getCategoryData(selectProjectData && selectProjectData[0] && selectProjectData[0].category_id?selectProjectData[0].category_id:null)
      }
    },
    async getById(id) {
      let res = await WsSettingService.findOne({ id: id });
      this.formData = res[0];
      console.log(this.formData,"show formData")
      // this.workSpaceChanged(this.formData.work_space_id)
      
      await this.getProjectData(this.formData.work_space_id);
      this.getAccounts(this.formData.work_space_id)
      let selectProjectData = this.projectData.filter(k=>k.id==this.formData.project_id)
      this.getCategoryData(selectProjectData && selectProjectData[0] && selectProjectData[0].category_id?selectProjectData[0].category_id:null)
      this.defaultData = this.formData && this.formData.default_name ? JSON.parse(this.formData.default_name) : {}
      if(this.defaultData && this.defaultData.sub_category_id){
        this.getSubCategoryData();
      }
    },
    async getAccounts(id) {
      let res = await AccountService.getAccountByIssue({work_space_id:id});
      this.accountData = res.length > 0 ? await commonService.getSort(res,'name','asc') : [];
    },
    async getWorkSpace() {
      let res = await WorkSpaceService.findAll();
      this.workData = res.length > 0 ? await commonService.getSort(res,'name','asc') : [];
    },
    async getProjectData(id) {
      // alert(id)
      let res = await ProjectService.getWorkSpaceProject({work_space_id:id});
      this.projectData = res.length > 0 ? await commonService.getSort(res,'name','asc') : [];
    },
    async workSpaceChanged($value){
      // alert($value)
      if($value){
      //   alert("ji")
      this.formData.account_id =null
      this.formData.project_id =null
      this.defaultData.category_id =null
      this.defaultData.sub_category_id =null
      this.categoryData = []
      this.subCategoryData = []
      this.getProjectData($value);
      this.getAccounts($value)
      }
      // this.formData.account_id=null
    },
    async getTaskData() {
      let res = await TaskService.findAll({task_type:'production'});
      this.taskData = res.length > 0 ? await commonService.getSort(res,'name','asc') : [];
    },
    async getCategoryData($value) {
      if($value){
      let res = await CategoryService.categoryById({project_id:this.selectProjectData.id});
      this.categoryData = res.length > 0 ? await commonService.getSort(res,'name','asc') : [];
      }
    },
    async getSubCategoryData() {
      let res = await SubCategoryService.findAll({
        category_id: this.defaultData.category_id,
      });
      this.subCategoryData = res.length > 0 ? await commonService.getSort(res,'name','asc') : [];
    },
    async getIssueTypeData() {
      let res = await IssueTypeService.findAll();
      this.issueTypeData = res.length > 0 ? await commonService.getSort(res,'name','asc') : [];
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid ) {
        console.log(validation.valid)
      }
      else{
        if (this.formData.id) {
          this.formData.default_name = JSON.stringify(this.defaultData)
          await WsSettingService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          try{
          this.formData.default_name = JSON.stringify(this.defaultData)
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
          await WsSettingService.create(this.formData);
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  }
}
</script>
  