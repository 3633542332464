<template>
  <div
    class="wrapper-form pa-7"
    v-if="
      formBuilder && formBuilder.sections && formBuilder.sections.length > 0
    "
  >
    <template
      v-for="($formBuilderData, sectionIndex) in formBuilderData"
      :key="sectionIndex"
    >
      <div class="title mb-4">
        {{ $formBuilderData.title }}
        <button
          v-if="addRowBtn"
          @click="addNewRow(sectionIndex)"
          class="menuBtn mr-2"
          type="button"
        >
          <i class="pi pi-plus-circle gap show-cursor add-btn"></i>
        </button>
      </div>
      <div class="table-wrapper">
        <table class="">
          <thead>
            <tr>
              <template
                v-for="(form, colIndex) in $formBuilderData.columns"
                :key="colIndex"
              >
                <th>{{ form.label }}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in $formBuilderData.rowsData"
              :key="rowIndex"
            >
              <template v-for="(form, i) in $formBuilderData.columns" :key="i">
                <td>
                  <TextFieldComponent
                    v-model="row[form.column_name]"
                    :showReadOnly="form.disabled"
                    v-if="
                      form.field_type == 'text' ||
                        form.field_type == 'password' ||
                        form.field_type == 'textfield' ||
                        form.field_type == 'date'
                    "
                    :placeholder="form.label"
                    :type="form.field_type"
                    :required="form.required"
                    density="compact"
                    :maxlength="form.maxLength"
                    :minLenght="form.minLength"
                    @input="formDataChange"
                  />

                  <!-- AutoComplete  -->

                  <AutoComplete
                    :items="form && form.options ? form.options : []"
                    v-model="row[form.column_name]"
                    :showReadOnly="form.disabled"
                    v-if="
                      form.field_type === 'selection' ||
                        form.field_type == 'autocomplete'
                    "
                    :placeholder="form.label"
                    density="compact"
                    variant="outlined"
                    :item-title="form.text"
                    :item-value="form.value"
                    :required="form.required"
                    :multiple="form.multiple"
                    @update:modelValue="formDataChange"
                  />
                  <!-- TextArea  -->

                  <TextArea
                    v-model="row[form.column_name]"
                    :showReadOnly="form.disabled"
                    v-if="form.field_type === 'textArea'"
                    :placeholder="form.label"
                    density="compact"
                    variant="outlined"
                    :maxlength="form.maxLength"
                    :minLenght="form.minLength"
                    @input="formDataChange"
                  />

                  <!-- CheckBox -->

                  <CheckBox
                    v-model="row[form.column_name]"
                    :showReadOnly="form.disabled"
                    v-if="form.field_type === 'checkBox'"
                    :placeholder="form.label"
                    :color="'primary'"
                    @change="$emit('formDataChange', formData)"
                  />

                  <!-- Select -->

                  <Select
                    v-model="row[form.column_name]"
                    :showReadOnly="form.disabled"
                    v-if="form.field_type === 'select'"
                    :placeholder="form.label"
                    density="compact"
                    variant="outlined"
                    :items="form.options"
                    :item-title="'name'"
                    :item-value="'id'"
                    :maxlength="form.maxLength"
                    :minLenght="form.minLength"
                    @update:modelValue="formDataChange"
                  />

                  <div
                    v-if="
                      form.field_type === 'datepicker' ||
                        form.field_type === 'date'
                    "
                  >
                    <DatePicker
                      :showReadOnly="form.disabled"
                      :placeholder="form.label"
                      @selectDate1="selectDate($event, form.column_name)"
                      @selectDate2="selectDate($event, form.column_name)"
                      :data="
                        row[form.column_name] == '@daily' ||
                        row[form.column_name] == '@weekly' ||
                        row[form.column_name] == '@monthly'
                          ? row[form.column_name]
                          : null
                      "
                      :data2="
                        row[form.column_name] ? row[form.column_name] : null
                      "
                    ></DatePicker>
                  </div>
                  <Switch
                    v-model="row[form.column_name]"
                    :showReadOnly="form.disabled"
                    v-if="form.field_type === 'switch'"
                    :placeholder="form.label"
                    :color="'primary'"
                    @change="formDataChange"
                  />

                  <!-- RadioButton -->

                  <div v-if="form.field_type === 'radioButton'">
                    <RadioButton
                      v-for="(option, optionIndex) in form.options"
                      :key="optionIndex"
                      v-model="row[form.column_name]"
                      :showReadOnly="form.disabled"
                      :value="option.id"
                      :label="option.name"
                      :color="'primary'"
                      @change="formDataChange"
                    />
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
  <div v-else>
    no data
  </div>
</template>

<script>
import {
  Switch,
  Select,
  CheckBox,
  AutoComplete,
  TextFieldComponent,
  RadioButton,
  TextArea,
  DatePicker,
} from "@tiburadev/vue-comp";

export default {
  components: {
    Switch,
    Select,
    CheckBox,
    AutoComplete,
    TextFieldComponent,
    RadioButton,
    TextArea,
    DatePicker,
  },
  props: ["formBuilder", "data", "showAdd", "selectedPreview"],
  data() {
    return {
      formBuilderData: [],
      addRowBtn: false,
    };
  },

  watch: {
    data(newVal) {
      this.formBuilderData = this.formBuilder.sections.map((section, index) => {
        let rowsData = [];
        if (newVal && newVal.length > 0) {
          rowsData = newVal[index] ? newVal[index] : [];
        }
        return {
          ...section,
          rowsData,
        };
      });

      console.log(this.formBuilderData, "kashif");
    },
    showAdd(newVal) {
      this.addRowBtn = newVal;
    },
  },

  created() {
    if (this.formBuilder) {
      if (this.selectedPreview) {
        this.removeLastRow(this.formBuilderData);
      }
      this.formBuilderData = this.formBuilder.sections.map((section, index) => {
        let rowsData = [];

        if (this.data && this.data.length > 0) {
          rowsData = this.data[index] ? this.data[index] : [];
        }
        return {
          ...section,
          rowsData,
        };
      });
    }

    if (!this.selectedPreview) {
      this.addNewRow();
    }
  },

  methods: {
    removeLastRow(data) {
      this.formBuilderData.forEach((section) => {
        if (section.rowsData && section.rowsData.length > 0) {
          section.rowsData.pop();
        }
      });

      this.formBuilderData = data;
    },

    addNewRow(sectionIndex) {
      const addRowToSection = (section) => {
        const newRow = {};
        section.columns.forEach((form) => {
          newRow[form.column_name] = "";
        });
        section.rowsData.push(newRow);
      };

      if (sectionIndex !== null && sectionIndex !== undefined) {
        const section = this.formBuilderData[sectionIndex];
        if (section) {
          const lastRow = section.rowsData[section.rowsData.length - 1];
          if (!lastRow) {
            addRowToSection(section);
          }
          const isLastRowEmpty = section.columns.some(
            (form) => !lastRow[form.column_name]
          );

          if (isLastRowEmpty) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Please fill out the current row before adding a new one.",
            });
            return;
          }
          addRowToSection(section);
        }
      } else {
        this.formBuilderData.forEach((section) => {
          addRowToSection(section);
        });
      }
    },

    formDataChange() {
      this.$emit(
        "detailChanged",
        this.formBuilderData.map((s) => s.rowsData)
      );
    },
  },
};
</script>

<style scoped>
/* .wrapper-form {
  background: #e9eaeb;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
} */

.title {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
}
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 10px;
}

table {
  width: 100%;
  font-size: 14px;
  background-color: #fafafb !important;
  border-collapse: collapse;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th,
table tr td {
  border-bottom: 1px solid #bbb;
  padding: 5px;
}
table tr th:last-child,
table tr td:last-child {
  border-right: 1px solid #bbb;
}

table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #bbb;
}
table tr th {
  background: #eee;
  text-align: left;
  border-top: solid 1px #bbb;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
thead,
th {
  background-color: #dcecfc !important;
  font-weight: 600;
  padding: 8px;
  text-align: left;
}

tr {
  border-bottom: 1px solid #d1d1d1;
}
tbody tr:last-child {
  border-bottom: none;
}

tbody td {
  font-weight: 400;
  padding: 8px;
  text-align: left;
}

tfoot td {
  font-weight: 700;
  text-align: center;
}

>>> .v-input__details {
  display: contents;
}
</style>
