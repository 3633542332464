<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          Menu
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close"
            class="x-closeIcon"></v-btn></v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <v-col cols="12">
            <v-switch label="Add Child Menu" color="primary"  :disabled="showReadOnly" @change="changeParentToChild()" v-model="parent_menu"></v-switch>

          </v-col>
          <v-col cols="4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model="formData.code"
              :disabled="showReadOnly" @keydown="prevent($event)" @keyup="checkCode()"/>
              <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <v-col cols="8" class="pl-4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name</v-label>
            <TextFieldComponent density="compact" placeholder="Name" :rules="nameRule" v-model="formData.name"
              :disabled="showReadOnly" />
          </v-col>
          <v-col cols="12" v-if="parent_menu">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Parent</v-label>
            <AutoComplete :items="parentMenuData" item-title="name" item-value="id" :disabled="showReadOnly"
              v-model="formData.parent_id" :placeholder="'Parent Menu'" />
          </v-col>

          <v-col cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Icon
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <TextFieldComponent density="compact" placeholder="Icon" v-model="formData.icon" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">
              Sequence
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <TextFieldComponent density="compact" placeholder="Sequence" v-model="formData.sequence_num"
              :disabled="showReadOnly" />
          </v-col>

          <v-col cols="6" class="pl-4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Route
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <TextFieldComponent density="compact" placeholder="Route" v-model="formData.route" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="12" v-if="parent_menu">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Parent</v-label>
            {{ activityId }}
            <AutoComplete :items="activities" item-title="name" item-value="id" :disabled="showReadOnly"
              v-model="activityId" :placeholder="'Select Activty'" />
            <v-chip closable @click:close="removeActivitySelection(index)" v-for="(activity,index) in activitySelected" v-bind:key="activity">
              {{ activity.name }} 
            </v-chip>
          </v-col>
          <!-- <v-col cols="4" class="pl-4">
            <v-label
              class="pb-1"
              style="font-size: 14px; color: #272727; font-weight: 600"
              >Activities
              <span
                v-if="!showReadOnly"
                style="color: #cf0707; padding-left: 2px; font-weight: 700"
              ></span
            ></v-label>
            <TextFieldComponent
              density="compact"
              placeholder="Activities"
              v-model="formData.activities"
              :disabled="showReadOnly"
            />
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import MenuService from "@/api/service/Menu/MenuService";
import ActivityService  from "@/api/service/Activity/ActivityService";
export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete
  },

  data() {
    return {
      activities:[],
      showReadOnly: false,
      dialog: false,
      activityId:null,
      codeError:false,
      type: null,
      activitySelected:[],
      parentMenuData: [],
      parent_menu: false,
      codeRule: [(value) => !!value || "Code is Required"],
      nameRule: [(value) => !!value || "Name ID is Required"],
      formData: {
        code: null,
        parent_id: null,
        sequence_num: null,
        icon: null,
        route: null,
        activities: null,
      },
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
    activityId() {
      if(this.activityId) {
        let $index = this.activitySelected.findIndex((obj)=>{
          return obj.id == this.activityId
        })
        if($index<=0) {
          let $index2 = this.activities.findIndex((obj)=>{
            return obj.id == this.activityId
          })
          if($index2>=0) {
            this.activitySelected.push(this.activities[$index2])
            this.activities.splice($index2,1)
          }
        }
        this.activityId=null
      }
     }
  },
  created() {
    this.getActivities();
    this.getParentData()
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },
  methods: {
    async getActivities() {
      this.activities = await ActivityService.findAll({"active":1,"deleted":0})
    },
    changeParentToChild() {
      this.formData.parent_id = !this.formData.parent_menu ? 0 : this.formData.parent_id
    },

    checkCode() {
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        MenuService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);
          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },

    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },

    removeActivitySelection($index) {
      //alert('here'+$index)
      if($index>=0) {
        let $spliced = this.activitySelected.splice($index,1)
        //alert(JSON.stringify($spliced))
        this.activities.push($spliced[0])
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    async getParentData() {
      try {
        let res = await MenuService.getParentMenuData()
        this.parentMenuData = res
      }
      catch (e) {
        this.parentMenuData = []
        console.log(e)
      }
    },
    async getById(id) {
      if (id) {
        let res = await MenuService.findAll({"id":id});
        this.formData = res[0];
        let parent_id =this.formData.parent_id
        this.parent_menu = parent_id == 0 ? false : true
        this.formData.activities =JSON.parse(this.formData.activities)
        let $index=0
        while($index<this.activities.length) {
          if(this.formData.activities.indexOf(this.activities[$index].id)>=0) {
            let $spliced = this.activities.splice($index,1)
            this.activitySelected.push($spliced[0])
            $index--;
          }
          $index++
        }
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
      this.formData.activities = this.activitySelected.map((element) =>{
          return element.id
      })
      this.formData.activities = JSON.stringify(this.formData.activities)
      if (this.formData.id) {
        
        this.formData.activity=JSON.stringify()
        await MenuService.update(this.formData);
        this.dialog = false;
        this.close();
        this.$emit("reload", true);
        this.formData = {};
      } else {
        this.formData.parent_id = this.formData.parent_id ? this.formData.parent_id : 0
        await MenuService.create(this.formData);
        this.dialog = false;
        this.close();
        this.$emit("reload", true);
        this.formData = {};
      }
    }
    },
  },
};
</script>
  