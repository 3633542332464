<template>
  <v-card flat>
      <v-card-title>
          
      </v-card-title>
      <div class="ml-2">
          <RequestTable />
      </div>
  </v-card>
</template>
<script>
import RequestTable from './RequestTable.vue'
export default {
  components:{
      RequestTable
  },
  data(){
      return{
          data:[]
      }
  },

}
</script>
<style scoped>

</style>