<template>
    <v-layout>
        <v-navigation-drawer v-model="drawer" width="700" location="right" temporary>
            <v-card>
                <v-card-item>
                    <v-card-title>
                        <h3 class="pa-2" style="font-weight:normal">Filter</h3>
                        <v-spacer />
                        <v-btn @click="applyFilter()" class="apply-filter" variant="outlined">Apply</v-btn>
                        <v-btn @click="closeFilter()" elevation="0" density="comfortable" icon="mdi-close"
                            class="x-closeIcon"></v-btn>
                    </v-card-title>
                </v-card-item>
                <v-divider class="border-opacity-50" />
                <v-card-text>
                    <v-row no-gutters dense class="mb-3">
                        <v-col cols="4">
                            <v-form @submit.prevent="submit" ref="form">
                                <v-row>
                                    <v-col cols="12">
                                        <span class="ma-2 mb-2"><b>Save this Filter</b></span>

                                        <TextFieldComponent placeholder="Name" v-model="filterName"
                                            :disabled="showReadOnly" />
                                    </v-col>
                                    <v-col cols-12>
                                        <v-btn type="submit" class="save-filter" variant="outlined">Save</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <v-divider style="margin-left: 15px;margin-top: -16px;margin-bottom: -11px;"
                            class="border-opacity-50" vertical />
                        <v-col cols="7" class="ml-5">
                            <v-row v-for="item in CustomFilterData" :key="item">
                                <v-col cols="12" v-if="item.field_type == 'input'">
                                    <TextFieldComponent v-model="formData[item.column_name]" :placeholder="item.label">
                                    </TextFieldComponent>
                                </v-col>
                                <v-col cols="12" v-if="item && item.field_type && item.field_type.toLowerCase() == 'autocomplete'">
                                    
                                <AutoComplete v-if="item.source_data && item.source_data.length > 1"  :items="item.source_data" v-model="formData[item.column_name]" 
                                    :placeholder="item.label" density="compact" variant="outlined" :item-title="item.text" :item-value="item.value"
                                    :required="item.required" :multiple="true"  />
            <!-- <AutoComplete v-if="item.source_data && item.source_data.length > 1" :items="item.source_data" :item-title="item.text" :item-value="item.value" density="compact"
                :placeholder="item.label"  :v-model="item['defaults']" :multiple="true"
              @update:modelValue="selectRepot" :disabled="showReadOnly" /> -->
                                    <!-- <v-autocomplete v-if="item.source_data && item.source_data.length > 1"
                                        v-model="item.defaults" density="compact" :label="item.label" variant="outlined"
                                        :items="item.source_data" :item-title="item.text" :item-value="item.value"
                                        :placeholder="item.label"></v-autocomplete> -->
                                </v-col>
                                <v-col cols="12" v-if="item.field_type == 'enum'">
                                    <v-autocomplete v-if="item.source_data && item.source_data.length > 1"
                                        v-model="item.defaults" density="compact" :label="item.label" variant="outlined"
                                        :items="item.source_data" :item-title="item.text" :item-value="item.value"
                                        :placeholder="item.label"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" v-if="item.field_type == 'status'">
                                    <v-autocomplete v-model="item.defaults" density="compact" label="Status"
                                        variant="outlined" :items="statusData" item-title="name" item-value="id"
                                        placeholder="Status"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" v-if="item.field_type == 'textArea'">
                                    <v-textarea v-model="item.defaults" density="compact" class="elevation-0" variant="solo"
                                        :label="item.label"></v-textarea>
                                </v-col>
                                <v-col cols="12" v-if="item.field_type == 'date'">
                                    <span class="p-float-label">
                                        <Calendar style="width: 100%;" :inputId="item.label" selectionMode="range"
                                            :manualInput="false"
                                            :panelStyle="{ width: '400px', zIndex: '9999999999', transformOrigin: 'center bottom', top: '170px', borderRadius: '10px' }"
                                            panelClass="calendar-container" v-model="item.defaults">
                                        </Calendar>
                                        <label :for="item.label">{{ item.label }}</label>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="border-opacity-50 mt-0" />
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
    </v-layout>
</template>
<script>
import listModelDetailService from '@/api/service/ListModelDetail/ListModelDetailService.js'
import listModelService from '@/api/service/ListModel/ListModelService.js'
import listModelFilterService from '@/api/service/ListModelFilter/ListModelFilterService'
import listModelFilterDetailService from '@/api/service/ListModelFilterDetail/ListModelFilterDetailService'
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import lodash from "lodash";
import Calendar from 'primevue/calendar';
import commonService from '@/api/service/Common/CommonService'
import enumService from '@/api/service/Enum/EnumService'
import AutoComplete from "@/components/ui/AutoComplete.vue";



export default {
    props: ['model', 'drawerStatus'],
    components: {
        Calendar,
        TextFieldComponent,
        AutoComplete
    },
    data() {
        return {
            CustomFilterData: [],
            filterName: '',
            modelName: '',
            modelId: null,
            existFilters: [],
            formData:{},
            nameRule: [(value) => !!value || "Name is Required."],
            drawer: false,
            statusData: [
                { id: 0, name: 'InActive' },
                { id: 1, name: 'Active' }
            ]
        }
    },
    created() {
        this.drawer = this.drawerStatus
        if (this.model) {
            this.modelName = this.model
            this.getListModelDetails()
        }
    },
    methods: {
        async getListModelDetails() {
            let $model = await listModelService.findAll('model:' + this.modelName);
            this.modelId = $model.length > 0 ? $model[0].id : 0;
            if (this.modelId > 0) {
                //this.getExistFilters(this.modelId);
                let $modelDetails = await listModelDetailService.findAll('modelId:' + this.modelId);
                $modelDetails = lodash.orderBy($modelDetails, ['sequence'], ['asc'])
                this.getDropdownData($modelDetails)
            }
        },
        async getExistFilters($id) {
            let $exist = await listModelFilterService.findAll({ modelId: $id });
            this.existFilters = $exist;
        },
        closeFilter() {
            this.drawer = false
            this.$emit('closeFilter')
        },
        async getDropdownData($data) {
            let filterData = []
            for (let i = 0; i < $data.length; i++) {
                if ($data[i].filter_required == 1) {
                    let str = $data[i].label
                    $data[i].label = str.charAt(0).toUpperCase() + str.slice(1);
                    if ($data[i] && $data[i].field_type && $data[i].field_type.toLowerCase() == "autocomplete") {
                        $data[i].source_data = await this.getValues($data[i].source, $data[i].column_name);
                        if ($data[i].source_data.length == 1) {
                            $data[i].defaults = $data[i].source_data[0][$data[i].value];
                        }
                    } else if ($data[i].field_type == "enum") {
                        $data[i].source_data = await this.getEnumValues($data[i].source);
                    }
                    filterData.push($data[i])
                }
            }
            this.CustomFilterData = JSON.parse(JSON.stringify(filterData));
            console.log(this.CustomFilterData,"oooooooooooooiiii")
        },
        async getValues($source) {
            let res = await commonService.findAll({ tableName: $source })
            return res ? res : [];
        },
        async getEnumValues(source) {
            let res = await enumService.findAllDomain({ domain: source })
            return res ? res : [];
        },
        async applyFilter() {
            this.drawer = false
            let $obj = {}
            let $keys =Object.keys(this.formData)
            if($keys && $keys.length>0){
                 for(let i=0;i<$keys.length;i++){
                   if( this.formData[$keys[i]] || this.formData[$keys[i]].length>0){
                        $obj[$keys[i]]=this.formData[$keys[i]]
                    }
                }
            }
            this.$emit('filterClosed', $obj)
        },
        async submit() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid) {
                console.log("Not valid")
            }
            else {
                let $data = {};
                $data.name = this.filterName; //this.filterName
                $data.modelId = this.modelId;
                let $filter = await listModelFilterService.create($data);
                this.CustomFilterData.forEach(async element => {
                    let $obj = {};
                    $obj.modelId = this.modelId;
                    $obj.columnName = element.column_name;
                    $obj.defaults = element.defaults;
                    $obj.modelFilterId = $filter.id;
                    await listModelFilterDetailService.create($obj);
                })
                this.applyFilter()
                // this.getExistFilters(this.modelId)
            }
        }
    }
}
</script>
<style scoped>
.apply-filter {
    font-size: 15px;
    position: absolute;
    background: #3C5AAA;
    color: white;
    top: 10px;
    right: 60px;
    cursor: pointer;
}

.save-filter {
    font-size: 15px;
    color: white;
    width: 100%;
    text-align: center;
    background: #3C5AAA;

}
</style>