<template>
    <SkeletonForCard v-if="loading" />
  
    <V-card density="compact" flat height="90vh" v-else>
      <CommonBoardHeader 
          :boardName="'Exception Reports'" 
          @search-data="getSearch" 
          @applyChange="applyChange" 
          @export-data="exportFile" 
          @show-zero-issue="getSwitchData"
          :existFilterData="existFilterData"
      />
        <v-card-text>
        <div style="display: flex; width: 100%; gap: 1%">
          <div
            v-if="false"
            style="
              border: 1px solid #c6c6c6c6;
              border-radius: 8px;
              overflow: hidden;
              height: 80vh;
              width: 20%;
            "
          >
            <DashboardFilter />
          </div>
          <div
            style="width: 79%; height: 80vh; overflow: auto"
            :style="{ width: false ? '79%' : '100%' }"
          >
            <LiveTable
              :tableData="tableData"
              :allData="ProductionData"
              @printData="addData"
            />
          </div>
        </div>
      </v-card-text>
    </V-card>
  </template>
  
  <script>
  import LiveTable from "./LiveTable.vue";
  import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
  import ExportService from "@/ExportService";
  import commonService from "@/common.service";
  import IssueService from "@/api/service/Issue/IssueService";
  import CommonBoardHeader from "./CommonBoardHeader.vue";
  import SkeletonForCard from "../SkeletonForCard.vue";
  import DashboardService from '@/api/service/Dashboard/DashboardService'

  
  export default {
    components: {
      LiveTable,
      CommonBoardHeader,
      SkeletonForCard
    },
  
    data() {
      return {
        existFilterData:null,
        menu: false,
        loading: true,
        downloadMenu: ["Excel", "Pdf", "Json"],
        ProductionData: [],
        search: null,
        searchClicked: false,
        tableData: [],
        finalExportData: [],
        backupTableData: [],
        isDrawer: false,
        switchBtn: "OFF",
        workTimingId: null,
      };
    },
  
    async created() {
      await this.getBoardData();
      this.finalExportData = this.tableData;
      
    },
    computed: {
      isDrawerOpen() {
        return this.$store.getters.getIssueViewDrawer;
      },
      isIssueChanged() {
        return this.$store.getters.getDefaultIssueInView;
      },
    },
    watch: {
      isIssueChanged() {
        this.showIssueView = true;
      },
    },
    methods: {
      
    async getBoardData(){
      alert("hi")
      let res = await DashboardService.getExceptionData()
      this.tableData = res && res.length>0?res :[]
      console.log(this.tableData,"eceptiondata")
    },
      applyChange($event){
        this.existFilterData = $event
        this.getProductionData($event)
      },
      async getFilterData(data) {
        this.filterData = data;
        this.filterDialog = false;
        this.getissuetype(this.filterData);
        this.menu = false;
      },
      async getissuetype($obj) {
        let obj = {};
        if ($obj) {
          obj = $obj;
        }
        obj.next_action = this.userId;
        var res = await IssueService.getAssignToMeData(obj);
        this.issueType = res && res.length ? res : [];
      },
      getSwitchData($event) {
        let switchData = [];
        for (let item of this.tableData) {
          let $userData = [];
          for (let item1 of item.userData) {
            if (item1.total_issues === 0) {
              $userData.push(item1);
            }
          }
          if ($userData.length > 0) {
            let data = JSON.parse(JSON.stringify(item));
            data.userData = $userData;
            switchData.push(data);
          }
        }
        if ($event !== "ON") {
          this.tableData = switchData;
        } else {
          this.getProductionData();
        }
      },
      goIssueView(item) {
        let status = this.$store.getters.getMyspace;
  
        localStorage.setItem(
          "_WS_DEFAULTS_",
          JSON.stringify({
            workspace: { id: item.work_space_id, name: item.work_space_name },
          })
        );
        localStorage.setItem("workSpaceId", item.work_space_id);
  
        // this.$router.push({ path: "/issueView2", query: { id: item.id } });
        this.$store.dispatch("setIssueInView", item.id);
        let wsDefaults = {
          workspace: { id: item.work_space_id, name: item.work_space_name },
          account: { id: item.account_id, name: item.account_name },
          project: { id: item.project_id, name: item.project_name },
        };
        let wsDefaultsData = JSON.stringify(wsDefaults);
        localStorage.setItem("_WS_DEFAULTS_", wsDefaultsData);
        this.$store.commit("SET_REFRESH_BREADCRUMP", true);
        this.$store.commit("CHANGE_MY_SPACE", !status);
      },
      toggleSearch() {
        this.searchClicked = !this.searchClicked;
        if (this.searchClicked) {
          document.addEventListener("click", this.handleOutsideClick);
        } else {
          document.removeEventListener("click", this.handleOutsideClick);
        }
      },
      handleOutsideClick(event) {
        const textField = this.$refs.searchContainer;
        if (textField && !textField.contains(event.target)) {
          this.searchClicked = false;
          document.removeEventListener("click", this.handleOutsideClick);
        }
      },
      getSearch($event) {
        let searchData = [];
        for (let item of this.backupTableData) {
          let $userData = [];
          for (let user of item.userData) {
            if (
              user &&
              user.userName &&
              user.userName.toLowerCase().includes($event.toLowerCase())
            ) {
              $userData.push(user);
            }
          }
          if ($userData.length > 0) {
            let data = JSON.parse(JSON.stringify(item));
            data.userData = $userData;
            searchData.push(data);
          }
        }
        this.tableData = searchData;
      },
      async getProductionData(filterData) {
        let $obj = {};
        $obj.type = "employee";
        $obj.filterData = filterData?filterData:null
        let res = await ProductionDashboardService.liveBoard($obj);
        for (let item of res) {
          item.userData.forEach((item1) => {
            item1.userName =
              item1 && item1.userName === null ? "" : item1.userName;
          });
          item.userData = await commonService.getSort(
            item.userData,
            "userName",
            "asc"
          );
          for (let item2 of item.userData) {
            item2.accountData = await commonService.getSort(
              item2.accountData,
              "account_name",
              "asc"
            );
          }
        }
        this.tableData = res.length > 0 ? res : [];
        this.backupTableData = JSON.parse(JSON.stringify(this.tableData));
        setTimeout(()=>{
          this.loading = false;
        },2000);
      },
  
      addData(data) {
        this.finalExportData = data;
      },
  
      async exportFile(type) {
        let sourceData = [];
        let column;
  
        this.finalExportData.forEach((workspace) => {
          workspace.userData.forEach((user) => {
            sourceData = [...sourceData, ...user.accountData];
          });
        });
  
        column = [
          {
            field: "id",
            header: "id",
            style: { width: "100px", color: "" },
            toolTip: "Name",
            sortfield: true,
          },
          {
            field: "account_id",
            header: "account_id",
            style: { width: "100px", color: "" },
            toolTip: "Name",
            sortfield: true,
          },
          {
            field: "created",
            header: "created",
            style: { width: "100px", color: "" },
            toolTip: "Name",
            sortfield: true,
          },
          {
            field: "sub_category_id",
            header: "sub_category_id",
            style: { width: "100px", color: "" },
            toolTip: "User Name",
            sortfield: true,
          },
          {
            field: "stage_code",
            header: "stage_code",
            style: { width: "100px", color: "" },
            toolTip: "Num",
            sortfield: true,
          },
          {
            field: "stage_name",
            header: "stage_name",
            style: { width: "100px", color: "" },
            toolTip: "Num",
            sortfield: true,
          },
          {
            field: "account_name",
            header: "account_name",
            style: { width: "100px", color: "" },
            toolTip: "Num",
            sortfield: true,
          },
          {
            field: "sub_category_name",
            header: "sub_category_name",
            style: { width: "100px", color: "" },
            toolTip: "Num",
            sortfield: true,
          },
          {
            field: "count",
            header: "count",
            style: { width: "100px", color: "" },
            toolTip: "Num",
            sortfield: true,
          },
        ];
  
        if (this.finalExportData !== this.tableData) {
          column.splice(2, 0, {
            field: "user",
            header: "User",
            style: { width: "100px", color: "" },
            toolTip: "Name",
            sortfield: true,
          });
        }
  
        await ExportService.export(sourceData, "Live Board", column, type);
      },
    },
  };
  </script>
  
  <style></style>
  