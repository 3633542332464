<template>
<v-card flat class="pa-3">
    <PrimeDataTable
      :title="'Questionnaire'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="false"
      :checkBoxOnRow="false"
      :showUpload="false"
      :showAdd="true"
      :columnData="columns"
      :data="QuestionnaireData"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="questionnaire"
      class="responsive-table"
    />

    <v-dialog v-model="dialog" width="1000px" persistent>
      <AddQuestionnaire
        @closeCard="closeCard"
        :id="questionnaireId"
        :viewType="viewType"
        @reload="reloaded"
      />
    </v-dialog>
    </v-card>
</template>

<script>
import AddQuestionnaire from "./addQuestionnaire.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import QuestionnaireService from "@/api/service/Questionnaire/QuestionnaireService.js";
import commonService from "@/common.service";
export default {
  components: {
    AddQuestionnaire,
    PrimeDataTable,
  },
  data() {
    return {
      columns: [],
      date: new Date(),
      questionnaireId: null,
      dialog: false,
      viewType: false,
      QuestionnaireData: [],
      reload:false
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getQuestionnaireData();
  },
  methods: {
    closeCard(value) {
      this.dialog = value;
    },
    reloaded() {
        this.getQuestionnaireData();
    },
    loadColumns() {
      this.columns = [
        {
          field: "title",
          header: "Question",
          style: { width: "100px", color: "" },
          toolTip: "Question",
          sortfield: true,
        },
        {
          field: "display_value",
          header: "Type Of The Question",
          style: { width: "100px", color: "" },
          toolTip: "Type Of The Question",
          sortfield: true,
        },
        {
          field: "name",
          header: "Assessment Category",
          style: { width: "100px", color: "" },
          toolTip: "Assessment Category",
          sortfield: true,
        },
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [{ action: "view" }, { action: "edit" }],
        },
      ];
    },
    rowActions($event) {
      if ($event.type === "view" || $event.type === "edit") {
        this.dialog = true;
        this.questionnaireId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type === "select") {
        this.dialog = true;
        this.questionnaireId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getQuestionnaireData() {
      let $uesrDetail =  JSON.parse(localStorage.getItem('user_details'));
      $uesrDetail.department = typeof $uesrDetail.department == 'string' ? JSON.parse($uesrDetail.department) : $uesrDetail.department;
      let res = await QuestionnaireService.findList({department : $uesrDetail.department});
      this.QuestionnaireData = res;
      console.log(this.QuestionnaireData.ds_questionnaire_list,'queeeee');
      for(let item of this.QuestionnaireData){
        item.title = item.title && item.title.length>50?item.title.substring(0, 50)+'...':item.title;
      }
      this.QuestionnaireData =  await commonService.getSort(
            this.QuestionnaireData,
            "created",
            "desc"
          );
      console.log(this.QuestionnaireData,"hello user")
    },
    addDiolog() {
      this.dialog = true;
      this.questionnaireId = null;
      this.viewType = false;
    },
    addFilter() {
      this.$refs.FilterDrawer.dialog = true;
      this.$refs.FilterDrawer.size = commonService.largeDialog();
    },
  },
};
</script>

<style scoped>
.table-container {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}

.responsive-table {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .table-container {
    padding: 0 8px;
  }
}
</style>
