import ApiService from '../../service/apiServiceMaster.js';
const querystring = require('querystring')
var ProductionDashboardService = {

    findAll: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "productionBoard"
            $options.method = "get"

            if ($data) {
                $options.url = "productionBoard?" + querystring.stringify($data)
                // console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    // liveBoardData: function ($data) {
    //     return new Promise(function (resolve, reject) {
    //         let $options = {};
    //         $options.url = "liveBoardData"
    //         $options.method = "post"

    //         if ($data) {
    //             $options.url = "liveBoardData?" + querystring.stringify($data)
    //             console.log($options.url)
    //         }
    //         ApiService.request($options)
    //             .then(response => {
    //                 console.log('test');
    //                 resolve(response.data.data)
    //             }).catch(error => {
    //                 reject(error);
    //             })
    //     });

    // },
    
    liveBoardData: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "liveBoardData";
            $options.method = "post"
            // console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    },
     
    liveBoardIssue: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "liveBoardIssue";
            $options.method = "post"
            // console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    productionIssue: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "productionIssue"
            $options.method = "get"

            if ($data) {
                $options.url = "productionIssue?" + querystring.stringify($data)
                // console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    extraRowD: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "extradata"
            $options.method = "get"

            if ($data) {
                $options.url = "extradata?" + querystring.stringify($data)
              //  console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                  //  console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    
    liveBoard: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "liveboard";
            $options.method = "post"
            // console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    // liveBoard: function ($data) {
    //     return new Promise(function (resolve, reject) {
    //         let $options = {};
    //         $options.url = "liveboard"
    //         $options.method = "get"

    //         if ($data) {
    //             $options.url = "liveboard?" + querystring.stringify($data)
    //             // console.log($options.url)
    //         }
    //         ApiService.request($options)
    //             .then(response => {
    //                 // console.log('test');
    //                 resolve(response.data.data)
    //             }).catch(error => {
    //                 reject(error);
    //             })
    //     });

    // },


};
export default ProductionDashboardService;