<template>
    <div class="mainContent">
        <div v-for="(item, index) in estimate" :key="index">
            <button :class="item.active ? 'active' : 'defaultBtn'" @click="selectItem(item, 'minutes_since_created')" class="estimate">{{
                item.value
            }}</button>
        </div>

        <div v-for="(priority, index) in priorityData" :key="index">
            <button :class="priority && priority.active ? 'active' : 'defaultBtn'" @click="selectItem(priority, 'priority')"
                class="priority">

                {{ priority.display_value }}
            </button>
        </div>

       <!--  <div v-for="(issueType, index) in issueTypeData" :key="index">
            <button :class="issueType.active ? 'active' : 'defaultBtn'" @click="selectItem(issueType, 'issueType')"
                class="issueType">{{ issueType.name }}</button>
        </div> -->
    </div>
</template>
  
<script>
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import EnumService from "@/api/service/Enum/EnumService";
import commonService from "@/common.service";

export default {
    data() {
        return {
            estimate: [
                { 'value': '30m', 'active': false, 'data_value': '30' },
                { 'value': '1 hr', 'active': false, 'data_value': '60' },
                { 'value': '2 hr', 'active': false, 'data_value': '120' },
                { 'value': '4 hr', 'active': false, 'data_value': '240' },
                { 'value': '8 hr', 'active': false, 'data_value': '480' },
                { 'value': '>8', 'active': false, 'data_value': '>480' }
                // { 'value': '>6 hr', 'active': false }
            ]
            ,
            priorityData: [],
            issueTypeData: [],
            selectedValues: {
                minutes_since_created: [],
                priority: [],
                issueType: []
            }
        };
    },

    created() {
        this.getPriorityData()
        this.getIssueTypeData()
    },

    methods: {

        selectItem(item, group) {
            if (group == 'minutes_since_created') {
                let index = this.estimate.findIndex(k => k.value == item.value)
                this.estimate[index].active = !this.estimate[index].active
            }
            if (group == 'priority') {
                let index = this.priorityData.findIndex(k => k == item)
                this.priorityData[index].active = !this.priorityData[index].active
            }
            if (group == 'issueType') {
                let index = this.issueTypeData.findIndex(k => k == item)
                this.issueTypeData[index].active = !this.issueTypeData[index].active
            }
            this.getSelectedFilter()
        },
        async getSelectedFilter() {
            let filterData = {
                minutes_since_created: [],
                priority: [],
                issue_type: []
            }
            console.log(filterData,"fily",this.priorityData)
            this.estimate.forEach(k => { if (k.active) { filterData.minutes_since_created.push(k.data_value) } })
            this.priorityData.filter(k => { if (k.active) { filterData.priority.push(k.data_value) } })
            this.issueTypeData.filter(k => { if (k.active) { filterData.issue_type.push(k.id) } })
            console.log(filterData,"fily",this.priorityData)

            filterData.minutes_since_created =filterData.minutes_since_created.length>0?filterData.minutes_since_created.join(','):null
            filterData.priority =filterData.priority.length>0?this.setStringValues(filterData.priority,'string'):null
            filterData.issue_type =filterData.issue_type.length>0?filterData.issue_type.join(','):null 
            console.log(filterData,"fily",this.priorityData)

            Object.keys(filterData).forEach(k=>{
                if(!filterData[k]){
                    delete filterData[k]
                }
            })
            this.$emit('selected-values',filterData)
        },
        setStringValues(data,type){
          if(type=='string'){
            let value=''
            data.forEach((k,index)=>{
                value+=`'${k}'`
                if(index!=data.length-1){
                 value+=','
                }
            })
            return value
          }
        
        },

        async getIssueTypeData() {
            try {
                let res = await IssueTypeService.findAll();
                this.issueTypeData =
                    res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
                this.issueTypeData.map(k => k.active = false)
            }

            catch (e) {
                this.issueTypeData = [];
            }

        },

        async getPriorityData() {
            try {
                let res = await EnumService.findAllDomain({ domain: "PRIORITY" });
                this.priorityData = res;
                this.priorityData.map(k => k.active = false)
            } catch (e) {
                this.priorityData = [];
            }
        },

    },
};
</script>

<style scoped>
.mainContent {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.defaultBtn {
    white-space: nowrap;
    text-transform: capitalize;
    color: #000;
    font-size: 10px;
    font-weight: 700;
    width: fit-content;
    padding: 0px 15px;
    height: 24px;
    border-radius: 4px;
    opacity: 0.5;
}

.estimate {
    border: 2px solid #ffbe65;
    background: #f9ecda;
}

.priority {
    border: 2px solid #0cb99a;
    background: #d1ffbd;
}

.issueType {
    border: 2px solid #240cb9;
    background: #d3cdf8;
}

.active {
    text-transform: capitalize;
    color: #000;
    font-size: 10px;
    font-weight: 700;
    width: fit-content;
    padding: 0px 15px;
    height: 24px;
    border-radius: 4px;
    opacity: 1;
}
</style>
  