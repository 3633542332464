import ApiServiceAssesments from "../apiServiceAssesments.js";
// import ApiServiceMasterCache from '../apiServiceMasterCache.js';

const querystring = require('querystring')
    var EvaluationService = {
    getAstEvaluationData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "Evaluation"
            $options.method = "get"

            if ($data) {
                $options.url = "Evaluation?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiServiceAssesments.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
};
export default EvaluationService;