<template>
  <div>
      <AvatarSelection
      v-on:selected = "selectWorkSpace"
      :moreItems="moreItems"
       v-if="workSpaceData && workSpaceData.length>0" 
       :avatarData="workSpaceData" />
      <v-menu  v-if="false" v-bind="menu">
        <template v-slot:activator="{ props }">
          <v-btn color="#fff" variant="text" v-bind="props">
            <span>Work Space</span>
            <i class="pi pi-angle-down" v-if="!menu" style="color: #ffff"></i>
            <i class="pi pi-angle-up" v-else style="color: #ffff"></i>
          </v-btn>
        </template>

        <v-card min-width="300">
          <v-list>
            <v-list-item
                :class="item.active ? 'selected' : ''"
              v-for="item in workSpaceData"
              :key="item"
              @click="selectWorkSpace(item)"
              class="menus"
            >
            <v-list-item-title 
            class="workspace-title"
          
            >{{ item.name }}</v-list-item-title>
              <v-text class="subtitle">{{ item.code }}</v-text>
              <template v-slot:append>
                <v-icon
                  v-if="item.active"
                  color="light-green"
                  large
                >mdi mdi-check</v-icon>
              </template>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-title>
                    View All
                </v-list-item-title>
            </v-list-item>  
          </v-list>
        </v-card>
      </v-menu>
</div>
</template>
<script>
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import AvatarSelection from './AvatarSelection.vue'
export default {
    props:["displayType"],
    components:{
      AvatarSelection
    },
    data() {
        return {
            workSpaceData:[],
            totalItems:0,
            moreItems:[],
            fav:false,
            avatarName:"",
        }
    },
  
    created() {
        this.getWorkSpaces()
    }, 
    methods:{
      menuShow() {},
      extraAvatar() {
      if (
        localStorage.getItem("selectedWorkSpaceId") ==
        localStorage.getItem("workSpaceId")
      ) {
        this.avatarName = localStorage.getItem("avatarName");
        this.show = true;
      } else {
        this.show = false;
      }
    },
      getRandomColor(index) {
            const availableColors = ["#C7D4E7", "#E7CED1", "#FDEFCA", "#F5D4DF"];
            if (index >= 0 && index < availableColors.length) {
            return availableColors[index];
            }
            return "#000000";
        },
        selectWorkSpace(data) {
        let status = this.$store.getters.getMyspace;
        this.show = false;
        localStorage.setItem("_WS_DEFAULTS_",JSON.stringify({"workspace":{"id":data.id,"name":data.name}}))
        localStorage.setItem("workSpaceId", data.id);
        this.$store.commit("WORK_SPACE_ID", data.id);
        this.$store.commit("SET_REFRESH_BREADCRUMP",true)
        this.activeWorkSpace("ws", data.id);
        this.$store.commit("CHANGE_MY_SPACE", !status);
        this.$router.push({ path: '/showProjectForWorkspaces', query: { workSpaceId: data.id } });
        this.$nextTick(() =>{
        })
        // location.reload();
        },

        async activeWorkSpace(type, id) {
      if (type == "ws") {
        this.workSpaceData.map((k) => {
          if (k.id == parseInt(id)) {
            k.active = true;
          } else {
            k.active = false;
          }
        });
        this.workSpaceData = [...this.workSpaceData];
      }
      if (type == "pj") {
        this.projectData.map((k) => {
          this.$store.commit("PROJECT_ID", id);
          if (k.id == parseInt(id)) {
            k.active = true;
          } else {
            k.active = false;
          }
        });
        this.projectData = [...this.projectData];
      }
      if (type == "ac") {
        this.accountData.map((k) => {
          if (k.id == parseInt(id)) {
            this.$store.commit("ACCOUNT_ID", id);
            k.active = true;
          } else {
            k.active = false;
          }
        });
        this.accountData = [...this.accountData];
      }
    },
        async getWorkSpaces() {
            try {
                let $workSpaceData = await WorkSpaceService.getWorkSpaceByUser({active:1});

                let selectId = localStorage.getItem("workSpaceId");
                $workSpaceData.map((k) => (k.active = false));
                this.totalItems = $workSpaceData.length>4 ? $workSpaceData.length -7 : $workSpaceData.length

                if($workSpaceData.length>=4) {
                    this.moreItems = $workSpaceData.splice(4,$workSpaceData.length - 4 )
                }
                this.workSpaceData  = $workSpaceData
                if (!selectId && this.workSpaceData.length > 0) {
                // localStorage.setItem("workSpaceId", this.workSpaceData[0].id);
                this.$store.commit("WORK_SPACE_ID", this.workSpaceData[0].id);
                this.activeWorkSpace("ws", this.workSpaceData[0].id);
                } else {
                this.activeWorkSpace("ws", selectId);
                this.$store.commit("WORK_SPACE_ID", selectId);
            
                }
            } catch (e) {
                console.log(e);
            }
        },
    }
}
</script>
<style>
.subtitle {
  margin: 0px;
  /* color: ; */
  font-size: 12px;
  font-weight: 400;
  text-align: start;
  color: #636363;
  border-radius: 5px;
  padding: 7px 3px 7px 0px;
}
.workspace-title {
    font-weight:500;
}
.selected {
    background-color:#E1EDFC;
}
</style>