<template>
    <v-card width="100%" style="border-radius: 12px; overflow: hidden">
      <v-form @submit.prevent="save" ref="form">
        <v-card-item>
          <v-card-title
          >
          <b>{{ viewType ? showReadOnly ? "View" : "Edit" : "Add" }}  Vacation Request</b>
          <v-btn style="float: right" @click="setFalse" elevation="0" density="comfortable" icon="mdi-close-circle"></v-btn>
      
    </v-card-title>
     </v-card-item>
  
      <v-card-text>
          <v-row no-gutters class="mt-3 pa-2">
          <!-- Input Field -->
  
          <v-col cols="12" class="px-0 py-0" style="display: grid" v-if="formdata.id">
              <v-radio-group  @change="onStatusChange" v-model="formdata.active" inline>
                <v-radio color="primary" label="Active" :value="1"></v-radio>
                <v-radio color="error" label="Inactive" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
  
          <v-col cols="6">
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Start Date
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <TextFieldComponent
              density="compact"
              type="date"
              placeholder="startDate"
              :rules="startDateRule"
              :min="moment().format('YYYY-MM-DD')"
              v-model="formdata.start_date"
              :disabled="showReadOnly"
              @input="getDayCalculation"
            />
          </v-col>
          <v-col cols="6" class="pl-2">
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">End Date
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <TextFieldComponent
              density="compact"
              type="date"
              placeholder="End Date"
              :rules="endDateRule"
              v-model="formdata.end_date"
              :disabled="!formdata.start_date || showReadOnly"
              :min="formdata.start_date"
              @input="getDayCalculation"
            />
          </v-col>  
          <v-col cols="12"  v-if="blockedError">
            <v-error>
              WARNING !!    selected leave dates were already blocked by your Team Leads. please proceed to apply.
            </v-error>
          </v-col>      
          <v-col cols="12" v-if="this.formdata.no_of_days && this.formdata.no_of_hours">
            <span style="color: red;
                        font-weight: bold;
                        padding: 5px;">
                        {{ `${this.formdata.no_of_days} day${this.formdata.no_of_days>1?'s':''}(${this.formdata.no_of_hours} hours)` }}
            </span>
          </v-col>
          <v-col cols="12">
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Vacation Type
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
            <AutoComplete
              density="compact"
              :items="VacationTypeServiceData"
              item-title="name"
              item-value="id"
              placeholder="Vacation Type"
              :rules="vacationTypeRule"
              v-model="formdata.vacation_type_id"
              :disabled="showReadOnly"
              @update:modelValue="getEmployeeVacationAllotmentData" 
            />
          </v-col>
  
                  
          <v-col cols="12" v-if="notAvailable">
            <v-error>{{ errorMsg || '' }}</v-error>
          </v-col>
          <!-- Input Field -->
  
          <v-col cols="12" >
             <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Remarks
            <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700"></span></v-label>
            <v-textarea
                  label="Remarks"
                  row-height="25"
                  class="opacity-50"
                  rows="3"
                  variant="outlined"
                  single-line
                  v-model="formdata.remarks"
                   :disabled="showReadOnly"
                ></v-textarea>
          </v-col>
            </v-row>
        </v-card-text>
        <v-card-action class="mr-3">
  
          <!-- Cancle And Save Button -->
  
          <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
            <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-action>
      </v-form>
    </v-card>
  </template>
  
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import EnumService from "@/api/service/Enum/EnumService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import VacationTypeService from "@/api/service/VacationType/VacationTypeService";
import VacationService from "@/api/service/Vacation/VacationService";
import EmployeeVacationAllotmentService from "@/api/service/EmployeeVacationAllotment/EmployeeVacationAllotmentService";
import BlockedCalendarService from "@/api/service/BlockedCalendar/BlockedCalendarService";
import moment from "moment";
  export default {
    props: ["id", "viewType"],
    components: {
      TextFieldComponent,
      AutoComplete
    },
  
    data() {
      return {
        showReadOnly: false,
        remarkRule: [(value) => !!value || "Domain is Required."],
        endDateRule: [(value) => !!value || "End Date is Required."],
        vacationTypeRule: [(value) => !!value || "Vacation type is Required."],
        startDateRule: [(value) => !!value || "Start Date is Required."],
        dialog: false,
        type: null,
        VacationTypeServiceData:[],
        formdata: {},
        dayMsg:null,
        userData:null,
        notAvailable:false,
        blockedError:false,
        employeeAvlData:[],
        errorMsg:'You do not have sufficient hours to apply for a leave under the Selected Vacation type.'
      };
    },
  
    watch: {
      id() {
        if (this.id) {
          this.formdata = {};
          this.getById(this.id);
        }
      },
    },
  
    created() {
      let $userData = localStorage.getItem('user_details')
      this.userData = $userData ? JSON.parse($userData) : null
      this.getVacationTypeServiceData();
      if (this.id) {
        this.getById(this.id);
      }

      // if (this.viewType) {
      //   this.showReadOnly = this.viewType == "edit" ? false : true;
      // }
    },
  
    methods: {
      moment,
      async checkAvaileHour(){
        if(this.employeeAvlData && this.employeeAvlData.length>0 && this.formdata.no_of_hours){
          this.notAvailable = false
          for(let item of this.employeeAvlData){
              this.notAvailable =item.available_qty <this.formdata.no_of_hours? true:false
          }   
                    
        }   
      },
      async getEmployeeVacationAllotmentData(value){
        if(value && this.userData && this.userData.id){
          let res = await EmployeeVacationAllotmentService.findAll({employee_id:this.userData.id,vacation_type_id:value,fy:moment().format('YYYY')})
          this.employeeAvlData = res && res.length>0 ? res :[]
          this.notAvailable = res && res.length>0 ? false :true
          this.checkAvaileHour()
        }
      },
      async getBlockedData(){
          let obj = {
            start_date:this.formdata.start_date,
            end_date:this.formdata.end_date,
            userId:JSON.stringify([this.userData.id])
          }
          try{
            let res = await BlockedCalendarService.checkBlockedDate(obj)
              this.blockedError = res && res.length>0?true:false
          }
          catch(exception){
            console.log(exception)
            this.blockedError = false
          }
      },
      onStatusChange(value){
        console.log(value.target.value,"onStatusChange");
        
        this.showReadOnly= value.target.value==0 ? true :false;
      },
      getDayCalculation(){
        if(this.formdata.start_date && this.formdata.end_date){
          
          let $startDate = moment(this.formdata.start_date)
          let $endDate = moment(this.formdata.end_date)
          let dates = this.getDates($startDate,$endDate)
          let count = 0
          if(dates && dates.length>0){
            let weekDays = []
              for(let item of dates){
                let weekend = [6,0]
                if(!weekend.includes(moment(item).day())){
                  weekDays.push(item)
                  count++
                }
              }
              console.log(weekDays,"ejjjjj");
          }
          this.formdata.no_of_days = count
          this.formdata.no_of_hours = count * 8          
          this.getBlockedData() 
          this.checkAvaileHour()
        }
      },
       getDates($startDate, $stopDate) {
            var dateArray = [];
            while ($startDate <= $stopDate) {
                dateArray.push( moment($startDate).format('YYYY-MM-DD') )
                $startDate = moment($startDate).add(1, 'days');
            }
            return dateArray;
        },
      checkDisplayValue(){
        this.formdata.data_value = this.formdata.display_value?this.formdata.display_value.toLowerCase().split(' ').filter(Boolean).join(''):'';
      },
      setFalse() {
        this.$emit("closeDialog");
      },
      async getById(id) {
        let res = await VacationService.findOne(id);
        this.formdata = res[0];
        this.formdata.start_date = moment(this.formdata.start_date).format("YYYY-MM-DD")
        this.formdata.end_date = moment(this.formdata.end_date).format("YYYY-MM-DD")
      },
      async getVacationTypeServiceData() {
        let res = await VacationTypeService.findAll();
        this.VacationTypeServiceData = res.length > 0 ? res : [];
      },
      checkDomain(){
        this.formdata.domain= this.formdata.domain?this.formdata.domain.toUpperCase():''
      },
      setupPlanned(){
        let diffNum = 2
        let currentDate = moment()
        diffNum = diffNum + currentDate.day() == 6?2:currentDate.day() == 0?1:0
        let $startDate = moment(this.formdata.start_date)
        this.formdata.planned = $startDate.diff(currentDate, 'days') > diffNum ? 1 : 0;
        // console.log(this.formdata)
        
      },
  
      async save() {
        let validation = await this.$refs.form.validate()
        if (!validation.valid || this.notAvailable) {
          console.log("Not valid")
        }
        else {
          this.setupPlanned()
          if (this.formdata.id) {
            let output = await VacationService.update(this.formdata);
            console.log(output,'output')
            this.dialog = false;
            this.setFalse();
            this.$emit("reload", true);
            this.formdata = [];
           
          } else {
            let output = await VacationService.create(this.formdata);
            console.log(output, "gfdgg");
            this.dialog = false;
            this.setFalse();
            this.$emit("reload", true);
            this.formdata = [];
          }
        }
      },
    },
  };
  </script>
  
  <style>
  </style>