<template>
  <v-card flat height="100vh" class="pl-2" style="overflow-y: auto;">
    <v-row class="mt-1 ">
      <v-col cols="8">
        <span
          style="font-weight: 500;
    font-size: 20px;"
          >Form Builder</span
        >
      </v-col>
      <v-col cols="4" style="display: flex;justify-content: space-between;">
        <div style="min-width: 68%;">
          <CustomAutoComplete
            v-if="sampleData && sampleData.length > 0 && !allowEdit"
            density="compact"
            placeholder="Select sample"
            :items="sampleData"
            item-title="name"
            item-value="id"
            @selected="selectPreview"
            @removeItem="removeItem"
          />
          <TextFieldComponent
            v-if="allowEdit"
            density="compact"
            placeholder="Enter name"
            :rules="nameRule"
            v-model="formData.name"
            @keyup="checkName()"
          />
          <v-error v-if="nameError">Already Exists!</v-error>
        </div>
        <!-- </v-col>
      <v-col cols="2"> -->
        <button class="addSample" @click="allowEdit = !allowEdit">
          {{ selectedPreview ? "Update" : "Add" }} Sample
        </button></v-col
      >
    </v-row>

    <!-- <v-card flat class="pa-4"> -->

    <div
      class="wrapper-form pa-7 my-3"
      v-if="
        formBuilderData &&
          formBuilderData.sections &&
          formBuilderData.sections.length > 0
      "
    >
      <FormBuilder
        :key="dynamicFormKey"
        :formBuilder="formBuilderData"
        @formChanged="formChanged"
        @formSubmitted="formSubmitted"
        :data="freeFormData"
      />
    </div>

    <AddDetails
      v-if="
        listBuilderData &&
          listBuilderData.sections &&
          listBuilderData.sections.length > 0
      "
      @detailChanged="detailChanged"
      :formBuilder="listBuilderData"
      :data="detailsData"
      :showAdd="allowEdit"
      :key="dynamicFormKey"
      :selectedPreview="selectedPreview"
    />

    <!-- </v-card> -->

    <v-row class="my-4">
      <v-col cols="3"><attachmodel @addModel="addModel"/></v-col>
    </v-row>

    <v-card-actions class="mr-3" v-if="allowEdit">
      <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
        <v-btn :loading="loading" class="save mr-3" @click="formSubmitted('update')">Save</v-btn>
        <v-btn :loading="loading1" v-if="selectedPreview" class="save " @click="formSubmitted"
          >Save As</v-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
  <v-dialog persistent v-model="dialog" width="650px">
    <associateDataModel
      @closeDialog="setFalse()"
      @reload="getAssociateDataModel(formId)"
      :formId="formId"
      :viewType="viewType"
      :selectedModels="selectedModels"
    />
  </v-dialog>
</template>

<script>
import { FormBuilder } from "@tiburadev/vue-comp";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AddDetails from "./AddDetails.vue";
import FormAssessmentSampleService from "@/api/service/FormBuilder/FormAssessmentSampleService";
import AssociateDataModelService from "@/api/service/FormBuilder/AssociateDataModelService";
import ListModelDetailService from "@/api/service/ListModelDetail/ListModelDetailService";
import associateDataModel from "./associateDataModel.vue";
import attachmodel from "./attachmodel.vue";
import CustomAutoComplete from "@/components/ui/CustomAutoComplete.vue";
// import AutoComplete from "@/components/ui/AutoComplete.vue";
export default {
  components: {
    attachmodel,
    associateDataModel,
    AddDetails,
    FormBuilder,
    // AutoComplete,
    CustomAutoComplete,
    TextFieldComponent,
  },
  data() {
    return {
      nameExistFlag: false,
      nameError: false,
      dynamicFormKey: 0,
      selectedPreviewData: [],
      allowEdit: false,
      selectedPreview: null,
      freeFormData: {},
      detailsData: {},
      detailData: {},
      formData: {},
      freeform: [],
      listForm: [],
      selectedModels: [],
      listBuilderData: {
        title: "Details",
        sections: [],
      },
      formBuilderData: {
        title: "Details",
        width: "",
        closeIcon: false,
        height2: "500",
        withContainer: false,
        layout: "",
        columns: 12,
        sections: [],
      },

      listmodeldetails: [],
      associatemodelData: [],
      viewType: false,
      loading: false,
      loading1: false,
      dialog: false,
      formId: null,
      sampleData: [],
    };
  },
  created() {
    this.formId = this.$route.query.id;
    this.getAssociateDataModel(this.formId);
    this.getSampleData(this.formId);
  },

  watch: {
    allowEdit(newVal) {
      this.updateFieldDisabledState(newVal);
    },
    freeFormData(newVal) {
      if (newVal) {
        this.dynamicFormKey++;
      }
    },
    selectedPreview(newVal) {
      this.getDatabyId(newVal);
    },
  },

  methods: {
    addModel() {
      this.dialog = true;
    },
    selectPreview(data) {
      console.log(data, "gdhddd");
      this.selectedPreview = data;
    },

    async checkName() {
      if (this.formData.name && this.formData.name.length > 0) {
        this.formData.name = this.formData.name.toUpperCase();

        await FormAssessmentSampleService.findAll({
          name: this.formData.name,
        }).then((res) => {
          let $exist = res.filter((key) => key.name == this.formData.name);

          this.nameExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.nameExistFlag) {
            this.nameError = true;
          } else {
            this.nameError = false;
          }
        });
      }
    },

    async removeItem(id) {
      let data = {
        id: id,
        active: 0,
      };
      await FormAssessmentSampleService.update(data);
      await this.getSampleData(this.formId)
    },
    async getSampleData(formID) {
      let res = await FormAssessmentSampleService.findAll({
        form_id: formID,
      });
      this.sampleData = res;
    },
    async getDatabyId(id) {
      if (!id) return;

      let res = await FormAssessmentSampleService.findAll({ id: id });
      res = res[0];

      this.formData = res;

      let sampleData = res.sample_data;
      sampleData = JSON.parse(sampleData);
      this.freeFormData = sampleData.header;
      this.detailsData = sampleData.detail;
    },
    async getAssociateDataModel(formID) {
      if (!formID) return;

      let $res = await AssociateDataModelService.findAll({
        form_id: formID,
      });

      let res = await Promise.all(
        $res.map(async (element) => {
          element.list_model_details = await ListModelDetailService.findListField(
            { model_id: element.list_model_id }
          );
          return element;
        })
      );
      if (!res || res.length === 0) return;

      this.selectedModels = res.map((item) => item.list_model_id);

      const getFormFields = async (items) => {
        return Promise.all(
          items
            .filter((item) => item.field_type !== null)
            .map(async (item) => ({
              multiple: item.multi_select,
              value: item.value,
              text: item.text,
              column_name: item.column_name,
              label: item.label,
              required: item.column_required === 1,
              duplicate: true,
              options: item.options,
              field_type: item.field_type,
              maxLength: item.maxLength || null,
              minLength: item.minLength || null,
              cols: item.width,
              disabled: true,
            }))
        );
      };

      const freeformItems = res.filter(
        (el) => el.form_display_type === "freeform"
      );
      const masterDetailItems = res.filter(
        (el) => el.form_display_type === "masterdetail"
      );
      // console.log(this.formBuilderData.sections,"form builder ##")
      // console.log(this.listBuilderData.sections,"listBuilderData builder ##")
      this.formBuilderData.sections = [];
      this.listBuilderData.sections = [];
      for (let freeform of freeformItems) {
        const formFields = await getFormFields(
          freeform.list_model_details || []
        );
        this.formBuilderData.sections.push({ columns: formFields });
      }

      let i = 0;
      for (let listForm of masterDetailItems) {
        const detailsField = await getFormFields(
          listForm.list_model_details || []
        );
        this.listBuilderData.sections.push({
          title: `masterdetail ${i++}`,
          columns: detailsField,
        });
        this.dynamicFormKey++;
      }
    },

    updateFieldDisabledState(allowEdit) {
      this.formBuilderData.sections.forEach((section) => {
        section.columns.forEach((field) => {
          field.disabled = !allowEdit;
        });
      });
      this.listBuilderData.sections.forEach((section) => {
        section.columns.forEach((field) => {
          field.disabled = !allowEdit;
        });
      });
    },

    setFalse() {
      this.dialog = false;
    },
    detailChanged($data) {
      this.detailData = $data;
    },
    formChanged($data) {
      this.freeFormData = $data;
      console.log($data);
    },
    async formSubmitted(type) {
     
      this.detailsData = this.detailData;
      if (!this.formData.name) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: "Give a name to this sample",
        });
        return;
      }
      console.log(this.detailsData, "whats going", this.detailData);
      let $data = { header: this.freeFormData, detail: this.detailsData };
      $data = JSON.stringify($data);
      console.log($data, "whats going");
      this.formData.form_id = this.formId;
      this.formData.sample_data = $data;
      let res;
      if (type == "update" && this.formData.id) {
        this.loading = true
        this.formData.id = this.selectedPreview;
        res = await FormAssessmentSampleService.update(this.formData);
        this.loading = false
        window.location.reload();
      } else {
        this.loading1 = true
        if (this.formData.id) {
          delete this.formData.id;
        }
        await this.checkName();
        if (this.nameExistFlag) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Already Exists! Change the sample name.",
          });          
          this.loading1 = false
          return;
        }
        res = await FormAssessmentSampleService.create(this.formData);
        this.loading = false
        window.location.reload();
      }
      console.log(res);
    },
  },
};
</script>

<style scoped>
.addSample {
  font-size: 14px;
  font-weight: 500;
  color: #1258ab;
  border: 2px solid #1258ab;
  border-radius: 6px;
  padding: 8px 12px;
  margin-left: 1rem;
  height: fit-content;
  width: fit-content;
}
</style>
