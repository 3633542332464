<template>
	<v-card flat height="100vh">
		<v-row class="mt-1 ml-2">
			<v-col cols="12">
				<span style="font-weight: 500;
    font-size: 20px;">Dashboards</span> 
			</v-col>
		</v-row> 
                <v-row class="mb-4 ml-2">
                    <v-col cols="3" v-for="item of dashboards" :key="item">
                        <v-card
                        class="mx-auto hover-card"
                        max-width="400"
                        >
                        <div class="circle">
                            <img class="img-cls" :src="getTheItemData(item,'imageUrl')" />
                        </div>
                        <v-divider class="mt-15 mb-10" ></v-divider>
                                <v-card-title>{{ item.name || '' }}</v-card-title>
                            <v-card-text>
                                <div>{{ getTheItemData(item,'summary') }}</div>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" text="Open" @click="selectItem(item)"></v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
		</v-card>
</template>
<script>
import DashboardService from '@/api/service/Dashboard/DashboardService';
import DashboardData from './index'
export default {
	data(){
		return {
			selectedOption:null,
			dashboards:[],
            displayData :[]
		}
	},
	created(){
		this.getDashboardData()
        this.displayData = DashboardData.getTheData()
        
	},
	methods:{
		async getDashboardData(){
			let res = await DashboardService.getDashboards()
			this.dashboards = res && res.length>0?res :[]
		},
		selectItem(item){
			this.selectedOption = item ?item:{}
			this.$router.push(item.route) 
		},
		getClass() {
			return this.defaultClass ? this.defaultClass : 'default-selection'
		},
        getTheItemData(item,key){
            let data = this.displayData.filter(k=>k.code == item.code)
            return data && data.length>0 && data[0][key]?data[0][key]:''
        }
	}

}
</script>
<style scoped>
.img-cls{
    height: 25px; 
    position: absolute;
    bottom: 84px;
    top: 8px;
    left: 13px;   
}
.circle {
    width: 50px;
    height: 50px;
    background-color: #eaedef;
    border-radius: 50%;
    position: absolute;
    bottom: 84px;
    top: 35px;
    left: 45px;
    z-index:1
}
.hover-card {
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.hover-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
</style>
