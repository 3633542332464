<template>
  <v-card flat style="width: 100%;">
    <v-card-item style="display: flex; justify-content: space-between;width: 100%;">
      <v-row class="pt-2" style="width:100%">
        <v-col cols="2"> {{ boardName }} </v-col>
        <v-col cols="5">
          <span ref="searchContainer" v-if="searchClicked">
            <text-field-component-vue
              @keyup="getSearch"
              v-model="search"
              @input="getSearch()"
              :placeholder="`Search by ${boardName == 'Employee Live Board'?'username':'account'}`"
              flat
              append-inner-icon="mdi-magnify"
            ></text-field-component-vue>
          </span>
          <v-icon v-else @click.stop="toggleSearch">mdi-magnify</v-icon>
        </v-col>
        <v-col cols="2">
          <span style="display: flex; float: right" class="cursor-pointer">
            <v-menu
              v-model="menu"
              transition="scale-transition"
              location="bottom"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">mdi-filter-outline</v-icon>
              </template>
              <v-list width="350px">
                <DashBoardFilter
                  :existFilterData="existFilterData"
                  :type="'openIssue'"
                  :type2="'live'"
                  :showFilter = "boardName == 'Employee Live Board'?['work_timing_id','work_space_id','account_id','project_id','user_id']:['work_space_id','account_id','project_id']"
                  @applyChange="applyChange"
                />
              </v-list>
            </v-menu>
          </span>
        </v-col>
        <v-col cols="3">
          <div style="display: flex;">
          <v-switch
                v-model="switchBtn"
                color="primary"
                label="Show only zero issue"
                @change="getSwitchData"
                style="margin-top: -10px"></v-switch>
           <v-menu>
            <template v-slot:activator="{ props }">
              <i
                class="mt-2 pi pi-file-export"
                style="cursor: pointer"
                v-bind="props"
              />
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in downloadMenu"
                :key="index"
                :value="index"
              >
                <v-list-item-title @click.stop="exportFile(item)">{{
                  item
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        </v-col>
      </v-row>      
      <v-row style="margin-top: -50px;margin-bottom: 2px;" v-if="chipName && chipName.length>0">
        <v-col v-for="(workspace, workspaceIndex) in chipName"
        :key="workspaceIndex" cols="auto" class="cursor-pointer">
          <span class="workspace show-cursor"  @click.stop=changeIndex(workspaceIndex)>
            {{workspace.workSpaceName}}
          </span>
        </v-col>
      </v-row>
    </v-card-item>
    <!-- <v-card-actions v-if="chipName && chipName.length>0">
        <v-row>
          <v-col v-for="(workspace, workspaceIndex) in chipName"
        :key="workspaceIndex" cols="auto" class="cursor-pointer">
          <span class="workspace"  @click.stop=changeIndex(workspaceIndex)>
            {{workspace.workSpaceName}}
          </span>
        </v-col>
        </v-row>
        </v-card-actions> -->
  </v-card>
  <v-divider></v-divider>
</template>

<script>
// import filterData from "./filterData.vue";
// import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
// import ExportService from "@/ExportService";
// import commonService from "@/common.service";
import TextFieldComponentVue from "../../../components/TextFieldComponent.vue";
import IssueService from "@/api/service/Issue/IssueService";
import DashBoardFilter from "./DashboardFilter.vue";
// import SkeletonForList from "../SkeletonForList.vue";

export default {
  props: ["boardName","existFilterData","workSpaceName"],
  components: {
    // filterData,
    TextFieldComponentVue,
    DashBoardFilter,
    // SkeletonForList
  },

  data() {
    return {
      menu: false,
      loading: true,
      downloadMenu: ["Excel", "Pdf"],
      ProductionData: [],
      search: null,
      searchClicked: false,
      tableData: [],
      finalExportData: [],
      backupTableData: [],
      isDrawer: false,
      switchBtn: false,
      workTimingId: null,
      chipName:null
    };
  },

  async created() {
    // await this.getProductionData();
    this.finalExportData = this.tableData;
    this.chipName=[...this.workSpaceName]
  },
  computed: {
    isDrawerOpen() {
      return this.$store.getters.getIssueViewDrawer;
    },
    isIssueChanged() {
      return this.$store.getters.getDefaultIssueInView;
    },
  },
  watch: {
    isIssueChanged() {
      this.showIssueView = true;
    },
  },
  methods: {
    changeIndex(index){
      this.$emit('scroll' ,index)
    },
    applyChange($event) {
      localStorage.setItem('filterData',JSON.stringify($event))
      this.$emit("applyChange", $event);
      this.menu = false;
    },
    gettimingId(id) {
      this.workTimingId = id;
      console.log(
        this.workTimingId,
        "this.workTimingIdthis.workTimingIdthis.workTimingId"
      );
    },
    async getFilterData(data) {
      this.filterData = data;
      this.filterDialog = false;
      this.getissuetype(this.filterData);
      this.menu = false;
    },
    async getissuetype($obj) {
      let obj = {};
      if ($obj) {
        obj = $obj;
      }
      obj.next_action = this.userId;
      var res = await IssueService.getAssignToMeData(obj);
      this.issueType = res && res.length ? res : [];
    },
    getSwitchData() {
      this.$emit("show-zero-issue", this.switchBtn);
    },
    toggleSearch() {
      this.searchClicked = !this.searchClicked;
      if (this.searchClicked) {
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    handleOutsideClick(event) {
      const textField = this.$refs.searchContainer;
      if (textField && !textField.contains(event.target)) {
        this.searchClicked = false;
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    getSearch() {
      this.$emit("search-data", this.search);
    },

    addData(data) {
      this.finalExportData = data;
    },

    async exportFile(type) {
      this.$emit("export-data", type);
    },
  },
};
</script>

<style>
.workspace {
  background-color: #fdf4f4;
  border: 1.5px solid #ffa1a1;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}
.v-card-item__content {
    align-self: center;
    grid-area: content;
    width: 100%;
}
</style>
