<template>
  <div>
    <div
      ref="htmlContent"
      style="width: fit-content; z-index: -999; position: absolute; top: -1000px"
    >
      <div
        v-for="data in dataArray"
        :key="data.header"
        style="padding: 10px;background: #fff;"
      >
        <div
          style="
            margin-bottom: 20px;
            background: #007bff;
            color: white;
            padding: 10px;
            border-radius: 5px;
          "
        >
          <div v-for="(value, key) in data.header" :key="key">
            <strong>{{ key.replace(/_/g, " ") }}:</strong> {{ value }}
          </div>
        </div>

        <!-- Details Table -->
        <table
          style="width: 100%; border-collapse: collapse; margin-bottom: 20px"
        >
          <thead>
            <tr>
              <th
                v-for="key in Object.keys(data.detail[0][0])"
                :key="key"
                style="
                  background: #007bff;
                  color: white;
                  padding: 10px;
                  border: 1px solid #ddd;
                "
              >
                {{ key.replace(/_/g, " ") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in data.detail[0]" :key="row['style#']">
              <td
                v-for="key in Object.keys(row)"
                :key="key"
                style="padding: 10px; border: 1px solid #ddd; color: black"
              >
                {{ row[key] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Rendered Image Preview -->
    <div v-if="renderedImage ">
      <img @click="openInNewTab" :src="renderedImage" alt="Generated Preview" />
    </div>
  </div>
</template>

<script>
import domtoimage from "dom-to-image-more";
// import FormAssessmentSampleService from "@/api/service/FormBuilder/FormAssessmentSampleService";

export default {
  props: ["sampleId","sampleData"],
  data() {
    return {
      dataArray: [],
      renderedImage: "",
    };
  },
  async created() {
    await this.getSampleData(this.sampleData);
  },
  methods: {
    async getSampleData(data) {
      try {
        this.dataArray = [data];
        this.$nextTick(() => {
          this.generateImage();
        });
      } catch (error) {
        console.error("Error fetching or processing sample data:", error);
      }
    },
    async generateImage() {
      console.log("Generating Image...");
      const node = this.$refs.htmlContent;

      if (!node) {
        console.error("htmlContent is not available.");
        return;
      }

      try {
        // const dataUrl = await domtoimage.toPng(node);

        const dataUrl = await domtoimage.toJpeg(node, { quality: 1 });
        this.renderedImage = dataUrl;
        // this.renderedImage = dataUrl; // Update the image URL
      } catch (error) {
        console.error("Error generating image:", error);
      }
    },
    openInNewTab() {
      if (this.renderedImage) {
        const newTab = window.open();
        newTab.document.body.innerHTML = `<img src="${this.renderedImage}" style="max-width: 100vw; height: auto;" alt="Preview Image" />`;
      } else {
        console.error("No image to open in a new tab.");
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
</style>
