<template>
    <div class="d-flex justify-center">
        <v-card class="tittle">
            <v-card-title class="d-flex justify-space-between align-center">
                <span class="font-weight-bold opacity-100">Vacation Approvals</span>
                <v-btn style="float: right" @click="close" elevation="0" density="comfortable"
                    icon="mdi-close-circle"></v-btn>
            </v-card-title>
            <v-form @submit.prevent="save">
                <v-card-text>
                    <v-row class="head">
                        <v-row>
                            <v-col cols="6" class="pl-6 mb-3">
                                <h5>#120034</h5>
                                <h3 style="margin: 0;">Velayutham Sankaran</h3>
                                <h5 style="margin-top:0; margin-bottom: 10px;">Process Associate</h5>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-chip outlined class="text-center chipcard" large>Unplanned</v-chip>
                            </v-col>
                        </v-row>
                    </v-row>
                    <v-card class="firstbox pr-2 mt-1">
                        <div style="width: 100%; display: flex; justify-content: space-between;">
                            <div class="request" style="width: 50%;">Sick Leave</div>
                            <div class="date" style="width: 50%;">09/01/2024 to 09/05/2024 <span class="d-block">4 Days
                                    32 Hours</span></div>
                        </div>
                    </v-card>
                    <div class="secondbox">
                        <div style="width: 100%; display: flex; justify-content: space-between;">
                            <div class="question" style="width: 25%;">Blocked Calendar <span
                                    class="d-block ans">Yes</span></div>
                            <div class="question" style="width: 25%;">Reason <span class="d-block ans">Fever</span>
                            </div>
                            <div class="question" style="width: 25%;">Balance <span class="d-block ans">40 Hours</span>
                            </div>
                            <div class="question" style="width: 25%;">Last availed on <span
                                    class="d-block ans">12-12-2023</span> (4 Hours) Monday</div>
                        </div>
                    </div>
                    <v-card class="thirdbox pr-2 mt-1">
                        <div style="width: 100%; display: flex; justify-content: space-between;">
                            <div class="health" style="width: 100%;">I am not feeling well</div>
                        </div>
                    </v-card>
                    <v-col cols="12" class="pr-2 mt-2">
                        <h3>Approval Lines</h3>
                    </v-col>
                    <v-container class="pa-0">
                        <v-row v-for="(item, index) in items" :key="index" class="my-4" align="center">
                            <v-col cols="1">
                                <v-chip color="pink" text-color="black" outlined class="text-center" large>
                                    {{ index + 1 }}
                                </v-chip>
                            </v-col>
                            <v-col cols="7">
                                <v-card class="fourthbox pr-2 mt-1">
                                    <div
                                        style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                                        <div class="name" style="width: 50%;">
                                            <span style="display: block;">Velayutham Sankaran</span>
                                            <span style="display: block;">20/08/2024 4 days old</span>
                                            <v-chip outlined class="text-center chipcard" large> Pending </v-chip>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="3" class="button">
                                <v-btn color="#1B1A47" class="mb-3" outlined block>Approve</v-btn>
                                <v-btn color="#1B1A47" class="mb-3" outlined block>Reject</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-form>
        </v-card>
    </div>
</template>
<script>
import CandidateProfileService from "@/api/service/CandidateProfile/CandidateProfileService";
export default {
    components: {},
    data() {
        return {
            items: [
                {
                    name: "Velayutham Sankaran",
                    date: "20/08/2024",
                    age: "4 days old",
                    status: "Pending",
                },
                {
                    name: "Velayutham Sankaran",
                    date: "20/08/2024",
                    age: "4 days old",
                    status: "Pending",
                },
            ],
            candidateProfileData: {
            }
        };
    },
    methods: {
        close() { this.$router.push("/requestBoard") },
        async save() {
            let output = await CandidateProfileService.create(this.candidateProfileData)
            console.log(output);
        }
    }
};
</script>
<style scoped>
.tittle {
    font-size: 14px;
    font-weight: 800;
    font-size: bold;
    color: #000000;
    margin-top: 15px;
    width: 850px;
    height: 800px;
}

.create {
    border-radius: 8px;
    margin-bottom: 25px;
}

.firstbox {
    height: 65px;
    width: 800px;
    border-radius: 5px;
    background-color: #F0F0F0;

    .request {
        font-weight: 500px;
        font-size: 14px;
        font-weight: medium;
        margin-top: 10px;
        margin-left: 5px;
    }

    .date {
        font-weight: 500px;
        font-size: 14px;
        font-weight: medium;
        color: black;
        margin-top: 10px;
        margin-right: 300px;
    }

    .d-block {
        font-weight: 400px;
        font-size: 14px;
        color: #959595;
    }
}

.secondbox {
    height: 65px;
    width: 800px;
    background-color: white;
    margin-top: 10px;

    .question {
        font-weight: 400px;
        font-size: 14px;
        color: #6D6D6D;
        margin-top: 5px;
        margin-left: 5px;
    }

    .ans {
        font-weight: 500px;
        font-size: 14px;
        color: #000000;
        margin-left: 5px;
        font-weight: bold;
    }
}

.thirdbox {
    height: 65px;
    width: 800px;
    border-radius: 5px;
    background-color: #F0F0F0;

    .health {
        font-weight: 400px;
        font-size: 14px;
        font-weight: medium;
        margin-top: 10px;
        margin-left: 5px;
    }
}

.fourthbox {
    width: 750px;
    height: 107px;
    border-radius: 5px;
    background-color: #F0F0F0;
    margin-left: -20px;

    .name {
        font-weight: 500px;
        font-size: 14px;
        font-weight: medium;
        margin-top: 15px;
        margin-left: -95px;
    }
}

.v-chip {
    background-color: #ff4d7e;
}

.button {
    margin-left: 40px;
    margin-top: 15px;
}

.chipcard {
    background-color: #EE4F75;
    color: #000000;
    font-weight: bold;
}
</style>