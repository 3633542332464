<template>
    <v-menu :activator="this.activateMenu">
       
      <v-list density="compact" max-height="350px" style="width: 100% !important">
        <div>
        <TextFieldComponent 
            v-if="options.length>5"
            density="compact"
            dense @input="Searching" @click.stop :placeholder="search" v-model="searchText"
            append-inner-icon="mdi-magnify" />
        </div>
        <template v-if="option==1">
        <v-list-item  v-for="(item, index) in filteredUsers" :key="index" :value="index" @click="action(index, item)">
          <template v-if="showAvatar" v-slot:prepend>
            <CustomAvatar2  :size="'small'" :fName="item.first_name" :lName="item.last_name" :imgUrl="item.n_image_url ? item.n_image_url :null" :theme="item.theme"></CustomAvatar2>
          </template>
          <v-list-item-title class="ml-2 mt-1">
            {{ item.title ? item.title : item.display_value ? item.display_value : item.name }}
            <v-list-item-subtitle v-if="item.subtitle">
              {{item.subtitle}}
            </v-list-item-subtitle>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-if="option==2">
        <v-list-item v-bind="option==2"  v-for="(item,index) in workspaceData" :key="index" :value="index" @click="getTeam(item)">
          <template v-if="showAvatar" v-slot:prepend>
            <CustomAvatar2  :size="'small'" :fName="itemfirst_name" :lName="item.last_name" :imgUrl="item.n_image_url ? item.n_image_url :null" :theme="item.theme"></CustomAvatar2>
          </template>
          <v-list-item-title class="ml-2 mt-1">
            {{ item.name}}
            <v-list-item-subtitle v-if="item.subtitle">
              {{item.subtitle}}
            </v-list-item-subtitle>
          </v-list-item-title>
        </v-list-item>
      </template>
      </v-list>
    </v-menu>
  </template>
  <script>
  import TextFieldComponent from '../../components/TextFieldComponent.vue';
  import moment from "moment-timezone";
  import CustomAvatar2 from '../../components/ui/CustomAvatar2.vue';
  import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
  import WorkSpaceTeamService from '@/api/service/WorkSpaceTeam/WorkSpaceTeamService';
  export default {
    props: ["activator", "options", "showAvatar","option"],
    components: {
      TextFieldComponent,
      CustomAvatar2
    },
    data() {
      return {
        activeIndex: 0,
        showSearch:false,
        searchText:"",
        activateMenu: this.activator,
        selected: false,
        userDetails: JSON.parse(localStorage.getItem("user_details")),
        workspaceData:null,
        members:[],
      };
    },
    watch: {  
      activator() {
        this.activateMenu = this.activator;
      }
    },
    created() {
      this.activateMenu = this.activator;
      this.getWorkSpace();
    },
    computed: {
        refreshOnUser() {
            return this.$store.getters.getAssignee;
        },
        filteredUsers() { 
            return this.options.filter(user => {
                const username = user && user.username? user.username.toLowerCase():'';
                const searchQuery = this.searchText.toLowerCase();
                return username.includes(searchQuery);
            });
        }
    },
    methods: {
      moment,
     async getTeam(item){
           await WorkSpaceTeamService.findAll({ "work_space_id": item.id })
           .then($options => {
                    this.members = $options 
                })
              console.log(this.members,"membersdata");
                this.members.forEach((item,index)=>{
                  this.$emit('menu-selected',item,index)
                }) 
        },
      async getWorkSpace() {
      let res = await WorkSpaceService.findAll();
      this.workspaceData = res;
      },
      async action(index, item) {
        this.$emit('menu-selected',item)
      },
      getAvatarName(value) {
        let name =
          value && value.first_name
            ? value.first_name.charAt(0)
            : "" + value && value.last_name
              ? value.last_name.charAt(0)
              : "";
        return name;
    }
}
  };
  </script>
  <style scoped>
  .image {
    width: 35px;
    height: 35px;
  }
  
  >>>::-webkit-scrollbar {
    width: 6px;
    /* height: 6px; */
  }
  
  /* Track */
  >>>::-webkit-scrollbar-track {
    background: #e8f3f9;
  }
  
  /* Handle */
  >>>::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.12);
  }
  </style>