import ApiService from '../../service/apiServiceIdentity.js';
const querystring = require('querystring')
var UserService = {

    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "users"
            $options.method = "get"

            if ($data) {
                $options.url = "users?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getLandDUser: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getLandDUser"
            $options.method = "get"

            if ($data) {
                $options.url = "getLandDUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getUserList: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getUserList"
            $options.method = "get"

            if ($data) {
                $options.url = "getUserList?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getReporterUserData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getReporterUserData"
            $options.method = "get"

            if ($data) {
                $options.url = "getReporterUserData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    
    getUserByRoleType: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getByRoleType?" + querystring.stringify($data)
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getViewUser: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getViewUser"
            $options.method = "post"

            if ($data) {
                $options.url = "getViewUser?"
                $options.data = $data
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },

    checkUser: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "checkUser"
            $options.method = "get"

            if ($data) {
                $options.url = "checkUser?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
  
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "users/" + $data.id
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "users/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    UserUpdate: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "verifyUser/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "users" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "users";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    addUser: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "addUser";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    }
};
export default UserService;