<template>
    <v-card
      style="background: #efeefd; border: 1px solid #dfdfdf"
      width="300"
      title="Summary"
      flat
    >
      <v-card-text>
        <v-row class="mb-2">
          <v-col cols="12" md="12">
            <v-row>
              <v-col>
                <span class="text-body-2"> Total Questions </span> <br />
                <span class="text-subtitle-1 font-weight-bold">{{ summaryData.total_questions }}</span>
              </v-col>
              <v-col>
                <span class="text-body-2"> Answered </span> <br />
                <span class="text-subtitle-1 font-weight-bold">{{ summaryData.total_answered }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12">
            <v-row>
              <v-col>
                <span class="text-body-2"> Correct Answers</span> <br />
                <span class="text-subtitle-1 font-weight-bold">{{ summaryData.correct_answer }}</span>
              </v-col>
              <v-col>
                <span class="text-body-2"> Incorrect Answers </span> <br />
                <span class="text-subtitle-1 font-weight-bold">{{ summaryData.incorrect_answer }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12">
            <v-row>
              <v-col>
                <span class="text-body-2"> Skipped </span> <br />
                <span class="text-subtitle-1 font-weight-bold">{{ summaryData.total_skipped }}</span>
              </v-col>
              <v-col>
                <span class="text-body-2"> Evaluator Review </span> <br />
                <span class="text-subtitle-1 font-weight-bold">{{ summaryData.total_evaluated }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <hr />
        <v-row class="mt-2">
          <v-col class="text-center" cols="12" md="12">
            <span class="text-body-2 font-weight-medium"> Total Marks </span><br />
            <span class="text-subtitle-1 font-weight-bold"> {{ summaryData.total_mark }} </span>
          </v-col>
          <v-col class="text-center" cols="12" md="12">
            <span class="text-body-2 font-weight-medium"> Percentage </span><br />
            <span class="text-subtitle-1 font-weight-bold">{{ summaryData.percentage }} &percnt;</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>
<script>
import AssessmentAnswerService from '@/api/service/AssessmentAnswers/AssessmentAnswerService';

export default {
  props: ["candidateId","interviewId"],
  data() {
      return {
        QuestionnaireData: [],
        summaryData: [],
      };
  },
  computed:{
      refresh(){
       return this.$store.getters.getRefreshSummary
      }
  },
  created() {
      if (this.candidateId) {
        this.getSummary(this.candidateId);
      }
  },
  watch: {
      candidateId() {
        this.getSummary(this.candidateId);
      },
      interviewId(){
        this.getSummary(this.candidateId)
      },
      refresh(){
        this.getSummary(this.candidateId)
      }
  },
  methods: {
      async getSummary(id) {
        let obj={candidateId: id}
        if(this.interviewId){
          obj={...obj,interviewId:this.interviewId}
        }
        let res = await AssessmentAnswerService.findSummary(obj);
        this.summaryData = res[0];
      },
  },
};
</script>
