<template>
  <v-container>
    <v-card class="pa-4 text-caption">
      <v-card-title>
        <span class="text-body-1 font-weight-bold"
          >Select The Exception Report</span
        >
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <AutoComplete width="400" :placeholder="'Select a SubCategory'" :items="subCategoryData" item-value="id"
          item-title="name" v-model="rulesData.sub_category_id" @update:modelValue="changeSubCategory" />
          </v-col>
          <v-col cols="12">
            <v-btn append-icon="mdi-plus" variant="text" v-if="rulesData.sub_category_id" class="text-body-2 font-weight-bold ml-n4"
          @click="dialog = true">Rules</v-btn>
          </v-col>
          <v-col cols="12">
            
          </v-col>
          <v-col cols="12">
            <v-container>
              <v-row
                v-for="list of listData"
                :key="list.id"
                class="row"
              >
                <v-col cols="12" md="2" sm="2">
                  <span class="text-subtitle-2"> If the subject contains </span>
                </v-col>
                <v-col cols="12" md="2" sm="1">
                  <span
                    class="font-weight-bold"
                    v-if="list && list.account_code"
                    >{{ list.account_code }}</span
                  >
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <span class="text-subtitle-2">Then default</span>
                </v-col>
                <v-col cols="12" md="2" sm="3">
                  <v-chip
                    label
                    class="bg-teal-accent-1 text-black font-weight-bold"
                    v-if="list && list.ws_name"
                    >{{ list.ws_name }}</v-chip
                  >
                  </v-col>
                  <v-col cols="12" md="2">
                  <v-chip
                    label
                    class="bg-teal-accent-1 text-black font-weight-bold"
                    v-if="list && list.project_code"
                    >{{ list.project_code }}</v-chip
                  >
                  <!-- <v-chip
                    label
                    class="bg-teal-accent-1 text-black font-weight-bold"
                    v-if="list && list.task_name"
                    >{{ list.task_name }}</v-chip
                  > -->
                </v-col>
                <v-col cols="12" md="2">
                  <i
                  class="pi pi-pencil show-cursor"
                  style="font-size: 16px"
                   @click="getEditValue(list)"
                />
                </v-col>
                <!-- <v-col cols="2" md="2" sm="2"
              ><v-chip
                label
                class="bg-teal-accent-1 text-black font-weight-bold"
                >{{ list.project_code }}</v-chip
              ></v-col
            >
            <v-col cols="2" md="2" sm="2"
              ><v-chip
                label
                class="bg-teal-accent-1 text-black font-weight-bold"
                >{{ list.task_name }}</v-chip
              ></v-col
            > -->
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="dialog" width="800px">
      <CreateException
        @closeDialog="close()"
        @reload="getExceptionData()"
        :categoryId="category_id"
        :subCategoryId="rulesData.sub_category_id"
        :id="editId"
        v-if="dialog"
        :viewType="viewType"
      />
    </v-dialog>
  </v-container>
</template>
<script>
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
// import EnumService from "@/api/service/Enum/EnumService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import CategoryService from "@/api/service/Category/CategoryService";
import ExceptionService from "@/api/service/Exception/exceptionService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import commonService from "@/common.service";
import CreateException from "./CreateException.vue";

export default {
  components: {
    AutoComplete,
    CreateException,
  },
  data() {
    return {
      rulesData: {
        sub_category_id: null,
      },
      selectedSubCategoryData: null,
      dialog: false,
      categoryData: [],
      subCategoryData: [],
      exceptionData: [],
      listData: [],
      editId: null,
      viewType: null,
      category_id: null,
    };
  },
  created() {
    this.getcategoryData();
  },
  methods: {
    changeSubCategory() {
      this.getExceptionData();
    },
    // customFilter(queryText, item) {
    //   const textOne = item.raw.name.toLowerCase();
    //   const searchText = queryText.toLowerCase();
    //   return (
    //     textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    //   );
    // },
    close() {
      this.dialog = false;
      (this.editId = null), (this.viewType = null);
    },
    getEditValue($event) {
      this.dialog = true;
      this.editId = $event.id;
      this.viewType = "edit";
    },
    handleSelection() {
      if (this.rulesData.sub_category_id !== null) {
        this.rulesData.category_id = this.subCategoryData.find(
          (item) => item.id === this.rulesData.sub_category_id
        ).category_id;
        this.getExceptionData(this.rulesData.sub_category_id);
      } else {
        this.listData = [];
      }
    },
    async save() {
      let $data = [];
      for (let $i = 0; $i < this.rulesData.account_id.length; $i++) {
        let obj = {
          account_id: this.rulesData.account_id[$i],
          category_id: this.rulesData.category_id,
          sub_category_id: this.rulesData.sub_category_id,
          project_id: this.rulesData.project_id,
          task_id: this.rulesData.task_id,
          work_space_id: this.rulesData.work_space_id,
        };
        $data.push(obj);
      }

      let output = await ExceptionService.create($data);
      console.log(output, "output");
      this.dialog = false;
    },
    async getExceptionData() {
      this.listData = [];
      try {
        let res = await ExceptionService.getExceptionList({
          active: 1,
          sub_category_id: this.rulesData.sub_category_id,
        });
        this.listData = JSON.parse(JSON.stringify(res));
        console.log(this.listData, "lietste datat");
      } catch (error) {
        console.error("Error fetching exception data:", error);
      }
    },
    async getcategoryData() {
      let res = await CategoryService.findAll({
        active: 1,
        code: "EXR",
      });
      this.categoryData =
        res && res.length > 0
          ? await commonService.getSort(res, "name", "asc")
          : [];
      this.category_id = this.categoryData[0].id;
      this.getSubCategoryData();
    },
    async getSubCategoryData() {
      let res = await SubCategoryService.findAll({
        active: 1,
        category_id: this.categoryData[0].id,
      });
      this.subCategoryData =
        res && res.length > 0
          ? await commonService.getSort(res, "name", "asc")
          : [];
    },
  },
};
</script>
<style scoped>
.row:hover {
  background-color: #fafafa;
}
</style>
