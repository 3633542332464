import IssueService from "@/api/service/Issue/IssueService"
class LiveBoardDataService {
    constructor() {

    }
    getHeaderData(group, type) {
        let headerData = [
            { key: "total_issues", label: "Total" },
            { key: "open_issues", label: "Open", code: 'open' },
            { key: "assigned_issues", label: "Assigned", code: 'assigned' },
            { key: "inprogress_issues", label: "In Progress", code: 'inprogress' },
            { key: "qapending_issues", label: "QA Pending", code: 'qapending' },
            { key: "clarification_issues", label: "Clarification", code: 'clarification' },
            { key: "hold_issues", label: "Hold", code: 'hold' },
            { key: "it_issues", label: "IT", code: 'itsupportrequired' },
            { key: "pastdue_issues", label: "Past Due" },
            { key: "completed_issues", label: "Closed/Completed Today" },
        ]
        if (group && group == 'dailyActivity') {
            headerData.splice(1, 0, { key: "unassigned_issues", label: "Un Assigned" });
        }
        let startData = this.getHeader(group, type)
        return type != 'headers2' ? startData.concat(headerData) : headerData
    }
    getHeader(group, type) {
        let $h = [{ key: "", label: "" }]
        let accountkey = { key: "account_name", label: "Account" }
        let userkey = { key: "user_name", label: "User" }
        let dailyActivitykey = { key: "created", label: "Created" }
        let keyValue = type == 'headers' && group == 'account' ? accountkey : type == 'headers' && group == 'employee' ?userkey : group == 'dailyActivity' ? dailyActivitykey  : type == 'exheaders' && group == 'account' ? userkey : accountkey
        $h.push(keyValue)
        return $h
    }
    async getIssueData2($header, $user, $workspace, $account, unAssigned, group) {
        let $payload = this.getPayload($header, $user, $workspace, $account, unAssigned, group)
        console.log($payload, "payload data")
        let res = await IssueService.findAllIssues($payload)
        console.log(res, "response data")
        return res && res.length > 0 ? res : []
    }
    getPayload($header, $user, $workspace, $account, unAssigned, group) {
        let obj = {}
        obj.stage = $header && $header.code ? $header.code : null
        obj.past_due = $header && $header.key == 'pastdue_issues' ? true : false
        obj.todayCompleted = $header && $header.key == 'completed_issues' ? true : false
        obj.account_id = $account && ($account.account_id || $account.accountId) ? ($account.account_id || $account.accountId) : null
        obj.work_space_id = $workspace && $workspace.workSpaceId ? $workspace.workSpaceId : null
        if (group && group == 'dailyActivity') {
            obj['created'] = $user && ($user.id) ? $user.id : null
            if ($header.key == 'unassigned_issues') {
                obj['unAssigned'] = 'true'
            }
        } else {
            obj['next_action'] = $user && ($user.userId || $user.user_id) ? ($user.userId || $user.user_id) : null
            obj['unAssigned'] = unAssigned
        }
        
        obj = this.getRemoveNullValues(obj)
        return obj
    }
    getRemoveNullValues(obj) {
        let keys = Object.keys(obj)
        for (let item of keys) {
            if (!obj[item]) {
                delete obj[item]
            }
        }
        return obj
    }

}
export default new LiveBoardDataService();