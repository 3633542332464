<template>
  <SkeletonForList v-if="loading === true" />

  <v-table v-else class="table pa-2 mb-3" height="63vh" style="border-radius: 8px">
    <thead>
      <tr class="headerRow">
        <th class="text-left"></th>
        <th class="text-left">User</th>
        <th class="text-left">Account#</th>
        <th class="text-left color">Task</th>
        <!-- <th class="text-left">Issue Type</th> -->
        <th class="text-left">Sub Category</th>
        <th class="text-left">Mail Date</th>
        <th class="text-left">Created</th>
        <th class="text-left">Due Date</th>

        <th class="text-left">Stage</th>
        <th class="text-left">Reported By</th>
        <th class="text-left">Ref#</th>

        <th class="text-left">Copy</th>
      </tr>
    </thead>
    <tbody style="text-wrap: wrap; line-height: normal">
      <tr v-for="item,index in allIssueData" :key="item.name" @click.stop="atClick(item)">
        <td>
          <v-checkbox color="primary" @click.stop="selectTheIssue(index)"></v-checkbox>
        </td>
        <td>
          <CustomAvatar2 v-if="item && item.next_action" :fName="item.first_Name" :lName="item.last_name"
            :theme="item.theme" :imgUrl="item.n_image_url" :size="'small'">
            <!-- <v-tooltip activator="parent" location="start">
            <span class="avatarTip">{{item && item.next_action_name?item.next_action_name : ''}}</span> 
            </v-tooltip> -->
          </CustomAvatar2>

        </td>
        <td class="font">
          <CustomAvatar2 :arounded="0" v-if="item && item.account_code" :acCode="item.account_code"
            :fName="item.account_name" :type="'account'" />
          <!-- <v-tooltip activator="parent" location="end" >
             <span class="avatarTip">{{item && item.account_name?item.account_name : ''}}</span>
             </v-tooltip> -->
        </td>
        <td class="font">
          <!-- {{item.id}} -->
          {{ item.name.length
            < 55 ? item.name : item.name.substring(0, 53) + ".." }} <v-icon v-if="item && item.priority == 'critical'"
            class="pi pi-exclamation-triangle" style="color:red;font-size: 12px;font-weight: 800;" />

          <v-icon v-if="item.week_end_mails == 'yes'" class="mdi mdi-circle-half-full" color="blue" />

        </td>

        <!-- <td>
          <v-chip v-if="item.issue_type_name" class="onText title-case" label v-on="on">
            {{ item.issue_type_name ? item.issue_type_name : "" }}
          </v-chip>
        </td> -->
        <td>
          <v-chip v-if="item.sub_category_name" class="onText2 title-case" label v-on="on">
            {{ item.sub_category_name }}</v-chip>
        </td>
        <td class="font">
          {{
            item && item.mail_date
            ? getformteTheDate(item.mail_date)
            : ""
          }}
          <br />
          {{
            item && item.mail_date
            ? moment(item.mail_date).format("hh:mm A")
            : ""
          }}
        </td>
        <td class="font">
          {{
            item && item.created
            ? getformteTheDate(item.created)
            : ""
          }}
          <br />
          {{
            item && item.created ? moment(item.created).format("hh:mm A") : ""
          }}
        </td>
        <td class="font" :class="isDateOverDue(item.end_date)">
          {{
            item &&
            item.end_date &&
            moment(item.end_date).format("MM/DD/YYYY hh:mm A") !=
            "Invalid date"
            ? getformteTheDate(item.end_date)
            : ""
          }}
          <br />
          {{
            item &&
            item.end_date &&
            moment(item.end_date).format("MM/DD/YYYY hh:mm A") !=
            "Invalid date"
            ? moment(item.end_date).format("hh:mm A")
            : ""
          }}
        </td>
        <td>
          <v-chip v-if="item.stage_name" class="onText3" label v-on="on">
            <b>{{ item.stage_name ? item.stage_name : "" }}</b></v-chip>
        </td>
        <td class="font title-case">{{ item.submitted_name }}</td>
        <td class="font">
          <b>{{ item.reference_num }}</b>
        </td>

        <td class="font" @click.stop="copyTheIssue(item)">
          <img src="//synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/dublicate.svg" style="width: 16px" />
        </td>
      </tr>
    </tbody>
    <v-dialog persistent v-model="dialog" width="700px">
      <CopyIssue @closeDialog="setClose()" @reload="getRole()" :item="copyItem" />
    </v-dialog>
  </v-table>
</template>

<script>
import moment from "moment";
import 'moment-timezone';
import lodash from "lodash";
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import IssueService from "@/api/service/Issue/IssueService";
import SkeletonForList from "./SkeletonForList.vue";
import StageService from "@/api/service/Stage/StageService";
import CopyIssue from "./CopyIssue.vue.vue";

export default {
  props: ["filters", "sortData", "searchData", "issueData", "mainFilter"],
  components: {
    CustomAvatar2,
    SkeletonForList,
    CopyIssue,
  },

  data() {
    return {
      loading: true,
      dialog: false,
      allIssueData: [],
      stageData: [],
      issueType: [],
      issueFilterData: [],
      copyItem: null,
    };
  },
  watch: {
    mainFilter() {
      // this.getissuetype(this.mainFilter);
    },
    issueData: {
      handler: function () {
        this.getWithoutClosedStage();
      },
      deep: true
    },

    filters() {
      // this.getissuetype(this.filters);
    },
    sortData() {
      this.getWithoutClosedStage()

      this.sortIssueData(this.sortData);
    },
    searchData() {
      this.searchData2(this.searchData);
    },
  },
  created() {
    this.getWithoutClosedStage();
    this.getStageData();
  },
  mounted() {
    // this.getissuetype({});
  },

  methods: {
    getformteTheDate(data){
      console.log(data,"hello date")
      const date = new Date(data);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();
      return `${month}/${day}/${year}`;
    },
    selectTheIssue(index){
      this.allIssueData[index].selectedIssue=this.allIssueData[index] && this.allIssueData[index].selectedIssue?!this.allIssueData[index].selectedIssue:true
      this.$emit('selected-user',this.allIssueData)
    },
    setClose() {
      this.dialog = false;
      // this.$emit("refreshTheData");
    },
    async copyTheIssue(item) {
      this.copyItem = null;
      this.dialog = true;
      this.copyItem = item;
      console.log(this.copyItem, 'kyaarhabvxj')
    },
    async getWithoutClosedStage() {
      if (this.issueData && this.issueData.length > 0) {
        let data = await this.issueData.filter((k) => k.stage != "closed");
        console.log(data, "inside a dataaaaa");
        this.allIssueData = [];
        this.allIssueData = [...data];
      }
      else {
        this.allIssueData = [];
      }
    },
    isDateOverDue(date) {
      if (date) {
        let currentDate = new Date();
        let dueDate = new Date(date);
        return currentDate > dueDate ? "text-red" : "text-orange";
      }
    },
    moment,
    async getStageData() {
      try {
        let res = await StageService.findAll();
        this.stageData = res.length > 0 ? res : [];
        this.loading = false;
      } catch (e) {
        this.stageData = [];
      }
    },
    getStage(value) {
      let opt = this.stageData.filter((k) => k.code == value);
      return opt && opt.length > 0 ? opt[0].name : "";
    },
    getAvatarName(value) {
      let fName = value && value.first_Name ? value.first_Name.charAt(0) : "";
      let lName = value && value.last_name ? value.last_name.charAt(0) : "";
      let name = fName + lName;
      return name;
    },
    searchData2($data) {
      this.issueType = this.issueFilterData.filter(
        (v) => v.name && v.name.toLowerCase().includes($data.toLowerCase())
      );
    },
    sortIssueData(data) {
      console.log(data, "vrg4");
      this.issueType = lodash.orderBy(
        this.issueType,
        [`${data.value}`],
        ["desc"]
      );
    },

    atClick(item) {
      let status = this.$store.getters.getMyspace;
      localStorage.setItem(
        "_WS_DEFAULTS_",
        JSON.stringify({
          workspace: { id: item.work_space_id, name: item.work_space_name },
        })
      );
      localStorage.setItem("workSpaceId", item.work_space_id);

      // this.$router.push({ path: "/issueView2", query: { id: item.id } });
      this.$store.dispatch("setIssueInView", item.id);
      let wsDefaults = {
        workspace: { id: item.work_space_id, name: item.work_space_name },
        account: { id: item.account_id, name: item.account_name },
        project: { id: item.project_id, name: item.project_name },
      };
      let wsDefaultsData = JSON.stringify(wsDefaults);
      localStorage.setItem("_WS_DEFAULTS_", wsDefaultsData);
      // console.log(wsDefaultsData,'alert111111111')
      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      this.$store.commit("CHANGE_MY_SPACE", !status);
    },
    async getissuetype(cond) {
      console.log(cond, 'dbjhbbscbs')
      var res = await IssueService.getBackLogData(cond);
      this.issueType = res;
      this.issueFilterData = JSON.parse(JSON.stringify(res));
    },
  },
};
</script>

<style scoped>
.headerRow {
  line-height: normal;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #fafafa;
}

.v-chip.v-chip--size-default {
  --v-chip-size: 0.875rem;
  --v-chip-height: 22px;
  font-size: 12px;
  padding: 0 12px;
}

.v-table--density-default>.v-table__wrapper>table>tbody>tr>td,
.v-table--density-default>.v-table__wrapper>table>thead>tr>td,
.v-table--density-default>.v-table__wrapper>table>tfoot>tr>td {
  height: calc(var(--v-table-row-height, 45px) + 0px);
}

.onText {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
  background: #f8d6e6 !important;
  opacity: 1 !important;
}

.onText2 {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
  background: #d6f8f6 !important;
  opacity: 1 !important;
}

.onText3 {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
  background: #e2d6f8 !important;
  opacity: 1 !important;
}

.font {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
}

.v-table {
  --v-table-header-height: 50px;
  border-radius: inherit;
  line-height: 0.5;
  max-width: 100%;
}

tr:hover {
  background-color: #fafafa;
  cursor: pointer;
}

v-table>.v-table__wrapper>table>tbody>tr>th,
.v-table>.v-table__wrapper>table>thead>tr>th,
.v-table>.v-table__wrapper>table>tfoot>tr>th {
  font-weight: bolder;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-align: start;
  color: #000000;
}

.td {
  border-style: dashed;
}

.avatarTip {
  text-transform: capitalize;
  /* opacity: 0.8; */
  /* font-size: 0.575rem; */
}

>>>.v-input__details {
    align-items: flex-end;
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    grid-area: messages;
    letter-spacing: 0.0333333333em;
    line-height: normal;
    min-height: 22px;
    padding-top: 6px;
    overflow: hidden;
    justify-content: space-between;
    display: contents;
}
>>>.v-checkbox .v-selection-control {
    min-height: fit-content;
}
</style>