class DashboardFData {
    getTheData(){
        let data =[
                {
                    code: "ACCOUNTSBOARD",
                    summary:'Task count as per client/workspace with the stage and how much completed today.',
                    imageUrl:
                    "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/live.png",
                },
                {
                    code: "EMPLOYEEBOARD",
                    summary:'No of task or count that allocated to each resource as per stage.',
                    imageUrl:
                    "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/account-live.png",
                },
                {
                    code: "DAILYACTIVITYBOARD",
                    summary:'Total task can be viewed as per task created date and their stage in Synergy.',
                    imageUrl:
                      "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/daily-activity.svg",
                }


                
        ]
        return data
    }
}
export default new DashboardFData();
