<template>
  <v-container fluid justify-center>
    <UserCardSkeleton v-if="loading === true" />
    <v-card v-else flat density="compact" min-height="90vh">
      <v-card-title density="compact">
        <v-row no-gutters dense>
          <v-col cols="6" dense>
            <span class="subtitle">{{
              issueData && issueData.reference_num
              ? issueData.reference_num
              : ""
            }}</span>

          </v-col>
          <v-col align="end" cols="6" class="text-right">
            <ButtonBar :username="username" v-if="issueData && issueData.id" @timeClicked="timeDialogue()"
              @qaClicked="qaDialogue()" :issueId="issueData && issueData.id" @refreshData="getIssueData()"
              :issueData="issueData" @userSelected="userSelected($event)" :nextAction="issueData.next_action" @checkStage="checkStage"/>
          </v-col>

          <v-col cols="6" class="pt-0 mb-2 mt-0">
            <b style="text-wrap: wrap">
              {{ issueData && issueData.name ? issueData.name : "" }}</b>
            <v-btn @click="editIssue()" variant="text" class="btn-color"
              v-if="issueData && issueData.stage != 'closed' && showEdit">
              <img style="width: 18px; height: 18px"
                src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg" />
            </v-btn>
          </v-col>

          <v-col cols="12" class="subtitle">
            <v-chip label class="tags pink-bg mx-1 ml-0 title-case" size="small"
              v-if="issueData && issueData.category_name">
              {{
                issueData && issueData.category_name && issueData.category_name
              }}
            </v-chip>
            <v-chip label class="tags teal-bg mx-1 title-case" size="small"
              v-if="issueData && issueData.sub_category_name">
              {{
                issueData &&
                issueData.sub_category_name &&
                issueData.sub_category_name
              }}
            </v-chip>
            <v-chip id="issue-type-menu-activator" @click="toggleMenu($event, 'issueType')" label
              class="tags purple-bg mx-1 title-case" size="small">
              <!-- {{ getIssueType }} -->
              {{
                issueData && issueData.issue_type_name && issueData.issue_type_name
              }}
              <v-icon v-if="issueData && issueData.stage != 'closed' && showEdit">mdi-arrow-down-drop-circle</v-icon>
            </v-chip>
            <v-chip label class="tags gold-bg mx-1 title-case" size="small" v-if="issueData && issueData.task_name">
              {{ issueData && issueData.task_name && issueData.task_name }}
            </v-chip>

            <v-chip id="priority-menu-activator" @click="toggleMenu($event, 'priority')" label :style="{
              backgroundColor: getBgColors(issueData && issueData.priority),
            }" class="tags mx-1" size="small">
              {{
                issueData && issueData.priority
                ? issueData.priority.charAt(0).toUpperCase() +
                issueData.priority.slice(1)
                : ""
              }}
              <v-icon v-if="issueData && issueData.stage != 'closed' && showEdit">mdi-arrow-down-drop-circle</v-icon>
            </v-chip>

            <v-chip id="stage-menu-activator" @click="toggleMenu($event, 'stage')" label class="tags purple-bg mx-1"
              size="small" style="background-color: #ffecd0 !important">
              {{ getStage(issueData.stage) }} 
              <v-icon v-if="showEdit">mdi-arrow-down-drop-circle</v-icon>
            </v-chip>
          </v-col>

          <div class="errorMsg" v-if="showErrorStage">
            <span class="ml-3">Close all sub task first</span>
          </div>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters dense>
          <v-col :cols="halfScreen ? 12 : 9" class="pr-2">
            <v-card flat class="pa-1 rounded" :elevation="0">
              <v-card-item class="pl-0">
                <v-text class="subtitle font-weight-black">Description</v-text>
                <v-btn @click="editDesc()" variant="text" class="btn-color"
                  v-if="issueData && issueData.stage != 'closed' && showEdit && !issueData.mail_box_id">
                  <img style="width: 16px; height: 16px"
                    src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg" />
                </v-btn>
                <v-dialog v-model="dDialog" :width="600">
                  <EditDescription @closeDialog="close()" ref="EditDescription" :id="issueData.id"
                    @reload="getIssueData()" />
                </v-dialog>
                <v-btn @click="openDialog" variant="text" class="btn-color" v-if="issueData && issueData.description">
                  <img style="width: 20px; height: 18px"
                    src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/expand.png" /></v-btn>
              </v-card-item>
              <v-card-text class="description" style="
                  background-color: #e0f3fd53;
                  min-height: 40px;
                  max-height: 300px;
                  overflow-y: auto;
                  font-size: 12px;
                  font-weight: 400;
                  padding-top: 1%;
                " v-html="issueData && issueData.description
                  ? issueData.description
                  : ''
                  " />
            </v-card>
            <Attachments v-if="issueData && issueData.id" :stage="issueData && issueData.stage ? issueData.stage : ''"
              :issueId="issueData && issueData.id" :showEdit="showEdit" class="mt-5 ml-2" :refreshAmt="refreshAmt" />
            <SubTasks :subTaskDefaults="getSubTaskDefaults()"
              v-if="issueData && issueData.id && !issueData.parent_issue_id" :parentId="issueData && issueData.id"
              class="ml-2" />
            <v-col v-if="halfScreen" cols="12">
              <v-row>
                <v-col cols="6">
                  <v-card variant="outlined" class="mb-7 grey-bg outlined">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-card-title class="hoverEdit" v-bind="props">
                        <v-row class="toggle-button" @click="toggleCollapse">
                          <v-col colsl="10">
                            <span> Details </span>
                          </v-col>
                          <v-col align="end">
                            <span>
                              <img v-if="isHovering && issueData.stage != 'closed' && showEdit" @click.stop="editDetails"
                                variant="text" class="editDetails btn-color"
                                src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
                                style="width: 15px; height: 15px" />
                              <v-icon class="chevron">{{
                                isCollapsed ? "mdi-chevron-down" : "mdi-chevron-up"
                              }}</v-icon>
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-title>
                    </v-hover>
                    <v-divider />
                    <v-card-text v-show="!isCollapsed">
                      <v-row no-gutters dense>
                        <v-col cols="6" class="py-2 subtitle bold-medium">
                          Created On <br />
                        </v-col>
                        <v-col cols="6" class="py-2">
                          {{
                            issueData && issueData.created && moment((issueData.created)).format(
                              "MM/DD/YYYY hh:mm A") != 'Invalid date'
                            ? moment((issueData.created)).format(
                              "MM/DD/YYYY hh:mm A"
                            )
                            : ""
                          }}
                        </v-col>

                        <v-col cols="6" v-if="issueData && issueData.mail_date" class="py-2 subtitle bold-medium">
                          Mail Received Date <br />
                        </v-col>
                        <v-col cols="6"  v-if="issueData && issueData.mail_date" class="py-2">
                          {{
                            issueData && issueData.mail_date && moment((issueData.mail_date)).format(
                              "MM/DD/YYYY hh:mm A") != 'Invalid date'
                            ? moment((issueData.mail_date)).format(
                              "MM/DD/YYYY hh:mm A"
                            )
                            : ""
                          }}
                        </v-col>

                        <v-col cols="6" class="py-2 subtitle bold-medium">
                          Due On <br />
                        </v-col>
                        <v-col cols="6" class="py-2" :class="isDateOverDue(issueData.end_date)">
                          {{
                            issueData && issueData.end_date && moment((issueData.end_date)).format(
                              "MM/DD/YYYY hh:mm A") != 'Invalid date'
                            ? moment((issueData.end_date)).format(
                              "MM/DD/YYYY hh:mm A"
                            )
                            : ""
                          }}
                        </v-col>
                        <v-col cols="6" class="py-2 subtitle bold-medium">
                          Assignee <br />
                        </v-col>
                        <v-col cols="6" class="py-2">
                          <span>
                            <UserSelection v-if="issueData && issueData.next_action" :theme="issueData &&
                              issueData.next_action_users &&
                              issueData.next_action_users.theme
                              ? issueData.next_action_users.theme
                              : ''
                              " :stage="issueData && issueData.stage ? issueData.stage : ''
    " :showEdit="showEdit" :nextActionId="issueData && issueData.next_action
    ? issueData.next_action
    : null
    " @userSelected="onUserSelected($event)" />
                          </span>
                        </v-col>

                        <v-col cols="6" class="py-2 subtitle bold-medium">
                          Reported By <br />
                        </v-col>
                        <v-col cols="6" class="py-2">
                          <v-row>
                            <v-col cols="2">
                              <CustomAvatar2 class="avatrBig ma-0 mr-2 show-cursor" v-if="issueData && issueData.submitted_by" :fName="issueData.first_Name" :lName="issueData.last_name" :theme="issueData.theme"  :size="'small'" />
                            </v-col>
                            <v-col cols="10">
                              {{ submittedByName ? submittedByName : "" }}
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="6" class="py-2 subtitle bold-medium" v-if="this.skillId">
                          Skill<br />
                        </v-col>
                        <v-col cols="6" class="py-2" v-if="this.skillId">
                          {{
                            issueData && issueData.skill_name ? issueData.skill_name : ""
                          }}
                        </v-col>

                        <v-col cols="6" class="py-2 subtitle bold-medium" v-if="this.quantityID">
                          Quantity<br />
                        </v-col>
                        <v-col cols="6" class="py-2" v-if="this.quantityID">
                          {{
                            issueData && issueData.quantity ? issueData.quantity : ""
                          }}
                        </v-col>

                        <v-col cols="6" class="py-2 subtitle bold-medium">
                          Estimates<br />
                        </v-col>
                        <v-col cols="6" class="py-2" v-if="issueData && issueData.estimate != 0">
                          {{
                            issueData && issueData.estimate ? issueData.estimate : ""
                          }} minute(s)
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <Time v-if="issueData && issueData.id" :id="issueData && issueData.id" />
                </v-col>
                <v-col cols="6">
                  <QAtaskDetails :halfScreen="halfScreen" @qaClicked="qaDialogue()" @setRefreshFalse="updateQA = false"
                    :issueId="issueData && issueData.id" :issueData="issueData" :refresh="updateQA" />
                </v-col>
                <v-col cols="6">
                  <QAtaskDetails :halfScreen="halfScreen" type="review" @qaClicked="qaDialogue()"
                    @setRefreshFalse="updateQA = false" :issueId="issueData && issueData.id" :issueData="issueData"
                    :refresh="updateQA" />
                </v-col>
              </v-row>
            </v-col>
            <Comments :issueId="issueData && issueData.id ? issueData.id : null" class="ml-2"
              @uploadComment="refreshData()" />
          </v-col>
          <v-col v-if="!halfScreen" cols="3">
            <v-card variant="outlined" class="mb-7 grey-bg outlined">
              <v-hover v-slot="{ isHovering, props }">
                <v-card-title class="hoverEdit" v-bind="props">
                  <v-row class="toggle-button" @click="toggleCollapse">
                    <v-col colsl="10">
                      <span> Details </span>
                    </v-col>
                    <v-col align="end">
                      <span>
                        <img v-if="isHovering && issueData.stage != 'closed' && showEdit" @click.stop="editDetails"
                          variant="text" class="editDetails btn-color"
                          src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
                          style="width: 15px; height: 15px" />
                        <v-icon class="chevron">{{
                          isCollapsed ? "mdi-chevron-down" : "mdi-chevron-up"
                        }}</v-icon>
                      </span>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-hover>
              <v-divider />
              <v-card-text v-show="!isCollapsed">
                <v-row no-gutters dense>
                  <v-col cols="6" class="py-2 subtitle bold-medium">
                    Created On <br />
                  </v-col>
                  <v-col cols="6" class="py-2">
                    {{
                            issueData && issueData.created && moment((issueData.created)).format(
                              "MM/DD/YYYY hh:mm A") != 'Invalid date'
                            ? moment((issueData.created)).format(
                              "MM/DD/YYYY hh:mm A"
                            )
                            : ""
                          }}
                  </v-col>

                  <v-col cols="6"  v-if="issueData && issueData.mail_date" class="py-2 subtitle bold-medium">
                          Mail Received Date <br />
                        </v-col>
                        <v-col cols="6"  v-if="issueData && issueData.mail_date" class="py-2">
                          {{
                            issueData && issueData.mail_date && moment((issueData.mail_date)).format(
                              "MM/DD/YYYY hh:mm A") != 'Invalid date'
                            ? moment((issueData.mail_date)).format(
                              "MM/DD/YYYY hh:mm A"
                            )
                            : ""
                          }}
                          <!-- {{
                            issueData && issueData.mail_date
                            ? moment(issueData.mail_date).format("MM/DD/YY")
                            : ""
                          }} -->
                        </v-col>

                  <v-col cols="6" class="py-2 subtitle bold-medium">
                    Due On <br />
                  </v-col>
                  <v-col cols="6" class="py-2" :class="isDateOverDue(issueData.end_date)">
                    {{
                      issueData && issueData.end_date && moment((issueData.end_date)).format(
                        "MM/DD/YYYY hh:mm A") != 'Invalid date'
                      ? moment((issueData.end_date)).format(
                        "MM/DD/YYYY hh:mm A"
                      )
                      : ""
                    }}
                  </v-col>
                  <v-col cols="6" class="py-2 subtitle bold-medium">
                    Assignee <br />
                  </v-col>
                  <v-col cols="6" class="py-2">
                    <span>
                      <UserSelection :theme="issueData &&
                            issueData.next_action_users &&
                            issueData.next_action_users.theme
                            ? issueData.next_action_users.theme
                            : ''
                          " :stage="issueData && issueData.stage ? issueData.stage : ''"
       :showEdit="showEdit" :nextActionId="issueData && issueData.next_action
    ? issueData.next_action
    : null
    " @userSelected="onUserSelected($event)" />
                    </span>
                  </v-col>

                  <v-col cols="6" class="py-2 subtitle bold-medium">
                    Reported By <br />
                  </v-col>
                  <v-col cols="6" class="py-2">
                    <v-row>
                      <v-col cols="2">
                        <!-- <CustomAvatar class="avatrBig ma-0 mr-2 show-cursor" v-if="issueData && issueData.submitted_by"
                          :userId="issueData.submitted_by" :size="'small'" /> -->
                          <CustomAvatar2 class="avatrBig ma-0 mr-2 show-cursor" v-if="issueData && issueData.submitted_by" :fName="issueData.submitted_name" :theme="issueData.theme" :imgUrl="issueData.image_url"  :size="'small'" />
                      </v-col>
                      <v-col cols="10">
                        {{ submittedByName ? submittedByName : "" }}
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="6" class="py-2 subtitle bold-medium" v-if="this.skillId">
                    Skill<br />
                  </v-col>
                  <v-col cols="6" class="py-2" v-if="this.skillId">
                    {{
                      issueData && issueData.skill_name ? issueData.skill_name : ""
                    }}
                  </v-col>

                  <v-col cols="6" class="py-2 subtitle bold-medium" v-if="this.quantityID">
                    Quantity<br />
                  </v-col>
                  <v-col cols="6" class="py-2" v-if="this.quantityID">
                    {{
                      issueData && issueData.quantity ? issueData.quantity : ""
                    }}
                  </v-col>

                  <v-col cols="6" class="py-2 subtitle bold-medium">
                    Estimates<br />
                  </v-col>
                  <v-col cols="6" class="py-2" v-if="issueData && issueData.estimate != 0">

                    {{
                      issueData && issueData.estimate ? issueData.estimate : ""
                    }} minute(s)
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <QAtaskDetails :halfScreen="halfScreen" type="review" @qaClicked="qaDialogue()"
              @setRefreshFalse="updateQA = false" :issueId="issueData && issueData.id" :issueData="issueData"
              :refresh="updateQA" />

            <QAtaskDetails :halfScreen="halfScreen" class="mt-3" @qaClicked="qaDialogue()"
              @setRefreshFalse="updateQA = false" :issueId="issueData && issueData.id" :issueData="issueData"
              :refresh="updateQA" />

            <Time v-if="issueData && issueData.id" :id="issueData && issueData.id" class="mt-7" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
  <v-dialog v-model="dialogOpen" class="dialogOpen" width="55%" max-width="55%">
    <v-card class="descriptionCard">
      <v-row>
        <v-col cols="4">
          <v-card-title>Description</v-card-title>
        </v-col>
        <v-col class="descBtn">
          <v-btn @click="editDesc()" variant="text" class="ml-7 editBtn btn-color" v-if="issueData.stage != 'closed' && showEdit && !issueData.mail_box_id
            ">
            <img src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/edit.svg"
              style="width: 16px; height: 20px" />
          </v-btn>
          <v-btn @click="closeDialog()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-col>
      </v-row>
      <v-card-text class="description" v-html="issueData.description" />
    </v-card>
  </v-dialog>

  <v-dialog v-model="editDialog" :width="600">
    <IssueEdit @closeDialog="close()" ref="IssueEdit" :id="issueData.id" :parent_id:="issueData.parent_issue_id"
      :parentIssueId="issueData.parent_issue_id" @reload="getIssueData()" />
  </v-dialog>
  <v-dialog v-model="editDelDialog" :width="600" persistent>
    <EditDetails @closeDialog="close()" ref="EditDetails" :id="issueData.id" @reload="getIssueData()" />
  </v-dialog>

  <v-dialog v-model="timesheetDialog" :width="900" persistent>
    <TimeSheets ref="TimeSheets" @closeDialog="close()" :timeSheetDefaults="getTimeSheetDefaults()"
      @reload="getIssueData()"></TimeSheets>
  </v-dialog>

  <v-dialog v-model="taskDialog" :width="700" persistent>
    <QaTask ref="QaTask" @closeDialog="close()" :timeSheetDefaults="getTimeSheetDefaults()" @reload="getIssueData()">
    </QaTask>
  </v-dialog>
  <!-- <MenuOptions v-if="showEdit" :options="stages" activator="#stage-menu-activator" @menu-selected="stageChanged" />
  <MenuOptions :options="issueTypes" activator="#issue-type-menu-activator" @menu-selected="issueTypeChanged" /> -->
  <!-- <MenuOptions v-if="issueData && issueData.stage != 'closed' && showEdit" :options="priorities"
    activator="#priority-menu-activator" @menu-selected="priorityChanged" /> -->
  <primeMenu :options="priorities" @optionselected="priorityChanged" ref="childMenu" />
  <primeMenu :options="stages" @optionselected="stageChanged" :menuType="'stage'" ref="childStage" />
  <primeMenu :options="issueTypes" :menuType="'issueType'" @optionselected="issueTypeChanged" ref="childIssueType" />
</template>
<script>
import primeMenu from "./View2/primeMenu.vue";
import UserCardSkeleton from "./SkeletonForIndex.vue";
// import MenuOptions from "../../../menus/MenuOptions.vue";
import EditDetails from "./EditDetails.vue";
import Comments from "./Comments.vue";
// import UserService from "@/api/service/User/UserService";
import IssueService from "@/api/service/Issue/IssueService";
import StageService from "@/api/service/Stage/StageService";
import EnumService from "@/api/service/Enum/EnumService";
import Time from "../Time.vue";
import TimeSheets from "./TimeSheets.vue";
// import documentService from "@/api/service/Document/DocumentService";
// import ForumService from "@/api/service/Forum/ForumService";
import Attachments from "./Attachments.vue";
import SubTasks from "./SubTasks.vue";
import moment from "moment";
import ButtonBar from "./ButtonBar.vue";
import QaTask from "./QaTask.vue";
import UserSelection from "../../../menus/UserSelection.vue";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
import UserService from "@/api/service/UserService";
import EditDescription from '../EditDescription.vue';
import QAtaskDetails from "./QAtaskDetails.vue";
import IssueEdit from "../IssueEdit.vue";
import ProjectService from "@/api/service/Project/ProjectService";
import CustomAvatar2 from '../../../components/ui/CustomAvatar2.vue';
export default {
  props: ['itemId', 'processView'],
  components: {
    UserCardSkeleton,
    primeMenu,
    TimeSheets,
    // MenuOptions,
    QaTask,
    ButtonBar,
    EditDescription,
    IssueEdit,
    Attachments,
    SubTasks,
    Comments,
    Time,
    EditDetails,
    QAtaskDetails,
    UserSelection,
    CustomAvatar2,
  },
  data() {
    return {
      allIssuesClosed: false,
      showErrorStage: false,
      loading: true,
      issueTypes: [],
      updateQA: false,
      stages: [],
      stageData: [],
      halfScreen: false,
      selectedOption: null,
      priorities: [],
      showEdit: true,
      isCollapsed: false,
      issueData: {},
      issueId: "",
      refreshAmt: false,
      nextAction: {},
      comments: [],
      viewsByUser: [],
      documents: [],
      dDialog: false,
      editDialog: false,
      dialogOpen: false,
      editDelDialog: false,
      timesheetDialog: false,
      taskDialog: false,
      userData: {},
      submittedByName: "",
      ID: null,
      quantityID: '',
      skillId: '',
      projectData: {},
      parent: {},
      // stageChange: false
    };
  },
  async created() {
    let wsDetails = JSON.parse(localStorage.getItem('_WS_DEFAULTS_'))
    this.userData = JSON.parse(localStorage.getItem('user_detail'))
    if (wsDetails && wsDetails.project && wsDetails.project.id) {
      try {
        let $projects = await ProjectService.findAll({ id: wsDetails.project.id })
        this.skillId = $projects[0].skill
        this.quantityID = $projects[0].quantity
      }
      catch (e) {
        this.projectData = {}
      }
    }

    this.userData = JSON.parse(localStorage.getItem("user_details"));
    if (this.getId) {
      this.halfScreen = false
      await this.getIssueData(null);
      this.ifChildClosed()
    }
    else {
      this.halfScreen = true
      await this.getIssueData(this.itemId);
      this.ifChildClosed()
    }
    // this.getParentstage()

    this.issueId = this.issueData.id;
    this.getPriority();
    // this.isStageChange()
    this.loading = false
  },
  updated() { },
  computed: {
    getRefresh() {
      return this.$store.getters.getInProgress;
    },

    changeToPending() {
      return this.$store.getters.getPending;
    },
    timerPushed() {
      return this.$store.getters.getRefreshTimeSheet;
    },
    // getIssueType(){
    //   if(this.issueData && this.issueData.issue_type_name){
    //     let opt =this.issueTypes.filter(
    //       (k)=>k.data_value == this.issueData.issue_type_name
    //     )
    //     return opt && opt.length > 0 ? opt[0].display_value : "";
    //   }
    //   else{
    //     return "";
    //   }
    // },
     
    getId() {
      return this.$route && this.$route.query && this.$route.query.id ? this.$route.query.id : null;
    },
  },
  watch: {

    itemId() {
      this.getIssueData(this.itemId);
    },

    getRefresh() {
      if (this.getRefresh) {
        this.getIssueData()
        this.showEdit = false;
      } else {
        this.showEdit = true;
      }
    },
    timerPushed() {
      if (this.timerPushed) {
        this.changeToprogress()
        this.getIssueData()
      } else {
        this.getIssueData()
      }
    },

    getId() {
      if (this.getId) {
        this.getIssueData(this.$route.query.id);
        // this.getParentstage(this.issueData.parent_issue_id)
      }
    },
    changeToPending() {
      if (this.changeToPending) {
        this.changingStageToPending()
      }
    },
  },
  methods: {

    getStage($stage) {
        let opt = this.stageData.filter( (k) => k.code == $stage);
        return  opt && opt[0]  && opt[0].name?opt[0].name:'' ;
    },
   
    isDateOverDue(date) {
      if(date){
      let currentDate = new Date();
      let dueDate = new Date(date);
      return currentDate > dueDate ? 'text-red' : 'text-orange';}
    },

    toggleMenu(event, type) {
      console.log(event, type, 'hhhhhhhhhhhhhhhhhhhhhhhhh')

      if (type == 'priority' && this.showEdit && this.issueData.stage != 'closed') {
        this.$refs.childMenu.toggle(event);
      } else if (type == 'stage' && this.showEdit) {
        this.$refs.childStage.toggle(event);
      } else if (type == 'issueType' && this.showEdit && this.issueData.stage != 'closed') {
        this.$refs.childIssueType.toggle(event);
      }
    },
    handleOptionSelected(option) {
      // Handle the selected option in the parent component
      this.selectedOption = option;
      alert(`Selected data_value in parent: ${this.selectedOption}`);
    },

    async changeToprogress() {
      await IssueService.update({
        id: this.issueData.id,
        stage: "inprogress",
      });
      this.getIssueData()
    },

    moment,

    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    async refreshData() {
      await this.getIssueData();
      this.refreshAmt = !this.refreshAmt;
    },
    setBreadCrumps() {
      let $wsDefaults = localStorage.getItem("_WS_DEFAULTS_");
      if ($wsDefaults) {
        $wsDefaults = JSON.parse($wsDefaults);
        //   alert(JSON.stringify(this.issueData,null,4))
        if (this.issueData) {
          //alert(this.issueData.issue_parent_id)
          if (this.issueData.parent_issue_id) {
            $wsDefaults.child_issue = {
              id: this.issueData.id,
              name: this.issueData.reference_num,
            };
          } else {
            delete $wsDefaults.child_issue;
            $wsDefaults.issue = {
              id: this.issueData.id,
              name: this.issueData.reference_num,
            };
          }
          localStorage.setItem("_WS_DEFAULTS_", JSON.stringify($wsDefaults));
          // localStorage.setItem("_WS_DEFAULTS_",JSON.stringify($wsDefaults))
          this.$store.commit("SET_REFRESH_BREADCRUMP", true);
        }
      }
    },
    async getReporterName(id) {
      if (id && id > 0) {
        try {
          let ress = await UserService.findOne({ id: id });
          this.ID = ress.length > 0 && ress[0].id ? ress[0].id : ""
          this.submittedByName = ress.length > 0 && ress[0].name ? ress[0].name : "";
        } catch (e) {
          this.submittedByName = "";
        }
      }
    },
    getTimeSheetDefaults() {
      if (this.issueData.id) {
        return {
          issueId: this.issueData.id,
          refNum: this.issueData.ref_num,
          name: this.issueData.name,
          startDate: this.issueData.start_date,
          accountId: this.issueData.account_id,
          accountName: this.issueData && this.issueData.account_name ? this.issueData.account_name : '',
          projectId: this.issueData.project_id,
          projectName: this.issueData && this.issueData.project_name ? this.issueData.project_name : '',
          // taskName: this.issueData && this.issueData.task_name ? this.issueData.task_name : '',
          taskId: this.issueData.task_id,
          user_id: this.issueData.user_id,
        };
      }
    },
    userSelected(value) {
      if (value) {
        IssueService.shareIssue(
          {
            toUser: value.id,
            issueId: this.issueData.id,
            fromUser: this.userData.id
          }
        ).then(res => {
          console.log(res)
          this.$store.dispatch('setSnackbar', { text: 'Share Successfully' })
        }).catch(e => {
          console.log(e)
          this.$store.dispatch('setSnackbar', { color: 'error', text: 'Something Went Wrong' })
        })
      }

    },

    formatDate(dateString, type) {
      //console.log(dateString, "hello ganesh")
      let inputDate = dateString
      let convertedDateString = inputDate;
      if (!convertedDateString) {
        return ''
      }
      else if (type == 'date') {
        const date = new Date(convertedDateString);
        return date.toLocaleDateString()// moment(date).utcOffset('+05:30')
      }
      else {
        const date = new Date(convertedDateString);
        //return moment(date).toLocal()
        return date.toLocaleString();
      }
    },
    getSubTaskDefaults() {
      if (this.issueData.id) {
        return {
          issue_parent_id: this.issueData.id,
          name: this.issueData.name,
          startDate: new Date(),
          account_id: this.issueData.account_id,
          project_id: this.issueData.project_id,
          task_id: this.issueData.task_id,
          priority: this.issueData.priority,
          category_id: this.issueData.category_id,
          sub_category_id: this.issueData.sub_category_id,
          issue_type_id: this.issueData.issue_type_id,
          stage: this.issueData.stage,
          end_date: this.issueData.end_date,
          skill_id: this.issueData.skill_id
        };
      }
    },
    async getIssueData($id) {
      this.$store.commit("SET_LOADER", true);
      let issueID;
      if ($id) {
        issueID = $id

      }
      else if (this.getId) {
        issueID = this.getId
      }
      let $issueData = await IssueService.findAllIssues(
        { id: issueID }
      );
      $issueData = $issueData[0];
      console.log($issueData, 'pppppppppp')
      // this.$store.commit("SET_LOADER", false);
      this.issueTypes = await IssueTypeService.findAll();
      console.log(this.issueTypes, 'issuetyprsss')
      this.stageData = await StageService.findAll();

      this.stages = this.stageData.filter((stg) => stg.code !== "qapending" && stg.code !== "qacompleted" && stg.code !== "inprogress");
      // this.priorities = await EnumService.findAll()
      await this.getReporterName(
        $issueData && $issueData.submitted_by ? $issueData.submitted_by : ""
      );
      if (issueID) {

        this.issueData = $issueData;
        console.log(this.issueData, 'issuedataaaaaas')
        this.issueData.followers = $issueData.followers
          ? JSON.parse($issueData.followers)
          : [];
        this.issueData.watchers = $issueData.watchers
          ? JSON.parse($issueData.watchers)
          : [];
        if (!this.issueData.watchers.includes(this.userData.id)) {
          this.issueData.watchers.push(this.userData.id);
          let $watchers = JSON.parse(JSON.stringify(this.issueData.watchers));
          let $obj = {};
          $obj.id = this.issueData.id;
          $obj.watchers = JSON.stringify($watchers);
          $obj.type = "view";
          IssueService.updateFollowers($obj);
        }
        this.setBreadCrumps();
        this.$store.commit("SET_LOADER", false);
      }
      if(this.issueData.stage == 'inprogress'){
        this.showEdit = false
        console.log(this.issueData.stage , 'fkjkjfkjkjhkdkcdhcd')
      }else{
        this.showEdit = true
      }
    },
    async onUserSelected(item) {
      let status = this.$store.getters.getAssignee;
      await IssueService.update({
        id: this.issueData.id,
        next_action: item.id,
      });
      this.getIssueData();
      await this.$store.commit("CHANGE_ASSIGNEE", !status);
    },
    async issueTypeChanged(item) {
      await IssueService.update({
        id: this.issueData.id,
        issue_type_id: item,
      });
      this.getIssueData();
    },

    // async stageChanged(item) {
    //   console.log(item, 'stageeee')
    //   await IssueService.update({
    //     id: this.issueData.id,
    //     stage: item.data_value,
    //   });
    //   this.getIssueData();
    //   this.updateQA = true;
    // },

    // async getParentstage() {
    //   if (this.issueData.parent_issue_id !== null) {
    //     let parentId = this.issueData.parent_issue_id
    //     let res = await IssueService.findAllIssues({ id: parentId })
    //     this.parent = res[0]
    //   }
    //   if (this.parent && this.parent.stage != 'closed') {
    //     console.log(this.parent, 'bala')
    //     this.stageChange = true
    //     console.log(this.stageChange, 'kashif')
    //   }
    //   else {
    //     this.stageChange = false
    //   }
    // },

    async ifChildClosed() {
      let res = await IssueService.findAll()
      let allIssues = res.filter((elm) => {
        return elm.parent_issue_id === this.issueData.id
      });

      this.allIssuesClosed = allIssues.every((issue) => {
        return issue.stage === 'closed'
      });

      console.log("Are all issues closed?", this.allIssuesClosed);

    },

    async stageChanged(item) {
      console.log(item, 'jhdgjkaf')
      let status = this.$store.getters.getStageInProgress;

      if (this.issueData.parent_issue_id !== null) {
        await IssueService.update({
          id: this.issueData.id,
          stage: item,
        });
        if (item === 'inprogress') {
          this.$store.commit("SET_STAGE_IN_PROGRESS", !status);
        }
        this.getIssueData();
        this.updateQA = true;
      }
      else if (this.issueData.parent_issue_id === null && item !== 'closed' && item !== 'completed') {
        await IssueService.update({
          id: this.issueData.id,
          stage: item,
        });
        if (item === 'inprogress') {
          let userDetails = localStorage.getItem("user_details")
          userDetails = JSON.parse(userDetails)
          await IssueService.update({
            id: userDetails.id,
            stage: 'inprogress',
          });
          this.$store.commit("SET_STAGE_IN_PROGRESS", !status);
        }
        this.getIssueData();
        this.updateQA = true;
      }
      else if (this.issueData.parent_issue_id === null && (item === 'closed' || item === 'completed')) {
        if (this.allIssuesClosed === true) {
          await IssueService.update({
            id: this.issueData.id,
            stage: item,
          });
          if (item === 'inprogress') {
            this.$store.commit("SET_STAGE_IN_PROGRESS", !status);
          }
          this.getIssueData();
          this.updateQA = true;
        }
        else {
          this.showErrorStage = true;
          setTimeout(() => {
            this.showErrorStage = false;
          }, 2000);
        }

        this.getIssueData();
        this.updateQA = true;
      }
    },
    async changingStageToPending() {
      await IssueService.update({
        id: this.issueData.id,
        stage: 'pending',
      });
      this.getIssueData()
      this.updateQA = true;
    },

    close() {
      let status = this.$store.getters.getAssignee;
      this.dDialog = false;
      this.editDialog = false;
      this.editDelDialog = false;
      this.timesheetDialog = false;
      this.taskDialog = false;
      this.$store.commit("CHANGE_ASSIGNEE", !status);
    },
    editDesc() {
      this.dDialog = true;
    },
    editIssue() {
      this.editDialog = true;
    },
    editDetails() {
      this.editDelDialog = true;
    },
    openDialog() {
      this.dialogOpen = true;
    },
    closeDialog() {
      let status = this.$store.getters.getAssignee;
      this.dialogOpen = false;
      this.$store.commit("CHANGE_ASSIGNEE", !status);
    },
    timeDialogue() {
      this.timesheetDialog = true;
    },
    async checkStage(data){
      if(data === true){
        await IssueService.update({
            id: this.issueData.id,
            stage: 'pending',
          });
          this.getIssueData()
      }
      console.log(this.issueData.stage , 'checkStageGetCurrents')
    },
    qaDialogue() {
      this.taskDialog = true;
    },

    async getPriority() {
      let res = await EnumService.findAllDomain({ domain: "PRIORITY" });
      this.priorities = res;
    },

    async priorityChanged(item) {
      this.issueData.priority = item;
      let $data = {
        id: this.issueData.id,
        priority: this.issueData.priority,
      };
      await IssueService.update($data);
      this.getIssueData();
      this.getPriority();
    },

    getBgColors(name) {
      if (name == "low") return "#D1FFBD";
      else if (name == "high") return "#FFCCCB";
      else if (name == "medium") return "#FFFDAF";
      else return "#F0ECFF ";
    },
  },
};
</script>
<style scoped>
.toggle-button {
  display: flex;
  justify-content: space-between;
  justify-content: space-between;
  cursor: pointer;
}

.reportedBy {
  display: flex;
}

.toggle-button span {
  margin-right: 8px;
}

.toggle-button v-icon {
  transition: transform 0.3s;
}

.toggle-button:hover v-icon {
  transform: rotate(180deg);
}

.hoverEdit {
  display: flex;
  font-size: 14px;
}



.descBtn {
  display: flex;
  justify-content: end;
}

.editBtn {
  margin-right: 55px;
  margin-top: 10px;
}

.subtitle {
  font-size: 12px;
}

.tags {
  font-size: 12px;
  font-weight: 400;
  color: #272727;
}

>>>.v-chip--variant-tonal .v-chip__underlay {
  background-color: inherit;
  color: #272727 !important;
}

>>>.v-chip__content {
  color: #272727 !important;
}

.pink-bg {
  background-color: #f8d6e6 !important;
  opacity: 1 !important;
}

.purple-bg {
  background-color: #e2d6f8 !important;
}

.gold-bg {
  background-color: #f8f2d6;
}

.teal-bg {
  background-color: #d6f8f6;
}

.grey-bg {
  background-color: #f4f4f423;
}

.outlined {
  border: 1px solid #dfdfdf;
}

.bold-medium {
  font-weight: 600;
}

.description {
  font-size: 16px;
  font-weight: 400;
  color: #272727;
}

>>>p {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #272727 !important;
}

.btn-color {
  color: #333333;
  font-size: 10px;
  width: fit-content;
}

>>>.v-btn--size-default {
  --v-btn-size: 0;
  --v-btn-height: 32px;
  font-size: 0.875rem;
  min-width: 20px;
  padding: 0 4px;
}

.chevron {
  position: relative;
  bottom: 2px;
}

.errorMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe7e6;
  border: solid #ff5757;
  border-width: 0 0 0 6px;
  color: #ff5757;
  width: 575px;
  border-radius: 3px;
  height: 30px;
  margin-top: 3px;
}
</style>