import accountVerification from "@/modules/dashboard/user/accountVerification.vue";
import store from '../store/index'
import { createRouter, createWebHistory } from "vue-router";
import UserDashboard from "@/modules/dashboard/user/UserDashboard.vue";
import myWorkSpace from "@/modules/dashboard/user/myWorkSpace.vue";
import issueChart from "@/modules/dashboard/user/issueChart.vue";
import Board from "@/Plans/Board.vue";
import Login from "../components/Login.vue";
import ProjectTeam from "../modules/project/ProjectTeam.vue";
import Logout from "../../src/components/Logout.vue";
import Verify from "../../src/components/Verify.vue";

import Avatar from "../components/ui/Avatar.vue";
import ForgetPassword from "../modules/forgetPassword/ForgetPassword.vue";
import PrimeButton from "../components/PrimeDataTable.vue";
import userProfile from "../modules/users/UserProfile.vue";
import IssueList from "../modules/issues/IssueList.vue";
import Settings from "../modules/settings/Settings.vue";
import Backlog from "../Plans/Backlog.vue";
import MenuGroup from "../menus/MenuGroup.vue";
import IdentitySetting from "../modules/settings/Identity/IdentitySetting.vue";
import ProjectList from "../modules/project/ProjectList.vue";
import ProjectView from "../modules/project/ProjectView.vue";
import IssueView from "../modules/issues/IssueView.vue";
import IssueView2 from "../modules/issues/views/Index.vue";
import ProjectSettings from "../modules/settings/Project/Project.vue";
import OrganizationSettings from "../modules/settings/Organization/Organization.vue";
import OthersSettings from "../modules/settings/Others/Others.vue";
import Account from "../modules/settings/Organization/Account/Account.vue";
import AccountView from "../modules/settings/Organization/Account/AccountView.vue";
import WorkSpace from "../modules/settings/Organization/WorkSpace/WorkSpace.vue";
import WorkSpaceList from "../modules/settings/Organization/WorkSpace/WorkSpaceList.vue";

import WsSetting from '../modules/settings/Organization/WorkSpace/WsSetting.vue'
import TechnicalSettings from '../modules/settings/Technical/Technical.vue'
import InProgress from "../components/ui/InProgress.vue";
import Verification from "../modules/verification/Verification.vue";
import createActivity from "../modules/issues/CreateActivity.vue";
import AddQuickIssue from "../modules/issues/AddQuickIssue.vue";
import NextAction from "../modules/issues/NextAction.vue";
import user from "../modules/users/User.vue";
import AccountList from "../modules/settings/Organization/Account/AccountList.vue";
import ProjectListWorkSpace from "../modules/project/ProjectListWorkSpace.vue";
import IssueConsolidated from "../modules/issues/IssueConsolidated.vue";
import LiveBoard from '../modules/issues/LiveBoard/LiveBoard.vue'
import EmployeeLiveBoard from '../modules/issues/LiveBoardEmployee/LiveBoard.vue'
import DailyActivityDashBoard from '../modules/issues/DailyActivityDashBoard/LiveBoard.vue'
import ProcessLeadDashboard from '../modules/issues/ProcessLeadDashboard/ProcessLeadDashboard.vue'
import ExceptionReports from '../modules/issues/ExceptionReports/ExceptionReports.vue'
import ExceptionBoard from '../modules/dashboard/ExceptionBoard/ExceptionBoard.vue'
import Timesheets from "../modules/issues/views/TimeSheets.vue";
import Timesheets2 from "../modules/TimeSheet/TimeSheets.vue"
import Loader from "../components/ui/Loader.vue"
import ReportFilter from "@/modules/Report/ReportFilter.vue"
import Report from "@/modules/Report/Reports.vue"
import ReportList from "@/modules/Report/ReportList.vue"
// import QuestionBank from "@/modules/Questionnaire/questionnaireList.vue"
// import PendingAssessments from "@/modules/Assesment/assessmentList.vue"
import assessmentStarter from "@/modules/assessmentStarter/assessmentStarter.vue"
import assements from '@/modules/assementsGrid/assementsGrid.vue'
import Activity from "@/modules/Activities/Activity.vue"
import WorkTimingSelection from '../modules/settings/Organization/WorkTimings/WorkTimingSelection.vue'
import ShowReports from '../components/ShowReports.vue'
import Index from '@/modules/ProductivityDashboard/Index.vue'
import IndexComparison from '../modules/ProductivityComparision/IndexComparison.vue'
// import TimerService from "@/api/service/TimeSheet/TimerService";
//import ForumTiny from '@/components/ui/Forum.vue';
import DashBoardRoute from "./DashBoardRoute";
import Security from '../modules/security/security.vue'
import Permissions from '../modules/security/Permissions.vue'

import SettingRoute from "./SettingRoute";
import DashboardWidget from '../modules/dashboard/Widgets/DashboardContainer.vue'
import LayoutDesigner from '../modules/LayoutDesigner/index.vue'

import IssueViewV2 from '../modules/IssueV2/index.vue'
import Jobs from '../modules/Jobs/index.vue'
import RutDashboard from '../modules/dashboard/ResourceUtilizationBoard/Dashboard';
import Contact from '@/modules/Contact/Contact.vue';
import ReportSubscription from '@/modules/Report/ReportSubscription.vue';
import AddReportSubscription from '@/modules/Report/AddReportSubscription.vue'

import AddScheduleReport from '../modules/Report/AddScheduleReport.vue'

import CommonBoard from '@/modules/dashboard/index.vue'
import ProcessLead from '@/modules/dashboard/ProcessLead/ProcessIndex.vue'
import ProductionDashboard from '@/modules/issues/LeadDashBoard/ReportBoard/ReportIndex'
import EscalationBoard from '@/modules/Escalation/EscalationBoard.vue'
import RequestDashboard from '@/modules/RequestDashbaord/RequestBoard.vue'
import RequestDashboard2 from '@/modules/dashboard/VacationBoard/index.vue'

import AddVacationTemplate from '@/modules/settings/Hr/VacationTemplate2/AddVacationTemplate.vue'
// import BlockedCalender from '@/modules/settings/Hr/BlockedCalender/BlockedCalenderAdd.vue'
import figma4 from '@/modules/RequestDashbaord/figma4.vue'  //leave request taken//
import figma5 from '@/modules/RequestDashbaord/figma5.vue'

// import CanditateProfile from '@/modules/CandidateProfile/ScheduleInterview.vue'
// import CanditateProfile from '@/modules/CandidateProfile/Candidatedetails.vue'
// import CanditateProfile from '@/modules/CandidateProfile/CandidateProfiles.vue'
import Exception from '@/modules/Exception/exceptionReport.vue'
import FormBuilder from '@/modules/FormBuilder/index.vue'
import RequestBoard from '@/modules/dashboard/requestBoard/RequestBoard.vue'

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/verify",
    name: "verify",
    component: Verify,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path:'/assements',
    name:'assements',
    component:assements,
  },
  {
    path: "/showReports",
    name: "showReports",
    component: ShowReports,
  },
  {
    path: "/Exception",
    name: "Exception",
    component: Exception,
  },
  {
    path: "/accountVerification",
    name: "accountVerification",
    component: accountVerification,
  },
  {
    path:'/assessmentstarter',
    name:'assementStarter',
    component:assessmentStarter,

  },
  {
    path: "/TechnicalSettings",
    name: "TechnicalSettings",
    component: TechnicalSettings,
  },
  {
    path: "/WsSetting",
    name: "WsSetting",
    component: WsSetting,
  },
  {
    path: "/userProfile",
    name: "userProfile",
    component: userProfile,
  },
  {
    path: "/",
    name: "UserDashboard",
    component: UserDashboard,
  },
  {
    path: "/myWorkSpace",
    name: "myWorkSpace",
    component: myWorkSpace,
  },
  {
    path: "/issueChart",
    name: "issueChart",
    component: issueChart,
  },
  {
    path: "/projectteam",
    name: "ProjectTeam",
    component: ProjectTeam,
  },
  {
    path: "/Avatar",
    name: "Avatar",
    component: Avatar,
  },

  {
    path: "/projectList",
    name: "ProjectList",
    component: ProjectList,
  },
  {
    path: "/projectView",
    name: "ProjectView",
    component: ProjectView,
  },
  {
    path: "/accountView",
    name: "AccountView",
    component: AccountView,
  },

  {
    path: "/ForgetPassword",
    name: "ForgetPassword",
    component: ForgetPassword,
  },

  {
    path: "/issuelist",
    name: "Issue List",
    component: IssueList,
  },
  {
    path: "/issueConsolidated",
    name: "Issues",
    component: IssueConsolidated,
  },
  {
    path: "/liveBoard",
    name: "LiveBoard",
    component: LiveBoard,
  },
  {
    path: "/employeeLiveBoard",
    name: "Employee Live Board",
    component: EmployeeLiveBoard,
  },
  {
    path: "/dailyActivityDashBoard",
    name: "Daily Activity DashBoard",
    component: DailyActivityDashBoard,
  },
  {
    path: "/escalationbaord",
    name: "Escaltion DashBoard",
    component: EscalationBoard,
  },
  {
    path: "/processLeadDashboard",
    name: "Process Lead Dashboard",
    component: ProcessLeadDashboard,
  },
  {
    path: "/exceptionReports",
    name: "Exception Reports",
    component: ExceptionReports,
  },
  {
    path: "/requestBoard",
    name: "Request Dashboard",
    component: RequestDashboard,
  },
  {
    path: "/requestBoard2",
    component: RequestBoard,
  },
  {
    path:"/vacationRequest",
    name:"Request Dashboard",
    component:RequestDashboard2
  },
  {
    path: "/figma5",
    name: "figma5",
    component: figma5,
  },
  {
    path: "/AddVacationTemplate",
    name: "AddVacationTemplate",
    component: AddVacationTemplate,
  },
  {
    path: "/figma4",
    name: "figma4",
    component: figma4,
  },
  {
    path: "/primeButton",
    name: "PrimeButton",
    component: PrimeButton,
  },
  {
    path: "/board",
    name: "Board",
    component: Board,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/backlog",
    name: "Backlog",
    component: Backlog,
  },
  {
    path: "/menuGroup",
    name: "MenuGroup",
    component: MenuGroup,
  },
  {
    "path":"/IssueViewV2",
    "name":"IssueViewV2",
    component:IssueViewV2
  },
  {
    path: "/issueView",
    name: "IssueView",
    component: IssueView,
  },
  {
    path: "/issueView2",
    name: "IssueView2",
    component: IssueView2,
  },
  {
    path: "/nextAction",
    name: "NextAction",
    component: NextAction,
  },
  {
    path: "/identity",
    name: "Identity Setting",
    component: IdentitySetting,
  },
  {
    path: "/project",
    name: "Project Setting",
    component: ProjectSettings,
  },
  {
    path: "/organisation",
    name: "Organisation Setting",
    component: OrganizationSettings,
  },

  {
    path: "/setting",
    name: "Technical Setting",
    children: SettingRoute,
  },
  {
    path: "/others",
    name: "Others Setting",
    component: OthersSettings,
  },
  {
    path: "/accountList",
    name: "AccountList",
    component: Account,
  },
  {
    path: "/AddIssue",
    name: "createActivity",
    component: createActivity,
  },
  {
    path: "/workspace",
    name: "WorkSpaces",
    component: WorkSpace,
  },
  {
    path: "/dashBoard",
    name: "Dashboard Setting",
    component:CommonBoard,
    children: DashBoardRoute,
  },
  {
    path: "/showWorkspaces",
    name: "ShowWorkSpaces",
    component: WorkSpaceList,
  },
  {
    path: "/showAccountForWorkspaces",
    component: AccountList,
  },
  {
    path: "/showProjectForWorkspaces",
    component: ProjectListWorkSpace,
  },
  {
    path: "/user",
    name: "User",
    component: user,
  },
  {
    path: '/autoSuggest',
    name: 'WorkTimingSelection',
    component: WorkTimingSelection
  },
  {
    path: '/productivityComparision',
    name: 'IndexComparison',
    component: IndexComparison
  },
  {
    path: "/notFound",
    name: "Improgress",
    component: InProgress,
  },
  {
    path: "/verifyUser",
    name: "Verification",
    component: Verification,
  },
  {
    path: "/addQuickIssue",
    name: "Add Quick Issue",
    component: AddQuickIssue,
  },
  {
    path: "/timeSheets",
    name: "TimeSheets",
    component: Timesheets,
  },
  {
    path: "/timeSheets2",
    name: "TimeSheets",
    component: Timesheets2,
  },
  {
    path: "/loader",
    name: "Loader",
    component: Loader,
  },
  {
    path: "/productivityDashboard",
    name: "ProductivityDashboard",
    component: Index,
  },
  {
    path: "/reportFilter",
    name: "ReportFilter",
    component: ReportFilter,
  },
  {
    path: "/reports",
    name: "Report",
    component: Report,
  },
  {
    path: "/reportsubscription",
    name: "ReportSubscription",
    component: ReportSubscription,
  },
  {
    path: "/addreportsubscription",
    name: "AddReportSubscription",
    component: AddReportSubscription,
  },
  {
    path: "/addschedulereport",
    name: "AddScheduleReport",
    component: AddScheduleReport,
  },
  {
    path: "/reportList",
    name: "ReportList",
    component: ReportList,
  },
  // {
  //   path: "/PendingAssessments",
  //   name: "PendingAssessments",
  //   component: PendingAssessments,
  // },
  // {
  //   path: "/CanditateProfile",
  //   name: "CanditateProfile",
  //   component: CanditateProfile,
  // },
  // {
  //   path: "/QuestionBank",
  //   name: "QuestionBank",
  //   component: QuestionBank,
  // },
  {
    path: "/activity",
    name: "Activity",
    component: Activity,
  },
  {
    path: "/security",
    name: "Security",
    component: Security,
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: Permissions,
  },
  {
    path: "/dashboardwidgets",
    name: "DashboardWidget",
    component: DashboardWidget,
  },
  {
    path: "/layoutDesigner",
    name: "LayoutDesiginer",
    component: LayoutDesigner,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    "path":"/jobs",
    "name":"Jobs",
    "component":Jobs
  },
  {
    "path":"/rut",
    "name":"RutDashboard",
    "component":RutDashboard
  },
  {       
    path: '/processLead',
    component: ProcessLead, 
  },
  { 
    path: '/productionDashboard',
    component: ProductionDashboard, 
  },
  {
    path: "/exceptionBoard",
    name: "ExceptionBoard",
    component: ExceptionBoard,
  },
  {
    path: "/formbuilder",
    name: "FormBuilder",
    component: FormBuilder,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
const publicPages = ["/login", "/verify", "/accountVerification"];

router.beforeEach((to, from, next) => {
  console.log(to,"checking route")
  const $token = localStorage.getItem("TOKEN");
  const deviceId = localStorage.getItem('deviceId')
  store.commit('SET_DEVICE_ID',deviceId);
  if (publicPages.includes(to.path)) {
    return next();
  }
 
  if ($token) {
    let $workTiming = localStorage.getItem('_WORK_TIMING_')
    if(!$workTiming && to.path!='/autoSuggest' && to.path!='/notFound') {
     // alert('routing'+to.path)
      return next("/autoSuggest");
    }
    let $timer = JSON.parse(localStorage.getItem("timer"))
    let $listBoard = localStorage.getItem("issueDisplay")
    if($listBoard){
      store.commit("SET_ISSUE_DISPLAY", $listBoard=='true' ? true : false)
    }
    //let timerData = JSON.parse(localStorage.getItem("breakormeeting"))
    if ($timer) {
      store.commit('SET_IN_PROGRESS', true)
    }
    else {
      store.commit('SET_IN_PROGRESS', false)
    }
    if (to.matched.length == 0) {
     
      return next("/notFound");
    } else {
      return next();
    }
  } else {
    return next("/login");
  }
});

export default router;
