<template>
    <v-card flat="" style="min-height: 90vh;" class="pt-3 pl-1">
        <v-card-title class="pl-5">
            <div class="text-h6 font-weight-bold">Report List</div>
        </v-card-title>

        <v-card-text class="mt-3">
            <div style="display: flex; justify-content: space-between;">
                <div style="width: 20%;" class="pl-1 mb-1">
                    <TextFieldComponent placeholder="Search in list" v-model="search" />
                </div>
                <div style="width: 20%;" class="pl-1 mb-1">
                    <v-switch  v-model="development" label="Show Development reports"  color="primary"></v-switch>
                </div>

                <!-- <div class="addButton mr-3" @click="addListModel">Add Report</div> -->
            </div>

            <div class="pa-2">
                <v-divider></v-divider>
                <!-- <div v-for="(item, i) in filteredListModelData" :key="i">
                     <div>{{ item.name }}</div>
                </div> -->
                <v-list>

                    <v-list-item v-for="(item, i) in filteredListModelData" :key="i" :value="item" color="primary"
                       >

                        <v-list-item-content @click="openReports(item)">
                            <v-row>
                                <v-col cols="10" style="display: flex;">
                                    <span style="display: flex;align-items: center;font-weight: 500;font-size: 15px;color: #8e8a8a;">{{ item.name }}</span>
                                </v-col>

                                <v-col>
                                    <!-- <div class="buttonView" @click="openReports(item)">Open Report</div> -->
                                </v-col>

                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <!-- <v-divider></v-divider> -->
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import ListModelService from '@/api/service/ListModel/ListModelService';
import TextFieldComponent from '@/components/TextFieldComponent.vue';
export default {
    components: {
        TextFieldComponent,
    },
    data() {
        return {
            listModelData: [],
            search: '',
            development:false
        }
    },
    created() {
        this.getListData()
    },
    watch:{
        development() {
            this.getListData()
        }
    },  
    computed: {
        filteredListModelData() {
            let searchTerm = this.search.toLowerCase();
            return this.listModelData.filter((item) => item.name.toLowerCase().includes(searchTerm));
        },
    },
    methods: {
        addListModel(){
            this.$router.push("/setting/technical/model")
        },
        openReports(item) {
            let selectedId = item.id;
            this.$router.push({ path: "/reports", query: { model_id: selectedId } });
        },
        async getListData() {
            let $filter = {}
            if(this.development) {
                $filter={}
            } else {
                
                $filter={"live":1}
            }
            //alert('here'+JSON.stringify($filter))
            let res = await ListModelService.findAll($filter)
            let $userDatails =JSON.parse(localStorage.getItem('user_details'))

            let $reports =[]
            for(let r of res) {
                console.log(r)
                r.roles =r.roles ? JSON.parse(r.roles) : []
                console.log(r.roles)
                if(r.roles.indexOf($userDatails.role_id)>=0){
                    $reports.push(r)  
                }else if(this.development==true){
                    
                    $reports.push(r) 
                }
            }   
            this.listModelData = $reports
        }
    }
}
</script>

<style scoped>
>>>.v-input__details {
    padding-inline-start: 2px !important;
    display: contents;
}

>>>.rounded-xl {
    border-radius: 5px !important;
}
/* >>>.v-list-item--density-default.v-list-item--one-line {
    min-height: 45px;
    padding-top: 4px;
    padding-bottom: 4px;
}

 >>>.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
    padding-inline-start: 50px;
    padding-inline-end: 0px;
    margin: 5px 0px;
} */
/* >>>.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line:hover {
    border: none;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
} */

.buttonView {
    display: grid;
    place-items: center;
    width: fit-content;
    border: 1px solid;
    border-radius: 4px;
    color: #fff;
    background-color: #1258ab;
    padding: 2px 15px;
}
.addButton {
    display: grid;
    place-items: center;
    height: 35px;
    width: fit-content;
    font-weight: 600;
    border-radius: 4px;
    background-color: #dee2e6;
    padding: 2px 15px;
    transition: ease-in-out .5s;
}

.addButton:hover{
    color: #fff;
    background-color: #1258ab;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}


</style>