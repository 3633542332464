<template>
    <v-layout>
  <table fixed-header class="table">
    <thead>
      <tr class="mainHead">
        <th class="heads" v-for="header in headers" :key="header.key">
          {{ header.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template
        v-for="(workspace, workspaceIndex) in displayData"
        :key="workspaceIndex"
      >
        <tr>
          <th
            class="fixedHeader1"
            :colspan="headers.length + 1"
            style="text-align: justify"
          >
            <div class="my-3">
              <span class="headd">
                {{ workspace.workSpaceName }}
              </span>
            </div>
          </th>
        </tr>
        <template
          v-for="(account, accountIndex) in workspace.accountData"
          :key="`account-${accountIndex}`"
        >
          <!-- {{ account }} -->
          <tr
          :class="
              account.accountName == ''
                ? 'fixedheader'
                : account.pin == true
                ? 'fixedHeader3'
                : 'bodyRow'
            "       
                 :style="{
              fontWeight:
                expandedRows.includes(accountIndex) &&
                workspaceIndex == selectedWorkSpace
                  ? 'bold'
                  : 'normal',
                  background: account.accountName ? ' #f3f3f3' : '#ffefef',

            }"
            class="hidden"
          >
            <td>
              <v-icon
                v-if="!account.pin"
                @click="pinTheData(workspace, account)"
                size="large"
                class="iconHover"
                >mdi-pin-outline</v-icon
              >
              <v-icon
                class="iconHover"
                v-if="account.pin"
                @click="pinTheData(workspace, account)"
                size="large"
                >mdi-pin-off-outline</v-icon
              >
            </td>
            <td style="text-align: left">
              <span
                :style="{
                  fontWeight: account.accountName ? 'normal' : 'bold',
                  color: account.accountName ? 'black' : 'red',
                }"
              >
                <strong>{{
                  account.accountName ? account.accountName : "Un Assigned"
                }}</strong>
              </span>
            </td>
            <td
              v-for="header in headers2"
              :key="header.key"
              style="text-align: left"
            >
              <!-- {{ header.key }} -->
              <span
                @click.stop="
                  getIssueData(header, null, workspace, account, 'outside')
                "
                :style="{
                  fontWeight: account.accountName ? 'normal' : 'bold',
                  color: account.accountName ? 'black' : 'red',
                }"
                class="cursorPointer"
                >{{ account[header.key] }}</span
              >
            </td>
            <v-icon
              v-if="
                expandedRows.includes(accountIndex) &&
                workspaceIndex == selectedWorkSpace
              "
              @click="toggleDetails(accountIndex, workspaceIndex)"
              style="position: absolute; right: 40px; top: 15px"
              >mdi-chevron-up-circle-outline</v-icon
            >
            <v-icon
              v-else
              @click="toggleDetails(accountIndex, workspaceIndex)"
              style="position: absolute; right: 40px; top: 15px"
              >mdi-chevron-down-circle-outline</v-icon
            >
          </tr>

          <template
            v-if="
              expandedRows.includes(accountIndex) &&
              workspaceIndex == selectedWorkSpace
            "
          >
            <tr
              class="bodyRow extraRow"
              v-for="(user, index) in account.userData"
              :key="index"
            >
              <td
                v-for="header in exheaders"
                :key="header.key"
                :colspan="header.key === 'user' ? 2 : 1"
                style="text-align: left"
              >
                <span
                  v-if="header.label === 'User'"
                  :style="{
                    fontWeight: user[header.key] ? 'normal' : 'bold',
                    color: user[header.key] ? 'black' : 'red',
                  }"
                >
                  {{ user[header.key] ? user[header.key] : "Un Assigned" }}
                </span>
                <span
                  v-else
                  @click.stop="
                    getIssueData(header, user, workspace, account, 'inside')
                  "
                  class="cursorPointer"
                  >{{ user[header.key] }}</span
                >
              </td>
            </tr>
          </template>
        </template>
      </template>
    </tbody>
  </table>

    <v-navigation-drawer
      :width="600"
      v-model="drawer"
      style="margin-top: 70px"
      location="right"
      permanent
    >
      <workSpaceView
        :filter="filterData"
        :WorkSpaceName="WorkSpaceName"
        :issueData="issueData"
        @closedrawer="closeDrawer"
      />
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import workSpaceView from "../DailyActivityDashBoard/workSpaceView.vue";
import ExceptionReportsDataService from "./ExceptionReportsDataService";
export default {
  props: ["tableData", "allData"],
  components: { workSpaceView },
  data() {
    return {
      drawer: false,
      selectedWorkSpace: "",
      bckupTableData: null,
      pinData: null,
      expandedRows: [],
      expandedData: [],
      filterData: null,
      WorkSpaceName: null,
      displayData: [],
      headers: [],
      headers2: [],
      exheaders: [],
      issueData: [],
    };
  },
  created() {
    let $pinData = localStorage.getItem("ACC_PIN_DATA");
    this.pinData = $pinData ? JSON.parse($pinData) : [];
    this.assignTheData();
    this.headers = ExceptionReportsDataService.getHeaderData("account", "headers");
    this.headers2 = ExceptionReportsDataService.getHeaderData("account", "headers2");
    this.exheaders = ExceptionReportsDataService.getHeaderData("account", "exheaders");
  },
  watch: {
    tableData() {
      this.assignTheData();
    },
  },

  methods: {
    closeDrawer($drawer) {
      this.drawer = $drawer;
    },
    assignTheData() {
      this.displayData = JSON.parse(JSON.stringify(this.tableData));
      if (this.tableData.length > 0) {
        this.getPinTheAccountData();
      }
    },
    pinTheData($workspace, $account) {
      let index = this.pinData.findIndex(
        (k) => k.workSpaceId == $workspace.workSpaceId
      );
      if (index > -1) {
        let accountIndex = this.pinData[index].account.findIndex(
          (u) => u == $account.accountId
        );
        if (accountIndex > -1) {
          this.pinData[index].account.splice(accountIndex, 1);
        } else {
          this.pinData[index].account.push($account.accountId);
        }
      } else {
        let obj = {};
        obj.workSpaceId = $workspace.workSpaceId;
        obj.account = [$account.accountId];
        this.pinData.push(obj);
      }
      localStorage.setItem("ACC_PIN_DATA", JSON.stringify(this.pinData));
      this.getPinTheAccountData();
    },
    getPinTheAccountData() {
      this.displayData = JSON.parse(JSON.stringify(this.tableData));
      console.log(this.displayData,'dispalyDataaaa')
      for (let item of this.displayData) {
        let index = this.pinData.findIndex(
          (k) => k.workSpaceId == item.workSpaceId
        );
        if (index > -1) {
          item.accountData = this.getSeparateAccount(
            item.accountData,
            this.pinData[index].account
          );
        }
      }
      console.log(this.displayData,'dispalyDataaaa')
    },
    getSeparateAccount(accountData, accountId) {
      let pinAccount = [];
      let unPinAccount = [];
      let unDefined = [];
      for (let account of accountData) {
        if (accountId.includes(account.accountId)) {
          account.pin = true;
          pinAccount.push(account);
        }else if (account.accountName == "") {
          unDefined.push(account);
        } else {
          account.pin = false;
          unPinAccount.push(account);
        }
      }
      let opt1 = pinAccount.concat(unPinAccount);
      let opt = unDefined.concat(opt1);
      return opt;
    },
    async getIssueData($header, $user, $workspace, $account, type) {
      this.issueData = await ExceptionReportsDataService.getIssueData2(
        $header,
        $user,
        $workspace,
        $account,
        type == "outside"
          ? false
          : type == "inside" && $user && ($user.userId || $user.user_id)
          ? false
          : true
      );
      this.drawer = true;
    },
    async toggleDetails(userIndex, workspaceIndex) {
      if (this.expandedRows.includes(userIndex)) {
        let index = this.expandedRows.findIndex((k) => k == userIndex);
        this.expandedRows.splice(index, 1);
      } else {
        this.expandedRows.push(userIndex);
        this.selectedWorkSpace = workspaceIndex;
      }
    },
    combineAt(n, a, b) {
      return a.reduce((ac, cv, ci) => {
        if (n === ci) {
          // Insert elements from array 'b' at position 'n'
          ac.push(...b, cv);
        } else {
          // Insert element from array 'a'
          ac.push(cv);
        }
        return ac;
      }, []);
    },

    merge(a1, a2, i) {
      return [...a1.slice(0, i), ...a2, ...a1.slice(i)];
    },
  },
};
</script>

<style scoped>
.issue-view-container {
  box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -7px 3px 9px 1px rgba(0, 0, 0, 0.75);
}
.table {
  border-spacing: 0 5px;
  width: 122%;
  overflow: auto;
  padding-bottom: 5%;
}

thead {
  position: sticky;
  top: 0;
  /* background: #fff; */
  z-index: 1;
}

thead > tr {
  height: 40px;
}

thead > tr > .heads {
  border-bottom: 2px solid #0cb99a;
  border-top: 2px solid #0cb99a;
}

thead > tr > .heads:nth-child(1) {
  border-left: 2px solid #0cb99a;
  border-radius: 4px 0 0 4px;
}

thead > tr > .heads:last-child {
  border-right: 2px solid #0cb99a;
  border-radius: 0 4px 4px 0;
}

tbody > tr > td:nth-child(1) {
  border-radius: 4px 0 0 4px;
}

tbody > tr > td:last-child {
  border-radius: 0 4px 4px 0;
}

tbody > tr {
  padding: 50px 0;
  height: 50px;
  text-align: center;
}

.mainHead {
  background-color: #edf5f4;
  border: 2px solid #0cb99a;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 15px;
  text-align: center;
}

.headd {
  background-color: #fdf4f4;
  border: 1.5px solid #ffa1a1;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.bodyRow {
  position: relative;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.extraRow {
  background-color: #fef7e5;
}
.SearchInput {
  border-left: 1px solid #0cb99a;
  border-top: 1px solid #0cb99a;
  border-bottom: 1px solid #0cb99a;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
.iconHover {
  visibility: hidden;
}
.hidden:hover .v-icon {
  visibility: visible;
}
.cursorPointer {
  cursor: pointer;
}
.cursorPointer:hover {
  text-decoration: underline;
}
.fixedheader {
  position: sticky;
  top: 90px;
  z-index: 2;
  background: #fff;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
}
.fixedHeader1 {
  position: sticky;
  top: 40px;
  z-index: 2;
  background: #fff;
}
.fixedHeader3 {
  position: sticky;
  top: 140.5px;
  z-index: 2;
  background: #fff;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
}
.heads{
  width: 5%;
}
</style>
