<template>
  <!-- Prime Data Table  -->
  <PrimeDataTable
    :title="'Role'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="role"

  />


  <!-- Dialog -->

  <v-dialog persistent v-model="dialog" width="700px">
    <CreateRole
      @closeDialog="setFalse()"
      @reload="getRole()"
      :id="editId"
      :viewType="viewType"
    />
  </v-dialog>
</template>
  
  <script>
import CreateRole from "./CreateRole.vue";
import PrimeDataTable from "@/components/PrimeDataTable";
import RoleService from "@/api/service/Role/RoleService";
import commonService from "@/common.service";
import EnumService from "@/api/service/Enum/EnumService";
//import RolePermissionService from '@/api/service/RolePermission/RolePermissionService'
export default {
  components: {
    CreateRole,
    PrimeDataTable,
    },
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      enumData:[],
      editId: null,
      dialog: false,
      viewType: false,
    };
  },
  async created() {
    this.loadColumns();
   // if($access) {
      await this.getEnumData();
    //}
      this.download = true;
      this.search = true;
      this.checkbox = true;
  },
  methods: {
    //  Prime Data Table Data

    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },

         {
          field: "roleType",
          header: "RoleType",
          style: { width: "100px", color: "" },
          toolTip: "RoleType",
          sortfield: true,
        },
        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" }, 
            { action: "edit" },
            // { action: "delete" },
          ],
        },
      ];
    },

    setFalse() {
      this.dialog = false;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.editId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getRole() {
     // let $access = await RolePermissionService.isAllowed('role','manage')
     // if($access) {
      let res = await RoleService.findAll(null,'list');
      for(let i=0; i<res.length; i++){
        let data = this.enumData.filter( (k)=>{if(res[i].roleType == k.data_value){ return k}})
        console.log(data,"0000000")
        res[i].roleType =  data && data.length>0?data[0].display_value:""
      }
      this.data = res; //} else { this.data=[]}
      
    },
   

    async getEnumData() {
    let res = await EnumService.findAllDomain({domain:'roleType'});
    this.enumData = res.length > 0 ? await commonService.getSort(res, 'display_value', 'asc') : [];
    this.getRole();
    },

    addDiolog() {
      this.viewType =null
      this.dialog = true;
      this.editId = null;
    },
    addFilter() {
      this.$refs.FilterDrawer.dialog = true;
      this.$refs.FilterDrawer.size = commonService.largeDialog();
    },
  },
};
</script>
  
  
  