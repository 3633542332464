import IssueService from "@/api/service/Issue/IssueService"
class ExceptionReportsDataService {
    constructor() {

    }
    getHeaderData(group,type){
        let headerData = [            
            { key: "id", label: "Id" },
            { key: "account_id", label: "account_id" },
            { key: "created", label: "created",code:'assigned' },
            { key: "sub_category_id", label: "sub_category_id",code:'inprogress' },
            { key: "stage_code", label: "stage_code",code:'itsupportrequired' },
            { key: "stage_name", label: "stage_name" },
            { key: "account_name", label: "account_name",code:'qapending' },
            { key: "sub_category_name", label: "sub_category_name",code:'clarification'},
            { key: "count", label: "count",code:'hold' },
            ]
        let startData = this.getHeader(group,type)
        return type!='headers2'?startData.concat(headerData):headerData
    }
    getHeader(group,type){
        let $h =[{ key: "", label: "" }]
        let accountkey = { key: "account_name", label: "Account" }
        let userkey = { key: "user_name", label: "User" }
        let keyValue = type == 'headers' && group =='account'?accountkey:type == 'headers' && group =='employee'?userkey:type == 'exheaders' && group =='account'?userkey:accountkey
        $h.push(keyValue)
        return $h
    }
    async getIssueData2($header,$user,$workspace,$account,unAssigned){
        let $payload = this.getPayload($header,$user,$workspace,$account,unAssigned)
        console.log($payload,"payload data")
        let res = await IssueService.findAllIssues($payload)
        console.log(res,"response data")
        return res && res.length>0?res:[]
    }
    getPayload($header,$user,$workspace,$account,unAssigned){
        let obj = {}
        obj.stage = $header && $header.code?$header.code:null
        obj.past_due = $header && $header.key =='pastdue_issues'?true:false
        obj.todayCompleted = $header && $header.key =='completed_issues'?true:false
        obj.account_id = $account && ($account.account_id || $account.accountId) ?($account.account_id||$account.accountId):null
        obj.work_space_id = $workspace && $workspace.workSpaceId ?$workspace.workSpaceId:null
        obj['next_action'] = $user && ($user.userId||$user.user_id)?($user.userId||$user.user_id):null
        obj['unAssigned'] = unAssigned       
        obj = this.getRemoveNullValues(obj)
        return obj
    }
    getRemoveNullValues(obj){
        let keys = Object.keys(obj)
        for(let item of keys){
            if(!obj[item]){
                delete obj[item]
            }
        }
        return obj
    }
    
}
export default new ExceptionReportsDataService();