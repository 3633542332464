<template>
    <v-card flat>
        <v-card-title>
            Exception Report
        </v-card-title>
        <div class="ml-2">
            <ExceptionTable />
        </div>
    </v-card>
</template>
<script>
import ExceptionTable from './ExceptionTable.vue'
export default {
    components:{
        ExceptionTable
    },
    data(){
        return{
            data:[]
        }
    },

}
</script>
<style scoped>
</style>