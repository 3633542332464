<template>
  <v-card width="100%" style="border-radius: 12px;overflow: auto;">
    <v-card-item>
      <v-card-title>
        {{ viewType == 'edit' ? 'Edit' : viewType == 'view' ? 'View' : "Add" }}
        <b>ListModel</b>
        <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
      </v-card-title>
    </v-card-item>
    <v-form @submit.prevent="save" ref="form">
      <v-card-text>
        <v-row no-gutters class="mt-3">
          <v-col cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" :rule="nameRule" placeholder="Name" v-model="listModel.name"
              :disabled="showReadOnly" />
          </v-col>
        </v-row>
        <CardSelection  v-if="!modelData" :menuOptions="modelOptions" @cardSelected="cardSelected" />
        <v-row no-gutters class="mt-3" v-if="listModel.model_type && listModel.model_type=='table'">
          <v-col cols="12">
            <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Models
              <span style="color:#CF0707;padding-left:2px;font-weight:700">*</span>
            </v-label>
            <AutoComplete :items="listModelData" item-title="value" v-model="listModel.model" item-value="value"
              :rules="ModelRule" :disabled="showReadOnly" :placeholder="'Models'" />
          </v-col>
        </v-row>
        <v-row v-if="listModel.model_type && listModel.model_type=='function'">
          <v-col cols="12">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Function Name
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" :rule="functionRule" placeholder="Function Name" v-model="listModel.function_name"
              :disabled="showReadOnly" />
          </v-col>
        </v-row>
        <v-row v-if="listModel.model_type && listModel.model_type=='query'">
          <v-col cols="12">
          <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Query
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
                <span v-if="validQuery" class="text-caption">Validating Query</span>
          </v-label>
          
          <v-textarea variant="outlined" v-model="listModel.query" @change="updateTable"/>
          </v-col>
          <v-col cols="12">
            <v-label class="pb-1"  style="width:100%;font-size: 14px; color: #272727; font-weight: 600">Tables</v-label>
            <br/>
              <v-chip label class="ma-2" v-for="table in listModel.tables" v-bind:key="table">
                {{table}}
              </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="listModel.model_type!='function' && listModel.model_type!='assessment-model'">
            <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Roles
              <span style="color:#CF0707;padding-left:2px;font-weight:700">*</span>
            </v-label>
            <AutoComplete multiple :items="roles" 
            item-title="name" v-model="selectedRoles" 
            item-value="id"
           :disabled="showReadOnly" :placeholder="'Select Roles'" />
          </v-col>
          <v-col cols="12" v-if="listModel.model_type=='assessment-model'">
            <v-row>
              <v-col>
            <span  v-if="attachmentData && attachmentData.length==0"> Upload Excel file to create Model</span>
            </v-col>
            <v-col>
            <uploadFiles
                  v-if="attachmentData && attachmentData.length==0"
                  @documentsData="documentsData($event)"
                  keyType="LM"
                  :fileForUpload="fileForUpload"
                  :inputType="InputType"
                />
          </v-col>
          </v-row>
          <v-row
            class="py-0 my-0"
            style="margin-left: 0%"
            v-if="attachmentData && attachmentData.length > 0"
          >
            <v-cols v-for="(attachment, index) in attachmentData" :key="index">
              <v-chip
                class="selectedChip"
                :close="true"
                closable
                close-icon="mdi-close-circle"
                close-transition="scale-transition"
                @click.stop="remove(index)"
              >
               {{ attachment.doc_name }}
              </v-chip>
            </v-cols>
          </v-row>
          </v-col>
            <v-col cols="12">
            <v-switch label="Live" v-model="listModel.live" />
              </v-col>
        </v-row>
       
      </v-card-text>
     
      <v-card-actions class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit" 
          v-if="(listModel.model_type=='query' &&  listModel.tables && listModel.tables.length>0) || (listModel.model_type!='query')"
          >Save</v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { CardSelection } from '@tiburadev/vue-comp';
import AutoComplete from '@/components/ui/AutoComplete.vue';
import TextFieldComponent from '@/components/TextFieldComponent.vue';
import ListModelService from '@/api/service/ListModel/ListModelService';
import ListModelDetailService from '@/api/service/ListModelDetail/ListModelDetailService'
import commonService from '@/common.service';
import RoleService from '@/api/service/Role/RoleService'
import documentService from "@/api/service/Document/DocumentService";
import uploadFiles from "@/components/uploadFiles.vue";

export default {
  props: ["id", "viewType","modelData"],
  components: {
    AutoComplete,
    TextFieldComponent,
    CardSelection,
    uploadFiles
  },
  data() {
    return {
      showReadOnly: false,
      type: "",
      dialog: false,
      attachmentId:[],
      attachmentData:[],
      fileForUpload:null,
      InputType: null,
      roles:[],
      validQuery:false,
      add: false,
      size: {},
      listModelData: [],
      listModel: {},
      selectedRoles:[],
      ModelRule: [(value) => !!value || "Models is Required."],
      selectedRoleRule: [(value) => !!value || "Role is Required."],
      functionRule: [(value) => !!value || "Function name is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      modelOptions: {
            "columns": 2,
            "height": "120px",
            "showSearchBox": false,
            "outlineOnHover": true,
            "outlineOnSelection": true,
            "actions": [],
            "multiSelection": false,
            "selectedValue": "code",
            "scrollContainerHeight": "275px",
            "options": [
                {
                    "title": "Table",
                    "code": "table",
                    "subTitle": "By Table",
                    "description": "Create model based on the table"
                },
                {
                    "title": "Query",
                    "code": "query",
                    "subTitle": "By Query",
                    "description": "Create model from the custom query"
                },                
                {
                    "title": "Function",
                    "code": "function",
                    "subTitle": "By Function",
                    "description": "Create model from the custom function"
                },        
                {
                    "title": "Assessment Model",
                    "code": "assessment-model",
                    "subTitle": "For Creating Asssesment ",
                    "description": "Create model for assessment"
                }
            ]
      },
    };
  },
  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
        this.type1 = this.type
      }
    },
  },
  created() {
    if(this.modelData && this.modelData.id) {
      this.listModel = this.modelData
      this.listModel.live = this.listModel.live==1 ? true : false
      this.selectedRoles = JSON.parse(this.listModel.roles)
    }
    this.getRoles()
    this.getListModelData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == 'edit' ? false : true;
    }
  },
  methods: {
    async handleDrop($event) {
      $event.preventDefault();
      this.showDragContainer = false;
      this.fileForUpload = $event.dataTransfer.files;
      this.InputType = "drop";
      await this.getAttachementData();
      // console.log(this.fileForUpload, this.type, "started");
    },
    async documentsData($event) {
      this.attachmentId.push($event.id);
      await this.getAttachementData();
    },
    async getAttachementData() {
      if (this.attachmentId.length > 0) {
        try {
          let res = await documentService.getAllDocuments({
            doc_id: JSON.stringify(this.attachmentId),
          });
          this.attachmentData = res;
          this.$forceUpdate();
        } catch (e) {
          console.log(e);
        }
      }
    },
    cardSelected($item) {
        this.listModel.model_type = $item
    },
    async getRoles() {
      this.roles = await RoleService.findAll({"active":1})
    },
    setFalse() {
      this.$emit('closeDialog')
    },
    async updateTable() {
      this.validQuery=true
      let $tables = await ListModelService.getTables({"query":this.listModel.query})
      this.listModel.tables = $tables
      this.validQuery=false

    },
    async getById(id) {
      let res = await ListModelService.findOne(id);
      this.listModel = res[0];
    },
    async getListModelData() {
      let res = await commonService.getTables()
      this.listModelData = res.length > 0 ? res : []
    },
    async save() {
      if (this.listModel.model || this.listModel.query ||this.listModel.model_type == 'function' ||
        (this.listModel.model_type=='assessment-model' && this.attachmentId && this.attachmentId.length==1)  
      ) {
        this.listModel.roles =JSON.stringify(this.selectedRoles)
        //this.listModel.attachmentId = this.attachmentId[0]
        if(this.listModel.id) {
          let output = await ListModelService.update(this.listModel);
          console.log(output);
          
        } else {
          let output = await ListModelService.create(this.listModel);
          output = output.data.data.options.data
          await ListModelDetailService.importFromExcel({"modelId":output.id,"documentId":this.attachmentData[0].file_name})
        }
        this.setFalse()
        this.dialog = false;
        this.$emit("reload", true);
      }
    },
  }

}
</script>

<style></style>