<template>
  <v-card variant="tonal" class="attachcard">
    <v-card-text>
      <div @click="addModel" class="attachaction">
        <v-icon class="mr-1" color="#2E3A59">mdi-plus-circle</v-icon>
        Attach a data Model
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    addModel() {
      this.$emit("addModel");
    },
  },
};
</script>

<style scoped>
.attachcard {
  border: 1px solid #d1d1d1;
  border-radius: 12px;
}
.attachaction {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 0;
  cursor: pointer;
}
</style>
