import axios from 'axios';
// var publicPages = ["login", "otpVerification", "simcard", "resetpassword"]
// import apiConfig from '../config/config.js'
// var publicPages = ["login", "resetpassword"]
import store from '../../store/index'
import apiConfig from '../config/config'

const ApiServiceAssesments = {
    request: async function ($options,$entity,$action) {
        const $token = (localStorage.getItem('TOKEN'))

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': $token,
            'entity':$entity,
            'action':$action
        }
        return new Promise(function (resolve, reject) {
            // store.commit("SET_LOADER", true);
            if ($options && $options.type &&  $options.type == 'firebase') {
                $options.url = apiConfig.gatWayUrl + "/" + $options.url;
            }
            else{
            $options.url = apiConfig.baseUrlAssesments + "/" + $options.url;
            }
            if ($options.method.toUpperCase() == 'POST') {

                let $h
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: headers }
                } else {
                    $h = { headers: headers }
                }
                axios.post($options.url, $options.data, $h)
                    .then(response => {
                        // store.commit("SET_LOADER", false);
                        resolve(response);
                    })
                    .catch(error => {
                        if (error && error.response && error.response.data && error.response.data.meta && error.response.data.meta.message && error.response.data.meta.message == 'Token Expired!') {
                             store.commit("SET_EXPIRE_SESSION", true);
                            // store.commit("SET_LOADER", false);
                        } else {
                            store.commit("SET_EXPIRE_SESSION", false);
                            // store.commit("SET_LOADER", false);
                            reject(error)
                        }

                    });
            } else if ($options.method.toUpperCase() == 'DELETE') {
                axios.delete($options.url, { "headers": headers })
                    .then(response => {
                        // store.commit("SET_LOADER", false);
                        resolve(response);
                    })
                    .catch(error => {
                        if (error && error.response && error.response.data && error.response.data.meta && error.response.data.meta.message && error.response.data.meta.message == 'Token Expired!') {
                             store.commit("SET_EXPIRE_SESSION", true);
                            // store.commit("SET_LOADER", false);
                        } else {
                            store.commit("SET_EXPIRE_SESSION", false);
                            // store.commit("SET_LOADER", false);
                            reject(error)
                        }

                    });
            } else if ($options.method.toUpperCase() == 'PUT') {
                axios.put($options.url, $options.data, { headers: headers })
                    .then(response => {
                        // store.commit("SET_LOADER", false);
                        //console.log(response);
                        resolve(response);
                    })
                    .catch(error => {
                        if (error && error.response && error.response.data && error.response.data.meta && error.response.data.meta.message && error.response.data.meta.message == 'Token Expired!') {
                             store.commit("SET_EXPIRE_SESSION", true);
                            // store.commit("SET_LOADER", false);
                        } else {
                            store.commit("SET_EXPIRE_SESSION", false);
                            // store.commit("SET_LOADER", false);
                            reject(error)
                        }

                    });
            } else {
                var $h = {}
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: headers }
                } else {
                    $h = { headers: headers }
                }
                axios.get($options.url, $h)
                    .then(response => {
                        // store.commit("SET_LOADER", false);
                        resolve(response);
                    })
                    .catch(error => {
                        if (error && error.response && error.response.data && error.response.data.meta && error.response.data.meta.message && error.response.data.meta.message == 'Token Expired!') {
                             store.commit("SET_EXPIRE_SESSION", true);
                            // store.commit("SET_LOADER", false);
                        } else {
                            store.commit("SET_EXPIRE_SESSION", false);
                            // store.commit("SET_LOADER", false);
                            reject(error)
                        }
                    });
            }


        });


    }

}

export default ApiServiceAssesments