<template>
    <svg
    class="svgClose"
        @click="clicked"
            @mouseout="mouseover"
            @mouseleave="mouseleave"
            @mouseenter="mouseenter"
            @mousedown="mousedown"
             width="16" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    >   <title>Close</title>
  <path d="M17.8059 1L1.46323 17.3427" :stroke=" color ? color :'#9A9DB0' " stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.8059 17.3438L1.46323 1.00108" :stroke=" color ? color : '#9A9DB0'" stroke-linecap="round" stroke-linejoin="round"/>

</svg>
</template>
<script>
export default {
    props:["color"],
    methods: {
        clicked() {
            this.$emit('clicked')
        },
        mouseover() {
            this.$emit('mouseover')
        },
        mouseleave() {
            this.$emit('mouseleave')
        },
        mousedown() {
            this.$emit('mousedown')
        },
        mouseenter() {
            this.$emit('mouseenter')
        },
    }
}
</script>
<style>
.svgClose:hover path {
    stroke:#EF2035;
    stroke-width:2;
}
</style>