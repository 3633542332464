<template>
  <div class="reportContainer">
    <div v-if="showData" class="titleContainer">
      <div>
            
            <span>{{
            issueType && issueType.length>0 && issueType[0].next_action_name?issueType[0].next_action_name:'Un Assigned'
            }}</span>
      </div>
      <div class="search-container">
        <span ref="searchContainer" v-if="searchClicked" class="search-input">
          <TextFieldComponent
            id="search"
            v-model="searchQuery"
            @input="searchIssues()"
            flat
            append-inner-icon="mdi-magnify"
            style="width: 300px"
          ></TextFieldComponent>
        </span>
        <v-icon v-else @click.stop="toggleSearch" class="search-icon"
          >mdi-magnify</v-icon
        >
        <v-icon @click="closeDrawer" size="small"
            >mdi-close
            </v-icon>
      </div>
    </div>
    <div class="centered-container" v-if="loader">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div style="height: 100vh" class="scrollableContent">
      <div
        v-if="this.issueType.length == 0 && !loader && !filterData"
        class="centered-message"
      >
        <strong>Please select the Workspace Or account</strong>
      </div>
      <div v-if="filterData" class="centered-message">
        <strong>No Data Found Based on your search</strong>
      </div>

      <v-card elevation="0" v-for="(item, index) in issueType" :key="index">
        <div
          style="display: flex; gap: 12px; cursor: pointer"
          @click="goIssueView(item)"
        >
          <div class="pl-3 pt-2 pr-0">
            <CustomAvatar2
              class="avatrBig ma-0 mr-2 show-cursor"
              :acCode="item.account_code"
              :fName="item.account_name"
              :theme="item.account_theme"
              :imgUrl="item.a_image_url ? item.a_image_url : null"
              :size="'large'"
            />
          </div>

          <div class="pr-3 py-3 pl-0" style="width: 100%">
            <div style="display: flex; justify-content: space-between">
              <div class="reff">
                {{ item.reference_num }}
                <div
                  class="priority"
                  v-if="
                    (item && item.priority == 'high') ||
                    item.priority == 'critical'
                  "
                >
                  <v-icon v-if="item && item.priority == 'high'"
                    >mdi-exclamation-thick</v-icon
                  >

                  <i
                    v-if="item && item.priority == 'critical'"
                    class="pi pi-exclamation-triangle"
                    style="color: red; font-size: 8px; font-weight: 800"
                  />
                  <v-icon
                    v-if="item.week_end_mails == 'yes'"
                    class="mdi mdi-circle-half-full"
                    color="blue"
                  >
                  </v-icon>
                </div>
              </div>

              <div style="display: flex">
                <div class="chip" v-if="item && item.stage">
                  {{ item.stage }}
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                justify-content: space-between;
                line-height: 1.2;
              "
            >
              <span style="width: 75%">
                <v-row>
                  <v-col cols="12">
                    <span class="issueName">{{ item.name }} </span>
                    <span class="chip" v-if="item && item.sub_category_name">
                      {{ item.sub_category_name }}
                    </span>
                  </v-col>
                </v-row>
              </span>

              <span style="display: flex">
                <div class="dateAndP" v-if="item.mail_date">
                  <span class="dateRow">
                    <span>
                      {{
                        item &&
                        item.mail_date &&
                        moment(item.mail_date).format("MM/DD/YYYY hh:mm A") !=
                          "Invalid date"
                          ? moment(item.mail_date).format("MM/DD/YYYY")
                          : ""
                      }}
                    </span>
                    <div>
                      {{
                        item &&
                        item.mail_date &&
                        moment(item.mail_date).format("MM/DD/YYYY hh:mm A") !=
                          "Invalid date"
                          ? moment(item.mail_date).format("ddd hh:mm A")
                          : ""
                      }}
                    </div>
                  </span>
                </div>
                <div class="dateAndP" v-else>
                  <span class="dateRow">
                    <span>
                      {{
                        item &&
                        item.created &&
                        moment(item.created).format("MM/DD/YYYY hh:mm A") !=
                          "Invalid date"
                          ? moment(item.created).format("MM/DD/YYYY")
                          : ""
                      }}
                    </span>
                    <div>
                      {{
                        item &&
                        item.created &&
                        moment(item.created).format("MM/DD/YYYY hh:mm A") !=
                          "Invalid date"
                          ? moment(item.created).format("ddd hh:mm A")
                          : ""
                      }}
                    </div>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <v-divider style="background-color: #adadad"></v-divider>
      </v-card>
    </div>
  </div>
</template>

<script>
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import moment from "moment";
import IssueService from "@/api/service/Issue/IssueService";
export default {
  props: ["filter", "WorkSpaceName", "drawer",'issueData'],
  components: {
    CustomAvatar2,
    TextFieldComponent,
  },

  data() {
    return {
      issueType: [],
      loader: false,
      searchQuery: "",
      searchClicked: false,
      showData: false,
      filterData: false,
      group: "",
      stringValue: "",
      closeNav: false,
    };
  },
  computed: {},
  created() {
    if (this.filter) {
      // this.getIssueData();
    }
    if(this.issueData){
        this.issueType = this.issueData
        this.showData = true;
    }
  },
  watch: {
    filter() {
      if (this.filter) {
        // this.getIssueData();
      }
    },
    issueData(){
      if(this.issueData){
        this.issueType = this.issueData
        this.showData = true;
        this.originalIssueType = [...this.issueType];
      }
    }
  },
  methods: {
    moment,
    closeDrawer() {
      this.$emit('closedrawer',this.closeNav)
      this.issueType=[]
    },
    async getIssueData() {
      try {
        let res = await IssueService.findAllIssues(this.filter);
        this.issueType = res && res.length > 0 ? res : [];
      } catch (e) {
        this.issueType = [];
        console.log(e);
      }
      this.showData = true;
    },

    goIssueView(item) {
      let status = this.$store.getters.getMyspace;

      localStorage.setItem(
        "_WS_DEFAULTS_",
        JSON.stringify({
          workspace: { id: item.work_space_id, name: item.work_space_name },
        })
      );
      localStorage.setItem("workSpaceId", item.work_space_id);

      // this.$router.push({ path: "/issueView2", query: { id: item.id } });
      this.$store.dispatch("setIssueInView", item.id);
      let wsDefaults = {
        workspace: { id: item.work_space_id, name: item.work_space_name },
        account: { id: item.account_id, name: item.account_name },
        project: { id: item.project_id, name: item.project_name },
      };
      let wsDefaultsData = JSON.stringify(wsDefaults);
      localStorage.setItem("_WS_DEFAULTS_", wsDefaultsData);
      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      this.$store.commit("CHANGE_MY_SPACE", !status);
    },
    async getIssueById() {
      console.log(this.mainFilterData, "main");
      let data = {
        work_space_id: this.workspaceId.id,
        account_id: this.accountId,
      };

      if (this.mainFilterData && this.mainFilterData.priority !== null) {
        data.priority = this.mainFilterData.priority;
      }

      // Add other properties based on conditions, e.g., timeData
      if (
        this.mainFilterData &&
        this.mainFilterData.timeData &&
        this.mainFilterData.timeData.data_value !== null
      ) {
        data.timeData = this.mainFilterData.timeData.data_value;
      }

      console.log(data, "kishoreissue");

      try {
        let $res = await ProductionDashboardService.productionIssue(data);

        if ($res && $res.length > 0) {
          this.issueType = $res;
        } else {
          this.issueType = []; // No data, set to an empty array
        }

        this.loader = false; // Set loader to false regardless of whether there's data or not
      } catch (error) {
        console.error("Error fetching data:", error);
        this.issueType = []; // Set to an empty array in case of an error
        this.loader = false; // Set loader to false in case of an error
      }
    },

    async searchIssues() {
      // Ensure you have the original data stored locally
      if (!this.originalIssueType) {
        this.originalIssueType = [...this.issueType];
      }

      let $next_action_name =  this.issueData && this.issueData.length > 0 && this.issueData[0].next_action_name ? this.issueData[0].next_action_name : ''

      // Filter based on the original data
      const filteredIssues = this.originalIssueType.filter((item) =>
        item.next_action_name === $next_action_name 
      );
      
      if(this.searchQuery.length>0){
        let $data = filteredIssues.filter((k)=> 
        { return k.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      });
        this.issueType = $data;
      }else{
        this.issueType = filteredIssues;
      }

      if (this.issueType.length == 0) {
        this.filterData = true;
      } else {
        this.filterData = false;
      }
      // Update the displayed issues
      
    },

    toggleSearch() {
      this.searchClicked = !this.searchClicked;
      if (this.searchClicked) {
        this.$nextTick(() => {
          this.$refs.searchContainer.querySelector("input").focus();
        });
      }
    },
    handleOutsideClick(event) {
      const textField = this.$refs.searchContainer;
      if (textField && !textField.contains(event.target)) {
        this.searchClicked = false;
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
  },
};
</script>

<style scoped>
.reportContainer {
  padding: 0px 18px;
  border-radius: 4px;
  border: 1px solid #c6c6c6;
  background: #fff;
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.centered-container {
  flex: 1;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  margin-top: 50%;
}

.titleContainer {
  font-size: 18px;
  font-weight: 700;
  padding-top: 18px;
  background: #fff;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;

}
.centered-message {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50%;
}

.search-container {
  display: flex;
  justify-content: flex-end;
}

.search-input {
  margin-left: auto; /* Push the search input to the end of the row */
}

.search-icon {
  align-self: flex-end; /* Align the icon to the end of the row */
}
.chip {
  text-transform: capitalize;
  border: 1px solid #f9c644;
  background: #ffe8ae;
  color: #000;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 4px;
  height: fit-content;
  width: fit-content;
  border-radius: 4px;
}

.imgContent {
  width: 32px;
  height: 32px;
  display: flex;
  place-items: center;
  border-radius: 4px;
  background: #fac5e4;
}

.alternateBackground {
  background: #0a93d0;
}
.changeColor {
  filter: invert(1);
}
.scrollableContent {
  overflow: auto;
}
.textContent {
  color: #000;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
</style>
