<template>
  <div class="px-5 pt-6 pb-4" no-gutters style="display: flex;align-items: center;width: 100%;">
    <div style="display: flex;align-items: center;width: 50%;gap: 20px;">
      <span class="title">Time Sheet</span>
      <v-switch v-if="userSelection" color="primary" class="small-switch" v-model="myTimeSheet"
        @update:modelValue="changeTimeSheetType" :label="myTimeSheet ? 'My timesheet' : 'All users timesheet'"></v-switch>
    </div>
    <div style="display: flex;justify-content: end;gap: 20px;width: 50%;">
      <div style="width: 30%;" v-if="type">
        <AutoComplete  :items="userData" item-value="id" :v-model="selectedUser" item-title="name"
          density="compact" placeholder="User" @update:modelValue="selectUser" />
      </div>
      <!-- <div style="width: 30%;" v-if="type">
      <AutoComplete :items="backupWorkspace"  :placeholder="'Workspace'" :item-title="'name'"
      :item-value="'id'"  />
    </div> -->
      <div style="display: flex;justify-content: end;gap: 20px;">
        <span v-if="!type" class="datetext"> <i class="pa-1 pi pi-chevron-left" style="cursor: pointer;"
            @click="changeDate('backward')" />
          {{ moment(startDate).format('YYYY-MMM-DD') }} - {{ moment(endDate).format('YYYY-MMM-DD') }}
          <i class="pa-1 pi pi-chevron-right" style="cursor: pointer;" @click="changeDate('forward')" />
        </span>
        <button v-if="timeSheetData && timeSheetData.length > 0" class="button" @click="getApproval(type)"> {{ type ?
          'Approve All' : 'Submit For Approval' }}</button>
      </div>
    </div>
  </div>
  <v-row>
    <v-col cols="12">
      <v-sheet class="overflow-auto">
        <v-table fixed-header height="100%">
          <thead>
            <tr>
              <th v-for="(column, index) in headerData" :key="index" class="text-left no-wrap header">
                <strong>{{ column.label }}</strong>
              </th>
            </tr>
          </thead>

          <tbody v-if="timeSheetData && timeSheetData.length > 0">
            <tr v-for="(row, rowIndex) in timeSheetData" :key="rowIndex">
              <td v-for="(column, colIndex) in headerData" :key="colIndex">

                <span v-if="column && column.display && column.display === 'date'" class="no-wrap">
                  {{ row[column.value] ? moment(row[column.value]).format('YYYY-MM-DD') : '' }}
                </span>
                <template v-else-if="column && column.display && column.display === 'time'">
                  <span v-if="row[column.value]">
                    {{ moment(row[column.value]).format('HH:mm') }}
                  </span>
                  <br v-if="row[column.value1] && row[column.value]">
                  <span v-if="row[column.value1]">
                    <strong>{{ moment(row[column.value1]).format('HH:mm') }}</strong>
                  </span>
                </template>
                <template v-else-if="column && column.display && column.display === 'actual_hrs'">
                  <span v-if="row[column.value]">
                    {{ row[column.value] }}
                  </span>
                  <br v-if="row[column.value1] && row[column.value]">
                  <span v-if="row[column.value1]">
                    <strong>{{ row[column.value1] }}</strong>
                  </span>
                </template>
                <span v-else-if="column && column.display && column.display === 'action' && !type">
                  <i class="pi pi-eye" @click="rowActions('view', row)" /> <i class="pi pi-file-edit"
                    @click="rowActions('edit', row)" />
                  <!-- <i class="pi pi-trash" @click="rowActions('delete', row)" /> -->

                </span>
                <span v-else-if="type && column && column.display && column.display === 'approve'">
                  <span class="approve-btn" @click="setApprove(row.id)">Approve</span>
                </span>
                <span v-else class="no-wrap">
                  {{ row[column.value1] ? row[column.value] : row[column.value] }}
                </span>
              </td>
            </tr>
          </tbody>

          <tbody v-else style="width: 100%;">
            <tr>
              <td colspan="12">
                <div style="width: 100%;background-color: #fff;display: grid;place-items: center;">
                  <img :src="require('@/assets/no-record.png')" width="160" height="160" />
                  <p class="text-center">
                    No Results found
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-sheet>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" :width="900" persistent>
    <AddTimeSheets ref="TimeSheets" @closeDialog="close()" @reload="getTimeSheetData()" :id="timeSheetId"
      :viewType="viewType" />
  </v-dialog>
  <v-dialog v-model="rDialog" :width="900" persistent>
    <RaiseNotice ref="TimeSheets" @closeDialog="close()" @reload="getTimeSheetData(this.userDetails.id)" :id="timeSheetId"
      :viewType="viewType" />
  </v-dialog>
</template>
<script>
import TimeSheetService from "@/api/service/TimeSheet/TimeSheetService";
import moment from 'moment'
import RaiseNotice from "./RaiseNotice.vue";
import AddTimeSheets from "../../modules/TimeSheet/AddTimeSheets.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService';
import commonService from '@/common.service';


export default {
  components: {
    RaiseNotice,
    AddTimeSheets,
    AutoComplete
  },
  data() {
    return {
      viewType: null,
      rDialog: false,
      dialog: false,
      userDetails: null,
      timeSheetData: [],
      startDate: null,
      endDate: null,
      headerData: [],
      myTimeSheet: true,
      timeSheetId: null,
      type: null,
      userId: null,
      userSelection: false,
      userData: [],
      selectedUser: null,
      backupWorkspace: []
    }
  },

  async created() {
    this.getWorkspaceData();
    this.userId = this.$route && this.$route.query && this.$route.query.userId ? this.$route.query.userId : null
    this.type = this.$route && this.$route.query && this.$route.query.type ? this.$route.query.type : null
    await this.getData()
    let userData = localStorage.getItem('user_details')
    this.userDetails = userData ? JSON.parse(userData) : null
    this.userSelection = this.userDetails && this.userDetails.roles && this.userDetails.roles.role_type && parseInt(this.userDetails.roles.role_type) < 6 ? true : false
    // console.log(this.userSelection, "userSelectionnnn")
    if (this.userSelection) {
      if (this.userSelection && this.userId && this.userId != this.userDetails.id) {
        this.selectedUser = this.userId
        this.type = 'approve'
        this.getUserDetails(this.userDetails.roles.code)
        this.getTimeSheetData()
      }
      else {        
        this.userId = null
        this.type = null
        this.getTimeSheetData()
      }

    }
    else {   
      this.userId = null
      this.type = null
      this.getTimeSheetData()
    }
    this.setHeaderData()


  },
  methods: {
    moment,
    async getWorkspaceData() {
			let res = await WorkSpaceService.findAll({ active: 1 });
			this.backupWorkspace = res && res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
			this.backupWorkspace = [...this.backupWorkspace];
		},
    changeTimeSheetType($event) {
      if (!$event) {
        if (this.userSelection) {
          this.type = 'approve'
          this.getUserDetails(this.userDetails.roles.code)
        }
        this.timeSheetData = []
      }
      else {
        this.userId = null
        this.type = null
        this.getTimeSheetData()
      }
    },
    selectUser($event) {
      if ($event) {
        if (this.userDetails.id == $event) {
          this.userId = null
          this.type = null
          this.myTimeSheet = true
          this.getTimeSheetData()
        }
        else {
          this.userId = $event
          this.type = 'approve'
          this.getTimeSheetData()
        }
      }

    },
    getUserDetails(code) {
      TimeSheetService.getTimeSheetUserData({ code: code }).then(res => {
        // console.log(res, "uuuuuuuueesss")
        this.userData = res && res.length ? res : []
      }).catch(e => {
        this.userData = []
        console.log(e)
      })

    },
    async getApproval(type) {
      if (type) {
        this.setApprove()
      } else {
        let res = await TimeSheetService.approveTimeSheet({
          userRole:this.userDetails.roles.code,
          userRoleType:this.userDetails.roles.role_type,          
          time_sheet_data: this.timeSheetData,
          email:this.userDetails.email
        });
        if (res) {
          if (res.data && res.data.data && res.data.data.message && res.data.data.message == 'nochange') {
            this.$store.dispatch("setSnackbar", { text: "There are no changes" });
          }
          else {
            this.$store.dispatch("setSnackbar", { text: "Request Raised Successfully" });
          }
        }
        else {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      }
    },
    setApprove(id) {
      let arr = []
      if (id) {
        arr.push(id)
      } else {
        arr = this.timeSheetData.map(item => item.id);
      }
      let outPut = TimeSheetService.setApprove({ timeSheetData: arr })
      if (outPut) {
        this.$store.dispatch("setSnackbar", { text: "Approved Successfully" });
        this.getTimeSheetData()
      }
      else {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: "Something Went Wrong",
        });
      }
    },
    getTimeSheetData() {
      let obj = {
        resource_id: this.userId || this.userDetails.id
      }
      if (!this.type) {
        obj.from_date = this.startDate
        obj.to_date = this.endDate
      }
      else {
        obj.notApprove = true
      }

      TimeSheetService.getAllApproveData(obj).then(res => {
        this.timeSheetData = res
        // console.log(this.timeSheetData, "ooooooooo3333")
      }).catch(e => {
        this.timeSheetData = []
        console.log(e)
      })
    },
    getData() {
      var currentDate = moment();
      var weekStartDate = currentDate.clone().startOf('week');
      var weekEndDate = currentDate.clone().endOf('week');
      this.startDate = weekStartDate.format('YYYY-MM-DD');
      this.endDate = weekEndDate.format('YYYY-MM-DD');
    },
    rowActions($event, data) {
      // console.log($event, data, "chkkkkk");
      if ($event == "view") {
        this.dialog = true;
        this.viewType = $event;
        this.timeSheetId = data.id;
        console.log("view");
      }
      if ($event == "select") {
        this.dialog = true;
        this.viewType = "view";
        this.timeSheetId = data.id;
      }
      if ($event == "edit") {
        // alert('raise')
        this.rDialog = true;
        this.timeSheetId = data.id;
      }
    },

    changeDate(type) {
      let $startDate = moment(this.startDate)
      let $endDate = moment(this.endDate)
      if (type == 'forward') {
        this.startDate = $startDate.add(7, 'days')
        this.endDate = $endDate.add(7, 'days')
      }
      else {
        this.startDate = $startDate.subtract(7, 'days')
        this.endDate = $endDate.subtract(7, 'days')
      }

      this.startDate = this.startDate.format('YYYY-MM-DD');
      this.endDate = this.endDate.format('YYYY-MM-DD');
      this.getTimeSheetData()

    },
    close() {
      this.dialog = false;
      this.rDialog = false;
      this.getTimeSheetData()
    },
    getBgColors(name) {
      if (name == "Login") return "#C8FFFF";
      else if (name == "LogOut") return "#FFABDD";
      else if (name == "Meeting") return "#E2FCE9";
      else if (name == "Break") return "#FFF1E9";
      else return "#F0ECFF ";
    },
    setHeaderData() {
      let $header = [
        {
          label: "Resource Name",
          value: 'resource_name'
        },
        {
          label: "Task Name",
          value: 'task_name',
          display: 'chip'
        },
        {
          label: "Reference Number",
          value: 'reference_num'
        },
        {
          label: "Entry Date",
          value: 'entry_date',
          display: "date"
        },
        {
          label: "Start Time",
          value: 'start_date',
          value1: 'new_start_date',
          display: "time"
        },
        {
          label: "End Time",
          value: 'end_date',
          value1: 'new_end_date',
          display: "time"
        },
        {
          label: "Actual Hrs",
          value: 'actual_hrs',
          value1: 'new_actual_hrs',
          display: "actual_hrs"
        }
      ]
      if (this.userId && this.type) {
        $header.push({
          label: "Approve",
          display: 'approve'
        })
      }
      else {
        $header.push({
          label: "Action",
          display: 'action'
        })
      }
      this.headerData = $header

      //       {
      //     "id": 2902,
      //     "actual_hrs": 0,
      //     "approved": null,
      //     "approval_notes": null,
      //     "company_id": 120,
      //     "currency_id": null,
      //     "customer_id": 0,
      //     "description": "login",
      //     "entry_date": "2024-02-28T05:23:05.000Z",
      //     "issue_id": 0,
      //     "issue_task_id": null,
      //     "project_id": 0,
      //     "reviewer_id": null,
      //     "stage": "pending",
      //     "task_id": 55,
      //     "active": 1,
      //     "created": "2024-02-28T11:11:45.000Z",
      //     "modified": "2024-02-28T11:11:45.000Z",
      //     "created_by": 0,
      //     "modified_by": null,
      //     "deleted": 0,
      //     "start_date": "2024-02-28T05:41:45.000Z",
      //     "end_date": "2024-02-28T05:41:45.000Z",
      //     "new_start_date": null,
      //     "new_end_date": null,
      //     "new_actual_hrs": null,
      //     "delete_request": 0,
      //     "new_description": null,
      //     "project_name": null,
      //     "task_name": "Logged In",
      //     "resource_id": 301,
      //     "work_space_name": null,
      //     "account_name": null,
      //     "resource_name": "Bala Ganesh"
      // }
    }


  },

}
</script>
<style scoped>
>>>.v-input__details {
  align-items: flex-end;
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  grid-area: messages;
  letter-spacing: 0.0333333333em;
  line-height: normal;
  min-height: 22px;
  padding-top: 6px;
  overflow: hidden;
  justify-content: space-between;
  display: contents;
}

.overflow-auto {
  overflow-x: auto;
}

.button {
  color: #fff;
  background-color: #1258ab;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  height: fit-content;
}

.title {
  font-weight: 600;
  font-size: 20px;
}

.datetext {
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 600;
  font-size: 14px;
}

.no-wrap {
  white-space: nowrap;
}

.header {
  background-color: #e4f1ff !important;
  color: #7d7b7d;
  height: 40px !important;
  font-weight: 500;
}

i {
  cursor: pointer;
}

.approve-btn {
  cursor: pointer;
  color: blue;
}

.approve-btn:hover {
  text-decoration: underline;
}
</style>
