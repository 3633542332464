<template>
    <v-card flat>
        <v-card-title>
            Escalation Dashboard
        </v-card-title>
        <div class="ml-2">
            <EscalationTable />
        </div>
    </v-card>
</template>
<script>
import EscalationTable from './EscalationTable.vue'
export default {
    components:{
        EscalationTable
    },
    data(){
        return{
            data:[]
        }
    },

}
</script>
<style scoped>

</style>