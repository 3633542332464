
import ListModel from '../modules/settings/Technical/Model/ListModel.vue'
import ActivityGroup from '../modules/settings/Technical/ActivityGroup/ActivityGroup.vue'
import Activity from '../modules/settings/Technical/Activity/Activity.vue'
import Event from '../modules/settings/Technical/Event/Event.vue'
import Menus from '../modules/settings/Technical/Menus/Menu.vue'
//import ListModelDetails from '../modules/settings/Technical/Model/ListModelDetails.vue'
import Category from '../modules/settings/Project/Category/Category.vue'
import AssessmentCategory from '../modules/settings/Project/AssessmentCategory/AssessmentCategory.vue'
import IssueType from '../modules/settings/Project/IssueType/IssueType.vue'
import Risk from '../modules/settings/Project/Risk/Risk.vue'
import SubCategory from '../modules/settings/Project/SubCategory/SubCategory.vue'
import TaskGroup from '../modules/settings/Project/TaskGroup/TaskGroup.vue'
import Udfs from '../modules/settings/Project/UDFS/Udfs.vue'
import ProjectSetting from '../modules/settings/Project/Project.vue'
import Task from '../modules/settings/Project/Task/Task.vue'
import Role from '../modules/settings/Identity/Role/Role.vue'
import User from '../modules/settings/Identity/User/User.vue'
import RolePermission from '../modules/settings/Identity/RolePermission/RolePermission.vue'
import IdentitySetting from '../modules/settings/Identity/IdentitySetting.vue'
import OrganizationSettings from '../modules/settings/Organization/Organization.vue'
import TechnicalSettings from '../modules/settings/Technical/Technical.vue'
import Location from '../modules/settings/Organization/Location/Location.vue'
import Recurringtask from '../modules/Jobs/index.vue'
import WorkSpace from '../modules/settings/Organization/WorkSpace/WorkSpace.vue'
import Account from '../modules/settings/Organization/Account/Account.vue'

import WorkTiming from '../modules/settings/Organization/WorkTimings/WorkTiming.vue'
import Sbu from '../modules/settings/Organization/Sbu/Sbus.vue'
import Holidays from '../modules/settings/Organization/Holidays/Holiday.vue'
import HolidayTemplate from '../modules/settings/Organization/Holidays/HolidayTemplate.vue'
import CreateHolidayTemplate from '../modules/settings/Organization/Holidays/CreateHolidayTemplate.vue'
import Enum from '../modules/settings/Others/Enum/Enum.vue'
import VacationType from '../modules/settings/Hr/VacationType/VacationType.vue'
import FormBuilder from '../modules/settings/Hr/FormBuilder/FormBuilder.vue'
import VacationTemplate from '../modules/settings/Hr/VacationTemplate2/VacationTemplate.vue'
import VacationRequestBoard from '@/modules/dashboard/VacationBoard/index.vue'
import CompanyProfile from '../modules/settings/Others/CompanyProfile/CompanyProfile.vue'
import OthersSettings from '../modules/settings/Others/Others.vue'
import HrSettings from '../modules/settings/Hr/Hr.vue'
import BlockedCalender from '@/modules/settings/Hr/BlockedCalender/BlockedCalender.vue'
import EmployeeVacationAllotments from '@/modules/settings/Hr/EmployeeVacationAllotments/EmployeeVacationAllotments.vue'

import userProfile from '../modules/users/UserProfile.vue'
import CreateWorkTiming from '../modules/settings/Organization/WorkTimings/CreateWorkTiming.vue'
///import CreateRolePermission from '../modules/settings/Identity/RolePermission/CreateRolePermission.vue'
// import CreateRolePermission from '../modules/settings/Identity/RolePermission/CreateRolePermission.vue'
import WsSetting from '../modules/settings/Organization/WorkSpace/WsSetting.vue'
import Permission from '../modules/security/Permissions.vue'
import ScoreCards from '../modules/settings/KraReview/ScoreCards/ScoreCards.vue'
import ScoreCardsTemplate from '../modules/settings/KraReview/ScoreCardTemplate/ScoreCardTemplate.vue'
import KraReview from '../modules/settings/KraReview/KraReview/KraReview.vue'
import UserScoreCard from '../modules/settings/KraReview/UserScoreCard/UserScoreCard.vue'
import ReviewSetting from '../modules/settings/KraReview/Review.vue'
import ErrorCode from '@/modules/ErrorCode/ErrorCode.vue';
import Stage from '@/modules/settings/Project/Stage/Stage.vue';
import QuestionBank from "@/modules/Questionnaire/questionnaireList.vue"
import PendingAssessments from "@/modules/Assesment/assessmentList.vue"
import CandidateProfile from '@/modules/CandidateProfile/CandidateProfiles.vue'
import EvaluvationSheet from '@/modules/settings/Hr/EvaluvationSheet.Vue/Evaluation.vue'

export default [
    {
        
        path: 'project',
        component: ProjectSetting,
        children: [{
            path: 'category',
            component: Category,
        },
        {
            path: "subCategory",
            component: SubCategory

        },
        {
            path: "assessmentCategory",
            component: AssessmentCategory

        },
        {
            path: "stage",
            component: Stage

        },
        {
            path: "subTaskGroup",
            component: TaskGroup

        },
        {
            path: "udfs",
            component: Udfs

        },
        {
            path: "TaskType",
            component: IssueType

        },
        {
            path: "subTask",
            component: Task

        },
        {
            path: "skill",
            component: Risk

        },
        {
          path: "errorcode",
          component: ErrorCode,
        }]
    },
    {
        path: 'technical',
        component: TechnicalSettings,
        children: [{
            path: 'model',
            component: ListModel,
        },
       /* {
            path: "listmodeldetails",
            component: ListModelDetails

        },*/
        {
            path: "activityGroups",
            component: ActivityGroup

        },
        {
            path: "activity",
            component: Activity

        },
        {
            path: "events",
            component: Event

        },
        {
            path: "menus",
            component: Menus
        }]
    },

    {
        path: 'organisation',
        component: OrganizationSettings,
        children: [{
            path: 'location',
            component: Location,
        },
        {
            path: "workSpace",
            component: WorkSpace

        },
        {
            path: "recurringTask",
            component: Recurringtask

        },
        {
            path: "account",
            component: Account

        },
       
        
        {
            path: "workTiming",
            component: WorkTiming

        },
        {
            path: "workSpaceSetting",
            component: WsSetting

        },
        {
            path: "sbu",
            component: Sbu

        },
        {
            path: "holidays",
            component: Holidays
        },
        {
            path: "holidayTemplate",
            component: HolidayTemplate
        },
        {
            path: 'CreateHolidayTemplate',
            name: 'CreateHolidayTemplate',
            component: CreateHolidayTemplate
          },

          {
            path: 'CreateWorkTiming',
            name: 'CreateWorkTiming',
            component: CreateWorkTiming
          },
          
    ]
        },
        
    {
        path: 'identity',
        component: IdentitySetting,
        children: [{
            path: 'role',
            component: Role,
        },
        {
            path: "user",
            component: User

        },
        {
            path: "rolePermission",
            component: RolePermission

        },
        {
            path: "userProfile",
            component: userProfile

        },
        {
            path: "permissions",
            component: Permission

        }]
    },

    {
        path: 'hr',
        component: HrSettings,
        children: [        
        {
            path: "assessments",
            name: "Assessments",
            component: PendingAssessments,
        },
        {
            path: "candidateProfile",
            name: "CandidateProfile",
            component: CandidateProfile,
        },
        {
            path: "questionBank",
            name: "QuestionBank",
            component: QuestionBank,
        },
        {
            path: "formBuilder",
            name: "formBuilder",
            component: FormBuilder,
        },
        {
            path: 'EvaluvationSheet',
            component: EvaluvationSheet,
        },    
        {
            path: 'vacationTypes',
            component: VacationType,
        },{
            path: 'vacationTemplate',
            component: VacationTemplate,
        },{
            path: 'vacationRequestBoard',
            component: VacationRequestBoard,
        },{
            path: 'BlockedCalender',
            component: BlockedCalender,
        },{
            path: 'employeeVacationAllotment',
            component: EmployeeVacationAllotments,
        },
    ]
    },{
        path: 'others',
        component: OthersSettings,
        children: [{
            path: 'enums',
            component: Enum,
        }, {
            path: 'companyProfile',
            component: CompanyProfile,
        },
    ]
    },

    {
        path: 'review',
        component: ReviewSetting,
        children: [
            {
                path: "scoreCard",
                component: ScoreCards
            },
            {
                path: "scoreCardsTemplate",
                component: ScoreCardsTemplate
            },
            {
                path: "userScoreCard",
                component: UserScoreCard
            },
            {
                path: "kraReview",
                component: KraReview
            },]
    }

];
