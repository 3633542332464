<template>
    <div class="d-flex justify-center">
        <v-card class="tittle">
            <v-card-title class="d-flex justify-space-between align-center">
                <span class="font-weight-bold opacity-100">Vacation Request </span>
                <v-btn style="float: right" @click="close" elevation="0" density="comfortable"
                    icon="mdi-close-circle"></v-btn>
            </v-card-title>
            <v-form @submit.prevent="save">
                <v-card-text>
                    <v-row class="head">
                        <v-col cols="6" class="pr-2 mt-n3">
                            <v-textHead class="text-body-2 font-weight-medium  opacity-50">Start Date</v-textHead>
                            <TextFieldComponent v-model="candidateProfileData.first_name"
                                :placeholder="'Vacation Type Code'" :type="'date'"/>
                        </v-col>
                        <v-col cols="6" class="pr-2 mt-n3">
                            <v-textHead class="text-body-2 font-weight-medium opacity-50">End Date</v-textHead>
                            <TextFieldComponent v-model="candidateProfileData.first_name"
                                :placeholder="'Vacation Type Code'" :type="'date'"/>
                        </v-col>
                        <v-col cols="12" class="pr-2 mt-n6">
                            <v-textHead class="text-body-2 font-weight-bold opacity-100">4 days (40 Hours)</v-textHead>
                        </v-col>
                        <v-col cols="12" class="pr-2 mt-n2">
                            <v-textHead class="text-body-2 font-weight-medium opacity-50">Vacation Type</v-textHead>
                            <TextFieldComponent v-model="candidateProfileData.first_name"
                                :placeholder="'Vacation Type Code'" />
                        </v-col>
                        <v-col cols="12" class="pr-2 mt-n7">
                            <v-textHead class="text-body-2 font-weight-medium opacity-50">Approval Managers</v-textHead>
                            <TextFieldComponent v-model="candidateProfileData.first_name"
                                :placeholder="'Manager name'" />
                        </v-col>
                        <v-col cols="12" class="pr-2 mt-n6">
                            <v-textHead class="text-body-2 font-weight-medium  opacity-50">Remarks</v-textHead>
                            <v-textarea label="Reason for the leave"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn class="create" color="#1B1A47" width="100" variant="flat" type="submit">
                        Create
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </div>
</template>
<script>
import { TextFieldComponent } from "@tiburadev/vue-comp";
import CandidateProfileService from "@/api/service/CandidateProfile/CandidateProfileService";
export default {
    components: { TextFieldComponent },
    data() {
        return {
            candidateProfileData: {
            },
        };
    },
    methods: {
        close() { this.$router.push("/requestBoard") },
        async save() {
            let output = await CandidateProfileService.create(this.candidateProfileData)
            console.log(output);
        }
    }
};
</script>
<style>
.tittle {
    font-size: 14px;
    font-weight: 800;
    font-size: bold;
    color: #000000;
    margin-top: 15px;
    width: 50%;
    height: max-content
}
.create {
    border-radius: 0px;
    margin-bottom: 25px;
}
.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: -30px;
  margin-right: 12px;
  padding-left: 15px;
}
.button1 {
  background-color: #1B1A47;
  color: #f9f9f9;
}
.email {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>