<template>
    <v-card width="100%" style="border-radius: 12px; overflow: hidden">
      <v-form @submit.prevent="save" ref="form">
        <v-card-item>
          <v-card-title>
            Account Selection
            <v-btn @click="setFalse()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
          </v-card-title>
        </v-card-item>
  
        <v-card-text>
          <v-row no-gutters class="mt-3 pa-2">

            <v-col cols="12" class="dropdown">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">{{`Choose you respective ${data && data.name?data.name:''} Account`}}
                <span  style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
              <AutoComplete :items="accountData" item-value="id" item-title="name" density="compact"
                placeholder="Account" :rules="accountRule" v-model="formData.account_id" />
  
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-action class="mr-3">
          <v-row justify="end" class="dialogBottom">
            <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
            <v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
          </v-row>
        </v-card-action>
      </v-form>
    </v-card>
  </template>
  <script>
  import AutoComplete from "@/components/ui/AutoComplete.vue";
  import commonService from "@/common.service";  
  export default {
    props: ['data'],
    components: {
      AutoComplete,
    },
  
    data() {
      return {
        dialog: false,
        type: null,
        accountData :[],
        accountRule: [(value) => !!value || "Account is Required."],
        formData: {},
      };
    },
    created() {
      this.getAccountData()
    },
    methods: {
        
        setFalse() {
        this.$emit("closeDialog");
        },
        async getAccountData(){
            try{
                let res = await commonService.getTableData({tableName:'accounts'})
                console.log(res,"meeeting account response data###")
                this.accountData = res && res.length>0?res:[]
            }
            catch(e){
                console.log(e);
                this.accountData = []
            }
        },
        async save(){
            let validation = await this.$refs.form.validate()
            if (!validation.valid) {
                console.log("Not valid")
            }
            else{
                let $data = this.data 
                $data.account_id = this.formData.account_id
                this.$emit('account-selection',$data)
            }
        }
    }
  };
  
  </script>
    