<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th class="first-column"></th>
          <th class="headerElem" v-for="project in projectData" :key="project.id">
            
            <div class="headerText"><span>{{ getProjectData(project.name )}} 
            <v-tooltip
            activator="parent"
            >{{ project.name }}
          </v-tooltip>
          </span><i :class="`pi ${sortId && sortId == project.id?'pi-sort-numeric-up':'pi-sort-numeric-down-alt'} show-cursor rotate-90`" @click="getSortTheData(project.id)"></i></div>

          </th>
          <th class="headerElem total-column total-header-cell">
            <div class="headerText total-header">Total</div>
          </th>
        </tr>
      </thead>
      <tbody class="scrollable-body">
        <tr v-for="account in accounData" :key="account.id">
          <td>
            <span style="font-weight: bold" class="headerElem account-name">
              {{ account.name }}</span>
          </td>
          <td v-for="project in projectData" :key="project">
            <div @click="
              issueData(project.id,account.id)
              " class="project-cell" style="font-weight: bold; cursor: pointer">
              {{ getCellValue(project.id, account.id) || "-" }}
            </div>
          </td>

          <td class="total-column-cell">
            <span style="font-weight: bold;cursor: pointer;" @click="issueData(null,account.id)">
              {{
                this.switchData === "estimate"
                ? getAccountTotal(account.id)
                : getAccountTotal(account.id)
              }}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="total-row">
          <td class="total-column-cell">Total</td>
          <td style="background-color: #eef6fb;width: 30px;" class="total-column-cell"
            v-for="project in projectData" :key="project.id">
            <span style="font-weight: bold;cursor: pointer;"  @click="issueData(project.id,null)">
              {{
                this.switchData === "estimate"
                ? getProjectTotal(project.id)
                : getProjectTotal(project.id)
              }}</span>
          </td>
          <td class="total-column-cell">
            <span style="font-weight: bold">
              {{ this.switchData === "estimate" ? getTotal() : getTotal() }}
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
import commonService from "@/common.service";
import lodash from 'lodash'
export default {
  props: ["filterData", "switchData"],
  data() {
    return { 
      ProductionData: null,
      accounData : [],
      projectData :[],
      sortId:null

     };
  },
  created() {
    this.sortId = localStorage.getItem('LB_SORT')
    this.getProductionData();
  },
  computed: {
  },
  watch: {
    filterData() {
      if (this.filterData) {
        this.getProductionData(this.filterData);
      }
    },
  },
  methods: {
    async uniqueData(){
      this.accounData = []
      let accountId = [];
      this.projectData = [] 
      let projectId = [];
      if (this.ProductionData) {
        this.ProductionData.forEach((item) => {
          if (!accountId.includes(item.account_id)) {
            this.accounData.push({id:item.account_id,name:item.account_name})
            accountId.push(item.account_id)
          }
          if (!projectId.includes(item.project_id)) {
            this.projectData.push({id:item.project_id,name:item.project_name})
            projectId.push(item.project_id)
          }
        });
        this.projectData = await commonService.getSort(this.projectData,'name','asc')
        this.accounData = await commonService.getSort(this.accounData,'name','asc')
      }
    },
    async getProductionData(filter) {
      let res = await ProductionDashboardService.liveBoardData(filter?filter:{});
      this.ProductionData = res.length > 0 ? res : [];
      if(this.sortId){
        this.getSortTheData(this.sortId)
      }
      this.uniqueData()
    },

    async getFilterDashboardData(data) {
      let obj = {};

      if (data.priority && data.priority.length > 0) {
        obj.priority = [...data.priority];
      }

      if (data.timeData && data.timeData.data_value) {
        obj.timeData = data.timeData.data_value;
      }

      let res = await ProductionDashboardService.findAll(obj);
      this.ProductionData = res.length > 0 ? res : [];
    },

    getCellValue(wId, aId) {
      let data = this.ProductionData.filter(k=>k.project_id==wId && k.account_id ==aId)
      if(data && data.length>0){
        return !this.switchData || this.switchData =='issues' ?Math.floor(data[0].total_issues):Math.floor(data[0].estimated)
      }
    },
    getSortTheData(wId){
      this.sortId = wId
      localStorage.setItem('LB_SORT',wId)
      this.ProductionData = lodash.orderBy(this.ProductionData, [
                (item) => item.project_id !== wId,
                (item) => item.project_id,
                (item) => item[!this.switchData || this.switchData =='issues'?'total_issues':'estimated']
            ], ['asc', 'asc', 'asc']);
      this.uniqueData()
    },

    getProjectData(name){
      if(name && name.length > 15){
        let sliced  = name.slice(0, 15);
        sliced = `${sliced}...`
        return sliced
      }else{
        return name
      }
    },
    getAccountTotal(aId) {
      let total = 0;
      let data = this.ProductionData.filter(k=>k.account_id ==aId)
      if(!this.switchData || this.switchData =='issues'){
        total = data.reduce((sum, project) => sum + (project.total_issues || 0), 0);
      }
      else{
        total = data.reduce((sum, project) => sum + (project.estimated || 0), 0);
      }
      return Math.floor(total);
    },

    getProjectTotal(wId) {   
      let total = 0;
      let data = this.ProductionData.filter(k=>k.project_id ==wId)
      if(!this.switchData || this.switchData =='issues'){
      total = data.reduce((sum, project) => sum + (project.total_issues || 0), 0);
      }      
      else{
        total = data.reduce((sum, project) => sum + (project.estimated || 0), 0);
      }
      return Math.floor(total);
    },

    issueData(pId, aId) {
      // if (pId && aId) {
        // Emitting the event with Workspace ID and Account ID
        this.$emit("workspace-clicked", {
          projectId: pId,
          accountId: aId,
        });
      // }
    },

    getTotal() {
      let total = 0;
      if (this.ProductionData) {
        this.ProductionData.forEach((item) => {
          total += item && item.total_issues ?item.total_issues:0;
        });
      }
      return total;
    },
  },
};
</script>

<style scoped>
/* Add a class to the "Total" column to keep it fixed */
.overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }

table {
  border-collapse: collapse;
  white-space: nowrap;
  width: fit-content;
}


.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the header and "Total" column to be fixed */
thead {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}

/* Add padding and adjust white-space for the account name */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
  position: relative;
  z-index: 4;
  /* Set a higher z-index to bring it to the front */
}

.scrollable-body {
  overflow-x: auto;
  max-height: 300px;
  /* Set the desired max height for the scrollable area */
}

.table-container {
  overflow-x: auto;
  max-height: 600px;
}

/* Add a class to the "Total" column to keep it fixed */
td.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the header and "Total" column to be fixed */
thead {
  position: sticky;
  top: 0;
  background: #fff;
}

/* Add padding and adjust white-space for the account name */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
}

.scrollable-body {
  overflow: auto;
  max-height: 300px;
  /* Set the desired max height for the scrollable area */
  z-index: -1;
  /* Set the desired max height for the scrollable area */
}

.headerElem {
  white-space: nowrap;
  height: 15em;
}

.headerText {
  /* transform: rotate(-90deg); */
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
}

td {
  padding: 6px;
  height: 42px;
  /* width: 50px; */
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

th:nth-child(1) {
  width: 150px !important;
  position: sticky;
  left: 0;
  background: #eef6fb;
  z-index: 3;
}

td:nth-child(1) {
  width: 150px !important;
  position: sticky;
  left: 0;
  text-align: left !important;
  font-size: 14px;
  font-weight: 700;
  background-color: #eef6fb;
}

th:last-child {
  /* width: 150px !important; */
  position: sticky;
  right: 0;
  z-index: 3;
}

td:last-child {
  /* width: 150px !important; */
  position: sticky;
  right: 0;
  font-size: 14px;
  font-weight: 700;
}

th:nth-child(odd) {
  /* width: 50px; */
}

td:nth-child(odd) {
  /* width: 50px; */
}

td:nth-child(even) {
  background: #e1eaef;
}
.rotate-90 {
    transform: rotate(90deg);
    margin: 50%;
}

/* Add these styles to your existing styles */

/* Set the first and last columns to stay fixed */
td.account-name,
td.total-column,
th.headerElem:nth-child(1),
th.headerElem.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
}

/* Set the first header cell to stay fixed */
th.headerElem:nth-child(1) {
  left: 0;
}

.total-header-cell {
  background-color: #eef6fb;
  /* Set the background color for the "Total" header cell */
}

/* Add a class for the "Total" value cells */
.total-column-cell {
  background-color: #eef6fb;
}

th.headerElem.total-column {
  position: sticky;
  left: 0;
  background-color: #eef6fb;
  z-index: 3;
  width: 20px !important;
}

/* Set the styles for the "Total" column header text */
.headerText.total-header {
  /* transform: rotate(-90deg); */
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
}

/* Set the last header cell to stay fixed */
th.headerElem.total-column {
  position: sticky;
  left: auto;
  background-color: #eef6fb;
  z-index: 3;
  width: 20px !important;
}

/* Set the styles for the text within the header cells */
.headerText {
  transform: rotate(-90deg);
  position: relative;
  word-break: break-all;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  overflow: visible;
  position: relative;
  top: 60px;
}

/* Set the styles for the first column */
td.account-name {
  padding: 8px;
  /* Adjust the padding as needed */
  white-space: nowrap;
  position: relative;
  z-index: 4;
  /* Set a higher z-index to bring it to the front */
}

/* Adjust other styles as needed */

.total-row {
  position: sticky;
  bottom: 0;
  background-color: #eef6fb;
  z-index: 3;
}

.total-row td {
  font-weight: bold;
}

.headerElem:nth-child(even) {
  background: #e1eaef;
  width: 20px !important;
}


.first-column {
  width: auto;
  /* Let the width be determined by content */
}

/* Add a general style for other columns */
.table-container th:not(.first-column),
.table-container td:not(.first-column) {
  width: 30px !important;
  overflow: hidden;
  /* You can customize other styles here */
}
</style>
