<template>
  <v-card flat >
    <v-row>
      <v-col cols="12">
          <span class="tab">Details</span>
          <i class="pi pi-plus-circle gap show-cursor add-btn" @click="addDiolog()" />
      </v-col>
    </v-row>
     <v-row>
      <v-col cols="12">
        <v-table  class="table"  style="border-radius: 8px">
    <thead>
      <tr class="headerRow">
        <th class="text-left">Type</th>
        <th class="text-left">Allocated Qty</th>
        <th class="text-left">Approval Heirarchy</th>
        <th class="text-left">Encashment</th>
        <th class="text-left">Carry Over</th>
        <th class="text-left">Action</th>
      </tr>
    </thead>
    <tbody style="text-wrap: wrap; line-height: normal">
      <tr v-for="item in data" :key="item.id">
        <td class="font">{{item && item.vacation_type_name ?item.vacation_type_name:''}}</td>
        <td class="font">{{item && item.allocated_qty ?item.allocated_qty:''}}</td>
        <td class="font">{{item && item.approval_heirarchy ?JSON.parse(item.approval_heirarchy).length:''}}</td>
        <td class="font">{{item && item.encashment ?item.encashment:''}}</td>
        <td class="font">{{item && item.carry_over ?item.carry_over:''}}</td>
        <td class="font">
          <span>
            <i class="pi pi-pencil gap2 show-cursor"  style="font-size: 12px;" @click="rowActions(item,'edit')" />
            <i class="pi pi-eye gap2 show-cursor" style="font-size: 12px;" @click="rowActions(item,'view')"  />          </span>
        </td>
      </tr>
    </tbody>
  </v-table>
      </v-col>
    </v-row> 
      <!-- <PrimeDataTable
      :title="'Details'"
      :showFilter="true"
      :searchOption="true"
      :selectAll="true"
      :showDownload="true"
      :showUpload="true"
      :showAdd="true"
      :columnData="columns"
      :data="data"
      @addDiolog="addDiolog()"
      @rowAction="rowActions($event)"
      activity="VACATIONTYPE"
    /> -->
    <v-dialog persistent v-model="dialog" width="900px">
      <AddVacationTemplateDetails
        ref="AddEnum"
        @reload="getVacationTemplateDetailData()"
        @closeDialog="setFalse()"
        :id="editId"
        :templateId="templateId"
        :viewType="viewType"
      />
    </v-dialog>
  </v-card>
  
</template>

<script>

// import PrimeDataTable from "@/components/PrimeDataTable";
import AddVacationTemplateDetails from "./AddVacationTemplateDetails.vue";
import VacationTemplateDetailService from "@/api/service/VacationTemplateDetail/VacationTemplateDetailService";

export default {
  components: {
    // AutoComplete,
    // PrimeDataTable,
    AddVacationTemplateDetails,
  },
  props: ["templateId"],
  data() {
    return {
      data: [],
      columns: [],
      vacationTemplateDetailData: [],
      date: new Date(),
      editId: null,
      dialog: false,
      viewType: false,
    };
  },
  
  watch: {
    templateId() {
      if (this.id) {
        this.data = [];
        this.getVacationTemplateDetailData();
      }
    },
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    if(this.templateId){
      this.getVacationTemplateDetailData();
    }
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "code",
          header: "Code",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "name",
          header: "Name",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },

        {
          field: "modified",
          header: "Modified",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
        {
          field: "status",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "Status",
          sortfield: true,
        },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
          ],
        },
      ];
    },

    setFalse() {
      this.dialog = false;
    },

    rowActions($event,type) {
      if (type == "view") {
        this.dialog = true;
        this.editId = $event.id;
        this.viewType = type;
      }
      if (type == "edit") {
        this.dialog = true;
        this.editId = $event.id;
        this.viewType = type;
      }
    },

    async getVacationTemplateDetailData() {
      if(this.templateId){
          let res = await VacationTemplateDetailService.getDetailList({vacation_template_id:this.templateId});
          this.data = res;
      }
      else{
        this.data =[]
      }
    },
    addDiolog() {
      this.dialog = true;
      this.editId = null;
      this.viewType = false;
    },
  },

};
</script>

<style scoped>

.gap {
    margin: 0 10px;
}

.gap2 {
    margin: 0 5px;
}
.add-btn{
    font-size: 18px;
    font-weight: bold;
    color: #0c3b73;
}
.tab{
    font-size: 20px;
    font-weight: 600;
}


.headerRow {
  line-height: normal;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #fafafa;
}

.v-chip.v-chip--size-default {
  --v-chip-size: 0.875rem;
  --v-chip-height: 22px;
  font-size: 12px;
  padding: 0 12px;
}

.v-table--density-default>.v-table__wrapper>table>tbody>tr>td,
.v-table--density-default>.v-table__wrapper>table>thead>tr>td,
.v-table--density-default>.v-table__wrapper>table>tfoot>tr>td {
  height: calc(var(--v-table-row-height, 45px) + 0px);
}

.onText {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
  background: #f8d6e6 !important;
  opacity: 1 !important;
}

.onText2 {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
  background: #d6f8f6 !important;
  opacity: 1 !important;
}

.onText3 {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
  background: #e2d6f8 !important;
  opacity: 1 !important;
}

.font {
  font-size: 12px;
  color: #636363;
  font-weight: 500;
  text-align: start;
}

.v-table {
  --v-table-header-height: 50px;
  border-radius: inherit;
  line-height: 0.5;
  max-width: 100%;
}

tr:hover {
  background-color: #fafafa;
  cursor: pointer;
}

v-table>.v-table__wrapper>table>tbody>tr>th,
.v-table>.v-table__wrapper>table>thead>tr>th,
.v-table>.v-table__wrapper>table>tfoot>tr>th {
  font-weight: bolder;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-align: start;
  color: #000000;
}

.td {
  border-style: dashed;
}

.avatarTip {
  text-transform: capitalize;
  /* opacity: 0.8; */
  /* font-size: 0.575rem; */
}

>>>.v-input__details {
    align-items: flex-end;
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    grid-area: messages;
    letter-spacing: 0.0333333333em;
    line-height: normal;
    min-height: 22px;
    padding-top: 6px;
    overflow: hidden;
    justify-content: space-between;
    display: contents;
}
>>>.v-checkbox .v-selection-control {
    min-height: fit-content;
}
</style>