<template>
  <canvas
    ref="myChart"
    style="width: 100%; height: 200px; max-width: 600px"
  ></canvas>
</template>

<script>
import { Chart, registerables } from "chart.js";
import VacationService from "@/api/service/Vacation/VacationService";

// Register all necessary components
Chart.register(...registerables);

export default {
  name: "LineChart",
  props: ["selection"],
  data() {
    return {
      xValues: [],
      yValues: [],
      chart: null,
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    selection: "createChart",
  },
  methods: {
    createChart() {
      let labels = [];
      let data = [];

      if (this.selection === "month") {
        this.xValues = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];
        this.yValues = [5, 8, 10, 7, 9];
        labels = this.xValues;
        data = this.yValues;
      } else if (this.selection === "week") {
        this.xValues = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        this.yValues = [5, 8, 10, 7, 9, 11, 6];
        labels = this.xValues;
        data = this.yValues;
      } else {
        this.xValues = Array.from({ length: 24 }, (_, i) => `${i}:00`);
        this.yValues = Array.from({ length: 24 }, () =>
          Math.floor(Math.random() * 20)
        );
        labels = this.xValues;
        data = this.yValues;
      }

      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(this.$refs.myChart, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              fill: false,
              lineTension: 0,
              backgroundColor: "rgba(0,0,255,1.0)",
              borderColor: "rgba(0,0,255,0.1)",
              data: data,
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: "category",
            },
            y: {
              min: 0,
              max: 20,
            },
          },
        },
      });
      this.getVacationData();
    },
    async getVacationData() {
      let res = await VacationService.getChartData({selection: this.selection});
      console.log(res,'lllllllllllllllllllllllllllll');
    },
  },
};
</script>

<style scoped></style>
