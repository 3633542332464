const apiConfig = {
   "baseUrl":"https://synergy-cf-api.axodesk.com:8081",
   "baseUrlMaster":"https://synergy-cf-api.axodesk.com:8082",
    "baseUrlDocument":"https://synergy-cf-api.axodesk.com:8083",
    "baseUrlIssue":"https://synergy-cf-api.axodesk.com:8084",
    "baseUrlBoard":"https://synergy-cf-api.axodesk.com:8085",
    "baseUrlMasterWs":"https://synergy-cf-api.axodesk.com:8086",
    "baseUrlMasterProject":"https://synergy-cf-api.axodesk.com:8087",
    "baseUrlIdentity": "https://synergy-cf-api.axodesk.com:8088",
    "baseUrlOthers": "https://synergy-cf-api.axodesk.com:8089",
    "baseUrlSecurity": "https://synergy-cf-api.axodesk.com:8090",
    "baseUrlReport": "https://synergy-cf-api.axodesk.com:8091",
    "baseUrlCache": "https://synergy-cf-api.axodesk.com:8096",
    "baseUrlAssesments":"https://synergy-cf-api.axodesk.com:8098",
    "baseUrlHr":"https://synergy-cf-api.axodesk.com:8099",

   "gatWayUrl":"https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/dev",
   "apiKey": "apiKey",
   "appId": "b0dc92bf-3710-4e11-a87d-4d72585cd281",
   "secrect": "secrect"
}

export default apiConfig;
