<template>
    <v-card width="100%" style="border-radius: 12px; overflow: auto">
      <v-form ref="form">
        <v-card-item>
          <v-card-title>  
            Upload Status      
            <v-btn
              @click="closeDialog"
              elevation="0"
              density="comfortable"
              icon="mdi-close"
              class="x-closeIcon"
            ></v-btn>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-4">
                <v-col cols="12">
                <div  v-text="message" style="white-space: pre-wrap;color:red"></div>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card-item>
      </v-form>
    </v-card>
  </template>
  
  <script>
  export default {
    props: ["message"],
    data(){
        return {
           data:"SYNTvST-5337 NO access\nfagaga" 
        }
    },
    methods:{
        closeDialog(){
            this.$emit('closeDialog',true)
        }
    }
  
  }
  </script>
  