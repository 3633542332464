<template>
  <v-card flat>
    <!-- <v-tabs v-model="tab" color="primary">
      <v-tab value="one" class="font-weight-medium text-subtitle-2"
        >Needs Review</v-tab
      >
      <v-tab value="two" class="font-weight-medium text-subtitle-2"
        >Review All</v-tab
      >
    </v-tabs> -->
    <v-tabs v-model="tab" color="primary">
      <v-tab value="one" class="font-weight-medium text-subtitle-2">
        Needs Review
      </v-tab>

      <v-tab value="two" class="font-weight-medium text-subtitle-2">
        Review All
      </v-tab>

      <div class="pa-3 w-25">
        <i
          class="pi pi-download cursor-pointer"
          v-if="!loader"
          style="position: relative; left: 80px"
          @click="getPdf"
        >
          <v-tooltip activator="parent" location="bottom">Download</v-tooltip>
        </i>
        <v-progress-circular
          style="position: relative; left: 80px"
          v-show="loader"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-tabs>
    <v-card-text class="pt-0">
      <v-window v-model="tab">
        <v-window-item value="one">
          <QuestionList :QuestionnaireData="QuestionnaireData" />
        </v-window-item>

        <v-window-item value="two">
          <QuestionList2 :QuestionnaireData="QuestionnaireData" />
        </v-window-item>
      </v-window>
    </v-card-text>

    <!-- <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="one">
          <QuestionList type="freeform" :QuestionnaireData="QuestionnaireData" />
        </v-tabs-window-item>

        <v-tabs-window-item value="two">
          <QuestionList2  :QuestionnaireData="QuestionnaireData" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text> -->
  </v-card>
</template>
<script>
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
import QuestionList from "./QuestionList.vue";
import QuestionList2 from "./QuestionList2.vue";
import QuestionnaireService from "@/api/service/Questionnaire/QuestionnaireService";
import AssessmentResultService from "@/api/service/AssessmentResult/AssessmentResultService";

export default {
  props: ["candidateId", "interviewId"],
  components: {
    QuestionList,
    QuestionList2,
  },
  data() {
    return {
      tab: null,
      totalMarks: 0,
      loader: false,
      evaluationMark: null,
      fileForUpload: null,
      InputType: null,
      QuestionnaireData: [],
    };
  },
  computed: {
    refresh() {
      return this.$store.getters.getRefreshSummary;
    },
  },
  created() {
    this.tab = "one";
    if (this.candidateId) {
      this.getQuestionnaireData(this.candidateId);
    }
  },

  watch: {
    candidateId() {
      this.getQuestionnaireData(this.candidateId);
    },
    interviewId() {
      this.getQuestionnaireData(this.candidateId);
    },
    refresh() {
      this.getQuestionnaireData(this.candidateId);
    },
  },

  methods: {
    async getPdf() {
      let obj = { candidateId: this.candidateId };
      this.loader = !this.loader;
      if (this.interviewId) {
        obj = { ...obj, interviewId: this.interviewId };
      }
      AssessmentResultService.getAssessmentResult(obj)
        .then((response) => {
          console.log(response);
          this.downloadPdf(response.content, response.fileName);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    downloadPdf(base64String, fileName) {
      const link = document.createElement("a");
      link.href = base64String;
      link.download = fileName;
      // document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
      this.loader = !this.loader;
    },
    async getQuestionnaireData(id) {
      let obj = { candidate_id: id };
      if (this.interviewId) {
        obj = { ...obj, interviewId: this.interviewId };
      }
      let res = await QuestionnaireService.findQuestions(obj);
      this.QuestionnaireData = res;
    },
  },
};
</script>
