<template>
<v-card flat class="pa-3">
  <PrimeDataTable
    :title="'Report Subscription'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="true"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="reportsubscription"
  />
  <v-dialog v-model="recurringDialog" width="850px" persistent>
    <CreateJob
      style="overflow: auto"
      ref="createjob"
      :filterData="savedFilters"
      :jobId="jobId"
      @reload="hasRecurringTask"
      :from="'list'"
      endPoint="reports"
      :viewType="viewType"
      :params="params"
      :reportData="modelData"
      @closeDialog="close()"
    >
    </CreateJob>
  </v-dialog>
</v-card>
</template>

<script>
import PrimeDataTable from "@/components/PrimeDataTable";
// import ReportSubscriptionService from "@/api/service/ReportSubscription/ReportSubscriptionService";
// import commonService from "@/common.service";
import CreateJob from "@/modules/Jobs/Create.vue";
import JobService from "@/api/service/Jobs/JobsService";
export default {
  components: {
    PrimeDataTable,
    CreateJob,
  },
  data() {
    return {
      data: [],
      columns: [],
      recurringDialog: false,
      jobId: null,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getSubscriptions();
  },
  methods: {
    loadColumns() {
      this.columns = [
        {
          field: "reportName",
          header: "Report Name",
          style: { width: "100px", color: "" },
          toolTip: "holiday_description",
          sortfield: true,
        },
        // {
        //   field: "export_options",
        //   header: "Exports",
        //   type: "boolean",
        //   style: { width: "100px", color: "" },
        //   toolTip: "mandatory",
        //   sortfield: true,
        // },

        {
          field: "email",
          header: "Email To",
          style: { width: "100px", color: "" },
          toolTip: "modified",
          sortfield: true,
        },
        {
          field: "cc",
          header: "Cc Email",
          style: { width: "100px", color: "" },
          toolTip: "active",
          sortfield: true,
        },
        {
          field: "next_date",
          header: "Schedule Date & time",
          style: { width: "100px", color: "" },
          toolTip: "active",
          type: "datetime",
          sortfield: true,
        },
        {
          field: "active",
          header: "Status",
          style: { width: "100px", color: "" },
          toolTip: "active",
          sortfield: true,
        },
        // {
        //   field: "modified",
        //   header: "Modified on",
        //   style: { width: "100px", color: "" },
        //   toolTip: "active",
        //   type: "datetime",
        //   sortfield: true,
        // },
        {
          field: "action",
          header: "Action",
          style: { width: "100px", color: "" },
          toolTip: "Action",
          sortfield: true,
          rowActions: [
            { action: "view" },
            { action: "edit" },
            // { action: "delete" },
          ],
        },
      ];
    },

    close() {
      this.recurringDialog = false;
      this.getSubscriptions();
    },
    rowActions($event) {
      // if ($event.type == "view" || $event.type == "edit" || $event.type == "select") {
      //     this.$router.push({
      //         path: '/addreportsubscription',
      //         query: { type: $event.type, id: $event.data.id },
      //     });
      // }
      if ($event.type == "view") {
        this.recurringDialog = true;
        this.jobId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.recurringDialog = true;
        this.jobId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.recurringDialog = true;
        this.jobId = $event.data.id;
        this.viewType = "view";
      }
    },
    async getSubscriptions() {
      let res = await JobService.getReportList();
      for(let item of res){
        item.email = item.email && item.email.length>50?item.email.substring(0, 30)+'...':item.email;
        item.cc = item.cc && item.cc.length>50?item.cc.substring(0, 30)+'...':item.cc;
      }
      this.data = res;
      console.log(this.data, "hgvghvhghg");
    },
    addDiolog() {
      this.viewType = null;
      this.recurringDialog = true;
      this.jobId = null;
    },
  },

  close() {
    this.dialog = false;
  },
  rowActions($event) {
    if ($event.type == "view" || $event.type == "edit" || $event.type == "select") {
      this.$router.push({
        path: "/addreportsubscription",
        query: { type: $event.type, id: $event.data.id },
      });
    }
  },
  async getSubscriptions() {
    let res = await JobService.reportList();
    this.data = res;
    this.data.forEach((k) => {
      k.paramName = JSON.parse(k.params);
    });

    console.log(this.data, "kishore");
  },
  addDiolog() {
    this.$router.push("/addschedulereport");
    this.viewType = false;
  },
};
</script>
