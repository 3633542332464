<template>
  <div>
    <v-autocomplete
      density="compact"
      variant="outlined"
      single-line
      v-model="search"
      :items="items"
      color="blue-grey-lighten-2"
      item-title="name"
      item-value="id"
      label="Select"
      @update:modelValue="handleSelect"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props"   class="py-0">
            <div >{{ item.name }}
              <v-icon small style="position: absolute;right: .5rem;top: .5rem;" @click.stop="removeItem(item.value)">mdi-close</v-icon>
          </div>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: ["items", "selected", "disabled", "rules", "placeholder"],
  data() {
    return {
      search: this.selected || "",
    };
  },

  methods: {
    handleSelect(selectedItem) {
      this.$emit("selected", selectedItem);
    },

    removeItem(itemId) {
      this.$emit("removeItem", itemId);
    },
  },
};
</script>

<style scoped>
.sample-name {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rowItem {
  width: 100%;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}
.rowItem:hover {
  background-color: #f8f4f4;
}

>>>.v-list-item__content {
    align-self: center;
    grid-area: content;
    overflow: hidden;
    width: 100%;
    display: contents !important;
}
</style>
