<template>
  <div class="border-round border-1 surface-border p-4 surface-card">
    <div class="flex mb-3" style="display: flex; flex-direction: column;">
      <Skeleton height="90px" class="mb-2"></Skeleton>
    </div>
    <div class="ml-2">
    <div class="flex mb-3" style="display: flex; flex-direction: column;">
      <Skeleton height="40px" class="mb-2"></Skeleton>
    </div>
    <div class="flex mb-3" style="display: flex; flex-direction: column;">
      <Skeleton width="100px" height="20px" class="mb-2"></Skeleton>
    </div>
    <div class="flex mb-3" style="display: flex; flex-direction: column;">
      <Skeleton height="50px" class="mb-2"></Skeleton>
      <Skeleton height="50px" class="mb-2"></Skeleton>
      <Skeleton height="50px" class="mb-2"></Skeleton>
      <Skeleton height="50px" class="mb-2"></Skeleton>
      <Skeleton height="50px" class="mb-2"></Skeleton>
    </div>
    <div class="flex mb-3" style="display: flex; flex-direction: column;">
      <Skeleton height="40px" class="mb-2"></Skeleton>
    </div>
    <div class="flex mb-3" style="display: flex; flex-direction: column;">
      <Skeleton width="100px" height="20px" class="mb-2"></Skeleton>
    </div>
    <div class="flex mb-3" style="display: flex; flex-direction: column;">
      <Skeleton height="50px" class="mb-2"></Skeleton>
      <Skeleton height="50px" class="mb-2"></Skeleton>
      <Skeleton height="50px" class="mb-2"></Skeleton>
      <Skeleton height="50px" class="mb-2"></Skeleton>
      <Skeleton height="50px" class="mb-2"></Skeleton>
    </div>
  </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  components: {
    Skeleton,
  },
};
</script>

<style></style>
