<template>
  <SkeletonForCard v-if="loading" />

  <V-card density="compact" flat height="90vh" v-else>
    <CommonBoardHeader :boardName="'Daily Activity DashBoard'" @search-data="getSearch" @applyChange="applyChange"
      @export-data="exportFile" @show-zero-issue="getSwitchData" :existFilterData="existFilterData"
      :workSpaceName="tableData" @scroll="scrollToPosition" />
    <v-card-text>
      <div style="display: flex; width: 100%; gap: 1%">
        <div v-if="false" style="
            border: 1px solid #c6c6c6c6;
            border-radius: 8px;
            overflow: hidden;
            height: 80vh;
            width: 20%;
          ">
          <DashboardFilter />
        </div>
        <div class="table-container" :style="{ width: false ? '79%' : '100%' }" ref="tableContainer">
          <LiveTable :tableData="backupTableData" :allData="ProductionData" @printData="addData" :shift="shift"
            @shiftData="shift = false" />
        </div>
      </div>
    </v-card-text>
  </V-card>
</template>

<script>
import LiveTable from "./LiveTable.vue";
import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
import ExportService from "@/ExportService";
import commonService from "@/common.service";
import IssueService from "@/api/service/Issue/IssueService";
import CommonBoardHeader from "./CommonBoardHeader.vue";
import SkeletonForCard from "../SkeletonForCard.vue";
import moment from 'moment';


export default {
  components: {
    LiveTable,
    CommonBoardHeader,
    SkeletonForCard
  },

  data() {
    return {
      existFilterData: null,
      menu: false,
      loading: true,
      downloadMenu: ["Excel", "Pdf", "Json"],
      ProductionData: [],
      search: null,
      searchClicked: false,
      tableData: [],
      finalExportData: [],
      backupTableData: [],
      isDrawer: false,
      switchBtn: "OFF",
      workTimingId: null,
      fixedIndex: null,
      shift: false,
      switchEvent: null,
    };
  },

  async created() {
    await this.getProductionData();
    this.finalExportData = this.tableData;
  },
  computed: {
    isDrawerOpen() {
      return this.$store.getters.getIssueViewDrawer;
    },
    isIssueChanged() {
      return this.$store.getters.getDefaultIssueInView;
    },
  },
  watch: {
    isIssueChanged() {
      this.showIssueView = true;
    },
  },
  methods: {
    moment,
    scrollToPosition(index) {
      this.fixedIndex = index;
      let spliceData = [this.tableData[this.fixedIndex]];
      let switchData = [];
      for (let item of spliceData) {
        let $createdData = [];
        for (let item1 of item.createdData) {
          if (item1.pastdue_issues !== 0) {
            $createdData.push(item1);
          }
        }
        if ($createdData.length > 0) {
          let data = JSON.parse(JSON.stringify(item));
          data.createdData = $createdData;
          switchData.push(data);
        }
      }
      if (this.switchEvent !== "ON") {
        this.backupTableData = switchData;
      } else {
        this.backupTableData = JSON.parse(JSON.stringify(spliceData));
      }
      this.shift = true;
    },
    applyChange($event) {
      this.existFilterData = $event
      this.getProductionData($event)
    },
    async getFilterData(data) {
      this.filterData = data;
      this.filterDialog = false;
      this.getissuetype(this.filterData);
      this.menu = false;
    },
    async getissuetype($obj) {
      let obj = {};
      if ($obj) {
        obj = $obj;
      }
      obj.next_action = this.userId;
      var res = await IssueService.getAssignToMeData(obj);
      this.issueType = res && res.length ? res : [];
    },
    getSwitchData($event) {
      this.switchEvent = $event
      let switchData = [];
      for (let item of this.tableData) {
        let $createdData = [];
        for (let item1 of item.createdData) {
          if (item1.pastdue_issues !== 0) {
            $createdData.push(item1);
          }
        }
        if ($createdData.length > 0) {
          let data = JSON.parse(JSON.stringify(item));
          data.createdData = $createdData;
          switchData.push(data);
        }
      }
      if ($event !== "ON") {
        this.backupTableData = switchData;
      } else if ($event !== "OFF") {
        let spliceData = [this.tableData[this.fixedIndex]];
        this.backupTableData = JSON.parse(JSON.stringify(spliceData));
      } else {
        this.backupTableData = [...this.tableData];
      }
      this.shift = true;
    },
    goIssueView(item) {
      let status = this.$store.getters.getMyspace;

      localStorage.setItem(
        "_WS_DEFAULTS_",
        JSON.stringify({
          workspace: { id: item.work_space_id, name: item.work_space_name },
        })
      );
      localStorage.setItem("workSpaceId", item.work_space_id);

      // this.$router.push({ path: "/issueView2", query: { id: item.id } });
      this.$store.dispatch("setIssueInView", item.id);
      let wsDefaults = {
        workspace: { id: item.work_space_id, name: item.work_space_name },
        account: { id: item.account_id, name: item.account_name },
        project: { id: item.project_id, name: item.project_name },
      };
      let wsDefaultsData = JSON.stringify(wsDefaults);
      localStorage.setItem("_WS_DEFAULTS_", wsDefaultsData);
      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      this.$store.commit("CHANGE_MY_SPACE", !status);
    },
    toggleSearch() {
      this.searchClicked = !this.searchClicked;
      if (this.searchClicked) {
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    handleOutsideClick(event) {
      const textField = this.$refs.searchContainer;
      if (textField && !textField.contains(event.target)) {
        this.searchClicked = false;
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    getSearch($event) {
      let searchData = [];
      if ($event.length > 0) {
        for (let item of this.tableData) {
          let $createdData = [];
          for (let user of item.createdData) {
            if (
              user &&
              user.created &&
              user.created.includes($event)
            ) {
              $createdData.push(user);
            }
          }
          if ($createdData.length > 0) {
            let data = JSON.parse(JSON.stringify(item));
            data.createdData = $createdData;
            searchData.push(data);
          }
          this.backupTableData = searchData;
        }
      } else {
        this.backupTableData = JSON.parse(JSON.stringify(this.tableData));
      }
      this.shift = true;
    },
    async getProductionData(filterData) {
      let $obj = {};
      $obj.type = "dailyActivity";
      $obj.filterData = filterData ? filterData : null
      let res = await ProductionDashboardService.liveBoard($obj);
      for (let item of res) {
        item.createdData = item.createdData.filter((item1) => { return item1.total_issues > 0 });
        item.createdData = await commonService.getSort(
          item.createdData,
          "id",
          "asc"
        );
        for (let item2 of item.createdData) {
          item2.accountData = await commonService.getSort(
            item2.accountData,
            "account_name",
            "asc"
          );
        }
      }
      this.tableData = res.length > 0 ? res : [];
      this.backupTableData = JSON.parse(JSON.stringify(this.tableData));
      console.log(this.backupTableData, 'backupTableData....');
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },

    addData(data) {
      this.finalExportData = data;
    },

    async exportFile(type) {
      let sourceData = [];
      let column;
      this.finalExportData.forEach((workspace) => {
        workspace.createdData.forEach((created) => {
          created.work_space_name = workspace.workSpaceName
          created.created= moment(created.created).format('MM-DD-YYYY')
          sourceData.push(created)
        });
      });

      column = [
        {
          field: "work_space_name",
          header: "WorkSpace",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "created",
          header: "Created",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        
        {
          field: "total_issues",
          header: "Total",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },   
        {
          field: "unassigned_issues",
          header: "Un Assigned",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },       
        {
          field: "open_issues",
          header: "Open",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "assigned_issues",
          header: "Assigned",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        
        {
          field: "inprogress_issues",
          header: "Inprogress",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        
        {
          field: "qapending_issues",
          header: "Qa pending",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "clarification_issues",
          header: "Clarification",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "hold_issues",
          header: "Hold",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "it_issues",
          header: "IT",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "pastdue_issues",
          header: "Past Due",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "completed_issues",
          header: "Completed/Today",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
      ];

      if (this.finalExportData !== this.tableData) {
        column.splice(2, 0, {
          field: "user",
          header: "User",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        });
      }

      await ExportService.export(sourceData, "Daily Activity Board", column, type);
    },
  },
};
</script>

<style>
.table-container {
  width: 79%;
  height: 80vh;
  overflow: auto
}
</style>
