import ApiServiceAssesments from '../apiServiceAssesments';
const querystring=require('querystring');

const AssessmentResult={
    getAssessmentResult: function($data){
        return new Promise(function(resolve,reject){
            let $options={}
            $options.url="getAssessmentResult"
            $options.method="get"

            if($data){
                $options.url="getAssessmentResult?"+querystring.stringify($data)
                console.log($options.url);    
            }
            ApiServiceAssesments.request($options).then((response)=>{
                resolve(response.data.data)
            }).catch((error) => {
                console.log(error);
                reject(error)
            })
        })
    }
}

export default AssessmentResult