import ApiServiceAssesments from "../apiServiceAssesments.js";
// import ApiServiceMasterCache from '../apiServiceMasterCache.js';

const querystring = require('querystring')
var ScheduleInterviewService = {

    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "scheduleInterview"
            $options.method = "get"

            if ($data) {
                $options.url = "scheduleInterview?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiServiceAssesments.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
  
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "scheduleInterview/" + $data
            console.log($options.url)
            $options.method = "get"
            if (!$data) {
                $options.data = $data
            }
            ApiServiceAssesments.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "scheduleInterview/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiServiceAssesments.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "scheduleInterview/" + $data.id;
            $options.method = "post"
            console.log($options)
            ApiServiceAssesments.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    scheduledDate($data){
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "scheduleInterviewDate";
            $options.method = "get";
      
            if ($data) {
              $options.url = "scheduleInterviewDate?" + querystring.stringify($data);
              console.log($options.url, "asss");
            }
            ApiServiceAssesments.request($options)
              .then((response) => {
                console.log("test");
                resolve(response.data.data);
              })
              .catch((error) => {
                reject(error);
              });
          });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "scheduleInterview";
            $options.method = "post"
            console.log($options)
            ApiServiceAssesments.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    }
};
export default ScheduleInterviewService;