<template>
  <v-container class="w-50">
    <v-card flat rounded>
      <v-img width="615" src="../../assets/banner-test6.svg"></v-img>
      <v-card-text>
        <v-row>
          <v-col cols="1">
            <v-avatar color="#abd1f5" size="x-large">
              <span>
                <v-img
                  :src="require('../../assets/command.svg')"
                  width="50px"
                  height="32px"
                />
              </span>
            </v-avatar>
          </v-col>

          <v-col cols="8" class="text-left pl-10">
            <div style="line-height:20px;font-size:20px;font-weight:900">
              <span>{{ assesData.name }}</span>
              <br />
              <!-- <small>
                <span style="font-weight:400;font-size:12px"
                  >{{
                    assesData.assessment_type == "pos"
                      ? "Final Assessment"
                      : "Pre Assessment"
                  }}
                </span></small
              > -->
            </div>
          </v-col>
          <v-col cols="3" class="text-right">
            <span small class="pa-1 chip-label bg-pink3">
              {{ assesData.stage }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="pl-10">
          <v-col cols="1" />
          <v-col cols="3">
            <v-card variant="flat" class="mr-2 bg-small-card">
              <v-container class="text-center">
                <span style="font-size:20px;font-weight:900">
                  {{ assesData ? assesData.no_of_question : "" }}
                </span>
                <br />
                <span style="font-size:12px;font-weight:400">
                  Questions
                </span>
              </v-container>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card variant="flat" class="mr-2 bg-small-card">
              <v-container class="text-center">
                <span style="font-size:20px;font-weight:900">
                  {{ assesData ? assesData.duration : "" }}
                </span>
                <br />
                <span style="font-size:12px;font-weight:400">
                  Minutes
                </span>
              </v-container>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card variant="flat" class="mr-2 bg-small-card">
              <v-container class="text-center">
                <span style="font-size:20px;font-weight:900">
                  {{ assesData ? assesData.score_to_pass : "" }}
                </span>
                <br />
                <span style="font-size:12px;font-weight:400">
                  Marks to pass
                </span>
              </v-container>
            </v-card>
          </v-col>

          <v-col cols="2" />
        </v-row>
        <v-row no-gutters dense class="mt-10 pl-10 ml-10">
          <v-col cols="12">
            <span class="conditions mt-3 mb-3">
              Terms & Conditions
            </span>
          </v-col>
          <v-col cols="12" class="mt-3 mb-3">
            <ol class="ml-4">
              <li v-for="terms of termsAndConditions" :key="terms">{{ terms }}</li>
            </ol>
          </v-col>

          <v-col cols="12" class="text-center mt-10">
            <v-text v-if="showQualityInternet">
              Quality of the internet <b>Stable</b></v-text
            >
            <v-text v-if="chekInternet"> Check internet</v-text>
            <v-progress-circular
              class="ml-1"
              :size="25"
              :width="3"
              indeterminate
              color="green"
              v-if="spinner"
            ></v-progress-circular>
          </v-col>
          <br />
          <v-col cols="12" class="text-center">
            <v-text>You are all set to Go. Congratulations</v-text>
          </v-col>
          <v-col cols="12" class="text-center mt-3" v-if="showQualityInternet">
            <v-btn flat block class="btnStyle" @click="goToAssessment()">
              {{ assesData.active == 1 ? "Connect" : "Reschedule" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="text-center"> </v-card-actions>
    </v-card>
    <!-- <vue-internet-checker
  @status="status"
  @event="event"
/> -->
  </v-container>
</template>

<script>
import AssesmentService from "@/api/service/Assesments/AssesmentsService1";
// import ProgrammesService from "@/api/service/Programmes/ProgrammesService";
import moment from "moment";
// import vueInternetChecker from 'vue-internet-checker';
export default {
  components: {
    // vueInternetChecker
  },
  data() {
    return {
      spinner: true,
      showQualityInternet: false,
      chekInternet: true,
      assesData: {},
      termsAndConditions: [
        "Do Not press back button.",
        "Please make sure you have a stable internet connection",
        "Do not close the browser before you submit",
        "Please contact adminsitrator in case of any troubles.",
        "Each question carries a weigtage of 10 Marks",
        "You Should score a minimum of 20 marks to pass in the assessment",
      ],
    };
  },
  methods: {
    moment,
    status(ele) {
      console.log(ele, "status");
      // this.onLine = ele;
    },
    event(ele) {
      console.log(ele);
    },
    async getAsses(id) {
      let result = await AssesmentService.findOne({ id: id });
      this.assesData = result[0];
      console.log(this.assesData, "hhhhh");
      // let res = await ProgrammesService.findOne({
      //   id: this.assesData.programme_id,
      // });
      // this.assesData.name = res[0].name;
    },

    async goToAssessment() {
      let $assessment = await AssesmentService.startAssessment({
        id: this.$route.query.id,
      });
      console.log($assessment, "result...");
      if (
        $assessment &&
        $assessment.data.length > 0 &&
        $assessment.data[0].error &&
        $assessment.data[0].stage == "inprogress"
      ) {
        let url =
          window.location.origin + "/assements?id=" + this.$route.query.id;
        window.open(url, "window", "toolbar=no, menubar=no, resizable=yes");
        this.$router.go(-1);
      } 
      // else if (
      //   $assessment &&
      //   $assessment.data.length > 0 &&
      //   $assessment.data[0].stage == "inprogress"
      // ) {
      //   let url =
      //     window.location.origin + "/assements?id=" + this.$route.query.id;
      //   window.open(url, "window", "toolbar=no, menubar=no, resizable=yes");
      //   this.$router.go(-1);
      // }
    },
  },
  created() {
    let id = this.$route.query.id;
    this.getAsses(id);
  },
  mounted() {
    let id = this.$route.query.id;
    this.getAsses(id);
    setTimeout(() => {
      this.spinner = false;
      this.showQualityInternet = true;
      this.chekInternet = false;
    }, 2000);
  },
};
</script>

<style scoped>
.cardCenter {
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.lifeInsuranceCard {
  width: 617px;
  min-height: 70%;
  padding: 22px;
  border-radius: 12px;
}

.avatarBoxs {
  width: 65px !important;
  height: 56px !important;
  background: #def8fe;
  position: relative;
  right: 12px;
}

.lifeInsurancetext {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  position: relative;
  top: 10px;
  right: 120px;
}

.questions {
  width: 122px;
  height: 66px;
  background: #d7fbdb;
  border-radius: 4px;
}

.questionsCard {
  height: 66px;
  width: 81px;
  background: #d7fbdb;
  border-radius: 4px;
  position: relative;
  left: 24px;
}

.questions1 {
  width: 122px;
  height: 66px;
  background: #d7fbdb;
  border-radius: 4px;
  position: relative;
  right: 20px;
}

.questions2 {
  width: 122px;
  height: 66px;
  background: #d7fbdb;
  border-radius: 4px;
  position: relative;
  right: 24px;
}

.ma-2 {
  background-color: #fadddd;
  border-radius: 4px;
  position: relative;
  bottom: 1px;
  left: 28px;
}

.per {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.ten {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding-top: 10px;
}
.Questions {
  text-align: center;
  padding-top: 4px;
}

.buttons {
  border-radius: 12px;
  background-color: #00ac5b;
  align-items: center;
}

.strat {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* color: #FFFFFF */
}

.conditions {
  /* width: 145px;
  height: 17px; */
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.ipsum {
  text-align: justify;
}

.Quality {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.minutesten {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding-left: 5px;
  position: relative;
  top: 4px;
}
.minutes {
  padding-left: 5px;
  font-size: 12px;
  position: relative;
  top: 4px;
}
.minutes1 {
  padding-left: 5px;
  padding-top: 9px;
}
.banner {
  height: 100px;
  width: 100%;
  display: block;
  background: url("../../assets/banner-test6.svg") no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.chip-label {
  margin-top: 5px;
  font-size: 12px;
  border: 2px solid;
  font-weight: 400;
  border-radius: 8px;
}
.bg-pink3 {
  background-color: #8800d723;
  color: #2f4899;
  font-weight: 800;
  border-color: #9180c0;
}
.bg-small-card {
  background-color: #abd1f5;
}
.btnStyle {
  background-color: #3c5aaa;
  border-radius: 8px;
  color: #fff;
  font-weight: 800;
  text-transform: none;
}
</style>
