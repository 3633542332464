<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>Employee Vacation Allotments</b>
          <v-btn style="float: right" @click="setFalse" elevation="0" density="comfortable"
            icon="mdi-close-circle"></v-btn>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
        <v-col cols="12" class="px-0 py-0" style="display: grid" v-if="formData.id">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

        <!-- <v-col cols="6" class="pb-1 pr-3">
           <v-label style="font-size:14px;color:#272727;font-weight:600">Code
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Code"
            :rules="codeRule"
            v-model="formData.code"
            :disabled="showReadOnly"            
            @keyup="checkCode()"
            @keydown="prevent($event)"
          />
          <v-error v-if="codeError">Already Exists!</v-error>
        </v-col> -->

        <!-- Input Field -->
        <v-col cols="12" class="pb-1 pr-3">
            <v-label  style="font-size: 14px; color: #272727; font-weight: 600">Vacation Type
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

            <AutoComplete :items="vacationTypeData" item-value="id" item-title="name" density="compact"
              placeholder="Vacation Type" :rules="vacationTypeRule" v-model="formData.vacation_type_id"
              :disabled="showReadOnly" />

          </v-col>

        <v-col cols="4" class="pb-1 pr-3">
           <v-label  style="font-size:14px;color:#272727;font-weight:600">Allocated Qty
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Allocated Qty"
            v-model="formData.allocated_qty"
            :disabled="showReadOnly"
          />
        </v-col>
        <v-col cols="4" class="pb-1 pr-3">
           <v-label  style="font-size:14px;color:#272727;font-weight:600">Encashment
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Encashment"
            v-model="formData.encashment"
            :disabled="showReadOnly"
          />
        </v-col>
        <v-col cols="4" class="pb-1 pr-3">
           <v-label style="font-size:14px;color:#272727;font-weight:600">Carry Over
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Carry Over"
            :rules="nameRule"
            v-model="formData.carry_over"
            :disabled="showReadOnly"
          />
        </v-col>
        <v-col cols="12" class="pb-1 pr-3">
           <v-label style="font-size:14px;color:#272727;font-weight:600">UOM
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="UOM"
            :rules="uomRule"
            v-model="formData.uom"
            :disabled="showReadOnly"
          />
        </v-col>
        <v-col cols="12" class="dropdown">
            <v-label style="font-size: 14px; color: #272727; font-weight: 600">Approval Heirarchy
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

            <AutoComplete :items="roleData" item-value="id" item-title="name" density="compact"
              placeholder="Select Approval Heirarchy" v-model="formData.approval_heirarchy"
              @update:modelValue="approvalChanged" :disabled="showReadOnly" />
            <v-chip class="selectedChip" closable label v-for="item in selectedAccounts" :key="item" :close="false"
              :disabled="showReadOnly" @click:close="cancelApprovel(item)">
              {{ item.name }}
            </v-chip>
          </v-col>
          </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <!-- Cancle And Save Button -->
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import VacationTemplateDetailService from "@/api/service/VacationTemplateDetail/VacationTemplateDetailService";
import VacationTypeService from "@/api/service/VacationType/VacationTypeService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import RoleService from "@/api/service/Role/RoleService";
import commonService from "@/common.service";



export default {
  props: ["id", "viewType","templateId"],
  components: {
    TextFieldComponent,
    AutoComplete
  },
  data() {
    return {
      roleData:[],
      showReadOnly: false,
      vacationTypeData:[],
      vacationTemData: [],
      displayNameRule: [(value) => !!value || "Display Name is Required."],
      datavalueRule: [(value) => !!value || "Data Value is Required."],
      vacationTypeRule:[(value) => !!value || "Vacation Type is Required."],
      dialog: false,
      codeError: false,
      type: null,
      formData: {},
      selectedApprovelId:[],      
      backupRole: [],
      approvelDetails: []
    };
  },
  watch: {
    id() {
      if (this.id) {
        this.formData = {};
        this.getById(this.id);
      }
    },
  },

  async created() {
    this.getVacationTemData();
    this.getVacationTypeData();
    await this.getRole()
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },

  methods: {
    approvalChanged($value) {
      this.selectedApprovelId.push($value);
      this.setDatas();
    },
    setDatas() {
      let chipRes = commonService.getMultipleChip(
        this.backupRole,
        this.selectedApprovelId,
        "id"
      );
      this.roleData = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupRole),
        this.selectedApprovelId,
        "id"
      );
      this.selectedAccounts = chipRes;
      this.roleData = [...this.roleData];
      this.formData.approval_heirarchy = null;
    },

    async getRole() {
      let res = await RoleService.findAll({active:1});
      this.roleData = res; 
      this.backupRole = JSON.parse(JSON.stringify(res))
      return true      
    },
    async getVacationTypeData() {
      let res = await VacationTypeService.findAll();
      this.vacationTypeData = res;
    },
    prevent(e) {
        if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
          e.preventDefault();
        }
      },
    checkCode() {
          if(this.formData.code && this.formData.code.length>0){
          this.formData.code = this.formData.code.toUpperCase();
          VacationTemplateDetailService.findAll({ code: this.formData.code }).then((res) => {
            let $exist = res.filter(key => key.code == this.formData.code)
            this.codeExistFlag = $exist.length > 0 ? (this.formData.id && $exist[0].id == this.formData.id) ? false : true : false;
            if (this.codeExistFlag) {
              this.codeError = true
            }
            else {
              this.codeError = false
            }
          });
        }
    },
    checkDisplayValue(){
      this.formData.data_value = this.formData.display_value?this.formData.display_value.toLowerCase().split(' ').filter(Boolean).join(''):'';
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await VacationTemplateDetailService.findOne(id);
      this.formData = res[0];
      this.selectedApprovelId = this.formData.approval_heirarchy ? JSON.parse(this.formData.approval_heirarchy):[]
      this.selectedApprovelId = JSON.parse(JSON.stringify(this.selectedApprovelId))
      console.log(this.selectedApprovelId,"hellllllooooo");
      this.setDatas()
    },
    async getVacationTemData() {
      let res = await VacationTemplateDetailService.findAll();
      this.vacationTemData = res.length > 0 ? res : [];
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log(validation.valid)
      }
      else {
        this.formData.vacation_template_id = this.templateId
        this.formData.approval_heirarchy = JSON.stringify(this.selectedApprovelId?this.selectedApprovelId:[])
        if (this.formData.id) {
          let output = await VacationTemplateDetailService.update(this.formData);
          console.log(output,'output')
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
         
        } else {
          let output = await VacationTemplateDetailService.create(this.formData);
          console.log(output, "gfdgg");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
        }
      }
    },
  },
};
</script>

<style></style>