<template>
      <v-btn icon variant="text" class="cursor-pointer mt-n1 mr-2">
        <v-icon icon="mdi-dots-vertical"></v-icon>
        <v-menu activator="parent" location="bottom" transition="scale-transition">
          <v-list>
            <v-list-item v-for="(item,i) in options" :key="i" link @click="emitTitle(item.title)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-menu
                :open-on-focus="true"
                activator="parent"
                open-on-hover
                submenu
                v-if="item.title == 'Update status'"
                location="left"
              >
                <v-list>
                  <v-list-item v-for="(status,index) in items" :key="index" class="py-0 cursor-pointer" link @click="emitTitle(status.title)">
                    <v-list-item-title > {{ status.title }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
  </template>
  <script>
  export default {
    props:["options", "items"],
    methods: {
    emitTitle(title) {
      this.$emit('title-selected', title);
    }
  }
  }
</script>
  