<template>
    <v-row class="ml-3">
        <v-col cols="4">
            <label class="pb-1" style="font-size: 15px; color: #cf0707; font-weight: 800">Workspace</label>
            <AutoComplete multiple :placeholder="'Workspace'" :items="backupWorkspace" :item-title="'name'"/>
        </v-col>
        <v-col cols="4">
            <label class="pb-1" style="font-size: 15px; color: #cf0707; font-weight: 800">Account</label>
            <AutoComplete multiple :placeholder="'Account'" :items="backupAccount"/>
        </v-col>

        <v-col cols="2" class=" mt-7">
            <label style="font-size: 16px; color: #cf0707; font-weight: 800">Total task count</label>
    </v-col>

        <v-col cols="2">
        <v-card class="round d-flex justify-center align-start" style="height: 50px; width: 50px; border-radius: 50%;">
        <span class="numbersto">10</span> 
        </v-card>
      </v-col>
    </v-row>


    <v-card class="cardings" hover>
        <v-card-item>
            <v-card-title>Task >3 days old</v-card-title>
            <v-card-subtitle>All stages</v-card-subtitle>
        </v-card-item>

        <v-card-text>
            <v-data-table-virtual
                :headers="headers"
                :items="virtualBoats"
                height="400"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import WorkSpaceService from '@/api/service/WorkSpace/WorkSpaceService';
import commonService from '@/common.service';
import AccountService from '@/api/service/Account/AccountService';

export default {
    components: {
        AutoComplete
    },
    data() {
        return {
            backupWorkspace: [],
            backupAccount: [],
            accountData: [],
            headers: [
                { title: 'Account_Code', align: 'center', value: 'Account_Code' },
                { title: 'Reference_Num', align: 'center', value: 'Referce_Num' },
                { title: 'Project_Name', align: 'center', value: 'Project_Name' },
                { title: 'Category_Name', align: 'center', value: 'Category_Name' },
                { title: 'Priority', align: 'center', value: 'Priority' },
                { title: 'Created_Date', align: 'center', value: 'Created_Date' },
                { title: 'First_Action_Date', align: 'center', value: 'First_Action_Date' },
                { title: 'Past_Due', align: 'center', value: 'Past_Due' },
                { title: 'Stage', align: 'center', value: 'Stage' },
                { title: 'Escalation_Assigned_To', align: 'center' , value: 'Escalation_Assigned_To' },
                { title: 'Roles', align: 'center', value: 'Roles' },
            ],
            boats: [
                {
                    Account_Code: 'AHQ',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'HDR',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'CA',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'CA',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'AHQ',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'CPB',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'CCR',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'VAN',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'IWR',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'LFW',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
                {
                    Account_Code: 'HDR',
                    Referce_Num: 'D2D-DIST-12345',
                    Project_Name: 'All project',
                    Category_Name: "Sales order",
                    Priority: 'High',
                    Created_Date: "08-26-2024",
                    First_Action_Date: "08-30-2024",
                    Past_Due: "6 days",
                    Stage: "Clarification",
                    Escalation_Assigned_To: "Team lead",
                    Roles: "Process_Lead",
                },
            ],
        };
    },
    computed: {
        virtualBoats() {
            return [...Array(10).keys()].map(i => {
                const boat = { ...this.boats[i % this.boats.length] };
                return boat;
            });
        }
    },
    created() {
        this.getWorkspaceData();
        this.getAccountData();
    },
    methods: {
        async getWorkspaceData() {
            let res = await WorkSpaceService.findAll({ active: 1 });
            this.backupWorkspace = res && res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
            this.backupWorkspace = [...this.backupWorkspace];
        },
        async getAccountData() {
            let res = await AccountService.findAll({ active: 1 });
            this.backupAccount = res && res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
            this.backupAccount = [...this.backupAccount];
            this.accountData = JSON.parse(JSON.stringify(this.backupAccount));
        }
    }
};
</script>

<style scoped>
.cardings {
    width: 100%;
    height: auto;
}
.round {
    background-color: #2314f7; 
    margin-top: 18px;
    margin-left: -90px;
  }
.numbersto{
    align-items: center;
    color: white;
    font-size: 20px;
    padding-top: 10px;
}
 
</style>
