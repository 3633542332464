<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto ">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b>Blocked Calenders </b>
          <v-btn style="float: right" @click="setFalse" elevation="0" density="comfortable"
            icon="mdi-close-circle"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
          <!-- <v-col cols="4" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Code" :rules="codeRule" v-model="formdata.code"
              :disabled="showReadOnly" @input="checkCode"/>
          </v-col> -->
          <v-col cols="6" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">From Date
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Enter the Date"  :min="moment().format('YYYY-MM-DD')"
            :rules="dateRule" :type="'date'"
              v-model="formdata.from_date" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="6" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">To Date
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Enter the Date"  :min="formdata.from_date"
            :rules="dateRule" :type="'date'"
              v-model="formdata.to_date" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="12" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Subject
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <TextFieldComponent density="compact" placeholder="Enter the Subject" :rules="subRule"
              v-model="formdata.subject" :disabled="showReadOnly" />
          </v-col>

          <v-col cols="12" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Reason
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>

            <EnumAutoComplete density="compact" :showReadOnly="showReadOnly"  placeholder="Update your reason" :selected="formdata.reason" :domain="'REASON'"
              @changeEnumData="changeReason($event)" :rules="reasonRule"/>
          </v-col>

          <v-col cols="12" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Work Space
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
           
            <AutoComplete :placeholder="'Select the account'" :items="workspaceData" item-title="name"
              :disabled="showReadOnly" item-value="id" v-model="work_space_id" @update:modelValue="wsChanged" />


              <v-chip class="selectedChip" closable label v-for="item in selectedWsData" :key="item" :close="false"
              :disabled="showReadOnly" @click:close="cancelWs(item)">
              {{ item.name }}
            </v-chip>
          </v-col>

          <v-col cols="12" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Account
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
            <AutoComplete :placeholder="'Select the account'" :items="accountData" item-title="name"
              :disabled="showReadOnly" item-value="id" v-model="account_id" @update:modelValue="accountChanged" />
              <v-chip class="selectedChip" closable label v-for="item in selectedAccountData" :key="item" :close="false"
              :disabled="showReadOnly" @click:close="cancelAccount(item)">
              {{ item.name }}
            </v-chip>
          </v-col>

          <v-col cols="12" class="pl-1">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Users
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>            
            
            <AutoComplete :placeholder="'Select the user'" :items="usersData" item-title="name"
              :disabled="showReadOnly" item-value="id" v-model="user_id" @update:modelValue="userChaged" />

              <v-chip class="selectedChip" closable label v-for="item in selectedUserData" :key="item" :close="false"
              :disabled="showReadOnly" @click:close="cancelUser(item)">
              {{ item.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import BlockedCalendarService from "@/api/service/BlockedCalendar/BlockedCalendarService";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import AccountService from "@/api/service/Account/AccountService";
import commonService from "@/common.service";
import EnumAutoComplete from "@/components/others/EnumAutoComplete.vue";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";


import UserService from "@/api/service/UserService";
import moment from "moment";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
    AutoComplete,
    EnumAutoComplete
  },

  data() {
    return {
      showReadOnly: false,
      usersData: [],
      dialog: false,
      codeRule:[v=>!!v||'Code is required'],
      dateRule:[v=>!!v||'Date is required'],
      subRule:[v=>!!v||'Subject is required'],
      reasonRule:[v=>!!v||'Reason is required'],
      entitiesRule:[v=>!!v||'Entities is required'],
      usersRule:[v=>!!v||'Users is required'],
      formdata: {},
      accountData:[],
      selectedAccountId:[],
      selectedAccountData:[],
      backupAccountData:[],
      selectedUserId:[],
      selectedUserData:[],
      backupUserData:[],
      workspaceData:[],
      selectedWsId:[],
      selectedWsData:[],
      backupWsData:[],
      ws_id:null,
      user_id:null,
      account_id:null,
      work_space_id:null
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formdata = {};
        this.getById(this.id);
      }
    },
  },

  async created() {
    await this.getAccountData()
    await this.getWorkSpaceData()
    await this.getUsersData()
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },

  methods: {
    // checkDisplayValue(){
    //   this.formdata.data_value = this.formdata.display_value?this.formdata.display_value.toLowerCase().split(' ').filter(Boolean).join(''):'';
    // },
    moment,
    userChaged($value) {
      this.selectedUserId.push($value);
      this.setData();
    },
    
    wsChanged($value) {
      this.selectedWsId.push($value);
      this.setWsData();
    },
    
    accountChanged($value) {
      this.selectedAccountId.push($value);
      this.setAccountData();
    },
    
    
    changeReason($value) {
      this.formdata.reason = $value
    },
    setData() {
      this.user_id = null;
      let chipRes = commonService.getMultipleChip(
        this.backupUserData,
        this.selectedUserId,
        "id"
      );

      this.usersData = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupUserData),
        this.selectedUserId,
        "id"
      );
      this.selectedUserData = chipRes;
      this.usersData = [...this.usersData];
    },
    
    setAccountData() {
      this.account_id = null;
      let chipRes = commonService.getMultipleChip(
        this.backupAccountData,
        this.selectedAccountId,
        "id"
      );

      this.accountData = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupAccountData),
        this.selectedAccountId,
        "id"
      );
      this.selectedAccountData = chipRes;
      this.accountData = [...this.accountData];
    },
    
    setWsData() {
      this.work_space_id = null
      let chipRes = commonService.getMultipleChip(
        this.backupWsData,
        this.selectedWsId,
        "id"
      );

      this.workspaceData = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupWsData),
        this.selectedWsId,
        "id"
      );
      this.selectedWsData = chipRes;
      this.workspaceData = [...this.workspaceData];
      this.ws_id = null;
    },
    
    cancelUser(data) {
      let index = this.selectedUserId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedUserId.splice(index, 1);
      }
      this.setData();
    },
    
    cancelWs(data) {
      let index = this.selectedWsId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedWsId.splice(index, 1);
      }
      this.setWsData();
    },
    cancelAccount(data) {
      let index = this.selectedAccountId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedAccountId.splice(index, 1);
      }
      this.setAccountData();
    },
    async getAccountData(){
      try{
        let res = await AccountService.findAll({active:1})
        this.accountData = res && res.length>0?res:[]
        this.backupAccountData = JSON.parse(JSON.stringify(this.accountData))
      }
      catch(e){
        console.log(e)
        this.accountData = []
      }
    },
    async getWorkSpaceData(){
      try{
        let res = await WorkSpaceService.findAll({active:1})
        this.workspaceData = res && res.length>0?res:[]
        this.backupWsData = JSON.parse(JSON.stringify(this.workspaceData))
      }
      catch(e){
        console.log(e)
        this.workspaceData = []
        this.backupWsData = []
      }
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await BlockedCalendarService.findOne({id});
      this.formdata = res[0];
      this.formdata.from_date=moment(this.formdata.from_date).format('YYYY-MM-DD')
      this.formdata.to_date=moment(this.formdata.to_date).format('YYYY-MM-DD')
      this.selectedUserId = JSON.parse(this.formdata.user_id)
      let entitieData = JSON.parse(this.formdata.entities)
      this.selectedWsId = entitieData.work_space_id? entitieData.work_space_id:[]
      this.selectedAccountId = entitieData.work_space_id? entitieData.account_id:[]
      console.log(this.selectedUserId,"jjjjdd");
      console.log(this.selectedWsId,"jjjjdd");
      console.log(this.selectedAccountId,"jjjjdd");
      this.setData()
      this.setAccountData()
      this.setWsData()
    },
    async getUsersData() {
      let res = await UserService.findAll({active:1});
      this.usersData = res.length > 0 ? res : [];
      this.backupUserData = JSON.parse(JSON.stringify(this.usersData))
      console.log(this.usersData, "lllllllll");
    },
    // checkDomain(){
    //   this.formdata.domain= this.formdata.domain?this.formdata.domain.toUpperCase():''
    // },
    checkCode() {
      if (this.formdata && this.formdata.code && this.formdata.code.length > 0) {
        this.formdata.code = this.formdata.code.toUpperCase();
        BlockedCalendarService.findAll({ code: this.formdata.code }).then((res) => {
          let $exist = res.filter(key => key.code == this.formdata.code)
          this.codeExistFlag = $exist.length > 0 ? (this.formdata.id && $exist[0].id == this.formdata.id) ? false : true : false;
          if (this.codeExistFlag) {
            this.codeError = true
          }
          else {
            this.codeError = false
          }
        });
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log("Not valid");
      } else {
        this.formdata.entities = JSON.stringify({work_space_id:this.selectedWsId,account_id:this.selectedAccountId})
        this.formdata.user_id = JSON.stringify(this.selectedUserId && this.selectedUserId.length>0 ?this.selectedUserId:[])
        if (this.formdata.id) {
          let output = await BlockedCalendarService.update(this.formdata);
          console.log(output, "output");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
        } else {
          let output = await BlockedCalendarService.create(this.formdata);
          console.log(output, "gfdgg");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
        }
      }
    },
  },
};
</script>

<style></style>
