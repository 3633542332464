<template>
  <SkeletonForCard v-if="loading" />
  <V-card density="compact" flat height="90vh" width="100%" v-else>
    <CommonBoardHeader
      boardName="Account Live Board"
      @search-data="getSearch"
      @applyChange="applyChange"
      @export-data="exportFile"
      @show-zero-issue="getSwitchData"
    />
    <v-card-text>
      <div style="display: flex; width: 100%; gap: 1%">
        <div
          v-if="false"
          style="
            border: 1px solid #c6c6c6c6;
            border-radius: 8px;
            overflow: hidden;
            height: 80vh;
            width: 20%;
          "
        >
          <DashboardFilter />
        </div>
        <div
          style="width: 79%; height: 80vh; overflow: auto"
          :style="{ width: false ? '79%' : '100%' }"
        >
          <LiveTable
            :tableData="tableData"
            :allData="ProductionData"
            @printData="addData"
          />
        </div>
      </div>
    </v-card-text>
  </V-card>
</template>

<script>
import LiveTable from "./LiveTable.vue";
import ProductionDashboardService from "@/api/service/ProductionDashboard/ProductionDashboard";
import ExportService from "@/ExportService";
import commonService from "@/common.service";
import IssueService from "@/api/service/Issue/IssueService";
import CommonBoardHeader from "../LiveBoardEmployee/CommonBoardHeader.vue";
import SkeletonForCard from "../SkeletonForCard.vue";
import moment from "moment";

export default {
  components: {
    LiveTable,
    CommonBoardHeader,
    SkeletonForCard,
  },

  data() {
    return {
      menu: false,
      loading: true,
      downloadMenu: ["Excel", "Pdf", "Json"],
      ProductionData: [],
      search: null,
      searchClicked: false,
      tableData: [],
      finalExportData: [],
      backupTableData: [],
      isDrawer: false,
      switchBtn: "OFF",
      workTimingId: null,
    };
  },

  async created() {
    await this.getProductionData();
    this.finalExportData = this.tableData;
  },
  computed: {
    isDrawerOpen() {
      return this.$store.getters.getIssueViewDrawer;
    },
    isIssueChanged() {
      return this.$store.getters.getDefaultIssueInView;
    },
  },
  watch: {
    isIssueChanged() {
      this.showIssueView = true;
    },
  },
  methods: {
    moment,
    applyChange($event) {
      this.getProductionData($event);
    },
    async getFilterData(data) {
      this.filterData = data;
      this.filterDialog = false;
      this.getissuetype(this.filterData);
      this.menu = false;
    },
    async getissuetype($obj) {
      let obj = {};
      if ($obj) {
        obj = $obj;
      }
      obj.next_action = this.userId;
      var res = await IssueService.getAssignToMeData(obj);
      this.issueType = res && res.length ? res : [];
    },
    getSwitchData($event) {
      let switchData = [];      
      if ($event) {
        for (let item of this.tableData) {
        let $accountData = [];
        for (let item1 of item.accountData) {
          if (item1.total_issues === 0) {
            $accountData.push(item1);
          }
        }
        if ($accountData.length > 0) {
          let data = JSON.parse(JSON.stringify(item));
          data.accountData = $accountData;
          switchData.push(data);
        }
      }
        this.tableData = switchData;
      } else {
        this.getProductionData();
      }
    },
    goIssueView(item) {
      let status = this.$store.getters.getMyspace;

      localStorage.setItem(
        "_WS_DEFAULTS_",
        JSON.stringify({
          workspace: { id: item.work_space_id, name: item.work_space_name },
        })
      );
      localStorage.setItem("workSpaceId", item.work_space_id);

      // this.$router.push({ path: "/issueView2", query: { id: item.id } });
      this.$store.dispatch("setIssueInView", item.id);
      let wsDefaults = {
        workspace: { id: item.work_space_id, name: item.work_space_name },
        user: { id: item.user_id, name: item.user_name },
        project: { id: item.project_id, name: item.project_name },
      };
      let wsDefaultsData = JSON.stringify(wsDefaults);
      localStorage.setItem("_WS_DEFAULTS_", wsDefaultsData);
      this.$store.commit("SET_REFRESH_BREADCRUMP", true);
      this.$store.commit("CHANGE_MY_SPACE", !status);
    },
    toggleSearch() {
      this.searchClicked = !this.searchClicked;
      if (this.searchClicked) {
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    handleOutsideClick(event) {
      const textField = this.$refs.searchContainer;
      if (textField && !textField.contains(event.target)) {
        this.searchClicked = false;
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    getSearch($event) {
      let searchData = [];
      for (let item of this.backupTableData) {
        let $accountData = [];
        for (let account of item.accountData) {
          if (
            account &&
            account.accountName &&
            account.accountName.toLowerCase().includes($event.toLowerCase())
          ) {
            $accountData.push(account);
          }
        }
        if ($accountData.length > 0) {
          let data = JSON.parse(JSON.stringify(item));
          data.accountData = $accountData;
          searchData.push(data);
        }
      }
      this.tableData = searchData;
    },
    async getProductionData(filterData) {
      let $obj = {};
      $obj.filterData = filterData ? filterData : null;
      let res = await ProductionDashboardService.liveBoard($obj);
      for (let item of res) {
        item.accountData.forEach((item1) => {
          item1.accountName =
            item1 && item1.accountName === null ? "" : item1.accountName;
        });
        item.accountData = await commonService.getSort(
          item.accountData,
          "accountName",
          "asc"
        );
        for (let item2 of item.accountData) {
          item2.userData = await commonService.getSort(
            item2.userData,
            "user_name",
            "asc"
          );
        }
      }
      this.tableData = res.length > 0 ? res : [];
      this.backupTableData = JSON.parse(JSON.stringify(this.tableData));
      setTimeout(()=>{
        this.loading = false;
      },2000);
    },

    addData(data) {
      this.finalExportData = data;
    },

    async exportFile(type) {
      let sourceData = [];
      let column;
      this.finalExportData.forEach((workspace) => {
        workspace.accountData.forEach((account) => {
          account.work_space_name= workspace.workSpaceName
          sourceData.push(account);
        });
      });

      column = [
        {
          field: "work_space_name",
          header: "WorkSpace",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        // {
        //   field: "user_name",
        //   header: "User",
        //   style: { width: "100px", color: "" },
        //   toolTip: "Name",
        //   sortfield: true,
        // },
        // {
        //   field: "project_name",
        //   header: "Project",
        //   style: { width: "100px", color: "" },
        //   toolTip: "Name",
        //   sortfield: true,
        // },
        {
          field: "accountName",
          header: "Account Name",
          style: { width: "100px", color: "" },
          toolTip: "Account Name",
          sortfield: true,
        },
        
        {
          field: "total_issues",
          header: "Total",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },        
        {
          field: "open_issues",
          header: "Open",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "assigned_issues",
          header: "Assigned",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        
        {
          field: "inprogress_issues",
          header: "Inprogress",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        
        {
          field: "qapending_issues",
          header: "Qa pending",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "clarification_issues",
          header: "Clarification",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "hold_issues",
          header: "Hold",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "it_issues",
          header: "IT",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        {
          field: "pastdue_issues",
          header: "Past Due",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
        // {
        //   field: "qacompleted_issues",
        //   header: "Qa completed",
        //   style: { width: "100px", color: "" },
        //   toolTip: "Num",
        //   sortfield: true,
        // },
        {
          field: "completed_issues",
          header: "Completed/Today",
          style: { width: "100px", color: "" },
          toolTip: "Num",
          sortfield: true,
        },
      ];

      if (this.finalExportData !== this.tableData) {
        column.splice(2, 0, {
          field: "account",
          header: "Account",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        });
      }
      await ExportService.export(sourceData, "Account Live Board", column, type);
    },
  },
};
</script>

<style></style>
