// import ProcessLead from '@/modules/dashboard/ProcessLead/ProcessIndex.vue'
// import ProductionDashboard from '@/modules/issues/LeadDashBoard/ReportBoard/ReportIndex'
import ExceptionBoard from '@/modules/dashboard/ExceptionBoard/ExceptionBoard'
// import Dashboard from '../modules/dashboard/ResourceUtilizationBoard/DashBoard.vue'
// import UnAssignedIssues from '@/modules/dashboard/ProcessLead/UnAssignedIssues.vue'
// import AllWorkSpaceUser from '@/modules/dashboard/ProcessLead/AllWorkSpaceUser.vue'
// import UserByshifts from '@/modules/dashboard/ProcessLead/UserByShifts.vue'
// import CommonBoard from '@/modules/dashboard/index.vue'
// import LiveBoard from '../modules/issues/LiveBoard/LiveBoard.vue'
// import EmployeeLiveBoard from '../modules/issues/LiveBoardEmployee/LiveBoard.vue'
// import DailyActivityDashBoard from '../modules/issues/DailyActivityDashBoard/LiveBoard.vue'

export default [
    // {  
        
    //     path: 'processLead',
    //     component: ProcessLead, 
    // },
    // {  
        
    //     path: 'productionDashboard',
    //     component: ProductionDashboard, 
    // },
    
    {  
        
        path: 'exceptionBoard',
        component: ExceptionBoard, 
    },   
    // {
    //     path: "/liveBoard",
    //     name: "LiveBoard",
    //     component: LiveBoard,
    // },     
  //   {
  //       path: "/liveBoard",
  //       name: "LiveBoard",
  //       component: LiveBoard,
  //   }, 
    
  // {
  //   path: "/employeeLiveBoard",
  //   name: "Employee Live Board",
  //   component: EmployeeLiveBoard,
  // },
  // {
  //   path: "/dailyActivityDashBoard",
  //   name: "Daily Activity DashBoard",
  //   component: DailyActivityDashBoard,
  // },
    // {  
        
    //     path: 'commonboard',
    //     component: CommonBoard, 
    // },
    // {  
        
    //     path: 'unassigned',
    //     component: UnAssignedIssues, 
    // },
    // {  
        
    //     path: 'allworkspace',
    //     component: AllWorkSpaceUser, 
    // },
    // {  
        
    //     path: 'userbyshifts',
    //     component: UserByshifts, 
    // },
    
]
    