<template>
  <v-card flat>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <span 
          :class="[{ tab: item.value == selection, tab2: item.value != selection }, 'gap', 'show-cursor']" 
          v-for="item in listData" 
          :key="item.value"
        @click="getData(item.value)">
          {{ item.display }}
        </span>

        <i
          class="pi pi-plus-circle gap show-cursor add-btn"
          @click="addRequest"
        />
      </v-col>
    </v-row>
    <v-row class="ma-1">
      <v-col cols="6" v-for="item in data" :key="item">
        <v-card flat class="list-card">
          <v-row>
            <v-col cols="6" class="d-flex" style="gap: 10px">
              <span>
                <CustomAvatar2
                  :fName="item.first_name"
                  :lName="item.last_name"
                  :size="'medium'"
                ></CustomAvatar2>
              </span>
              <span>
                <span class="name-cls next-row"> {{ item.employee_name||'' }} </span>
                <span class="role-cls"> {{ item.role_name || '' }} </span>
              </span>
              <span>
                <i
                  class="pi pi-pencil gap2 show-cursor"
                  style="font-size: 12px"
                  @click="editRequest(item.id)"
                />
                <i
                  class="pi pi-trash gap2 show-cursor"
                  style="font-size: 12px"
                  @click="deleteDialog(item.id)"
                />
                <img
                  src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/coolicon.svg"
                  class="gap2"
                  width="12px"
                />
              </span>
            </v-col>
            <v-col cols="6" class="d-flex flex-column align-end">
              <span class="next-row"> {{ getFormateData(item.start_date,'date')}} to {{ getFormateData(item.end_date,'date')}}</span>
              <span class="role-cls"> {{ item.no_of_days }} day{{ item.no_of_days>1?'s':'' }} ({{item.no_of_hours}} Hours) </span>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col>
              <span>
                {{item.remarks||''}}
              </span>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col cols="8" class="d-flex" style="gap: 10px">
              <span class="role-cls"> {{getFormateData(item.created)}}</span>
              <span class="role-cls"> {{getRelativeTime(item.created)}}</span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex align-end justify-end"
              style="gap: 10px"
            >

              <span class="chip-cls show-cursor" :style="{'background-color':value.stage=='pending'?'#ffff0091':value.stage=='rejected'?'#f24545':'#7ef6b6'}" v-for="(value,index) of getVacationApprovelData(item.vacation_approval_data)" :key="value" @click="vacationApproval(item)">
                {{ index+1 }}
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
  <v-dialog persistent v-model="dialog" width="700px">
    <CreateRequest
      @closeDialog="close()"
      @reload="getRequestData()"
      :id="editId"
      :viewType="viewType"
    />
  </v-dialog>
  <v-dialog persistent v-model="dialog1" width="700px">
    <VacationApproval
      :userData="userData"
      :approvedId="approvedId"
      @closeDialog="close()"
      @reload="getRequestData()"
      :viewType="viewType"
    />
  </v-dialog>
  <v-dialog persistent v-model="dialog2" width="500px">
        <DeleteRequest 
         @closeDialog="close()"
         @delete="deleteRequest()"

        />
  </v-dialog>
</template>
<script>
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import CreateRequest from "./CreateRequest.vue";
import VacationApproval from "./VacationApproval.vue";
import VacationService from "@/api/service/Vacation/VacationService"
import moment from 'moment'
import DeleteRequest from "./DeleteRequest.vue";

export default {
  props:["userData","superUser","boardOption","index"],
  components: {
    CustomAvatar2,
    CreateRequest,
    VacationApproval,
    DeleteRequest
  },
  data() {
    return {
      data: [],
      selection:1,
      listData:[
        {
          value:1,
          display:'My Vacation Requests'
        },
        {
          value:2,
          display:'Completed Requests'
        }
      ],
      dialog: false,
      dialog1: false,
      dialog2:false,
      deleteId:null,
      editId: null,
      approvedId:null,
      viewType: false
    };
  },
  watch:{
    boardOption(){                  
      this.getRequestData()
    },
    superUser(){
      if(this.superUser){
          this.addPendingRequest()
      }
      else{        
        this.addPendingRequest('remove')
      }
    },
    userData(){
      if(this.userData){                
        this.getRequestData()
      }
    }
  },
  created(){
    this.getRequestData()
    if(this.superUser){
      this.addPendingRequest()
    }
    else{        
      this.addPendingRequest('remove')
    }
  },
  methods: {
    moment,
    deleteDialog(id){
         this.dialog2=true;
         this.deleteId=id;
    },
    addPendingRequest(type){
      if(type){
        let index = this.listData.findIndex(k=>k.value == 3)
        if(index>-1){
          this.listData.splice(this.listData.length-1,index)
        }
      }
      else{
        this.listData.push({
          value:3,
          display:"Pending Requests"
        })
      }
     
    },
    getData(value){
      this.selection = value
      let obj = {}
      if(value==1){
        obj.userId = this.userData?this.userData.id:null
        obj.approved = 'notcompleted'
      }
      else if(value == 2){
        obj.userId = this.userData?this.userData.id:null

        obj.approved = `completed`
      }
      else{
        obj.approved = 'notcompleted'
        obj.mgr_id = this.userData?this.userData.id:null
      }
      this.getRequestData(obj)
    },
    getVacationApprovelData(data){
      return data?JSON.parse(data):[]
    },
    getFormateData(date,type){
      if(!date){
        return ''
      }
      return moment(date).format(type=='date'?'DD-MM-YYYY':'DD-MM-YYYY h:mm A')
    },
    getRequestData(value) {      
      let $obj = {
        userId:this.userData?this.userData.id:null,
        approved:'notcompleted'}
      VacationService.getRequestList(value?value:$obj).then(res=>{
        if(this.boardOption=="team" && this.index!=0){
          this.data=this.data.concat(res)
        }else{
          this.data=res
        }
      }).catch((e)=>{
        console.log(e)
      })
    },
    getRelativeTime(date){
        let $date = moment(date)
        const now = new Date();
        const seconds = Math.floor((now - $date) / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) return `${days} day(s) ago`;
        if (hours > 0) return `${hours} hour(s) ago`;
        if (minutes > 0) return `${minutes} minute(s) ago`;
        return `${seconds} second(s) ago`;
      },
    addRequest() {
      this.dialog = true;
      this.viewType=false;
      this.editId=null;
    },
    editRequest(id) {
      this.editId = id
      this.dialog = true;
      this.viewType=true;
    },
 async deleteRequest() {
      let result = await VacationService.update({id:this.deleteId, active:0});
      console.log(result,'daaaaaaaaaa');
      result && this.getRequestData()
      this.close();
    },
    
    vacationApproval(value) {
      this.approvedId = value.id      
      this.dialog1 = true;

    },
    close() {
      this.dialog = false;
      this.dialog1 = false;
      this.dialog2 = false;
    },
  },
};
</script>
<style scoped>
.tab {
  font-size: 16px;
  font-weight: 600;
}
.tab2 {
  font-size: 16px;
  font-weight: 400;
}
.gap {
  margin: 0 10px;
}

.gap2 {
  margin: 0 5px;
}
.add-btn {
  font-size: 20px;
  font-weight: bold;
}
.name-cls {
  font-size: 14px;
  font-weight: 500;
}
.role-cls {
  font-size: 14px;
  font-weight: 400;
  color: #a1a1a1;
}
.chip-cls {
  background-color: #7ef6b6;
  padding: 2px 10px;
}
.list-card {
  border-radius: 15px;
  background-color: #f4f4f4;
  padding: 25px;
}
.next-row {
  display: block;
  margin-bottom: 4px;
}
</style>
