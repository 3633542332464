<template>
  <v-card :class="type == 'openIssue' ? 'menuFilter' : 'notmenuFilter'" flat width="100%">
    <v-card-item>
      <v-card-title style="display: flex; justify-content: space-between">
        <div>Filter</div>
        <div v-if="type == 'openIssue'" @click="$emit('applyChange', formData.filterData)">
          <v-btn block color="primary">Apply</v-btn>
        </div>
        <!-- <button v-if="type=='openIssue'" @click="$emit('close')"> <v-icon>mdi-close</v-icon>
</button> -->
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-row dense>        
        <v-col cols="12" v-if="showFilter.includes('work_timing_id')">
          <v-label class="pb-1">Work Timing</v-label>
          <AutoComplete hide-details multiple :items="worktimingData" item-title="name" item-value="id" density="compact"
            placeholder="Select work timing" v-model="formData.filterData.work_timing_id"
            @update:model-value="$emit('filterchanged', formData.filterData)" />
        </v-col>
        <v-col cols="12" v-if="showFilter.includes('work_space_id')">
          <v-label class="pb-1">Work space</v-label>
          <AutoComplete hide-details multiple :items="workspaceData" item-title="name" item-value="id" density="compact"
            placeholder="Select work space" v-model="formData.filterData.work_space_id"
            @update:model-value="$emit('filterchanged', formData.filterData)" />
        </v-col>
        <v-col cols="12"  v-if="showFilter.includes('account_id')">
          <v-label class="pb-1">Account</v-label>
          <AutoComplete hide-details multiple :items="accountData" item-title="name" item-value="id" density="compact"
            placeholder="Select Account" v-model="formData.filterData.account_id"
            @update:model-value="$emit('filterchanged', formData.filterData)" />
        </v-col>
        <v-col cols="12"  v-if="showFilter.includes('project_id')">
          <v-label class="pb-1">Project</v-label>
          <AutoComplete hide-details multiple :items="projectData" item-title="name" item-value="id" density="compact"
            placeholder="Select Project" v-model="formData.filterData.project_id"
            @update:model-value="$emit('filterchanged', formData.filterData)" />
        </v-col>
        <v-col cols="12"  v-if="showFilter.includes('stages')">
          <v-label class="pb-1">Stage</v-label>
          <AutoComplete hide-details multiple :items="stageData" item-title="name" item-value="code" density="compact"
            placeholder="Select Stage" v-model="formData.filterData.stages"
            @update:model-value="$emit('filterchanged', formData.filterData)" />
        </v-col>
        <v-col cols="12"  v-if="showFilter.includes('user_id')">
          <v-label class="pb-1">Next Action </v-label>
          <AutoComplete hide-details multiple :items="users" item-value="id" item-title="name" density="compact"
            placeholder="Select next action" v-model="formData.filterData.next_action"
            @update:model-value="$emit('filterchanged', formData.filterData)" />
        </v-col>
        <!--   <v-col cols="6">
                       <v-label class="pb-1" >Due On</v-label>
                       <TextFieldComponent  density="compact" placeholder="Due on" type="datetime-local" showtime
                           hoursFormat="24" v-model="filterData.end_date" />
                   </v-col>
   
                   <v-col cols="6" class="pl-4">
                       <v-label class="pb-1" >Created</v-label>
                       <TextFieldComponent density="compact" placeholder="Created" type="date" v-model="filterData.created" />
                   </v-col> -->

        <v-col cols="12"  v-if="showFilter.includes('category_id')">
          <v-label class="pb-1">Category</v-label>
          <AutoComplete hide-details multiple :items="categoryData" item-value="id" item-title="name" density="compact"
            placeholder="Select Category" v-model="formData.filterData.category_id"
            @change="$emit('filterchanged', formData.filterData)" />
        </v-col>

        <v-col cols="12"  v-if="showFilter.includes('sub_category_id')">
          <v-label class="pb-1">Sub category</v-label>
          <AutoComplete hide-details multiple :items="subCategoryData" item-value="id" item-title="name" density="compact"
            placeholder="Select Sub-category" v-model="formData.filterData.sub_category_id"
            @change="$emit('filterchanged', formData.filterData)" />
        </v-col>
        <v-col cols="12" v-if="showFilter.includes('task_id')">
          <v-label class="pb-1">Sub Task</v-label>
          <AutoComplete hide-details multiple :items="taskData" item-value="id" item-title="name" density="compact"
            placeholder="Select Sub Task" v-model="formData.filterData.task_id"
            @change="$emit('filterchanged', formData.filterData)" />
        </v-col>

        <v-col cols="12" v-if="showFilter.includes('issue_type_id')">
          <v-label class="pb-1">Task type</v-label>
          <AutoComplete hide-details multiple :items="issyeTypeData" item-value="id" item-title="name" density="compact"
            placeholder="Select Task-type" v-model="formData.filterData.issue_type_id"
            @change="$emit('filterchanged', formData.filterData)" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
//import TextFieldComponent from "@/components/TextFieldComponent.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
import ProjectService from "@/api/service/Project/ProjectService";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import AccountService from "@/api/service/Account/AccountService";
import CategoryService from "@/api/service/Category/CategoryService";
import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import TaskService from "@/api/service/Task/TaskService";
import IssueTypeService from "@/api/service/IssueType/IssueTypeService";
//    import EnumService from '@/api/service/Enum/EnumService';
import StageService from "@/api/service/Stage/StageService";
import UserService from "@/api/service/UserService";
import WorkTimingService from "@/api/service/WorkTiming/WorkTimingService";
// import filterService from "@/api/service/Filter/filterService";
import commonService from "@/common.service";
export default {
  props: ["showDrawer", "existFilterData", "showApply", "type", "process","type2","showFilter"],
  components: {
    // TextFieldComponent,
    AutoComplete,
  },
  data() {
    return {
      drawer: true,
      projectData: [],
      accountData: [],
      categoryData: [],
      subCategoryData: [],
      issyeTypeData: [],
      taskData: [],
      users: [],
      stageData: [],
      workspaceData: [],
      worktimingData: [],
      formData: {
        filterData: {
          project_id: null,
          account_id: null,
          category_id: null,
          user_id: null,
          sub_category_id: null,
          task_id: null,
          issue_type_id: null,
          created: null,
          end_date: null,
          work_space_id: null,
          stages: null,
          workTimingId: null,
          work_timing_id:null
        },
      },
      filterData1: {},
    };
  },
  watch: {
    existFilterData() {
      if (this.existFilterData) {
        this.setFormData(this.existFilterData)
      }
      else {
        this.setFormData(null)
      }
    },
    filterData1() {
      if (this.filterData1) {
        this.setFormData(this.filterData1)
      }
      else {
        this.setFormData(null)
      }
    },
    filterData() {
      this.$emit("filterchanged", this.formData.filterData);
    },
    showDrawer() {
      this.drawer = this.showDrawer;
    },
  },
  created() {
    this.getStageData();
    this.getWorkspaceData();
    this.getWorktimingData();
    this.getProjects();
    this.getAccountData();
    this.getCategoryData();
    this.getSubCategoryData();
    this.getTaskData();
    this.getIssueTypeData();
    this.getFilterData();
    this.getuser();
    if (this.existFilterData) {
      console.log(this.existFilterData, "existData");
      this.setFormData(this.existFilterData)
    }
    else {
      this.setFormData(null)
    }
  },
  methods: {
    setFormData(data) {
      console.log(data,"jjjjjjjjjjjjjjddddddd")
      this.formData = {
        filterData: {
          project_id: data && data.project_id ? data.project_id : null,
          account_id: data && data.account_id ? data.account_id : null,
          category_id: data && data.category_id ? data.category_id : null,
          user_id: data && data.user_id ? data.user_id : null,
          sub_category_id: data && data.sub_category_id ? data.sub_category_id : null,
          task_id: data && data.task_id ? data.task_id : null,
          issue_type_id: data && data.issue_type_id ? data.issue_type_id : null,
          created: data && data.created ? data.created : null,
          end_date: data && data.end_date ? data.end_date : null,
          work_space_id: data && data.work_space_id ? data.work_space_id : null,
          stages: data && data.stages ? data.stages : null,
          next_action: data && data.next_action ? data.next_action : null,
          workTimingId: data && data.workTimingId ? data.workTimingId : null,
          work_timing_id: data && data.work_timing_id ? data.work_timing_id : null,
        },
      }
    },
    getFilterData() {
      let data = localStorage.getItem("filterData")
      this.filterData1 = data && data != undefined && data !='undefined' ? JSON.parse(data) : {}
      if (this.filterData1) {
        this.formData.filterData = this.filterData1 || {};
      }
    },    
    async getWorktimingData() {
      await WorkTimingService.findAll().then(async ($options) => {
        this.worktimingData =
          $options.length > 0 ? await commonService.getSort($options, "name", "asc") : [];
      });
    },
    async getWorkspaceData() {
      await WorkSpaceService.getWorkSpaceByUser().then(async ($options) => {
        this.workspaceData =
          $options.length > 0 ? await commonService.getSort($options, "name", "asc") : [];
      });
    },
    async getProjects() {
      await ProjectService.findAll({active:1}).then(async ($options) => {
        this.projectData =
          $options.length > 0 ? await commonService.getSort($options, "name", "asc") : [];
      });
    },
    async getAccountData() {
      await AccountService.findAll({active:1}).then(async ($options) => {
        this.accountData =
          $options.length > 0 ? await commonService.getSort($options, "name", "asc") : [];
      });
    },
    async getCategoryData() {
      let res = await CategoryService.findAll();
      this.categoryData = res.length > 0 ? res : [];
    },
    async getSubCategoryData() {
      let res = await SubCategoryService.findAll();
      this.subCategoryData = res.length > 0 ? res : [];
    },
    async getTaskData() {
      let res = await TaskService.findAll({ task_type: "production" });
      this.taskData =
        res.length > 0 ? await commonService.getSort(res, "name", "asc") : [];
    },
    async getIssueTypeData() {
      let res = await IssueTypeService.findAll();
      this.issyeTypeData = res.length > 0 ? res : [];
    },
    async getuser() {
      try {
        let workSpaceId = localStorage.getItem("workSpaceId");
        let res = await UserService.findAll();
        console.log(workSpaceId);
        //await UserService.getNextAction({ work_space_id: workSpaceId })
        this.users = res.length ? res : [];
      } catch (e) {
        this.users = [];
      }
    },
    async getStageData() {
      let res = await StageService.findAll();
      this.stageData = res;
      //    this.stageData.unshift({
      //        data_value: null,
      //        display_value: "All Stages"
      //    });
    },
    close() {
      this.$emit("closeDialog");
    },

    async save() {
      this.filterData.end_date = commonService.changeTheDateToUtc(
        this.filterData.end_date,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      // await filterService.update(this.filterData);
      this.$store.dispatch("setSnackbar", { text: "Filter Added Successfully" });
      this.dialog = false;
      this.close();
      this.$emit("reload", this.formData.filterData);
      this.filterData = {};
    },
  },
};
</script>

<style scoped>
.nav-drawer {
  position: absolute;
  top: 70px !important;
  box-shadow: -10px 0px 10px 1px #aaaaaa;
}

.save {
  background-color: #1258ab;
  color: #fff;
}

.menuFilter {
  height: 71vh;
  overflow: auto;
}

.notmenuFilter {
  height: "100%";
}
</style>
