import ApiService from '../../service/apiServiceBoard.js';
const querystring = require('querystring')
var DashboardService = {

    insights: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "insights"
            $options.method = "get"

            if ($data) {
                $options.url = "insights?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    getInsightByCategory: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "insightsByCategory"
            $options.method = "get"

            if ($data) {
                $options.url = "insightsByCategory?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    
    getDashboards: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "getDashboards"
            $options.method = "get"

            if ($data) {
                $options.url = "getDashboards?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    
    getExceptionData: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "exceptionReports";
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    getExceptionIssues: function ($data) {
        return new Promise(function (resolve, reject) {
            let $options = {};
            $options.url = "exceptionIssues"
            $options.method = "get"

            if ($data) {
                $options.url = "exceptionIssues?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
                .then(response => {
                    console.log('test');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
};
export default DashboardService;