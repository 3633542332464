<template>
  <v-row>
    <v-col cols="12" md="3" v-for="(listItem, index) in list" :key="index">
      <v-card rounded class="pa-4" color="#E0E0E0">
        <v-avatar size="40" :color="getColor(index)">{{ listItem.data }}</v-avatar>
        <strong class="ml-2 font-weight-medium">{{ listItem.title }}</strong>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["list", "data"],
  methods: {
    getColor(index) {
      switch (index) {
        case 0:
          return 'secondary';
        case 1:
          return 'light-green';
        case 2:
          return 'red';
        case 3:
          return 'yellow';
        default:
          return 'grey';
      }
    },
  },
};
</script>
