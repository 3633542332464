<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
  <center>
    <h2>Leave Management Board</h2>
  </center>
  <div style="display: flex; align-items: center;">
    <h3 style="margin: 0;">Hello, Bala Ganesh</h3>
    <v-icon id="nextaction-menu-activators" style="cursor: pointer; color: #636363; margin-left: 8px;"
      size="14px">mdi-pencil</v-icon>
  </div>
  <v-card class="cards pa-4">
    <v-row>
      <v-col cols="2" class="head">
        <div>
          2024<span class="d-block subhead"> Vacation</span>
          <span class="d-block down">40 Hours</span>
          <div>
            <span class="d-block subhead"> Request</span>
            <span class="d-block down">40 Hours</span>
          </div>
        </div>
      </v-col>
      <v-col cols="2" class="head">
        <div>
            ****<span class="d-block subhead"> PTO</span>
          <span class="d-block down">40 Hours</span>
          <div>
            <span class="d-block subhead"> Pipeline</span>
            <span class="d-block down">40 Hours</span>
          </div>
        </div>
      </v-col>
      <v-col cols="1">
        <div class="vertical-line">
        </div>
      </v-col>
      <v-col cols="2" class="secondtable mt-n2">
        <div>
           **** <span class="d-block subhead"> Planned Vacation</span>
          <span class="d-block down">40 Hours / 5 People</span>
          <div>
            <span class="d-block subhead"> Un Planned Vacation</span>
            <span class="d-block down">40 Hours / 5 People</span>
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <v-card class="linechart mt-12  mx-auto overflow-visible" max-width="400">
    <v-sheet class="v-sheet--offset mx-auto" color="#BB9AB1">
      <v-sparkline
        :labels="labels"
        :model-value="value"
        color="black"
        line-width="2"
        padding="16"
      ></v-sparkline>
    </v-sheet>
  </v-card>
      </v-col>
      <v-col cols="2" class="thirdtable pa-4">
        <div>
          <h5 class=" calender"> Today | This Week | This Month</h5>
          <span class="d-block subhead"> Blocked Calendar </span>
          <span class="d-block down">12-10-204 TO 14-102-24</span>
          <div>
            <span class="d-block down"> 12-10-204 TO 14-102-24</span>
            <span class="d-block down">12-10-204 TO 14-102-24 </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
  <v-row class="rows">
    <v-col cols="11">
      <v-tabs v-model="activeTab">
        <v-tab v-for="(tab, index) in tabs" :key="index">{{ tab.title }}</v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="1">
      <v-icon @click="plus" class="plus" icon="mdi-plus-circle" size="x-large"></v-icon>
    

      <!-- <i @click="expand2 = !expand2" class='fas fa-bullhorn' style='font-size:24px'></i>
          <i class="glyphicon glyphicon-search"></i>
        <v-expand-x-transition>
          <v-card v-show="expand2" flat>
            <v-card-text>
              <section class="team-leaves">
                <h2>
                  My Team Leaves
                  <v-btn size="medium" icon="mdi-calendar"></v-btn>
                  <span>{{ formattedDate }}</span>
                  <v-tooltip activator="parent" location="bottom">Leave calendar</v-tooltip>
                </h2>
                <ul>
                  <li>John cruise - Vacation leave - Approved</li>
                  <li>Deena Dayalan - PTO - Not approved</li>
                  <li>MG Ramachadran - Unplanned - Not approved</li>
            
                </ul>
              </section>
        
              <section class="announcements">
                <h2>Announcements
                  <v-btn size="medium" icon="mdi-plus"></v-btn>
                  <v-tooltip activator="parent" location="bottom">Add notes</v-tooltip>
                </h2>
                <ul>
                  <li>Leaves frozen from Aug 5th to Aug 8th - so leave</li>
                  <li>Aug 15th Skeleton crew no leave</li>
                  <li>Sick leave must be recorded on the next day</li>
             
                </ul>
              </section>
            
              <section class="latest-leaves">
                <h2>My Latest Leaves</h2>
                <ul>
                  <li>Unpaid leave - Pending (26/08/2021-27/08/2021)</li>
                  <li>Paid Time Off - Pending (10/07/2021-20/07/2021)</li>
                 
                </ul>
              </section>
            </v-card-text>
          </v-card>
        </v-expand-x-transition> -->

    </v-col>
  </v-row>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" v-for="(request, index) in requests" :key="index">
        <v-card class="active-card">
          <v-row>
            <v-col cols="12" md="8" class="user-info">
              <div class="user-details d-flex align-center">
                <img :src="request.image" alt="User Image" class="user-avatar mr-3" />
                <div class="user-text">
                  <h3 class="mb-1">{{ request.name }}</h3>
                  <span class="position">{{ request.position }}</span>
                </div>
                <div class="icons ml-3 mb-6">
                  <v-btn @click="edit" icon="mdi-pencil" size="x-small" color="#ff8080"> </v-btn>
                  <v-dialog max-width="500">
                    <template v-slot:activator="{ props: activatorProps }">
                      <v-btn v-bind="activatorProps" size="x-small" icon="mdi-delete" variant="flat"
                        color="#ff8080"></v-btn></template>
                    <template v-slot:default="{ isActive }">
                      <v-card title="Delete">
                        <v-card-text> Please confirm ! Do we need to delete the request ??? </v-card-text>
                        <v-card-actions> <v-spacer></v-spacer>
                          <v-btn text="Delete" @click="isActive.value = false"></v-btn>
                        </v-card-actions> </v-card>
                    </template> </v-dialog>
                  <v-btn @click="dialog = true" size="x-small" icon="mdi-message-outline" variant="flat"
                    color="#ff8080"></v-btn>
                  <v-dialog v-model="dialog">
                    <v-card class="box pa-4">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea label="Update your comments"></v-textarea>
                            <v-btn @click="close" class="create" color="#1B1A47" width="100" variant="flat"
                              type="submit"> Close </v-btn>
                            <v-btn class="create" color="#1B1A47" width="100" variant="flat" type="submit"> Save
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div class="leave-reason pl-4 mt-2">
                <p>{{ request.reason }}</p>
              </div>
              <div class="date-info d-flex justify-space-between mt-2 pl-4">
                <span>{{ request.date }}</span>
                <span class="date-separator"></span>
                <span>{{ request.daysAgo }} days ago</span>
              </div>
            </v-col>
            <v-col cols="12" mb="2" md="4" class="leave-info d-flex flex-column justify-space-between align-center">
              <div class="leave-dates text-center">
                <span>{{ request.leaveDates }}</span>
                <span class="leave-duration d-block">{{ request.leaveDuration }}</span>
              </div>
              <div class="approval-chips d-flex justify-center mt-2">
                <v-chip color="green lighten-4" class="mx-1">1</v-chip>
                <v-chip color="light-green lighten-3" class="mx-1">2</v-chip>
                <v-chip color="amber lighten-3" class="mx-1">3</v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" v-for="(request, index) in requests" :key="index">
        <v-card class="active-card">
          <v-row>
            <v-col cols="12" md="8" class="user-info">
              <div class="user-details d-flex align-center">
                <img :src="request.image" alt="User Image" class="user-avatar mr-3" />
                <div class="user-text">
                  <h3 class="mb-1">{{ request.name }}</h3>
                  <span class="position">{{ request.position }}</span>
                </div>
                <div class="icons ml-3 mb-6">
                  <v-btn @click="edit" icon="mdi-pencil" size="x-small" color="#ff8080"> </v-btn>
                  <v-dialog max-width="500">
                    <template v-slot:activator="{ props: activatorProps }">
                      <v-btn v-bind="activatorProps" size="x-small" icon="mdi-delete" variant="flat"
                        color="#ff8080"></v-btn></template>
                    <template v-slot:default="{ isActive }">
                      <v-card title="Delete">
                        <v-card-text> Please confirm ! Do we need to delete the request ??? </v-card-text>
                        <v-card-actions> <v-spacer></v-spacer>
                          <v-btn text="Delete" @click="isActive.value = false"></v-btn>
                        </v-card-actions> </v-card>
                    </template> </v-dialog>
                  <v-btn @click="dialog = true" size="x-small" icon="mdi-message-outline" variant="flat"
                    color="#ff8080"></v-btn>
                  <v-dialog v-model="dialog">
                    <v-card class="box pa-4">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea label="Update your comments"></v-textarea>
                            <v-btn @click="close" class="create" color="#1B1A47" width="100" variant="flat"
                              type="submit">
                              Close </v-btn>
                            <v-btn class="create" color="#1B1A47" width="100" variant="flat" type="submit"> Save
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div class="leave-reason pl-4 mt-2">
                <p>{{ request.reason }}</p>
              </div>
              <div class="date-info d-flex justify-space-between mt-2 pl-4">
                <span>{{ request.date }}</span>
                <span class="date-separator"></span>
                <span>{{ request.daysAgo }} days ago</span>
              </div>
            </v-col>
            <v-col cols="12" mb="2" md="4" class="leave-info d-flex flex-column justify-space-between align-center">
              <div class="leave-dates text-center">
                <span>{{ request.leaveDates }}</span>
                <span class="leave-duration d-block">{{ request.leaveDuration }}</span>
              </div>
              <div class="approval-chips d-flex justify-center mt-2">
                <v-chip color="green lighten-4" class="mx-1">1</v-chip>
                <v-chip color="light-green lighten-3" class="mx-1">2</v-chip>
                <v-chip color="amber lighten-3" class="mx-1">3</v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    
    labels: [
      'Bala',
      'Deena',
      'sathis',
      'Vels',
      'Andrew',
    ],
    value: [
      180,
      675,
      410,
      1000,
      310,
    ],
    selectedCard: null,
    activeTab: 0,
    dialog: false,
    expand2: false,
    tabs: [
      { title: "All Vacation Requests" },
      { title: "Pending Approvals" },
      { title: "Approved" },
    ],
    requests: [
      {
        name: "Velayutham Sankaran",
        position: "Process Lead",
        image: "path_to_user_image", // Replace with actual image path
        reason: "My personal Trip with family for 4 days. Hence requesting to grant leave on those days",
        date: "08-10-2024 09:00 AM",
        daysAgo: 3,
        leaveDates: "12-10-2024 to 12-15-2024",
        leaveDuration: "4 days (32 Hours)",
      },
      // Repeat the above object for each request
    ],
  }),
  methods: {
    plus() {
      this.$router.push("/figma4");
    },
    close() {
      this.$emit('close', false);
    },
    edit() {
      this.$router.push("/figma5");
    }
  },
};
</script>


<style scoped>
.border {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.cards {
  width: 100%;
  height: 200px;
  border-radius: 14px;
  background-color: #f3f2f5;
  padding: 16px;
  border-color: #c6c6c7;
}
.head {
  font-size: 14px;
  font-weight: bold;
}
.secondtable {
  font-size: 14px;
  font-weight: bold;
  margin-left: -170px;
  margin-bottom: 145px;
}
.thirdtable {
  font-size: 14px;
  font-weight: bold;
  margin-left: 49px;
  margin-bottom: 30px;
}
.date {
  font-size: 14px;
  font-weight: bold;
  color: blue;
}
.down {
  font-size: 12px;
}
.second {
  padding-top: 35px;
}
.subhead {
  font-size: 14px;
  font-weight: bold;
  padding-top: 20px;
}
.user-details .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.user-text h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.user-text .position {
  color: #8a939b;
  font-size: 14px;
}
.icons {
  display: flex;
  gap: 8px;
  margin-left: auto;
  font-size: 16px;
  color: #495057;
}
.leave-reason p {
  margin: 8px 0;
  font-size: 12px;
  color: #495057;
}
.date-info {
  display: flex;
  gap: 8px;
  color: #6c757d;
  font-size: 12px;
}
.leave-info .leave-dates {
  text-align: right;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
}
.leave-info .leave-duration {
  color: #adb5bd;
  font-size: 12px;
}
.approval-chips {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.active-card {
  width: 670px;
  height: 140PX;
  border-radius: 10px;
  margin-left: -140px;
  background-color: #f3f2f5;
  border-color: #c6c6c7;
}
.plus {
  margin-top: 0px;
  margin-right: 10px;
}
.rows {
  background-color: #5cfcd1e9;
  height: 50%;
  margin-top: 5px;
  border-radius: 20px;
}
.box {
  width: 700px;
  height: 250px;
  border-radius: 14px;
  margin-left: 400px;
}
.create {
  border-radius: 8px;
  margin-bottom: 25px;
  display: inline-block;
  margin-left: 110px;
  justify-content: space-between;
}
.line-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.vertical-line {
  width: 2px;
  height: 60%;
  background-color: gray;
  opacity: 0.5;
  margin-left: -100px;
}
.calender {
  font-size: 13px;
  font-weight: bold;
}
.linechart {
    top: -24px; 
  }
  .sideview {
margin-top: 15px;
height: max-content;
width: max-content;
padding-bottom: 5px;
margin-top: 0px;
margin-left: 35px;
}
.team-leaves {
background-color: #33f9f9;
border-radius: 8px;
padding: 1rem;
margin-bottom: 1rem;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
height: max-content;
width: max-content;
font-size: 12px;
border: 1px solid #b9b7b7;
border-radius: 10px;
}
.announcements {
background-color: #d0a4d5;
height: max-content;
width: max-content;
font-size: 12px;
margin-top: -12px;
border: 1px solid #b9b7b7;
border-radius: 10px;
}
.latest-leaves {
background-color: #88cd8e;
height: max-content;
width: max-content;
font-size: 12px;
margin-top: 4px;
border: 1px solid #b9b7b7;
border-radius: 10px;
}
section {
border: 1px solid #ddd;
border-radius: 8px;
padding: 1rem;
background-color: #f9f9f9;
}
section h3 {
font-size: 1.5rem;
color: #333;
margin-bottom: 0.5rem;
border-bottom: 2px solid #007bff;
padding-bottom: 0.5rem;
}
section ul {
list-style-type: none;
padding: 0;
margin: 0;
}
section li {
padding: 0.5rem 0;
border-bottom: 1px solid #eee;
}
section li:last-child {
border-bottom: none;
}
</style>
