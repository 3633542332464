<template>
    <v-card flat style="height:100vh;overflow: auto;">
        <Header :userData="userData" :superUser="superUser"  :boardOption="boardOption"   @user-data="userSelection" @board-option="changeOption" />
        <DetailBoard :userData="userData" :boardOption="boardOption"  :superUser="superUser" class="ma-4" />
        <List :userData="userData"  :boardOption="boardOption" :index="index"  :superUser="superUser" class="ma-4" />
    </v-card>

</template>
<script>
import Header from './Header.vue'
import DetailBoard from './DetailBoard.vue'
import List from './List.vue'
export default {
    components:{
        List,
        Header,
        DetailBoard
    },
    data(){
        return{
            userData:null,
            superUser:false,
            boardOption:'myBoard',
            index:null,
        }
    },
    created(){
        let $userData = localStorage.getItem('user_details')
        this.userData = $userData ? JSON.parse($userData) : null
        if(this.userData && this.userData.roles && this.userData.roles.role_type){
            this.superUser = parseInt(this.userData.roles.role_type)<=5?true:false
        }
    },
    methods:{
        userSelection($event,index){
            this.userData = $event,
            this.index=index
        },
        changeOption($event){
            this.boardOption = $event
        }
    }

}
</script>


<style scoped>
</style>