<template>
  <div>
    <ckeditor
      :editor="editor"
      :model-value="value"
      @input="changeText"
      :disabled="showReadOnly"
    ></ckeditor>
    <div v-if="showError" class="error-message" :style="{ fontSize: '14px' }">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: ["data", "showReadOnly"],
  methods: {
    showErrorMessage(message) {
      this.errorMessage = message;
      this.showError = true;
    },
    changeText(event) {
      this.value = event;
      this.$emit("textData", this.value);
      this.showError = false;
    },
  },
  data() {
    return {
      showError: false,
      editor: ClassicEditor,
      value: null,
      editorDisabled: true,
      errorMessage: "",
    };
  },
  created(){
      if(this.data){
        this.value = this.data
      }
  },
  watch: {
    data(newData) {
      if (newData !== undefined && newData !== null) {
        this.value = newData;
      } else {
        this.value = null;
      }
    },
  },
};
</script>
