<template>
    <v-row class="mt-2 ml-2">
        <v-col cols="2">
            <span class="gm">
                Good Morning
            </span>
            <br/>
            <span class="name">
                {{ this.option==2?'Team':userData && userData.name?userData.name:userData.users.name }}
            </span>
            <v-icon v-if="superUser"
                id="nextaction-menu-activators"
                style="cursor: pointer"
                color="#636363"
                size="14px"
                >mdi mdi-pencil</v-icon
            >
        </v-col>
        <v-col cols="4" v-if="superUser">            
            <v-radio-group color="primary" @change="changeData"  v-model="option" inline>
                <v-radio label="My Board" value="1"></v-radio>
                <v-radio label="Team" value="2"></v-radio>
            </v-radio-group>
        </v-col>
        <NextAction
            activator="#nextaction-menu-activators"
            :options="users"
            :option="option"
            @menuSelected="userSelected"
            :showAvatar="true"
        />
    </v-row>

</template>
<script>
import NextAction from "@/modules/IssueV2/NextAction.vue";
import VacationService from "@/api/service/Vacation/VacationService";
import RoleService from "@/api/service/Role/RoleService";
export default {
    props:["userData","superUser"],
    components:{
        NextAction
    },
    data(){
        return{
            users:[],
            option:'1',
            loggedUsers:null,
        }
    },
    watch:{
        superUser(){
            if(this.superUser){
                this.getUserData()
            }
        }
    },
    created(){
        let $userData = localStorage.getItem('user_details')
        this.loggedUsers = $userData?JSON.parse($userData):null
        if(this.superUser){
                this.getUserData()
            }
    },
    methods:{
        async changeData(){
            
            this.$emit("board-option",this.option==1?'myBoard':"team")
             
        },
        async getUserData(){
            try{
                let res = await VacationService.getReportingUser()
                this.users = res && res.length>0 ? res:[]
                this.users.unshift({id:0,name:"My Space"})
            }
            catch(e){
                console.log(e);
            }
        },  
        async userSelected($event,index){
            this.$emit("board-option",this.option==1?'myBoard':"team")
            if($event.id == 0){
                this.$emit('user-data',this.loggedUsers)
                return
            }
            try{
                let userData = $event.users ?  $event.users :  $event
                let res = await RoleService.findAll({id:userData.role_id})
                userData.roles = res && res.length>0 ?res[0]:{}
                this.$emit('user-data',userData,index)
            }catch(exception){
                console.log(exception)
            }
        }
    }

}
</script>
<style scoped>
.name{
    font-weight: 700;
    font-size: large;
    margin-right: 5px;
}
.gm{
    font-weight: 400;

}
.db-btn{
    background-color: #1258ab;
    color: #fff
}
</style>