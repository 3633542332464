<template>
  <v-card elevation="0" style="border-radius:10px">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>
          QA Task
          <v-btn style="float: right;" @click="close()" elevation="0" density="comfortable" icon="mdi-close"></v-btn>
        </v-card-title>
      </v-card-item>
 
 
      <v-col cols="12" class="pl-5 pr-2">
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-radio-group :disabled="showReadOnly || qaFail" inline v-model="formData.pass" @change="handlePassChange">
              <v-radio label="Pass" value="1" color="primary" :disabled="showReadOnly"></v-radio>
              <v-radio label="Fail" value="0" color="primary" :disabled="showReadOnly"></v-radio>
            </v-radio-group>
            </v-col>
            <v-col cols="3" v-if="formData.pass === '0'">
                        <v-radio-group :disabled="showReadOnly" v-model="formData.qa_external" inline @change="handleInterOrExternal">
                            <v-radio color="primary" label="Internal" :value="0"></v-radio>
                            <v-radio color="primary" label="External" :value="1"></v-radio>
                        </v-radio-group>
              </v-col>
          </v-row>
 
          <v-row v-if="formData.pass === '1' || formData.pass === '0'">
            <v-col cols="3" class="dropdown" style="margin-bottom: -14px;" v-if="formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color:#cf0707; font-weight: 800">{{ formData.pass === '0'?'Error Reported Date':'Date' }}
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
              </v-label>
              <!-- <DatePicker :data="formData.error_reported_date" :minDate="minimumDate" :placeholder="'Error Reported Date'" @selectDate="selectErrorRep($event)" /> -->
              <!-- <DatePicker2  :data="formData.error_reported_date" :maxdate="errorReportedDateMax"  @selectDate="selectErrorRep"/> -->
              <TextFieldComponent  density="compact" placeholder="Error Reported Date" type="date" showtime :showReadOnly="showReadOnly"
               v-model="formData.error_reported_date" />
 
            </v-col>
            <v-col cols="3" class="dropdown" v-if="formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color:#cf0707; font-weight: 800">Error made by
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
              </v-label>
              <AutoComplete :placeholder="'Error made by'" :items="users" :item-title="'username'"
                :disabled="showReadOnly" :item-value="'id'" v-model="formData.user_id" :rules="userRule"
               />
            </v-col>
            <v-col cols="3" v-if="formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Error severity [E]
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <EnumAutoCompleteVue class="dropdown" density="compact" v-model="formData.severity"
                :selected="formData.severity" :domain="'ERRORSEVERITY'" placeholder="Error severity" @changeEnumData="changeSeverty($event)"
                :rules="severityRule" :showReadOnly="showReadOnly" />
            </v-col>
 
            <v-col cols="3" v-if="formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Error type [E]
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <EnumAutoCompleteVue class="dropdown" density="compact" v-model="formData.error_type"
                :selected="formData.error_type" :domain="'ERRORTYPE'" placeholder="Error type"
                @changeEnumData="changeErrorType($event)" :rules="errorTypeRule" :showReadOnly="showReadOnly" />
            </v-col>
           
            <v-col cols="3" v-if="formData.pass === '0'" >
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Error category [E]
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <!-- <TextFieldComponent class="dropdown" density="compact" v-model="formData.error_category" placeholder="Error category"
              :rules="errorCategoryRule" :showReadOnly="showReadOnly" /> -->
              
              <EnumAutoCompleteVue class="dropdown" density="compact" v-model="formData.error_category"
                :selected="formData.error_category" :domain="'ERRORCATEGORY'" placeholder="Error category"
                @changeEnumData="changeErrorCategory($event)" :rules="errorCategoryRule" :showReadOnly="showReadOnly" />
            
            </v-col > 
            <v-col cols="3" v-if="formData.pass === '0'" >
           
              <v-label class="pb-1" style="font-size: 14px; color:blue; font-weight: 700">Error Reported By Name
               </v-label>
              <!-- <TextFieldComponent density="compact" placeholder="Error Reported By Name" v-model="formData.error_reported_by"
                :disabled="showReadOnly" /> -->
                
              <AutoComplete :placeholder="'Error Reported By Name'" :items="errorReportUser" :item-title="'name'"
                :disabled="showReadOnly" :item-value="'id'" v-model="formData.error_reported_by" 
               />
            </v-col>              
              
            <v-col cols="3" class="dropdown" v-if="formData.pass === '1' || formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color: blue; font-weight: 600">Entry person
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
              </v-label>
              <AutoComplete :placeholder="'Entry person'" :items="entryUserData" :item-title="'username'"
                :disabled="showReadOnly" :item-value="'id'" v-model="formData.entry_person" :rules="entryRule" :v-if="true"
               />
            </v-col>
            <v-col cols="3" class="dropdown" v-if="formData.pass === '1' || formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color: blue; font-weight: 600">QA person name
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
              </v-label>
              <!-- <AutoComplete :placeholder="'QA approved by'" :items="qaApprovedUsersData" :item-title="'username'"
                :disabled="showReadOnly" :item-value="'id'" v-model="formData.qa_approved_by"
               /> -->
 
               <AutoComplete :placeholder="'QA person name'" :items="qaApprovedUsersData" item-title="username"
                :disabled="showReadOnly" item-value="id" v-model="formData.qa_approved_by"
               />
 
            </v-col>
            <v-col cols="3" class="dropdown" style="margin-bottom: -14px;"  v-if="formData.pass === '0'">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">{{ formData.pass === '0'?'Correction completed date':'Date' }}
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span>
              </v-label>
              <TextFieldComponent  density="compact" placeholder="Correction completed date" type="date" showtime :showReadOnly="showReadOnly"
              v-model="formData.correction_completed_date" />
              <!-- <DatePicker :data="formData.correction_completed_date" :minDate="minimumDate" @selectDate="selectCorrectiveDate($event)" :placeholder="'Correction completed date'" /> -->
            </v-col>
            <v-col cols="3" v-if="formData.pass === '0'">
              <!-- <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Error Code paremeters
                <span style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
              <AutoComplete :items="errorCodeData" :placeholder="'Error Code paremeters'" :item-title="'name'"
                :disabled="showReadOnly" :item-value="'id'" @update:modelValue="selectionChange" v-model="formData.error_code_id" />
           
            -->
             
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Error Code paremeters
              <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>
            <AutoComplete :items="errorCodeData" item-value="id" item-title="name" density="compact"
              placeholder="Error Code paremeters"  v-model="formData.error_code_id"
              @update:modelValue="selectionChange" :disabled="showReadOnly" />
             
              </v-col>
              <v-col cols="6" class="pt-10" v-if="formData.pass === '0'"><v-chip class="selectedChip" label closable v-for="item in chipData" :key="item" :close="false"
              close-icon="mdi-close-circle"  :disabled="showReadOnly" close-transition="scale-transition" @click:close="cancelError(item)">
              {{ item.name }}
            </v-chip>
 
              </v-col>
 
          </v-row>
          <!-- <v-row style="padding-bottom: 15px;">
            <v-col cols="6" v-if="formData.pass === '0'">
            <v-chip class="selectedChip" label closable v-for="item in chipData" :key="item" :close="false"
              close-icon="mdi-close-circle"  :disabled="showReadOnly" close-transition="scale-transition" @click:close="cancelError(item)">
              {{ item.name }}
            </v-chip>
            </v-col>
          </v-row> -->
          <v-row v-if="formData.pass === '1' || formData.pass === '0'">
            <v-col cols="2" class="">
              <strong class="">Attachment</strong>
            </v-col>
            <v-col class="pa-0 ml-0 pl-0 pt-3" v-if="!showReadOnly">
              <uploadFiles @documentsData="documentsData($event)" :type="'icon'" />
            </v-col>
          </v-row>
 
          <v-row style="margin-left: 0%;"
            v-if="(formData.pass === '1' || formData.pass === '0') && attachmentId.length > 0 && attachmentData.length > 0">
            <v-cols v-for="(file, index) in attachmentData" :key="index">
              <v-chip class="selectedChip" :close="true" closable close-icon="mdi-close-circle" :disabled="showReadOnly"
                close-transition="scale-transition" @click.stop="remove(index)"
                v-if="formData.pass === '1' || formData.pass === '0'">
                <span @click.stop="downloadDoc(file)">{{ file.doc_name }}</span>
              </v-chip>
            </v-cols>
          </v-row>
 
          <v-row v-if="formData.pass === '0'">
            <v-col cols="12 mb-0">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 800">Root cause ( Reason for the issue)</v-label>
                <v-textarea variant="outlined" class="mt-1 text-Field" rows="1" v-model="formData.cause" :disabled="showReadOnly"
                :rules="rootCauseRule"	></v-textarea>
            </v-col>  
            <v-col cols="12 mb-0">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 800">Corrective action (resolution to the error)</v-label>
                <v-textarea variant="outlined" class="mt-1 text-Field" rows="1" :disabled="showReadOnly" v-model="formData.corrective_action"
                :rules="correctiveRule" ></v-textarea>
            </v-col>  
          </v-row>
 
          <v-row v-if="formData.pass === '1' || formData.pass === '0'">
            <v-col cols="12">
              <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 800">{{ formData.pass === '0' ?'Preventive action':'Comment'}}</v-label>
                <v-textarea variant="outlined" class="mt-1 text-Field" rows="1" v-model="formData.description" :disabled="showReadOnly"
                :rules="qaRemarkRule" ></v-textarea>
            </v-col>
          </v-row>
 
        </v-card-text>
      </v-col>
      <div v-if="viewType != true">
        <v-card-action class="mr-3" v-if="formData.pass === '1' || formData.pass === '0'">
          <v-row justify="end" class="dialogBottom">
            <v-btn style=" --v-btn-height: 35px;" class="mr-2 cancel" @click="close()">Cancel</v-btn>
            <v-btn style=" --v-btn-height: 35px;" class="save" type="submit">Save</v-btn>
          </v-row>
        </v-card-action>
      </div>
    </v-form>
  </v-card>
</template>
   
<script>
import uploadFiles from '@/components/uploadFiles.vue';
// import DatePicker from "@/components/ui/DatePicker.vue";
// import DatePicker2 from "@/components/ui/DatePicker2.vue";
// import TextArea from "@/components/ui/Description.vue";
import documentService from "@/api/service/Document/DocumentService";
import QaService from '@/api/service/Qa/QaService';
import EnumAutoCompleteVue from '@/components/others/EnumAutoComplete.vue';
import AutoComplete from "@/components/ui/AutoComplete.vue";
import UserService from '@/api/service/UserService';
import ErrorCodeService from "@/api/service/ErrorCode/ErrorCodeService";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import commonService from "@/common.service";
import moment from 'moment';
import ContactService from "@/api/service/Contact/ContactService";


export default {
  props: ['timeSheetDefaults', 'viewType', 'id','issueId','issueData','qaFail'],
  components: {
    uploadFiles,
    // DatePicker,
    // DatePicker2,
    // TextArea,
    EnumAutoCompleteVue,
    AutoComplete,
    TextFieldComponent
  },
  data() {
    return {
      impactRule: [(value) => !!value || "Impact is Required"],
      severityRule: [(value) => !!value || "Severity is Required"],
      errorTypeRule: [(value) => !!value || "Error Type is Required"],
      errorCategoryRule: [(value) => !!value || "Error category is Required"],
      userRule: [(value) => !!value || "User is Required"],
      entryRule: [(value) => !!value || "User is Required"],
      rootCauseRule: [(value) => !!value || "Root Cause is Required"],
      correctiveRule: [(value) => !!value || "Corrective action is Required"],
      qaRemarkRule: [(value) => !!value || "Preventive measure is Required"],
      attachmentData: [],
      errorReportedDateMax: moment().add(1, 'days').format('MM-DD-YYYY'),
      showReadOnly: false,
      attachmentId: [],
      document_id: [],
      pass: 1,
      formData: {
        followup_type: null,
        user: null,
      },
      tempId:null,
      causeError:false,
      correctiveActionError:false,
      descError:false,
      users: [],
      usersData: [],
      entryUserData: [],
      qaApprovedUsersData: [],
      QAData: {},
      errorCodeData: [],
      selectedErrorCodeId: [],
      chipData:[],
      backupErrorCodeData: [],
      minimumDate:new Date(),
      errorReportUser:[]
      // impact: "taskType",
      // severity:"severity" ,
 
    }
  },
  watch: {
    timeSheetDefaults() {
      if(!this.id){
        this.formData = this.getDefaults()
      }
      this.formData.qa_external = 0
      this.getErrorReportBy(this.formData.qa_external)
    },
    getIssueId(){
      if(this.getIssueId){
        this.getById(this.id)
      }
    }
 
  },
  computed:{
    getIssueId(){
      return this.id
    }
  },
  created() {  
    this.getUser();
    this.getUsers();
    this.getErrorCodeData();
    if(this.issueData && this.issueData.completed_date){
      // this.minimumDate = moment(this.issueData.completed_date).format('YYYY/MM/DD')
      // console.log(this.minimumDate,"jjjjjjjjjjjjjjjjjj")
    }
    if (this.id) {
      this.getById(this.id)
    }
    else{      
      this.formData = this.getDefaults();
      this.formData.qa_external ='0'
      this.getErrorReportBy(this.formData.qa_external)
    }
 
    // this.formData.pass = this.qaFail ? 0:null
 
    // if(this.timeSheetDefaults.id){
    //   this.getById(this.timeSheetDefaults.id)
    // }
    if (this.viewType) {
      this.showReadOnly = this.viewType
    }
   
  },
  methods: {
    moment,
    selectionChange($value) {
      this.selectedErrorCodeId.push($value)
      this.setTheData()
    },
    async getUsers(type) {
      this.formData.user = null;
      if (type == 1 || type == "internal") {
        let res = await UserService.findAll();
        this.usersData = res && res.length > 0 ? res : [];
        console.log(this.usersData, "users");
      } else if (type == 0 || type == "external") {
        let res = await ContactService.allContacts();
        this.usersData =
          res && res.length > 0 ? res.filter((contact) => contact.active === 1) : [];
      } else {
        this.usersData = [];
      }
    },
 
    setTheData() {
      let res = commonService.getObjectSliceMethod(JSON.stringify(this.backupErrorCodeData), this.selectedErrorCodeId, 'id')
      let chipData = commonService.getMultipleChip(this.backupErrorCodeData, this.selectedErrorCodeId, 'id')
      this.errorCodeData = [...res]
      this.chipData =[...chipData]
      this.formData.error_code_id =''
    },
      async getErrorCodeData() {
      try {
        let res = await ErrorCodeService.findAll()
        this.errorCodeData = res && res.length > 0 ? res : []
        this.backupErrorCodeData = JSON.parse(JSON.stringify(this.errorCodeData))
 
      } catch (e) {
        this.errorCodeData = []
        this.backupErrorCodeData = []
        console.log(e)
      }
    },
   
    cancelError(data) {
      let index = this.selectedErrorCodeId.findIndex(k => k == data.id)
      if (index > -1) {
        this.selectedErrorCodeId.splice(index, 1)
      }
      this.setTheData()
 
    },
    handlePassChange(){
      this.descError = false
      this.causeError = false
      this.correctiveActionError = false
      this.formData.qa_external =0
      this.getErrorReportBy(this.formData.qa_external)
    },
    handleInterOrExternal(){
      this.getErrorReportBy(this.formData.qa_external)
    },
    getDefaults() {
      return {
        "error_reported_date": new Date(),
        "issue_id": this.timeSheetDefaults.issueId,
        "user_id": this.timeSheetDefaults && this.timeSheetDefaults.user_id ? this.timeSheetDefaults.user_id : null,
        "pass": this.timeSheetDefaults.pass ? this.timeSheetDefaults.pass : null,
        "severity": this.timeSheetDefaults.severity ? this.timeSheetDefaults.severity : null,
        "cause": this.timeSheetDefaults.cause ? this.timeSheetDefaults.cause : null,
        "impact": this.timeSheetDefaults.impact ? this.timeSheetDefaults.impact : null,
        // "description":this.timeSheetDefaults.description ? this.timeSheetDefaults.description : null,
        "qa_user_id": this.timeSheetDefaults.qa_user_id ? this.timeSheetDefaults.qa_user_id : null,
      }
    },
    async getUser() {
      let $res = await UserService.findAll()
      this.users = $res && $res.length>0 ? this.getStringfyTheData($res) : []
      this.entryUserData = $res && $res.length>0 ? this.getStringfyTheData($res) : []
      this.qaApprovedUsersData = $res && $res.length>0 ? this.getStringfyTheData($res) : []
      this.$forceUpdate()
      this.formData.userName = $res && $res.length > 0 ? $res[0].name : ''
    },
    async getErrorReportBy(type,$flag) {
      if(!$flag){
        this.formData.error_reported_by = null;
      }
      if (type == 0 || type == "internal") {
        let res = await UserService.findAll();
        this.errorReportUser = res && res.length > 0 ? res : [];
      } else if (type == 1 || type == "external") {
        let res = await ContactService.allContacts();
        this.errorReportUser =
          res && res.length > 0 ? res.filter((contact) => contact.active === 1) : [];
      } else {
        this.errorReportUser = [];
      }
    },
    getStringfyTheData(data){
      return JSON.parse(JSON.stringify(data))
    },
   
    selectErrorRep($event) {
      this.formData.error_reported_date = $event;
    },
   
    selectCorrectiveDate($event) {
      this.formData.correction_completed_date = $event;
    },
 
    async getById(id) {
      let res = await QaService.findOne({ id: id });
      this.QAData = res[0];
      this.formData = this.QAData
      this.formData.doc_id = JSON.parse(this.QAData.doc_id)
      this.attachmentId.push(this.formData.doc_id)
      this.formData.description = this.QAData.description
      this.formData.error_reported_date = this.formData.error_reported_date?moment(this.formData.error_reported_date).format("YYYY-MM-DD"):null
      this.formData.correction_completed_date = this.formData.correction_completed_date?moment(this.formData.correction_completed_date).format('YYYY-MM-DD'):null
      this.selectedErrorCodeId =this.formData && this.formData.error_code_id?JSON.parse(this.formData.error_code_id):[]
      // alert(typeof this.formData.error_reported_by)
      this.formData.error_reported_by = typeof this.formData.error_reported_by == 'string' ? parseInt(this.formData.error_reported_by): this.formData.error_reported_by
      this.setTheData()
      this.getAttachementData()
      this.getErrorReportBy(this.formData.qa_external,true)
    },
 
    async save() {
      let validation = await this.$refs.form.validate()
      let status = this.$store.getters.getQaUpdate;
      this.formData.error_code_id =this.formData && this.selectedErrorCodeId?JSON.stringify(this.selectedErrorCodeId):JSON.stringify([])
      this.causeError = false
      this.descError = false
      this.correctiveActionError = false
      if((this.formData.pass && this.formData.pass ==0 && !this.formData.cause) || (this.formData.pass && this.formData.pass ==0 && !this.formData.description) ){
          if(this.formData.pass && this.formData.pass ==0 && !this.formData.cause ){
            this.causeError = true
          }
          if(this.formData.pass && this.formData.pass ==0 && !this.formData.description ){
            this.descError = true
          }          
          if(this.formData.pass && this.formData.pass ==0 && !this.formData.corrective_action ){
            this.correctiveActionError = true
          }
         
      }
      else if (!validation.valid) {
        console.log(validation.valid)
      }
 
      else {
        if (this.timeSheetDefaults.id || this.formData.id) {
          // alert('old')
          // this.formData.doc_id=
          this.formData.id = this.timeSheetDefaults.id;
          delete this.formData.userName
          await QaService.update(this.formData)
        } else {
          // alert('new')
          await QaService.create(this.formData)
        }
        // alert(status)
          this.$store.commit("QATASK_CHANGE", !status);  
          this.$store.dispatch('setSnackbar', { text: 'Saved Successfully' })    
        if(this.formData && this.formData.pass==0){
          this.$emit('updateData',{id:this.issueData.id?this.issueData.id:this.issueId,stage:'errorlog',final:true},'stage')
          this.$emit("reload", true);
          this.close()
         }
         else{
          let checkStatus = await QaService.getQaForIssue({issueId:this.issueData.id??this.issueId,pass:0})
          if(checkStatus && checkStatus.length==0){            
            this.$emit('updateData',{id:this.issueData.id?this.issueData.id:this.issueId,stage:'closed',final:true},'stage')
          }
          this.$emit("reload", true);
          this.close()
         }
         
      }
    },
    documentsData($event) {
      this.attachmentId.push($event.id)
 
      this.getAttachementData()
    },
    async getAttachementData() {
      try {
        // console.log(this.attachmentId,"uuuuuuuuuuuuuuu")
        let res = await documentService.getAllDocuments({ doc_id: JSON.stringify(this.attachmentId) })
        this.attachmentData = res
        if (res.length == 1) {
          this.document_id = res[0].id
        } else {
          this.document_id = []
          for (let i = 0; i < res.length; i++) {
            this.document_id.push(res[i].id)
          }
        }
        this.formData.doc_id = JSON.stringify(this.document_id)
      }
      catch (e) {
        console.log(e)
      }
    },
 
    downloadDoc(item) {
      if (!this.viewType) {
        documentService.download({ id: item.id }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("name", "downloadLink");
          link.setAttribute("download", response.headers["x-suggested-filename"]); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    changeDescription($event) {
      if($event){
        this.formData.description = $event;
      }
    },
    changeCause($event) {
      if($event){
        this.formData.cause = $event;
      }
    },
    changeCorrective($event) {
      if($event){
        this.formData.corrective_action = $event;
      }
    },
    changeImpact($event) {
      this.formData.impact = $event
    },
    changeSeverty($event) {
      this.formData.severity = $event
    },
    changeErrorType($event) {
      this.formData.error_type = $event
    },
    changeErrorCategory($event) {
      this.formData.error_category = $event
    },
    changeRequestType($event) {
      this.formData.request_type = $event
    },
    close() {
      this.$emit("closeDialog");
    },
    remove(index) {
      if (index > -1) {
        this.attachmentId.splice(index, 1)
      }
    },
  },
}
</script>
   
<style scoped>
>>>.v-chip.v-chip--size-default {
  --v-chip-size: 0.875rem;
  --v-chip-height: 26px;
  font-size: 0.875rem;
  padding: 0 12px;
  background-color: aliceblue;
}
 
>>>.align {
  margin-left: -12px;
}
 
>>>.v-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: -19px;
  padding-bottom: 1px;
}
 
>>>[data-v-b13e3b0c] .v-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: -16px;
  margin-top: -17px;
  padding-bottom: 1px;
}
 
>>>[data-v-b13e3b0c] [data-v-b13e3b0c] .v-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: -16px;
  margin-top: -4px;
  padding-bottom: 1px;
}
</style>