<template>
  <div style="width: 100%;height: 100%;" class="ml-3 ">


    <v-card v-if="errorTrends.length > 0" flat class="pa-0 px-3" style="overflow: auto; height: 100%">

      <div class="cardTitle pt-3">
        Error Trends
      </div>
      <div v-for="(item, index) in errorTrends" :key="index">
        <v-row class="mt-0">
          <v-col col="2" class="text">
            <div><span class="noTime">{{ item.period_type }}</span></div>
          </v-col>
          <v-col cols="8">
            <div class="noIssue">
              <span class="Issue">{{ item.sample }} Error Tasks</span>
              <span class="Issue">{{ item.total_issues }} Tasks</span>
            </div>
            <v-progress-linear v-model="item.sample" :max="item.total_issues" :color="ProgressBarColor(item)" class="bar"
              height="10">
            </v-progress-linear>
          </v-col>
        </v-row>
        <div>
          <v-divider class="mt-2 divider"></v-divider>
        </div>
      </div>
    </v-card>

    <v-card v-else elevation="0" rounded="lg" class="pa-4  mt-4 pt-0"
      style="height: 100%; border-radius: 4px !important;">

      <v-card-text>
        <v-container class="text-center" justify-center>
          <v-icon style="font-size:131px;" class="mt-3">
            <img :src="require('@/assets/no-record.png')" width="100" height="100" />
          </v-icon>
          <br />
          <span class="text-body2 mt-9">
            <b>There are no entries to determine your error trend!</b>
          </span>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
    
<script>
// import IssueService from "@/api/service/Issue/IssueService";
import TimeSheetService from '@/api/service/TimeSheet/TimeSheetService';

export default {
  props: ["userId"],
  components: {
  },

  data() {
    return {
      errorTrends: [],
    };
  },
  watch: {
    userId() {
      this.getTrends();
    }
  },

  created() {
    this.getTrends();
  },

  methods: {

    async getTrends() {
      var res = await TimeSheetService.getErrorTrends({ userId: this.userId });
      this.errorTrends = res;
    },



    ProgressBarColor(totalIssues) {
      const percentage = (totalIssues.sample / totalIssues.total_issues) * 100;
      if (percentage >= 70) {
        return "#ff0000";
      } else if (percentage >= 40) {
        return "#FFA500";
      } else {
        return "#00FF00";
      }
    },
  },
};
</script>
    
<style scoped>
.text {
  display: flex;
  align-items: center;
  padding-bottom: 0px;

}

.bar {
  border-radius: 3px;
}

.noIssue {
  display: flex;
  justify-content: space-between;
}

/* .title{
    font-size: 18px;
    font-weight: 700;
} */
.noTime {
  font-size: 14px;
  font-weight: 400;
}

.Issue {
  font-size: 11px;
  font-weight: 400;
}

.divider {
  border-collapse: separate;
  border-spacing: 10px;
}

.divider {
  border-top: 1px dotted #000000;
  opacity: .5;
}


.cardTitle {
  color: #272727;
  font-size: 18px;
  font-weight: 700;
}
</style>