import ApiServiceAssesments from "../apiServiceAssesments.js";

const querystring = require("querystring");
var QuestionnaireService = {
  findAll: function($data) {
    return new Promise(function(resolve, reject) {
      let $options = {};
      $options.url = "assessmentAnswer";
      $options.method = "get";

      if ($data) {
        $options.url = "assessmentAnswer?" + querystring.stringify($data);
        console.log($options.url, "asss");
      }
      ApiServiceAssesments.request($options)
        .then((response) => {
          console.log("test");
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  findSummary: function($data) {
    return new Promise(function(resolve, reject) {
      let $options = {};
      $options.url = "getSummary";
      $options.method = "get";

      if ($data) {
        $options.url = "getSummary?" + querystring.stringify($data);
        console.log($options.url, "asss");
      }
      ApiServiceAssesments.request($options)
        .then((response) => {
          console.log("test");
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  findOne: function($data) {
    return new Promise(function(resolve, reject) {
      let $options = {};
      $options.url = "assessmentAnswer/" + $data.id;
      console.log($options.url);
      $options.method = "get";
      if (!$data) {
        $options.data = $data;
      }
      ApiServiceAssesments.request($options)
        .then((response) => {
          // console.log('Gpt the data');
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update: function($data) {
    return new Promise(function(resolve, reject) {
      let $options = {};
      $options.data = $data;
      $options.url = "assessmentAnswer/" + $data.id;
      $options.method = "post";
      console.log($options);
      ApiServiceAssesments.request($options)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create: function($data) {
    return new Promise(function(resolve, reject) {
      var $options = {};
      $options.data = $data;
      $options.url = "assessmentAnswer";
      $options.method = "post";
      $options.data = $data
      console.log($options);
      ApiServiceAssesments.request($options)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default QuestionnaireService;
