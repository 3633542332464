<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
        >{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
        <b>Vacation Type</b>
        <v-btn style="float: right" @click="setFalse" elevation="0" density="comfortable" icon="mdi-close-circle"></v-btn>
    
  </v-card-title>
   </v-card-item>

    <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">
        <!-- Input Field -->

        <v-col cols="12" class="px-0 py-0" style="display: grid" v-if="formdata.id">
            <v-radio-group :disabled="showReadOnly" v-model="formdata.active" inline>
              <v-radio color="primary" label="Active" :value="1"></v-radio>
              <v-radio color="error" label="Inactive" :value="0"></v-radio>
            </v-radio-group>
          </v-col>

        <v-col cols="12">
           <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Code
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Code"
            :rules="codeRule"
            v-model="formdata.code"
            :disabled="showReadOnly"            
            @keyup="checkCode()"
            @keydown="prevent($event)"
          />
          <v-error v-if="codeError">Already Exists!</v-error>
        </v-col>

        <!-- Input Field -->

        <v-col cols="12">
           <v-label class="pb-1" style="font-size:14px;color:#272727;font-weight:600">Name
          <span  v-if="!showReadOnly" style="color:#CF0707;padding-left:2px;font-weight:700">*</span></v-label>
          <TextFieldComponent
            density="compact"
            placeholder="Name"
            :rules="nameRule"
            v-model="formdata.name"
            :disabled="showReadOnly"
          />
        </v-col>
          </v-row>
      </v-card-text>
      <v-card-action class="mr-3">

        <!-- Cancle And Save Button -->

        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = setFalse()">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import VacationTypeService from "@/api/service/VacationType/VacationTypeService";

export default {
  props: ["id", "viewType"],
  components: {
    TextFieldComponent,
  },

  data() {
    return {
      showReadOnly: false,
      enumData: [],
      displayNameRule: [(value) => !!value || "Display Name is Required."],
      datavalueRule: [(value) => !!value || "Data Value is Required."],
      dialog: false,
      codeError: false,
      type: null,
      formdata: {},
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.formdata = {};
        this.getById(this.id);
      }
    },
  },

  created() {
    this.getEnumData();
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
  },

  methods: {
    prevent(e) {
        if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
          e.preventDefault();
        }
      },
    checkCode() {
          if(this.formdata.code && this.formdata.code.length>0){
          this.formdata.code = this.formdata.code.toUpperCase();
          VacationTypeService.findAll({ code: this.formdata.code }).then((res) => {
            let $exist = res.filter(key => key.code == this.formdata.code)
            this.codeExistFlag = $exist.length > 0 ? (this.formdata.id && $exist[0].id == this.formdata.id) ? false : true : false;
            if (this.codeExistFlag) {
              this.codeError = true
            }
            else {
              this.codeError = false
            }
          });
        }
    },
    checkDisplayValue(){
      this.formdata.data_value = this.formdata.display_value?this.formdata.display_value.toLowerCase().split(' ').filter(Boolean).join(''):'';
    },
    setFalse() {
      this.$emit("closeDialog");
    },
    async getById(id) {
      let res = await VacationTypeService.findOne(id);
      this.formdata = res[0];
    },
    async getEnumData() {
      let res = await VacationTypeService.findAll();
      this.enumData = res.length > 0 ? res : [];
    },

    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log(validation.valid)
      }
      else {
        if (this.formdata.id) {
          let output = await VacationTypeService.update(this.formdata);
          console.log(output,'output')
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
         
        } else {
          let output = await VacationTypeService.create(this.formdata);
          console.log(output, "gfdgg");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formdata = [];
        }
      }
    },
  },
};
</script>

<style>
</style>