<template>
  <v-card width="100%" style="border-radius: 12px; overflow: auto">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title
          >
          <!-- {{
            viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
          }} -->
          <b>Vacation Approval</b>
          <v-btn
            style="float: right"
            @click="setFalse"
            elevation="0"
            density="comfortable"
            icon="mdi-close-circle"
          ></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <div class="mb-4 ml-2">
             <span>#{{formData.doc_num||''}}</span> <br />
                <div class="d-flex ga-2">
                  <span class="font-weight-medium">{{formData.employee_name ||''}}</span>
                  <v-chip label class="rounded-md h-25 bg-pink-darken-1 text-caption">
                    {{formData && formData.planned==0?'Unplanned':'Planned'}} </v-chip
                  ><br />
                </div>
            <span class="opacity-50 text-caption">{{ formData.role_name || '' }}</span>
        </div>
        <v-card flat class="pa-4 mb-4" style="background-color:#edededcc">
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <span class="font-weight-medium">{{ formData.vacation_type_name||'' }}</span>
            </v-col>
            <v-col cols="12" md="4">
              <span class="font-weight-medium">{{getDateFormate(formData.start_date)}} to {{getDateFormate(formData.end_date)}}</span>
              <br />
              <span class="opacity-50 text-caption">{{ formData.no_of_days }} Days {{ formData.no_of_hours }} Hours</span>
            </v-col>
          </v-row>
        </v-card>
        <v-row no-gutters class="mb-4 ml-2">
          <v-col cols="12" md="4">
            <span class="opacity-50">Blocked Calendar</span> <br />
            <span class="font-weight-medium" :style="{'color':blockedData && blockedData.length>0?'red':'green'}">{{blockedData && blockedData.length>0?'Yes':'No'}}</span>
          </v-col>
          <!-- <v-col cols="12" md="2">
            <span class="opacity-50">Reason</span> <br />
            <span class="font-weight-medium">Fever</span>
          </v-col> -->
          <v-col cols="12" md="2">
            <span class="opacity-50">Balance</span> <br />
            <span class="font-weight-medium">{{vDetails && vDetails.employee_vacation_allotments && vDetails.employee_vacation_allotments.available_qty?vDetails.employee_vacation_allotments.available_qty:''}} Hours</span>
          </v-col>
          <v-col cols="12" md="4">
            <span class="opacity-50">Last availed on</span> <br />
            <span class="font-weight-medium">{{vDetails && vDetails.last_vacation_data && vDetails.last_vacation_data.start_date ?getDateFormate(vDetails.last_vacation_data.start_date,'-'):''}}({{ vDetails && vDetails.last_vacation_data && vDetails.last_vacation_data.no_of_hours?vDetails.last_vacation_data.no_of_hours:'' }} Hours) {{vDetails && vDetails.last_vacation_data && vDetails.last_vacation_data.start_date?moment(vDetails.last_vacation_data.start_date).format('dddd'):''}}</span>
          </v-col>
        </v-row>
        <v-card flat style="background-color:#edededcc" class="pa-6 mb-2">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <span class="font-weight-medium">{{formData.remarks || ''}}</span>
            </v-col>
          </v-row>
        </v-card>
        <span class="font-weight-bold text-subtitle-1">Approval Lines</span>
        <v-row no-gutters class="mt-6" v-for="data of formData.vacation_approval_data" :key="data">
          <v-col cols="12" md="1" class="mt-4">
            <span>
              <CustomAvatar2
                :fName="data.first_name"
                :lName="data.last_name?data.last_name:''"
                :size="'medium'"
              ></CustomAvatar2>
            </span>
          </v-col>
          <v-col>
            <v-card flat style="background-color:#edededcc" class="pa-6 mb-2">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <span class="font-weight-medium">{{ data.mgr_name||'' }}({{ data.role_name||'' }})</span>
                  <br />
                  <span class="opacity-50 text-caption"
                    >{{getDateFormate(data.created)}} {{getRelativeTime(data.created)}}</span
                  >
                  <br />
                  <v-chip
                    label
                    class="rounded-md h-25 text-caption mt-2" :style="{'background-color':data.stage=='pending'?'#ffff0091':data.stage=='rejected'?'#f24545':'#7ef6b6'}"
                  >
                    <span class="title-case pa-4">{{data.stage||''}}</span>
                  </v-chip>
                </v-col>
                <v-col cols="12" md="6" align="end" v-if="data.mgr_id == loggedUser.id">
                  <v-row v-if="data.stage=='pending'">
                    <v-col cols="12" class="pb-1">
                      <v-btn @click="updateStage(data,'approved')"
                          class="save float-right bg-indigo-darken-1"
                        >
                          Approve
                        </v-btn>

                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-btn class="save float-right" @click="updateStage(data,'rejected')" style="background-color: red;color: white;"
                        >Reject</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
import CustomAvatar2 from "@/components/ui/CustomAvatar2.vue";
import EnumService from "@/api/service/Enum/EnumService";
import VacationService from "@/api/service/Vacation/VacationService"
import moment from "moment";
import BlockedCalendarService from "@/api/service/BlockedCalendar/BlockedCalendarService";
import VacationApprovalService from "@/api/service/VacationApproval/VacationApprovalService";

export default {
  props: ["id", "viewType","approvedId","userData"],
  components: {
    // TextFieldComponent,
    CustomAvatar2,
  },

  data() {
    return {
      showReadOnly: false,
      remarkRule: [(value) => !!value || "Domain is Required."],
      endDateRule: [(value) => !!value || "End Date is Required."],
      startDateRule: [(value) => !!value || "Start Date is Required."],
      dialog: false,
      type: null,
      formData: {},
      loggedUser:{},
      blockedData:[],
      vDetails:null
    };
  },

  watch: {
    approvedId() {
      if (this.approvedId) {
        this.formData = {};
        this.getById(this.approvedId);
      }
    },
  },

  created() {
      let user = localStorage.getItem("user_details")
      this.loggedUser = user ? JSON.parse(user):{}
      if (this.approvedId) {
        this.formData = {};
        this.getById(this.approvedId);
      }
  },

  methods: {
    moment,
    setFalse() {
      this.$emit("closeDialog");
    },
    async updateStage(data,value){
      try{        
        let obj = {}
        obj.id = data.id
        obj.stage = value
        await VacationApprovalService.update(obj)
        this.getById(this.approvedId);
      }
      catch(e){
        console.log(e)
      }

    },
    async getById(id) {
      let res = await VacationService.getRequestList({id:id});
      this.formData = res[0];
      this.formData.vacation_approval_data = this.formData.vacation_approval_data?JSON.parse(this.formData.vacation_approval_data):[]
      this.getBlockedData()
      this.getVacationDetails()
      
    },
    async getVacationDetails(){     
      try{
        let obj = {
        userId:this.formData.employee_id,
        vacation_type_id:this.formData.vacation_type_id
      }
        let res = await VacationService.getVacationDetails(obj);
        this.vDetails = res

      } 
      catch(e){
        this.vDetails = null
      }
    },
    async getBlockedData(){
          let obj = {
            start_date:this.formData.start_date,
            end_date:this.formData.end_date,
            userId:JSON.stringify([this.formData.employee_id])
          }
          try{
            let res = await BlockedCalendarService.checkBlockedDate(obj)
              this.blockedData = res && res.length>0?res:[]
          }
          catch(exception){
            console.log(exception)
            this.blockedData = []
          }
      },
    getDateFormate(date,$type){
      if($type =='-'){        
      return moment(date).format('DD-MM-YYYY')
      }
      return moment(date).format('DD/MM/YYYY')
    },
    getRelativeTime(date){
        let $date = moment(date)
        const now = new Date();
        const seconds = Math.floor((now - $date) / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) return `${days} day(s) ago`;
        if (hours > 0) return `${hours} hour(s) ago`;
        if (minutes > 0) return `${minutes} minute(s) ago`;
        return `${seconds} second(s) ago`;
      },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log("Not valid");
      } else {
        if (this.formData.id) {
          let output = await EnumService.update(this.formData);
          console.log(output, "output");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
        } else {
          let output = await EnumService.create(this.formData);
          console.log(output, "gfdgg");
          this.dialog = false;
          this.setFalse();
          this.$emit("reload", true);
          this.formData = [];
        }
      }
    },
  },
};
</script>

<style></style>



<!-- {
  "id": 28,
  "doc_num": null,
  "doc_date": "2024-09-23T09:40:48.000Z",
  "planned": "0",
  "employee_id": "375",
  "start_date": "2024-09-23T18:30:00.000Z",
  "end_date": "2024-09-24T18:30:00.000Z",
  "vacation_type_id": "3",
  "remarks": "Testing",
  "no_of_days": 2,
  "no_of_hours": 16,
  "approved": null,
  "active": 1,
  "created": "2024-09-23T09:40:53.000Z",
  "employee_name": "Vishnu Vardhan",
  "first_name": "Vishnu",
  "last_name": "Vardhan",
  "role_name": "Process Associate",
  "vacation_approval_data": "[{\"id\": 167, \"stage\": \"create-request\", \"active\": 1, \"mgr_id\": \"301\", \"created\": \"2024-09-23 15:10:53.000000\", \"remarks\": null, \"mgr_name\": \"Bala Ganesh\", \"role_name\": \"Operation Manager\", \"vacation_id\": \"28\", \"approval_date\": \"2024-09-23 15:10:49.000000\", \"approver_grade\": \"4\"}, {\"id\": 168, \"stage\": \"create-request\", \"active\": 1, \"mgr_id\": \"284\", \"created\": \"2024-09-23 15:10:53.000000\", \"remarks\": null, \"mgr_name\": \"Jeff Clayton\", \"role_name\": \"Executive Management\", \"vacation_id\": \"28\", \"approval_date\": \"2024-09-23 15:10:49.000000\", \"approver_grade\": \"6\"}, {\"id\": 169, \"stage\": \"create-request\", \"active\": 1, \"mgr_id\": \"301\", \"created\": \"2024-09-23 15:10:53.000000\", \"remarks\": null, \"mgr_name\": \"Bala Ganesh\", \"role_name\": \"Operation Manager\", \"vacation_id\": \"28\", \"approval_date\": \"2024-09-23 15:10:49.000000\", \"approver_grade\": \"6\"}, {\"id\": 170, \"stage\": \"create-request\", \"active\": 1, \"mgr_id\": \"375\", \"created\": \"2024-09-23 15:10:54.000000\", \"remarks\": null, \"mgr_name\": \"Vishnu Vardhan\", \"role_name\": \"Process Associate\", \"vacation_id\": \"28\", \"approval_date\": \"2024-09-23 15:10:49.000000\", \"approver_grade\": \"6\"}]"
} -->