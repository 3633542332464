<template>
  <v-card
    class="mx-auto overflow-auto overflow-x-hidden"
    style="border-radius: 12px"
    width="1000"
    height="98vh"
    title="Question analysis"
  >
    <template v-slot:append>
      <v-btn icon="mdi-close" variant="text" @click="$emit('close')"></v-btn>
    </template>
    <v-card-text>
      <div class="mb-2">
        <strong> Total </strong>
      </div>
      <AnalyticHeader :list="list" :data="analysisData" />
      <div class="mt-2">
        <v-divider></v-divider>
      </div>
      <v-row>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="6">
          <div class="text-center mb-2 mt-2">
            <strong> Incorrect % by Experience </strong>
          </div>
          <AnalyticPercentage :listPercentage="listPercentage" />
        </v-col>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="4" class="mt-16 text-center">
          <div class="mb-2">
            <strong> Time taken for this question (mins) </strong>
          </div>
          <!-- {{ listTime }}
          <AnalyticsTime :listTime="listTime"/> -->
          <v-row>
            <v-col cols="12" md="12">
              <v-avatar
                size="40"
                color="orange-lighten-4 text-body-2 font-weight-medium"
                >{{ analysisData && analysisData.minimum }}</v-avatar
              >
              <strong> Min-time </strong>
            </v-col>
            <v-col cols="12" md="12">
              <v-avatar
                size="40"
                color="orange-lighten-4 text-body-2 font-weight-medium"
                >{{ analysisData && analysisData.average }}</v-avatar
              >
              <strong> Avg-time </strong>
            </v-col>
            <v-col cols="12" md="12">
              <v-avatar
                size="40"
                color="orange-lighten-4 text-body-2 font-weight-medium"
                >{{ analysisData && analysisData.maximum }}</v-avatar
              >
              <strong> Max-time </strong>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider vertical></v-divider>
  </v-card>
</template>
<script>
import QuestionnaireService from "@/api/service/Questionnaire/QuestionnaireService";
import AnalyticHeader from "./analyticHeader.vue";
import AnalyticPercentage from "./analyticPercentage.vue";
// import AnalyticsTime from "./analyticsTime.vue";

export default {
  props: ["id"],
  components: {
    AnalyticHeader,
    AnalyticPercentage,
    // AnalyticsTime,
  },
  data: () => ({
    analysisData:null,
    list: [
      { id: 1, title: "Attendees" },
      { id: 2, title: "Correct" },
      { id: 3, title: "Incorrect" },
      { id: 4, title: "Skipped" },
    ],
    listPercentage: [],
    listTime: [
      { id: 1, title: "Min-time" },
      { id: 2, title: "Avg-time" },
      { id: 3, title: "Max-time" },
    ],
  }),
  created() {
    if (this.id) {
      this.getAnalysisData();
    }
  },
  methods: {
    async getAnalysisData() {
      let res = await QuestionnaireService.findAnalyzingQues({ id: this.id });
      if (res) {
        this.list.forEach((element) => {
          element.data =
            element.title == "Attendees"
              ? res.total_questions
              : element.title == "Correct"
              ? res.correct_answer
              : element.title == "Incorrect"
              ? res.incorrect_answer
              : element.title == "Skipped"
              ? res.total_skipped
              : "";
        });
      }
      // this.listTime.forEach(($value) => {
      //     $value.data == "Min-time"
      //       ? res.minimum
      //       : $value.data == "Avg-time"
      //       ? res.average
      //       : $value.data == "Max-time"
      //       ? res.maximum
      //       : "";
      //   });
      let result = res && res.yearOfPercent;
      this.listPercentage = [
        {
          months: `0 to 3`,
          count: result.count_3month,
          percent: result.zerotothree,
        },
        {
          months: `4 to 6`,
          count: result.count_6month,
          percent: result.fourtosix,
        },
        {
          months: `7 to 9`,
          count: result.count_9month,
          percent: result.seventonine,
        },
        {
          months: `10 to 12`,
          count: result.count_12month,
          percent: result.tentotwelve,
        },
        {
          months: `13 to 18`,
          count: result.count_18month,
          percent: result.thirteentoeighteen,
        },
        {
          months: `19 to 24`,
          count: result.count_24month,
          percent: result.nineteentotwentyfour,
        },
        {
          months: `25 to 48`,
          count: result.count_48month,
          percent: result.twentyfivetofourtyeight,
        },
        {
          months: `More than 49`,
          count: result.count_49moremonth,
          percent: result.morethanfourtynine,
        },
        {
          months: `Experience not specified`,
          count: result.count_not_experience,
          percent: result.not_experience,
        },
      ];
      this.analysisData = res;
    },
  },
};
</script>
