
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import ProjectService from "@/api/service/Project/ProjectService";
import AccountService from "@/api/service/Account/AccountService";
class DetailService{

    async getWorkSpaceData(){
        try{
            let res = await WorkSpaceService.findAll()
            return res && res.length>0?res:[]
        }
        catch(e){          
            console.log(e)
            return []  
        }
    }
    async getAccountData(){
        try{
            let res = await AccountService.findAll()
            return res && res.length>0?res:[]
        }
        catch(e){          
            console.log(e)
            return []  
        }      
    }
    async getProjectData(){
        try{
            let res = await ProjectService.findAll()
            return res && res.length>0?res:[]
        }
        catch(e){          
            console.log(e)
            return []  
        }     
    }
    async formateWorkspace(data,selectedData){
        for(let item of selectedData){
            let index = data.findIndex(k=>k.id==item.workspace)
            if(index>-1){
                data.splice(index,1)
            }
            
        }
        return data

    }    

}
export default new DetailService();