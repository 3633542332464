<template>
  <v-text-field
    class="placeholder-color"
    @keydown.space="preventLeadingSpace"
    v-focus
    density="compact"
    @keydown="handleKeydown"
    :disabled="showReadOnly"
    color="#000000"
    variant="outlined"
  >
    <template v-slot:loader v-if="loader">
      <v-progress-linear
        active="loader"
        :model-value="progress"
        :color="color"
        height="7"
        indeterminate
      ></v-progress-linear>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: ["showReadOnly", "loader"],

  name: "textComponet",
  computed: {
    progress() {
      return 100;
    },
    color() {
      return ["error", "warning", "success"][Math.floor(this.progress / 40)];
    },
  },
  methods: {
    //     handleKeydown(event) {
    //   if (event.key === ' ') {
    //     event.preventDefault();
    //   }
    // }
    preventLeadingSpace(e) {
      e.target.value = e.target.value.trim();
    },
    trimSpace() {
      //   this.category.code = this.category.code.trim()
    },
  },
};
</script>
<style scoped>
>>> .v-field__field .v-field__input {
  cursor: text;
  background-color: #ececec !important;
  font-size: 14px;
  color: #777777;
  border-radius: 8px !important;
}

>>> .v-field__field .v-field__input:focus-within {
  cursor: text;
  background-color: #fff !important;
  font-size: 14px;
  color: #000;
  border-radius: 8px !important;
}

>>> .v-field__field .v-field__input::placeholder {
  font-size: 13px;
}

>>> .v-field--focused .v-field__input {
  background-color: #ffffff;
}
>>> .v-field__input input:placeholder-shown {
  color: #000000 !important;
  font-size: 12px;
}

>>> .v-field__outline {
  --v-field-border-width: 0px !important;
  border: 10px !important;
  --v-field-border-opacity: 0.38;
  align-items: stretch;
  contain: layout;
  display: flex;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 100%;
  border: 0px solid #d7d6d6 !important;
  border-radius: 8px !important;
}

>>> .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 0px !important;
  border: 0px solid #3c5aaa !important;
  box-shadow: 0 0 1px 2px #3c5aaa !important;
}

>>> .v-messages__message {
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-left: 0px;
  transition-duration: 150ms;
  text-align: left;
}

>>> .v-field.v-field--appended {
  cursor: text;
  background-color: #ececec !important;
  font-size: 14px;
  color: #777777;
  border-radius: 8px !important;
}

>>> .v-field.v-field--appended:focus-within {
  cursor: text;
  background-color: #ffffff !important;
  font-size: 14px;
  color: #777777;
  border-radius: 8px !important;
}
>>> .v-input__details {
  padding-inline-start: 2px !important;
}
>>> .v-field--disabled {
  pointer-events: none;
  opacity: 0.8 !important;
}
</style>
