<template>
  <v-row>
    <v-col cols="12" v-for="(item, index) in listPercentage" :key="index">
      <div class="d-flex justify-space-between">
        <span class="text-body-2 font-weight-medium"
          >{{ item.months }} months</span
        >
        <span class="text-body-2 font-weight-medium"
          >Participants - {{ item.count }}</span
        >
      </div>
      <v-progress-linear
        v-model="item.percent"
        :color="item.percent > 0 ? 'cyan lighten-5' : 'blue-grey'"
        height="25"
      >
        <template v-slot:default="{ value }">
          <strong>{{ value }}%</strong>
        </template>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["listPercentage"],
};
</script>
