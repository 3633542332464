

<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
      <v-card-item>
        <v-card-title>{{
          viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add"
        }}
          <b> Exception Rule</b>
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" class="x-closeIcon"></v-btn>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-row no-gutters class="mt-3 pa-2">

          <v-col cols="12" class="px-0 py-0" style="display: grid" v-if="formData.id">
            <v-radio-group :disabled="showReadOnly" v-model="formData.active" inline>
                  <v-radio color="primary" label="Active" :value="1"></v-radio>
                  <v-radio color="error" label="In Active" :value="0"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Work Space
                </v-label>
                <AutoComplete :items="workSpaceData" @update:modelValue="workSpaceChanged" item-value="id" item-title="name" density="compact"
                  placeholder="Work Space"  v-model="formData.work_space_id" :disabled="showReadOnly" />
                  <v-error v-if="codeError">Already Exists!</v-error>
          </v-col>

          <v-col cols="6" class="pl-4">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Project
                <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label>
                <AutoComplete :items="projectData" item-value="id" item-title="name" density="compact"
                  placeholder="Project" :rules="projectRule" v-model="formData.project_id" :disabled="showReadOnly" />
             
          </v-col>
          <v-col cols="12" class="dropdown">
            <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Accounts
                  <span v-if="!showReadOnly" style="color: #cf0707; padding-left: 2px; font-weight: 700"></span></v-label>

                <AutoComplete :items="accountData" item-value="id" item-title="name" density="compact"
                  placeholder="Select Accounts" :rules="accountRule" v-model="formData.account_id"
                  @update:modelValue="accountChanged" :disabled="showReadOnly" />
                <v-chip class="selectedChip" closable label v-for="item in selectedAccounts" :key="item" :close="false"
                  :disabled="showReadOnly" @click:close="cancelAccounts(item)">
                  {{ item.name }}
                </v-chip>
          </v-col>

          <v-col cols="7" class="dropdown">
                <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Task
                </v-label>
                <AutoComplete :items="taskData" item-value="id" item-title="name" density="compact"
                  placeholder="Task"  v-model="formData.task_id" :disabled="showReadOnly" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="mr-3">
        <v-row justify="end" class="dialogBottom" v-if="!showReadOnly">
          <v-btn class="mr-2 cancel" @click="dialog = close()">Cancel</v-btn>
          <v-btn class="save" :loading="saveClicked" type="submit">Save</v-btn>
        </v-row>
      </v-card-action>
    </v-form>
  </v-card>
</template>

<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import commonService from "@/common.service";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
// import AccountService from "@/api/service/Account/AccountService";
// import ProjectService from "@/api/service/Project/ProjectService";
import TaskService from "@/api/service/Task/TaskService";
// import CategoryService from "@/api/service/Category/CategoryService";
// import SubCategoryService from "@/api/service/SubCategory/SubCategoryService";
import ExceptionService from "@/api/service/Exception/exceptionService";


export default {
  props: ["id", "viewType","subCategoryId","categoryId"],
  components: {        
     AutoComplete, 
  },

  data() {
    return {
      showReadOnly: false,
      dialog: false,
      formData: {},
      codeError:false,
      workSpaceData: [],
      accountData: [],
      projectData: [],
      taskData: [],
      backupAccounts:[],
      selectedAccounts:[],
      selectedAccountId: [],
      categoryData: [],
      subCategoryData: [],
      account_idError: false,
    };
  },

  watch: {
    id() {
      if (this.id) {
        this.getById(this.id);
      }
    },
  },

  created() {    
    this.getWorkSpaceData();
    this.getTaskData();
    // this.getCategoryData();
    // this.getSubCategoryData();
    if (this.id) {
      this.getById(this.id);
    }
    this.showReadOnly = this.viewType === "view";
  },

  methods: {

    async getById(id) {
      try {
        let res = await ExceptionService.findOne(id);
        this.formData = res && res.length > 0 ? res[0] : {};
        this.workSpaceChanged(this.formData.work_space_id,'edit')
      } catch (e) {
        console.log(e);
        this.formData = {};
      }
    },
    cancelAccounts(data) {
      let index = this.selectedAccountId.findIndex((k) => k == data.id);
      if (index > -1) {
        this.selectedAccountId.splice(index, 1);
      }
      this.setDatas();
    },

    accountChanged($value) {
      this.selectedAccountId.push($value);
      this.setDatas();
    },
    setDatas() {
      this.formData.account_id = null;
      let chipRes = commonService.getMultipleChip(
        this.backupAccounts,
        this.selectedAccountId,
        "id"
      );
      this.accountData = commonService.getObjectSliceMethod(
        JSON.stringify(this.backupAccounts),
        this.selectedAccountId,
        "id"
      );
      this.selectedAccounts = chipRes;
      this.accountData = [...this.accountData];
    },

    async getWorkSpaceData() {
      try {
        const res = await WorkSpaceService.findAll({active:1});
        this.workSpaceData = res && res.length > 0 ?await commonService.getSort(res,'','asc') : [];
      } catch (e) {
        console.log(e);
        this.workSpaceData = [];
      }
    },
    
    async workSpaceChanged(value,type) {
      /*getAccountData*/
      this.getDublicateData(value)
      await this.getAccounts(value,type)
      await this.getProjects(value)
    },
    getDublicateData(value){
        if(value){
            ExceptionService.findAll({ sub_category_id: this.subCategoryId,work_space_id:value}).then((res) => {
              let $exist = res
              let codeExistFlag = $exist.length > 0 ? (this.formData.id && $exist[0].id == this.formData.id) ? false : true : false;
              if (codeExistFlag) {
                this.codeError = true
              }
              else {
                this.codeError = false
              }
            });
          }
    },
    async getAccounts(value,type) {
      try{
        let $options = await WorkSpaceService.getWSAccount({ workSpaceId: value })
        this.accountData = $options && $options.length > 0 ? await commonService.getSort($options, 'name', 'asc') : [];
        this.backupAccounts = JSON.parse(JSON.stringify(this.accountData));
        if(type){         
          this.selectedAccountId = this.formData.account_id?JSON.parse(this.formData.account_id ):[]
          this.setDatas()
        }  
      }
      catch(e){
        console.log(e,"helloooooo")
      }
    },
    async getProjects(value) {
        WorkSpaceService.getWSProject({ workSpaceId: value }).then(async ($options) => {
        this.projectData = $options && $options.length > 0 ? await commonService.getSort($options, 'name', 'asc') : [];
      });
    },
    async getTaskData() {
      try {
        const res = await TaskService.findAll({active:1});
        this.taskData = res && res.length > 0 ?await commonService.getSort(res,'name','asc') : [];
      } catch (e) {
        console.log(e);
        this.taskData = [];
      }
    },
    // async getCategoryData() {
    //   try {
    //     const res = await CategoryService.findAll();
    //     this.categoryData = res && res.length > 0 ?await commonService.getSort(res,'name','asc') : [];
    //   } catch (e) {
    //     console.log(e);
    //     this.categoryData = [];
    //   }
    // },
    // async getSubCategoryData() {
    //   try {
    //     const res = await SubCategoryService.findAll();
    //     this.subCategoryData = res && res.length > 0 ?await commonService.getSort(res,'','asc') : [];
    //   } catch (e) {
    //     console.log(e);
    //     this.subCategoryData = [];
    //   }
    // },
    
    close() {
      this.$emit("closeDialog");
    },


    async save() {
      const validation = await this.$refs.form.validate();
      if (!validation || this.codeError) {
        console.log("Not valid");
        return;
      }
      else{
            try {
            this.formData.category_id = this.categoryId
            this.formData.sub_category_id = this.subCategoryId
            this.formData.account_id = JSON.stringify(this.selectedAccountId?this.selectedAccountId:[])
            if (this.formData.id) {
              await ExceptionService.update(this.formData);
              this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
            } else {
              await ExceptionService.create(this.formData);
              this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            }
            this.dialog = false;
            this.close();
            this.$emit("reload", true);
            this.formData = {};
          } catch (e) {
            console.log(e);
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }

      }

      
    },
  },
};
</script>

<style scoped>
</style>