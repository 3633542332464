<template>
  <v-container class="d-flex justify-center">
    <v-card class="mt-4 pa-4 cardBg1" width="750">
      <v-card-title>
        <div><span>Results</span></div>
        <div>
          <span class="text-body-1 font-weight-regular	">Dear</span>
          <span class="text-body-1 font-weight-medium ms-1"
            >VELAYUTHAM SANKARAN</span
          >
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <span class="text-body-1 font-weight-bold">Congratulations</span> on Your Successful Interview! We are
          thrilled to inform you that you have successfully passed your
          interview. Welcome aboard!
        </div>
        <div>Your test results are <span class="text-body-1 font-weight-bold" color="#EE117B">35%</span></div>
        <span class="text-button font-weight-bold	">Summary</span>
        <v-row v-for="item of cardTitle" :key="item">
          <v-col>
            {{ item.heading }}
          </v-col>
          <v-col>
            {{ item.level1 }}
          </v-col>
          <v-col>
            {{ item.level2 }}
          </v-col>
          <v-col>
            {{ item.level3 }}
          </v-col>
          <v-col>
            {{ item.level4 }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-action class="d-flex justify-center">
        <v-btn
          class="text-none text-button"
          color="#3C5AAA"
          width="120"
          variant="flat"
          text="Ok"
          @click="resultData"
        ></v-btn>
      </v-card-action>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["finaldata"],
  data() {
    return {
      assesmentData: {},
      cardTitle: [
        {
          heading: "Difficulty",
          level1: "No.Of.Questions",
          level2: "Correct",
          level3: "Incorrect",
          level4: "Skipped",
        },
        {
          heading: this.finaldata.category,
          level1: this.finaldata.no_of_question,
          level2: "5",
          level3: "4",
          level4: "1",
        },
        // {
        //   heading: "Moderate",
        //   level1: "10",
        //   level2: "5",
        //   level3: "4",
        //   level4: "1",
        // },
        // {
        //   heading: "Advanced",
        //   level1: "10",
        //   level2: "5",
        //   level3: "4",
        //   level4: "1",
        // },
        // {
        //   head: "card",
        //   title: [
        //     "Difficulty",
        //     "No.Of.Questions",
        //     "Correct",
        //     "Incorrect",
        //     "Skipped",
        //   ],
        // },
        // {
        //   head: "Easy",
        //   title: ["Easy", 10, 5, 4, 1],
        // },
        // {
        //   head: "Moderate",
        //   title: ["Moderate", 10, 5, 4, 1],
        // },
        // {
        //   head: "Advanced",
        //   title: ["Advanced", 10, 5, 4, 1],
        // },
      ],
    };
  },
  methods: {
    resultData(){
      this.$emit('result',true)
    }
  },
};
</script>
<style setup>
.cardBg1 {
  background: url("../../assets/sad-face-svgrepo-com 1.svg") no-repeat right #ffff;
  background-size: 280px 280px;
}
</style>
