<template>
  <div>
    <img src="https://synergy-snaps.s3.ap-south-1.amazonaws.com/icons/uploadIcon.svg" v-if="type=='img' && !command" @click="triggerFileInput"
      width="250" class="mt-9" />
      <v-icon class="pl-6" size=20px color="primary" @click="triggerFileInput"  v-if="type!='img' && type!='imgage' && !command">mdi-plus-circle</v-icon>
      <v-icon class="pl-6" size=20px color="primary" @click="triggerFileInput"  v-if="type=='imgage' && !command">mdi-camera-plus</v-icon>

    <!-- <addicon @click="triggerFileInput"  v-if="type!='img'" ></addicon> -->
    <input type="file" ref="fileInput" accept=".jpg,.jpeg,.png,.pdf.csv,.pdf,.xlsx,.doc,audio/*,video/*" @change="uploadNew" style="display:none">
    
    <v-icon v-if="command" @click="triggerFileInput" style="cursor: pointer;"   class="pt-3">mdi-attachment</v-icon>
    <!-- <button @click="triggerFileInput">Add document</button> -->
  </div>
</template>

<script>
// import addicon from '../components/IconAdd.vue';
import documentService from "@/api/service/Document/DocumentService"
export default {
  props:['type','keyType',"keyId","command",'fileForUpload' , 'inputType','saveType'],
  components: {
    // addicon
  },
  data() {
    return {
      file: {},
      //  emits:[]

    }
  },

  watch:{
    fileForUpload(){
      this.uploadNew(this.fileForUpload , this.inputType)
    }
  },
  methods: {
 
    dragEnter(event) {
      event.preventDefault();
      // Add any styles or visual indicators to indicate the drag enter event
    },
    dragOver(event) {
      event.preventDefault();
      // Add any styles or visual indicators to indicate the drag over event
    },
    dragLeave(event) {
      event.preventDefault();
      // Remove any styles or visual indicators when leaving the drop area
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
  async uploadNew(event,type){
      let file;
      if(type === 'drop'){
         file = event;
      }else{
         file = event.target.files;
      }
      let $files = [...file]
      console.log($files,'after the map')
      $files.forEach(async item=>{
        let data=[];
        data.push(item);
        await this.handleFileUpload(data)
      })
    },
    async handleFileUpload(file) {
      for(let $i = 0; $i < file.length; $i++) {
        if (file[$i] !== undefined) {
          var $file = file[$i]
          this.file.name = file[$i].name
          const fr = new FileReader()
          fr.readAsDataURL(file[$i])
          fr.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
              let progress = (event.loaded / event.total) * 100;
              console.log(event.lengthComputable,"11111",progress)
            }
          });
          fr.addEventListener('load', async (event) => {
            if (event.lengthComputable) {
              let progress1 = (event.loaded / event.total) * 100;
              console.log(progress1,"00000")
            }
            $file.url = fr.result
            $file.imagefile = file[$i]
            var $json = {
              name: $file.name,
              doc_name: $file.name,
              file_name: $file.name,
              key_type: this.keyType ? this.keyType : "uploadFiles" ,
              key_id: this.keyId ? this.keyId : 1,
              file_type: $file.imagefile.type,
              active: 1,
              data: $file.url,
              profile:this.profile?this.profile:false
            }
            let res = await documentService.upload($json)
            this.$emit("documentsData", res.data)
          }
          )
        }
      }
    },
  },
}


</script>

<style scoped>
/* .drop-area{
  width: 100%;
  border: 1px solid black;
} */
</style>