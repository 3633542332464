<template>
   <v-autocomplete
          density="compact"
          variant="outlined"
          single-line
        >
          <template v-slot:chip="{ props, item }">
            <div v-bind="props" class="d-flex justify-space-between" v-if="data && data.length > 0">
              <div>
                <span class="text-caption"> {{ getKeyData(item.raw,data[0].code) }} </span> <br/>
                <span class=" text-caption font-weight-medium"> {{ getKeyData(item.raw,data[1].name) }} </span>
              </div>
              <div>
                <span class="text-body-2" v-if=" data && data[2].date"> {{ getKeyData(item.raw,data[2].date)  }} </span> <br/>
                <div class="text-caption font-weight-medium text-green text-right" v-if=" data && data[3].percent">  {{ getKeyData(item.raw,data[3].percent) }} &percnt; </div>
              </div>     
            </div>
          </template>

          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <div class="d-flex justify-space-between">
                <span class="font-weight-medium">{{ getKeyData(item.raw,data[1].name) }}</span><br />
                <div class="mt-n6">
                  <span class="text-body-2">{{ getKeyData(item.raw,data[2].date) }}</span
                  >
                  <div class="text-green text-right">
                    {{ getKeyData(item.raw,data[3].percent) }} &percnt;
                  </div>
                </div>
              </div>
              <div style="border: 1px dashed #000000"></div>
            </v-list-item>
          </template>
        </v-autocomplete>
</template>
<script>
export default {
  props:['data'],
    data() {
    return {
    };
  },
  methods: {
    getKeyData(obj,key){
      return obj[key] || ''
    }
  },
}
</script>
<style scoped>
>>>.v-field__outline {
    --v-field-border-width: 0px !important;
    border: 10px !important;
    --v-field-border-opacity: 0.38;
    align-items: stretch;
    contain: layout;
    display: flex;
}

>>>.v-field {
    cursor: text;
    background: #ECECEC !important;
    font-size: 14px;
    color: #777777;
    border-radius: 8px;
}

>>>.v-field:focus-within {
    cursor: text;
    background-color: #ffffff !important;
    border: 2px solid #3C5AAA !important;
    border-radius: 8px;
}

>>>.v-field--disabled {
    pointer-events: none;
    opacity: 0.8 !important;
}
>>>.v-autocomplete__selection {
    display: block !important;
}
>>>.v-field__input {
    display: block !important;
}
</style> 