<template>
  <PrimeDataTable
    :title="'Employee Vacation Allotments'"
    :showFilter="true"
    :searchOption="true"
    :selectAll="true"
    :showDownload="true"
    :showUpload="true"
    :showAdd="false"
    :columnData="columns"
    :data="data"
    @addDiolog="addDiolog()"
    @rowAction="rowActions($event)"
    activity="EmployeeVacationAllotment"
    @uploadData="uploadData"
  />
  <v-dialog persistent v-model="dialog" width="600px">
  <AddEmployeeVacationAllotment
  @closeDialog="setFalse()"
  :viewType="viewType"
  />
</v-dialog>
</template>

<script>
import EmployeeVacationAllotmentService from "@/api/service/EmployeeVacationAllotment/EmployeeVacationAllotmentService";
import PrimeDataTable from "@/components/PrimeDataTable";
import commonService from "@/common.service";
import AddEmployeeVacationAllotment from "./AddEmployeeVacationAllotment.vue";
export default {
  components: {
    PrimeDataTable,
    AddEmployeeVacationAllotment
  },
  props: [],
  data() {
    return {
      data: [],
      columns: [],
      date: new Date(),
      blockedCalendarId: "",
      dialog: false,
      viewType: false,
    };
  },
  created() {
    this.loadColumns();
    this.download = true;
    this.search = true;
    this.checkbox = true;
    this.getEmployeeVacationAllotmentData();
  },
  methods: {
    loadColumns() {
        //       {
        //   "id": 4,
        //   "vacation_template_id": "11",
        //   "vt_name": "Indian Leave Format",
        //   "vacation_template_detail_id": "9",
        //   "fy": "2024",
        //   "active": 1,
        //   "allocated_qty": 500,
        //   "used_qty": 40,
        //   "available_qty": 460,
        //   "uom": null,
        //   "employee_id": 301,
        //   "employee_name": "Bala Ganesh"
        // }
      this.columns = [
      {
          field: "employee_name",
          header: "Employee Name",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },
        {
          field: "vacation_type_name",
          header: "Vacation Type",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        {
          field: "vt_name",
          header: "Vacation Template",
          style: { width: "100px", color: "" },
          toolTip: "Code",
          sortfield: true,
        },
        
        {
          field: "allocated_qty",
          header: "Allocated Qty (this year)",
          style: { width: "100px", color: "" },
          toolTip: "Name",
          sortfield: true,
        },

        {
          field: "available_qty",
          header: "Available Qty",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },

        {
          field: "used_qty",
          header: "Used Qty (this year)",
          style: { width: "100px", color: "" },
          toolTip: "Modified",
          sortfield: true,
        },
      ];
    },

    setFalse() {
      this.dialog = false;
    },
    async uploadData($event) {      
      let res = await EmployeeVacationAllotmentService.uploadCsv({
        data: $event,
        table: "upload_employee_vacation_allotments",
      });
      if (res.length > 0) {
        this.uploadError = res[0].upload_data_results.message;
      }
      console.log(res[0].upload_data_results.message, "ressssssssssssss");
      this.uploadKey++;
    },
    rowActions($event) {
      if ($event.type == "view") {
        this.dialog = true;
        this.blockedCalendarId = $event.data.id;
        this.viewType = $event.type;
      }
      if ($event.type == "edit") {
        this.dialog = true;
        this.blockedCalendarId = $event.data.id;
        this.viewType = $event.type;
      }

      if ($event.type == "select") {
        this.dialog = true;
        this.blockedCalendarId = $event.data.id;
        this.viewType = "view";
      }
    },

    async getEmployeeVacationAllotmentData() {
      let res = await EmployeeVacationAllotmentService.findAllData();
      this.data = res;
      console.log(this.data, "dataaaaaaaaa");
    },
    addDiolog() {
      this.dialog = true;
      this.blockedCalendarId = null;
      this.viewType = false;
    },
  },

  addFilter() {
    this.$refs.FilterDrawer.dialog = true;
    this.$refs.FilterDrawer.size = commonService.largeDialog();
  },
};
</script>

<style>
</style>