<template>
	<v-card flat>
	<v-card-item>
		<v-form @submit.prevent="AddWorkSpace" ref="form">
			<v-row dense>
				<v-col cols="12" >
					<h3>Add Work Space</h3>
					</v-col>
					<v-col cols="3" >
						<v-label>
							Work Space
						</v-label>
						<AutoComplete
							:items="tempWorkspaceData"
							item-title="name"
							item-value="id"
							:rules="workspaceRule"
							v-model="modelData.workspace"
							placeholder="Select Work Space"
							@update:modelValue="selectWorkSpace"
						/>
					</v-col>
					<v-col cols="4">
						<v-label>
							Account
						</v-label>
						<AutoComplete
							:items="tempAccountData"
							item-title="name"
							item-value="id"
							:rules="accountRule"
							v-model="modelData.account"
							placeholder="Select Account"
							@update:modelValue="selectAccount"
							multiple="true"
						/>
					</v-col>
					<v-col cols="4" >
						<v-label>
							Project
						</v-label>
						<AutoComplete
							:items="tempProjectData"
							item-title="name"
							item-value="id"
							:rules="projectRule"
							v-model="modelData.project"
							placeholder="Select project"
							@update:modelValue="selectProject"
							multiple="true"

						/>
					</v-col>

					<v-col cols="1" >
						<v-btn  type="submit" variant="flat" style="color: #ffff;" class=" add-btn" block>Add </v-btn>
					</v-col>
			</v-row>		
		</v-form>
		<v-row class="header mt-8">
			<v-col cols="2">
				<span><strong>Work Space</strong></span>
			</v-col>
			<v-col cols="5"> 
				<span><strong>Account</strong></span>
			</v-col>
			<v-col cols="4"> 
				<span><strong>Project</strong></span>
			</v-col>			
			<v-col cols="1"> 
			</v-col>
		</v-row>
		<v-row class="pa-4 d-flex flex-row" >
			<v-col cols="12" v-for="(item,index) of data" :key="item" >
				<v-row>

					<v-col cols="2" v-if="item.workspace && item.workspace>0">
					<div v-for="key of getFilterData(item.workspace,'workspace')" :key="key" style="width:100%">
						<v-chip size="x-small" color="primary" variant="tonal">
							{{key.name}}
						</v-chip>
					</div>
				</v-col>
				<v-col cols="5">
					<div v-for="key of getFilterData(item.account,'account')" :key="key" style="width:100%">
						<v-chip size="x-small" color="primary" variant="tonal">
							{{key.name}}
						</v-chip>
					</div>
				</v-col>
				<v-col cols="4">
					<div v-for="item of getFilterData(item.project,'project')" :key="item" style="width:100%">
						<v-chip size="x-small" color="primary" variant="tonal">
							{{item.name}}
						</v-chip>
					</div> 
				</v-col>
				<v-col cols="1">
					<i style="cursor: pointer" @click="editTheRecord(index)" class="pi pi-file-edit" />
				</v-col>
				<v-col cols="12" >
					<v-divider></v-divider>
				</v-col>
				</v-row> 
			</v-col>
		</v-row>

	</v-card-item>
	</v-card>
</template>

<script>
import AutoComplete from "@/components/ui/AutoComplete.vue";
import WorkSpaceService from "@/api/service/WorkSpace/WorkSpaceService";
import DetailSevices from './DetailService'

export default {
	props:[
		"endPoint",
		"issueData",
		"jobId"
	],
	components:{
		AutoComplete
	},
	watch:{
		data(){
			if(this.data && this.data.length>0){					
				this.getTempWorkSpaceData()
			}
		}
	},
	data() {
		return {
			data:[],
			modelData:{
				workspace:null,
				account:null,
				project:null
			}, 
			backupModelData:{
				workspace:null,
				account:null,
				project:null
			},
			workspaceRule: [(value) => !!value || "Work Space is Required."],
			accountRule: [(value) => !!value || "Account is Required."],
			projectRule: [(value) => !!value || "Project is Required."],
			accountData:[],
			workspaceData:[],
			projectData:[],
			backupAccountData:[],
			backupWorkspaceData:[],
			backupProjectData:[],
			tempAccountData:[],
			tempProjectData:[],
			tempWorkspaceData:[]
		};
	},
	async created() {		
		this.workspaceData = await DetailSevices.getWorkSpaceData()
		this.backupWorkspaceData = JSON.parse(JSON.stringify(this.workspaceData))
		this.accountData = await DetailSevices.getAccountData()
		this.backupAccountData = JSON.parse(JSON.stringify(this.accountData))
		this.projectData = await DetailSevices.getProjectData()
		this.backupProjectData = JSON.parse(JSON.stringify(this.projectData))
		if(!this.jobId && this.issueData){
			this.modelData.workspace = this.issueData.work_space_id
			this.modelData.project=[this.issueData.project_id]
			this.modelData.account=[this.issueData.account_id]
			this.data.push(this.modelData)
			this.modelData = JSON.parse(JSON.stringify(this.backupModelData))
		}
		// console.log(this.data,"yyyeesss")		
		this.getTempWorkSpaceData()
	},
	methods:{

		async AddWorkSpace(){
			let validation = await this.$refs.form.validate()
			if (!validation.valid) {
				console.log("Not valid")
			}
			else{
					let index = this.data.findIndex(k=>k.workspace==this.modelData.workspace)
					if(index>-1){
						this.data[index] = this.modelData
					}
					else{
						this.data.push(this.modelData)
					}
					this.modelData = JSON.parse(JSON.stringify(this.backupModelData))
					// console.log(this.data,"uuuuuuuuuuuueesss")			
					this.getTempWorkSpaceData()			
					this.modelData.project = []
					this.modelData.account = []
			}
		
		},
		async getTempWorkSpaceData(){
			this.tempWorkspaceData = await DetailSevices.formateWorkspace(this.getSafeData(this.workspaceData),this.data)
		},
		selectWorkSpace(value){
			this.modelData.project = []
			this.modelData.account = []
			this.getAccountData(value)
			this.getProjectData(value)
		},
		async getProjectData(value){
			let res = await WorkSpaceService.getProjects({ workSpaceId: value })
			this.tempProjectData = res && res.projects && res.projects.length>0?res.projects:[]

		},
		
		async getAccountData(value){
			let res = await WorkSpaceService.getAccounts({ workSpaceId: value })
			this.tempAccountData = res && res.accounts && res.accounts.length>0?res.accounts:[]
		},
		getSafeData(data){
			return JSON.parse(JSON.stringify(data))
		},
		selectAccount(){

		},
		selectProject(){

		},
		getFilterData(value,type){
			let data =type == 'workspace'?this.backupWorkspaceData:type == 'account'?this.backupAccountData:this.backupProjectData
			// console.log(data)
			let $data =[]
			if(type == 'workspace'){
					for(let item of data){
						if(value == item.id){
							$data.push(item)
						}
					}
					return $data
			}
			else{				
					for(let item of data){
						if(value.includes(item.id)){
							$data.push(item)
						}
					}
					return $data
			}
		},
		editTheRecord(index){
			this.modelData = this.data[index]
			this.tempWorkspaceData = this.backupWorkspaceData.filter(k=>k.id==this.modelData.workspace)	
			this.getAccountData(this.modelData.workspace)
			this.getProjectData(this.modelData.workspace)
		}
	}
};
</script>
<style>
>>>.header{
	background-color: #d7edf2;
	padding-left: 15px;
	font-size:15px
}
>>>.add-btn{
	margin-top: 30%;
    background-color: #3c5aaa;
    color: #ffff;
}

</style>
