<template>
  <v-card class="pa-4 mx-auto" flat>
    <v-card-title>
      <v-row>
        <v-col cols="12" md="5" sm="6" lg="4">
          <h4 class="font-weight-medium">Profile Details</h4>
        </v-col>
        <v-col cols="12" md="4" sm="4" lg="4" class="d-flex justify-end">
          <v-radio-group v-model="profileSelection" inline>
            <v-radio color="primary" label="Candidate" :value="1"></v-radio>
            <v-radio
              color="primary"
              label="Existing employee"
              :value="0"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="1" sm="2" class="d-flex justify-end">
          <span class="show-cursor">
            <i class="pi pi-upload" @click="csvUpload()">
              <v-tooltip activator="parent" location="bottom">
                CSV Upload
              </v-tooltip>
              <UploadCsv
                @upload-data="uploadData"
                :key="uploadKey"
                ref="uploadCsv"
              />
            </i>
          </span>
        </v-col>
        <v-col cols="12" md="2" sm="4" class="d-flex justify-end">
          <v-btn
            class="text-none text-button"
            prepend-icon="mdi-plus"
            color="#3C5AAA"
            width="120"
            variant="flat"
            text="Add Profile"
            type="submit"
            @click="dialog = true"
          ></v-btn>
        </v-col>
        <v-col>
          <MenuList
            :options="options"
            :items="items"
            @title-selected="updateOptions"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" lg="2">
          <span class="text-body-2 font-weight-medium opacity-50">Name</span>
          <TextFieldComponent
            v-model="name"
            :placeholder="'Enter name'"
            @keyup="filterData(name)"
            width="160"
          />
        </v-col>
        <v-col cols="12" md="3" lg="2">
          <span class="text-body-2 font-weight-medium opacity-50">Email</span>
          <TextFieldComponent
            v-model="email"
            :placeholder="'Email of the Person'"
            @keyup="filterData(email)"
            width="160"
          />
        </v-col>
        <v-col cols="12" md="3" lg="2">
          <span class="text-body-2 font-weight-medium opacity-50"
            >Phone No.</span
          >
          <TextFieldComponent
            v-model="number"
            :placeholder="'Enter mobile no.'"
            @keyup="filterData(number)"
            type="number"
            width="160"
          />
        </v-col>
        <v-col cols="12" md="3" lg="2">
          <span class="text-body-2 font-weight-medium opacity-50"
            >Interview Status</span
          >
          <AutoComplete
            v-model="status"
            :items="item"
            item-title="title"
            :placeholder="'Select status'"
            @update:modelValue="filterData(status)"
            width="160"
          />
        </v-col>

        <v-col cols="12" md="3" lg="2">
          <span class="text-body-2 font-weight-medium opacity-50"
            >Scheduled on</span
          >
          <TextFieldComponent
            type="date"
            :placeholder="'Date'"
            :showtime="false"
            v-model="scheduleDate"
            width="160"
            @update:modelValue="filterData(scheduleDate)"
          />
        </v-col>
      </v-row>
      <v-card
        class="pa-4 mb-2 bg-blue-grey-lighten-5"
        flat
        v-for="CPD of displayData"
        :key="CPD.id"
        v-show="CPD.candidate_type == profileSelection"
      > 
        <v-row>
          <v-col cols="12" md="1">
            <span>{{ CPD.code }}</span> <br />
            <v-avatar :color="getRandomColor()" size="40">{{
              CPD.first_name && CPD.last_name
                ? CPD.first_name.charAt(0).toUpperCase() +
                  CPD.last_name.charAt(0).toUpperCase()
                : ""
            }}</v-avatar>
          </v-col>
          <v-col cols="12" md="2">
            <span class="text-body-2 font-weight-medium"
              >{{ CPD.resume_title }} with
              <span style="white-space: nowrap"
                >{{ CPD.years_of_expeirence }} years</span
              >
              Experience</span
            >
            <br />
            <span class="text-body-2 font-weight-medium">{{
              CPD.first_name + " " + CPD.middle_name + " " + CPD.last_name
            }}</span>
            <br />
            <span>{{ CPD.location }}</span>
          </v-col>
          <v-col cols="12" md="2">
            <span>Interview</span> <br />
            <span>Scheduled on</span>
            <br />
            <span class="text-body-2 font-weight-medium">{{
              CPD.schedule_date
                ? moment(CPD.schedule_date).format("YYYY-MM-DD")
                : ""
            }}</span>
          </v-col>
          <v-col cols="12" md="2">
            <span class="text-red">{{ CPD.years_of_expeirence }} Year </span> |
            <span style="white-space: nowrap"
              >{{ CPD.expected_ctc }} Lakhs</span
            >
          </v-col>
          <v-col cols="12" md="1">
            <v-img
              :src="require('@/assets/calendar.svg')"
              width="25"
              height="25"
              class="show-cursor"
              @click="scheduleData(CPD.id)"
              v-if="CPD.schedule_data && CPD.schedule_data.length < 1"
            ></v-img>
          </v-col>
          <v-col cols="12" md="2">
            <v-chip
              label
              :class="[
                getStyle(CPD.status_of_interview),
                getWrap(CPD.status_of_interview),
              ]"
              variant="outlined"
              class="show-cursor w-100 justify-center text-wrap show-cursor"
            >
              {{ CPD.status_of_interview }}
              <v-menu
                activator="parent"
                transition="scale-transition"
                location="end"
              >
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    :value="index"
                    class="py-0"
                    @click="updateStatus(CPD.id, item.title)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-chip>
          </v-col>
          <v-col>
            <v-icon
              icon="mdi-pencil-outline"
              @click="getValue(CPD.id)"
              class="cursor-pointer"
            ></v-icon>
          </v-col>          
          <v-col>
            <ShowMailDetails
              v-if="getMailStatus(CPD.schedule_data)"
              :data="CPD.schedule_data"
            />
            <!-- <v-icon v-if="getMailStatus(CPD.schedule_data)"
              icon="mdi-email-outline"
              @mouseenter="changeCursor(CPD.schedule_data)" 
              @mouseleave="resetCursor"
              class="show-cursor"
            ></v-icon> -->
          </v-col>
          <!-- <v-col >
            <v-img
              :src="require('@/assets/mail.png')"
              width="25"
              height="25"
              v-if="CPD.send_email"
            ></v-img>
          </v-col> -->
          <v-col>
            <v-checkbox
              v-model="CPD.checked"
              value="true"
              color="primary"
              @change="getCandidateId(CPD.id)"
              class="mt-n4"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col cols="12" md="1"> </v-col>
          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2" class="mt-n4 overflow-auto d-flex">
            <v-avatar
              size="30"
              :color="getRoundStyle(CPD, index)"
              v-for="(rd, index) of CPD.schedule_count"
              :key="rd"
              class="mt-3 mr-2 cursor-pointer"
              @click="handleRoundClick(CPD, index)"
            >
              {{ rd ? rd : "" }}
            </v-avatar>
            <v-btn
              icon="mdi-plus"
              size="30"
              class="mt-3 mr-2 cursor-pointer bg-indigo-darken-2 text-caption"
              @click="scheduleData(CPD.id)"
              v-if="CPD.schedule_count > 0"
            ></v-btn>
          </v-col>
          <v-col> </v-col>
          <v-col> </v-col>
          <v-col> </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card>
    </v-card-text>
  </v-card>
 
  <v-dialog v-model="dialog" transition="dialog-top-transition" persistent>
    <Candidatedetails
      @reload="reloaded"
      @closeCard="closeCard"
      :candidateId="candidateId"
  /></v-dialog>
  <v-dialog v-model="schedule" transition="dialog-top-transition" persistent>
    <ScheduleInterview
      @closeSchedule="closeCard"
      @reload="reloaded"
      :candidateId="candidateId"
      :candidateName="candidateName"
      :indexData="selectInterview"
      :reScheduleId="reScheduleId"
  /></v-dialog>
  <v-dialog v-model="uploadError" width="600">
    <!-- <ErrorCard :uploadError="uploadError" @close="uploadError=null"/> -->
    <UploadStatus @closeDialog="closeStatus()" :message="uploadError" />
  </v-dialog>
</template>

<script>
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import Candidatedetails from "./Candidatedetails.vue";
import ScheduleInterview from "./ScheduleInterview.vue";
import AutoComplete from "@/components/ui/AutoComplete.vue";
// import ErrorCard from "./ErrorCard.vue";
import UploadStatus from "../issues/UploadStatus.vue";
// import scheduleAssessment from "../PendingAssessments/scheduleAssessment.vue";
import moment from "moment";
import CandidateProfileService from "@/api/service/CandidateProfile/CandidateProfileService";
// import DatePicker from "@/components/ui/DatePicker.vue";
import UploadCsv from "@/modules/issues/views/BulkUpdata.vue";
import MenuList from "./MenuList.vue";
// import commonService from "@/common.service";
import ShowMailDetails from "./ShowMailDetails.vue";
// import ShowMailDetails from "./ShowMailDetails.vue";

export default {
  components: {
    TextFieldComponent,
    Candidatedetails,
    UploadCsv,
    UploadStatus,
    ScheduleInterview,
    MenuList,
    // ErrorCard,
    // DatePicker,
    AutoComplete,
    ShowMailDetails
    // scheduleAssessment,
  },
  data() {
    return {
      dialog: false,
      schedule: false,
      reSchedule: false,
      profileSelection: 1,
      CandidateProfileData: null,
      uploadKey: 0,
      email: "",
      name: "",
      number: "",
      status: null,
      scheduleDate: "",
      selectInterview: null,
      bckupData: [],
      displayData: [],
      candidateId: [],
      candidateName:null,
      uploadError: null,
      reScheduleId: null,
      mailData:null,
      items: [
        { title: "Pending" },
        { title: "Rejected" },
        { title: "Selected" },
        { title: "Not in for the interview" },
        { title: "Need to reschedule" },
      ],
      options: [{ title: "Bulk schedule" }, { title: "Update status" }],
      item: [
        { title: "Pending" },
        { title: "Rejected" },
        { title: "Selected" },
        { title: "Scheduled on" },
        { title: "Profile uploaded" },
        { title: "Not in for the interview" },
        { title: "Need to reschedule" }
      ],
    };
  },
  created() {
    this.getCandidateProfileData();
  },
  methods: {
    moment,
    getMailStatus(data){
      if(!data || data.length==0) return false
      if(data && data.length>0){
        for(let item of data){
          if(item && item.send_email==1){
            return true
          }
        }
      }
      return false
    },

    getWrap(status) {
      let classes =
        status && status != null && status.length > 12
          ? ["text-wrap", "pa-4"]
          : [];
      return classes.join(" ");
    },
    updateOptions(title) {
      if (title == "Bulk schedule") {
        this.schedule = true;
      } else {
        if (title !== "Bulk schedule" && title !== "Update status") {
          let updateStatus = CandidateProfileService.bulkUpdate({
            status_of_interview: title,
            candidateData: this.candidateId,
          });
          console.log(updateStatus);
          this.getCandidateProfileData();
          this.candidateId = [];
        }
      }
    },
    getCandidateId(id) {
      this.candidateId.push(id);
      console.log(this.candidateId, "ddddd");
      this.$store.commit("SET_CANDIDATE_ID", this.candidateId);
    },
    closeStatus() {
      this.uploadError = null;
    },
    csvUpload() {
      this.$refs.uploadCsv.uploadTheFile();
      this.$refs.uploadCsv.type = "bulkUpdate";
    },
    async uploadData($event) {
      let $uesrDetail = JSON.parse(localStorage.getItem('user_details'));
      let $department = typeof $uesrDetail.department == 'string' ? JSON.parse($uesrDetail.department) : $uesrDetail.department;
      $event = $event.filter(val => $department.includes(val.DEPARTMENT) );      
      let res = await CandidateProfileService.uploadCsv({
        data: $event,
        table: "upload_candidate_profile",
      });
      if (res.length > 0) {
        this.uploadError = res[0].upload_data_results.message.concat('\n',`${$department} department only insert`);
      }
      this.uploadKey++;
    },
    handleRoundClick(CPD, index) {
      if (CPD && CPD.schedule_data.length == 0) {
        return;
      } else {
        let reScheduleData =
          CPD && CPD.schedule_data.length > 0 && CPD.schedule_data[index]
            ? CPD.schedule_data[index]
            : null;
        this.reScheduleId = reScheduleData.id;
        this.candidateName = CPD.first_name.charAt(0).toUpperCase()+CPD.first_name.slice(1) + " " + CPD.middle_name + " " + CPD.last_name
        this.selectInterview = index;
        this.schedule = true;
        // if (reScheduleData.stage !== "Selected") {
        //   this.reScheduleId = reScheduleData.id;
        //   this.selectInterview = index;
        //   this.schedule = true;
        // }
      }
    },

    getRoundStyle(CPD, index) {
      let color = "grey";
      if (CPD && CPD.schedule_data.length > 0 && CPD.schedule_data[index]) {
        const roundData = CPD.schedule_data[index];
        if (roundData) {
          switch (roundData.stage) {
            case "Selected":
              color = "green";
              break;
            case "Rejected":
              color = "red";
              break;
            case "Hold":
              color = "orange";
              break;
            case "Not in for the Interview":
              color = "purple";
              break;
            default:
              color = "yellow";
          }
        }
      }
      return color;
    },

    getStyle(status) {
      return status == "Pending"
        ? "text-orange-accent-2"
        : status == "Rejected"
        ? "text-red"
        : status == "Selected"
        ? "text-green-accent-4"
        : status == "Not in for the Interview"
        ? "text-purple-accent-4"
        : "text-blue-darken-3";
    },

    getRandomColor() {
      const colors = [
        "red",
        "blue",
        "green",
        "yellow",
        "orange",
        "purple",
        "pink",
        "grey",
      ];
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      return randomColor;
    },

    selectDate($event) {
      this.scheduleDate = $event;
      this.filterData();
    },

    getValue(id) {
      this.candidateId.push(id);
      this.dialog = true;
    },

    scheduleData(id) {
      this.schedule = true;
      this.reSchedule = true;
      this.candidateId.push(id);
    },

    async updateStatus(id, status) {
      let output = await CandidateProfileService.update({
        id: id,
        status_of_interview: status,
      });
      console.log(output);
      this.getCandidateProfileData();
    },

    reloaded(val) {
      this.reload = val;
      if (this.reload) {
        this.getCandidateProfileData();
      }
    },

    closeCard(value) {
      this.candidateId = [];
      this.dialog = value;
      this.schedule = value;
      this.reScheduleId = null;
    },

    async getCandidateProfileData() {
      let $uesrDetail = JSON.parse(localStorage.getItem('user_details'));
      $uesrDetail.department = typeof $uesrDetail.department == 'string' ? JSON.parse($uesrDetail.department) : $uesrDetail.department;
      let res = await CandidateProfileService.findList({department: $uesrDetail.department});
      this.CandidateProfileData = JSON.parse(JSON.stringify(res));
      this.CandidateProfileData.forEach((CPD) => {
        CPD.middle_name = CPD.middle_name == null ? "" : CPD.middle_name;
        CPD.checked = false;
      });
      this.displayData = [...this.CandidateProfileData];
      this.bckupData = [...this.CandidateProfileData];

      console.log(this.displayData);
    },

    filterData($searchField) {
      const searchTerm =
        typeof $searchField == "string"
          ? $searchField.toLowerCase()
          : $searchField;
      let searchData = this.bckupData.filter((cpd) => {
        return (
          cpd &&
          ((cpd.first_name &&
            cpd.first_name.toLowerCase().includes(searchTerm)) ||
            (cpd.last_name &&
              cpd.last_name.toLowerCase().includes(searchTerm)) ||
            (cpd.email && cpd.email.toLowerCase().includes(searchTerm)) ||
            (cpd.phone_number && cpd.phone_number.includes(searchTerm)) ||
            (cpd.status_of_interview &&
              cpd.status_of_interview.toLowerCase().includes(searchTerm)) ||
            (cpd.schedule_date && cpd.schedule_date.includes(searchTerm)))
        );
      });
      this.displayData = searchData.length > 0 ? searchData : this.bckupData;
    },
  },
};
</script>

<style scoped>
.v-list-item--density-default.v-list-item--one-line {
  min-height: 32px;
}
.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
  padding-inline: 5px;
}
::placeholder {
  opacity: 0.5;
}
</style>
