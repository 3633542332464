<template>
  <v-icon size="18px" class="pl-3 mdi mdi-email-outline" 
  @mouseenter="changeCursor()" style="cursor: pointer">
    <v-menu activator="parent" open-on-hover transition="slide-x-transition">
      <v-list style="width: 250px">
        <v-list-title class="px-4"
          ><span style="font-size: 14px; font-weight: 600"
            >Mail Details</span
          ></v-list-title
        >
        <v-list-item class="px-4 pt-0">
          <v-row no-gutters>
            <v-col cols="3" class="detailsHeadingFont"><b>On :</b></v-col>
            <v-col cols="9" class="detailsFont">{{
              mailData &&
              mailData.mail_date &&
              moment(mailData.mail_date).format("MM/DD/YYYY hh:mm A") !=
                "Invalid date"
                ? moment(mailData.mail_date).format("MM/DD/YYYY hh:mm A")
                : ""
            }}</v-col>

            <v-col cols="3" class="detailsHeadingFont"><b>To :</b> </v-col>
            <v-col cols="9" class="detailsFont">{{
             mailData && mailData.email_to
                ? mailData.email_to
                : ""
            }}</v-col>

            <v-col cols="3" class="detailsHeadingFont"><b>CC :</b></v-col>
            <v-col cols="9" class="detailsFont">
                <span class="mr-1">{{
                  mailData && mailData.email_cc
                ? mailData.email_cc
                : ""
                }}</span
                >
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-icon>
</template>

<script>
import moment from "moment";
export default {
  props: ['data'],
  data(){
    return{
      mailData:null
    }
  },
  methods:{
    moment,
    changeCursor(){
      if(!this.data || this.data.length==0) return this.mailData= null
      if(this.data && this.data.length>0){
        for(let item of this.data){
          if(item && item.send_email==1){
            this.mailData = item
            return
          }
        }
      }
      else{
        this.mailData = null    
        return
      }
    }
  }

};
</script>

<style>
.cardStyle {
  width: 250px;
  height: fit-content;
  /* position: absolute;
    top: -300px;
    left: 310px; */
  border: 2px solid #adadad;
}

.detailsHeadingFont {
  font-size: 12px;
  font-weight: 500;
}

.detailsFont {
  font-size: 12px;
  font-weight: 400;
}
</style>
