<template>
  <div>
    <h4 style="margin-left: 20px;margin-top: 10px;">My Activity</h4>
    <v-row>
      <v-col cols="2" class="mt-5">
        <v-label style="margin-left: 30px;">Date</v-label>
        <div style="display: flex; justify-content: flex-end;">
         <div style="position: relative; margin-right: 12px;">
           <Calendar v-model="dates" :selectionMode="'range'" :manualInput="false" variant="outlined" />
         </div>
        </div>
      </v-col>
      <v-col cols="3" class="mt-5">
        <v-label style="line-height:16px;">User</v-label>
        <AutoComplete placeholder="User" density="compact" variant="outlined" item-value="id"
            item-title="name" :items="userData" required=true />
      </v-col>
      <v-col cols="7"  style="margin-top: 30px;">
        <v-card style="height: 90px; padding: 15px; background-color: white; margin-bottom: 50px;">
          <div style="display: flex">
            <v-sheet :height="55" :width="55" rounded style="background-color: #d7d7d7">
            </v-sheet>
            <div style="margin-left: 10px; width: 120px">
              <h5>Sankaran</h5>
              <p style="font-weight: 500; font-size: small;">Process Lead</p>
              <p style="font-weight: 500; font-size: small;">4years 3months</p>
            </div>
            <div style="display: flex; margin-left: 120px">
              <v-sheet style="
                  display: flex;
                  margin-left: 10px;
                  align-items: center;
                  justify-content: center;
                  background-color: #d7d7d7;
                " v-for="(person, index) in persons" :key="index" :height="60" :width="80" rounded>
                <div>
                  <div>
                    <p style="font-weight: 500; font-size: smaller;text-align: center">{{ person.name }}</p>
                  </div>
                  <div>
                    <p style="font-size:small;text-align: center">{{ person.role }}</p>
                  </div>
                </div>
              </v-sheet>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" style="margin-top: -65px;">
        <v-card style="padding: 10px;margin-left: 20px;height: 95%">
          <v-card style="height: 50px; padding: 15px; background-color: #d7d7d7">
              <div style="display: flex; align-items: center; justify-content: center;">
                <h4>Distribution Blue</h4>
              </div>
          </v-card>
          <v-table
  style="
    padding: 10px;
    height: 334px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  "
  fixed-header
>
  <thead>
    <tr>
      <th style="width: 2%; font-weight: 700; text-align: left;">No</th>
      <th style="width: 2%; font-weight: 700; text-align: left;">Tasks</th>
      <th style="width: 6%; font-weight: 700; text-align: left; background-color: #e4f3fe;">Count</th>
      <th style="width: 6%; font-weight: 700; text-align: left; background-color: #e4f3fe;">Total Mins</th>
      <th style="width: 6%; font-weight: 700; text-align: left; background-color: #e4f3fe; padding-left: 20px;">Average</th>
      <th style="width: 6%; font-weight: 700; text-align: left; background-color: #e4f3fe;">Count</th>
      <th style="width: 6%; font-weight: 700; text-align: left; background-color: #e4f3fe;">Total Mins</th>
      <th style="width: 6%; font-weight: 700; text-align: left; background-color: #e4f3fe; padding-left: 20px;">Average</th>
    </tr>
  </thead>
  <tbody style="max-height: 100%; overflow: scroll;">
    <tr v-for="item in distribution" :key="item.No">
      <td style="width: 2%; font-weight: 600; text-align: left;">{{ item.No }}</td>
      <td style="width: 2%; font-weight: 600; text-align: left;">{{ item.Tasks }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.Count }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.TotalMins }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe; padding-left: 20px;">{{ item.Average }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.Count }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.TotalMins }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe; padding-left: 20px;">{{ item.Average }}</td>
    </tr>
  </tbody>
  <br>
  <tbody style="max-height: 100%; overflow: scroll;">
    <tr v-for="item in distribution" :key="item.No">
      <td style="width: 2%; font-weight: 600; text-align: left;">{{ item.No }}</td>
      <td style="width: 2%; font-weight: 600; text-align: left;">{{ item.Tasks }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.Count }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.TotalMins }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe; padding-left: 20px;">{{ item.Average }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.Count }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.TotalMins }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe; padding-left: 20px;">{{ item.Average }}</td>
    </tr>
  </tbody>
  <br>
  <tbody style="max-height: 100%; overflow: scroll;">
    <tr v-for="item in distribution" :key="item.No">
      <td style="width: 2%; font-weight: 600; text-align: left;">{{ item.No }}</td>
      <td style="width: 2%; font-weight: 600; text-align: left;">{{ item.Tasks }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.Count }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.TotalMins }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe; padding-left: 20px;">{{ item.Average }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.Count }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe;">{{ item.TotalMins }}</td>
      <td style="width: 6%; font-weight: 600; text-align: left; background-color: #e4f3fe; padding-left: 20px;">{{ item.Average }}</td>
    </tr>
  </tbody>
</v-table>

        </v-card>
      </v-col>
      <v-col cols="2" style="margin-top: -63px;">
        <div>
          <v-card style="background-color: rgb(255 255 255);max-height: 100%; overflow: scroll;" :height="381"
            :width="150" rounded>
            <div style="margin-left: 10px" v-for="(production, index) in production" :key="index">
              <div style="margin-top: 30px; text-align: center">
                <p style="font-size:small;">{{ production.name }}</p>
              </div>
              <div style="text-align: center">
                <h3>{{ production.role }}</h3>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" style="margin-top: 40px;">
        <div style="display: flex">
          <v-card style="
              margin-left: 17px;
              margin-top: -74px;
              background-color: rgb(255 255 255);
              display: flex;
              width: 200vh;
              height: 80px;
            " rounded>
            <div>
              <h3 style="padding: 25px">Attendance</h3>
            </div>
            <div style="margin-left: 150px" v-for="(productions, index) in productions" :key="index">
              <div style="margin-top: 30px; text-align: center">
                <p style="font-size:small;">{{ productions.name }}</p>
              </div>
              <div style="text-align: center">
                <h4>{{ productions.role }}</h4>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { AutoComplete } from '@tiburadev/vue-comp';
import Calendar from "primevue/calendar";
import { ref } from "vue";
import UserService from '@/api/service/UserService';

export default {
  components: {
    AutoComplete,
    Calendar
  },
  data() {
    const dates = ref();
    return {
      dates,
      distribution: [
        {
          No: '1',
          Tasks: 'Allocation & packing',
          Count: '5',
          TotalMins: '30min',
          Average: '15min',
        },
        {
          No: '2',
          Tasks: 'Reports',
          Count: '5',
          TotalMins: '30min',
          Average: '15min',
        },
        {
          No: '3',
          Tasks: 'Sales Order',
          Count: '5',
          TotalMins: '30min',
          Average: '15min',
        },
        {
          No: '4',
          Tasks: 'Special Tasks',
          Count: '5',
          TotalMins: '30min',
          Average: '15min',
        },
        {
          No: '5',
          Tasks: 'Total',
          Count: '5',
          TotalMins: '30min',
          Average: '15min',
        },
      ],
      persons: [
        { name: 'Total Task', role: '33' },
        { name: 'Total error', role: '5' },
        { name: 'Major', role: '2' },
        { name: 'Minor', role: '3' },
        { name: 'Score', role: '5' },
      ],
      production: [
        { name: 'Production', role: '33' },
        { name: 'Meeting', role: '33' },
        { name: 'Break', role: '43' },
        { name: 'Others', role: '0' },
      ],
      productions: [
        { name: 'Working Days', role: '31d' },
        { name: 'Holidays', role: '0' },
        { name: 'Vacation', role: '4h' },
        { name: 'PTO', role: '2h' },
        { name: 'Scheduled', role: '2h' },
        { name: 'Unplanned', role: '4h' },
      ],
      userData:[]
    }
  },
  created(){
    this.getUserData()
  },
  methods:{
    async getUserData(){
      let res = await UserService.findAll()
      this.userData = res && res.length>0?res:[]
    }
  }
}
</script>

<style scoped>

</style>