<template>
    <v-container fluid style="background:#f3f9ff;padding:0px;margin:0px">
        <v-card dense flat>
                <v-card-title>
                    Permissions
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="3">
                            <v-label
                            class="pb-1"
                            style="font-size: 14px; color: #272727; font-weight: 600"
                            >Roles
                            <span
                            v-if="!showReadOnly"
                            style="color: #cf0707; padding-left: 2px; font-weight: 700"
                            >*</span
                            ></v-label
                        > 
                        <AutoComplete 
                            :items="roles"
                             v-model="formData.role_id" placeholder="Select Roles"
                            density="compact" variant="outlined" name="name" value="id" 
                            required=true
                            @update:modelValue="roleChanged" />
                        </v-col>
                        <v-col cols="3">
                            <v-label
                            class="pb-1"
                            style="font-size: 14px; color: #272727; font-weight: 600"
                            >Modules
                            <span
                            v-if="!showReadOnly"
                            style="color: #cf0707; padding-left: 2px; font-weight: 700"
                            >*</span
                            ></v-label
                        > 
                        <AutoComplete 
                            :items="modules" v-model="formData.module_id" placeholder="Select Module"
                            density="compact" variant="outlined" name="name" value="id" required=true
                            @update:modelValue="moduleChanged" />
                        </v-col>
                        <v-col cols="3" class="mt-6">
                            <v-btn v-if="formData.role_id" @click="cloneDialog=true" size="small" outlined icon="mdi mdi-content-copy" style="border-radius:4px">

                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
        </v-card>
        <v-row dense>
            &nbsp;
        </v-row>
        <v-row dense>
            <v-col cols="3">
                <v-card flat height="60vh" style="overflow: auto;">
                    <v-card-title>
                        Activity Groups
                    </v-card-title>
                    <v-card-text>
                        <ListView
                            :listItems="activityGroups" value="id" text="name" return-object=true add-on-click=false
                            @item-active="activityGroupChanged" @item-selected="activityGroupChanged" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card flat height="60vh"  style="overflow: auto;">
                    <v-card-title>
                        Activities
                    </v-card-title>
                    <v-card-text>
                        <ListView
                            :listItems="activities" value="id" text="name" return-object=true add-on-click=false
                            @item-active="activityChanged" @item-selected="activityChanged" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card flat height="60vh"  style="overflow: auto;">
                    <v-card-title>
                        Actions
                    </v-card-title>
                    <v-card-text v-if="actions && actions.length>0">
                        <v-row>
                            <v-col cols="1">
                                <v-checkbox hide-details density="compact"
                                v-model="selectAllData"
                                @click="selectAll"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="5" class="mt-3">
                                Select All
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <template v-for="action in actions" v-bind:key="action">
                            <v-col cols="1">
                                <v-checkbox hide-details density="compact"
                                @click="autoSaveRequired=true"
                                v-model="action.selected"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="5" class="mt-3">
                                {{ action.name }}
                            </v-col>
                        </template>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog max-width="400px" v-model="cloneDialog" persistent >
            <PermissionClone :roles="roles" :fromRoleId="this.formData.role_id" @closeDialog="cloneDialog=false" />
        </v-dialog>
    </v-container>
</template>
<script>
import PermissionClone from './PermissionClone.vue'
import RoleService from '@/api/service/Role/RoleService'
import ModuleService from '@/api/service/Module/ModuleService';
import ActivityGroupService from '@/api/service/ActivityGroup/ActivityGroupService';
import ActivityService from '@/api/service/Activity/ActivityService';
import { AutoComplete } from '@tiburadev/vue-comp';
import RolePermissionService from '@/api/service/RolePermission/RolePermissionService';
import { ListView }from '@tiburadev/vue-comp'
//import {Button} from '@tiburadev/vue-comp'
export default {
    components: {
        AutoComplete,
        ListView,
        PermissionClone
  //      Button
    },
    data() {
        return {
            cloneDialog:false,
            formData:{
                "actions":[]
            },
            autoSaveRequired:false,
            selectAllData:false,
            roles:[],
            modules:[],
            activityGroups:[],
            activities:[],
            actions:[]
        }
    },
    created() {
        this.getRoles()
        this.getModules()
    },
    methods:{
        async getModules() {
          this.modules = await ModuleService.findAll({ active: 1 });
        },
        async getRoles() {
            this.roles = await RoleService.findAll({ active: 1 });
        },
        selectAll() {
            let $selected = this.actions.filter((obj)=>{return obj.selected})
            let $selection=false
            if($selected.length == this.actions.length) {
                $selection=false
            } else if($selected.length>0) {
                $selection =true
            } else {
                $selection =true
            }
            for(let action of this.actions) {
                    action.selected=$selection
                }
            this.selectAllData=$selection
            this.autoSaveRequired=true
        },
        async roleChanged() {
            if(this.formData.activity_id) {
                await this.autoSave()
            }
        },
        async  moduleChanged($item) {
            if(this.formData.activity_id) {
                await this.autoSave()
            }
            this.formData.module_id=$item
            this.formData.activity_group_id=null
            this.formData.activity_id=null
            this.actions=[]
            this.getActivityGroups()
        },
        async activityGroupChanged($item) {
            if(this.formData.activity_id) {
                await this.autoSave()
            }
            this.formData.activity_group_id= $item.id
            this.actions=[]
            this.getActivities()
        },
        async activityChanged($item) {
           // alert('activity chanigne')
            if(this.formData.activity_id) {
                await this.autoSave()
            }
            this.formData.activity_id= $item.id
            this.actions = JSON.parse($item.actions)
           // alert('gettg permissions')
            this.getPermissions()
        },
        async getPermissions() {
          //  alert({"role_id":this.formData.role_id,"activity_id":this.formData.activity_id})
            let $permissions =await  RolePermissionService.findAll({"role_id":this.formData.role_id,"activity_id":this.formData.activity_id})
            $permissions = $permissions.length>0 ? $permissions[0] : {}
            if($permissions.id) {
                this.formData.id = $permissions.id
                let $allowedActions = $permissions.actions ? JSON.parse($permissions.actions) : []
                for(let $action of this.actions) {
                    if($allowedActions.indexOf($action.code)>=0) {
                        $action.selected=true 
                    } else {
                        $action.selected=false
                    }
                }
            } else {
                delete this.formData.id
            }
            let $selected 
            if(this.actions && this.actions.length>0){
           $selected= this.actions.filter((obj)=>{return obj.selected})
            }
            if($selected.length == this.actions.length) {
                this.selectAllData=true
            }  else {
                this.selectAllData=false
            }

            //alert(JSON.stringify(this.actions))
            
            

        },
        async autoSave() {
            if(this.autoSaveRequired) { 
            let $selected = this.actions.filter((obj)=>{
                return obj.selected
            })
            this.formData.actions = $selected.map((element) =>{
                return element.code
            })
            this.formData.actions =JSON.stringify(this.formData.actions)
            let $formData =Object.assign({},this.formData)
            delete $formData.module_id
            delete $formData.activity_group_id

            if(this.formData.id) {
                
                await RolePermissionService.update($formData)
            } else {
                await RolePermissionService.create($formData)
            }
            this.autoSaveRequired=false
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "Auto Saving...",
          });
        }
            
        },
        async getActivityGroups() {
            this.activityGroups = await ActivityGroupService.findAll({ active: 1, module_id: this.formData.module_id });
            this.activities=[]
        },
        async getActivities() {
            this.activities = await ActivityService.findAll({ "activity_group_id": this.formData.activity_group_id })
        },
    }
}
</script>