<template>
  <v-container justify-center style="inline-size:90vw">
    <v-card variant="text" style="background-color:#ffff">
      <v-card-title >
        <span style="font-size:12px">Exam # {{ assessmentData.id }}</span>
        <br/>
        <!-- <div style="line-height:18px;font-weight:800">{{ 
        assessmentData && assessmentData.programmes
          ? assessmentData.programmes.name
          : ""
      }}</div> -->
      </v-card-title>
      <v-card-subtitle style="font-size:12px">
        Programme Code #
        {{
          assessmentData && assessmentData.programmes
            ? assessmentData.programmes.code
            : ""
        }}
        | Programme Date 01-04-2023-15-04-2023 | Assesment Date
        {{
          assessmentData.start_time
            ? moment(
                assessmentData.start_time.slice(0, 19).replace("T", " ")
              ).format("DD-MM-YYYY")
            : ""
        }}
      </v-card-subtitle>
    </v-card>
    <v-row no-gutters dense class="mt-3">
      <v-col cols="9">
        <v-row no-gutters dense>
            <v-col cols="12">
              <v-card variant="text" style="background-color:#ffff">
                  <v-card-item>
                    <v-row no-gutters dense>
                      <v-col cols="5" class="colborders pl-2">
                        <small>Session #</small><br/>
                          <b>{{
                              assessmentData && assessmentData.session_id
                                ? assessmentData.session_id
                                : ""
                            }}</b>
                      </v-col>
                      <v-col cols="2" class="colborders pl-5">
                          <small>Question # </small><br/>
                        <b>{{
                              currentQuetions && currentQuetions.orderSeqeunce ? currentQuetions.orderSeqeunce : 0
                            }} of 
                            <!-- {{ assessmentData }} -->
                            {{
                              assessmentData && assessmentData.question_ids
                                ? assessmentData.question_ids.length
                                : 0
                            }}</b>
                        </v-col>
                        <v-col cols="2" class="colborders pl-5 ">
                          <small>Skipped # </small><br/>
                          <b>{{ skippedQuestions.length }}</b>
                        </v-col>
                        <v-col cols="2" class="colborders pl-5 ">
                          <small>Time to Answer </small><br/>
                            <b>{{ assessmentData && assessmentData.duration ? assessmentData.duration + " Seconds" : 5 + " Seconds"}}</b>
                        </v-col>
                        <v-col cols="1">
                          <v-icon @click="getByCard('prev',true)"  v-if="this.sequence!=0">mdi-chevron-left</v-icon>
                          <v-icon @click="getByCard('next',true)" v-if="this.sequence!=this.totalQuestion.length-1"  
                           >mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                  </v-card-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters dense>
            <v-col cols="12" class="mt-5">
                <v-card flat class="pa-3 card">
                  <v-row no-gutters dense>
                     
                      <v-col cols="1" style="font-size:16px;font-weight:800">
                        <v-avatar
                          class="avata3r"
                          size="25"
                          color="#7d96e7"
                          rounded="1"
                          ><span style="color: white">
                          {{
                            assessmentData.currentQuestion+1
                          }}
                          </span>
                        </v-avatar> 
                      </v-col>
                      <v-col cols="10" style="font-size:16px;font-weight:400">
                       <p v-html="
                          currentQuetions && currentQuetions.questionnaire
                            ? currentQuetions.questionnaire.title : '' ">
                       </p>
                      
                      </v-col>
                      <v-col cols="12">
                          <v-list
                            v-if="
                            currentQuetions.questionnaire &&
                            currentQuetions.selections &&
                            currentQuetions.questionnaire.options
                            ">
                              <v-list-item
                              v-for="data in currentQuetions.questionnaire.options"
                              :key="data"
                              >
                                <v-label
                                @click="setSelected(data)"
                                class="chip"
                                style="cursor: pointer;opacity:1"
                                :style="
                                getSelected(data)
                                ? 'background-color: #062aa0;color:#fff;font-weight:800'
                                : 'background-color: #bdc5e0;font-weight:400'
                                "
                                >
                                <span
                                >{{ data.sequence }} . {{ data.text }}</span
                                >
                                </v-label>
                            </v-list-item>
                          </v-list>
                      </v-col>
                      <v-col cols="12">
                            <v-text v-if="invalidError" style="color:red"
                            >Mininum select atleast 
                            {{
                            currentQuetions && currentQuetions.questionnaire
                            ? currentQuetions.questionnaire.min_selection
                            : 1
                            }} Answer</v-text>
                      </v-col>
                      <v-col cols="12">
                       
                      </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-row no-gutters dense>
                      <v-col cols="3">
                        <v-checkbox
                          @change="setDefault()"
                          label="Mark for later review"
                          v-model="currentQuetions.mark_for_review1"
                          ></v-checkbox>
                      </v-col>
                      <v-col cols="1">&nbsp;</v-col>
                      <!-- {{ this.sequence }} {{ this.totalQuestion.length }} -->
                      <v-col cols="7" class="text-right">
                        <v-btn class="buttons" style="inline-size:25%" @click="submit()"
                        > Submit
                        </v-btn
                      >
                        <v-btn
                        class="buttons"
                        style="inline-size:20%"
                        @click="updateQuestion()"
                        v-if="!currentQuetions.mark_for_review1"
                        >
                       Skip</v-btn
                      >
                    
                      <v-btn
                        class="buttons"
                        style="inline-size:20%"
                         @click="updateAnsweredQuestion('prev')" v-if="this.sequence!=0"
                        >Previous</v-btn>
                  
                      <v-btn
                        class="buttons"
                        style="inline-size:20%"
                        @click="updateAnsweredQuestion('next')"
                         v-if="assessmentData.question_ids && this.sequence!=this.assessmentData.question_ids.length-1"
                        >Next</v-btn
                      >
                    </v-col>
                    </v-row>
                  </v-card-actions>  
                </v-card>
            </v-col>
            <v-col cols="12">
              
            </v-col>
      </v-row>
      </v-col>
      <v-col cols="3">
        <v-row no-gutters dense>
          <v-col cols="12">
            <v-card variant="flat" class="ml-3 pa-2 mb-2">
                <v-card-title style="font-size:12px">Time Left</v-card-title>
                <v-card-item style="">                 
                    <span style="font-weight:800;font-size:28px">
                      {{ countDown }} 
                    </span> <span style="font-size:12px">&nbsp;seconds</span>
                </v-card-item>
              </v-card>
          </v-col>
          <v-col cols="12">
            <v-card variant="flat" class="ml-3 pa-2 mb-2">
              <v-row v-if="assessmentData.question_ids">
                  <v-col cols="2" v-for="(data,index) in assessmentData.question_ids" :key="data">
                    <v-avatar
                    style="cursor: pointer;"
                    @click="getByCard(data,false)"
                      rounded=""
                      :color="
                        assessmentData.status[index]=='notanswered' 
                          ? '#7d96e7'
                          : assessmentData.status[index]=='markforreview' 
                          ? '#FBEFD7'
                          : assessmentData.status[index]=='skipped' 
                          ? '#FADDDD'
                          : '#F0F0F0'
                      "
                      >{{ index+1 }}</v-avatar
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-avatar rounded="" size="18" color="#FADDDD"></v-avatar>
                    <v-text class="skipped"> skipped </v-text>
                  </v-col>
                  <v-col cols="6">
                    <v-avatar rounded="" size="18" color="#FBEFD7"></v-avatar>
                    <v-text class="skipped">For review</v-text>
                  </v-col>
                  <v-col cols="6">
                      <v-avatar rounded="" size="18" color="#F0F0F0"></v-avatar>
                      <v-text class="skipped">Answered</v-text>
                    </v-col>
                    <v-col cols="12">
                      <v-avatar rounded="" size="18" color="#D7FBDB"></v-avatar>
                      <v-text class="skipped">Not Answered yet</v-text>
                    </v-col>

                  
                  </v-row>
            </v-card>
          </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="showDialog" persistent max-width="800px" max-height="400px">
      <!-- <v-card  v-if="finaldata">
        <v-card-title>
           <b> Scorecard</b>
          <v-spacer></v-spacer>
            <IconClose class="close-icon" @clicked="closedialog" />
        </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="success-assessment"> Your have completed the assessment in 
                  <br/><b>{{answeredTime}} Seconds </b>
                  </div>
              </v-col>
              <v-col cols="4">
                <span class="left-score">Pass Score is {{ finaldata.passScore }}</span>
              </v-col>
              <v-col cols="4">
                <span class="middle-score"> Your score for the the Assessment is  {{ finaldata.score }} </span>
              </v-col>
              <v-col cols="4">
                <span class="right-score" :style="finaldata.result ? 'background-color: #299f58e823;':'background-color: #d5222223'"> 
                  You {{ finaldata.result ? 'Passed':'Failed' }} in the Assessment 
                </span>
              </v-col>
            </v-row> -->
             
            
             <!-- <div style="block-size:100px"> </div>  -->
             
             
          <!-- </v-card-text>
      </v-card> -->
<ResultCard :finaldata="finaldata" @result="resultData"/>
    </v-dialog>
    <v-dialog v-model="result" persistent max-width="800px" max-height="400px">
    <ResultCard1 :finaldata="finaldata" @result="resultData1"/>
    </v-dialog>
    <v-dialog v-model="result1" persistent max-width="800px" max-height="400px">
      <v-card class="cardBg2 pb-14">
        <v-card-title class="ml-2">
        <div>
        <span>Submitted</span>
        <v-spacer></v-spacer>
        <IconClose class="close-icon" @clicked="closedialog" />
        </div>
        <div>
          <span class="text-body-1 font-weight-regular	">Dear</span>
          <span class="text-body-1 font-weight-medium ms-1"
            >VELAYUTHAM SANKARAN</span
          >
        </div>
      </v-card-title>
      <v-card-text>

        Thanks for attending the interview. We wil advice you on the results shortly.
      </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import AssesmentService from "@/api/service/Assesments/AssesmentsService1";
import AssesmentQuestionsService from "@/api/service/AssessmentQuestions/AssessmentQuestionsService";
import ResultCard from "./resultCard.vue";
import ResultCard1 from "./resultCard1.vue";
import lodash from "lodash";
import moment from "moment";
import IconClose from "@/components/IconClose.vue"
export default {
  components: {
    IconClose,
    ResultCard,
    ResultCard1,
  },
  data() {
    return {
      assessmentData: {},
      totalQuestion: [],
      currentQuetions: {},
      skippedQuestions:[],
      sequence: 0,
      invalidError:false,
      result:false,
      result1:false,
      showDialog:false,
      finaldata:{},
      countDown:0,
      answeredTime:0
    };
  },
  created() {
    this.getAssessment();
   
  },
  methods: {
    moment,
    resultData(value){
      this.result=value
    },
    resultData1(value){
      this.result1=value
    },
    closedialog(){
      window.close()
    },
    async getAssessment() {
      let $assessment = await AssesmentService.findOne({
        id: this.$route.query.id,
      });
      this.assessmentData = $assessment[0];
      console.log(this.assessmentData,'ggggggggg');
      this.assessmentData.question_ids = this.assessmentData.question_ids
        ? JSON.parse(this.assessmentData.question_ids)
        : [];
        this.assessmentData.currentQuestion=0;
        this.assessmentData.status={}
        for(let $i=0;$i<3;$i++) {
          this.assessmentData.status[$i]="notanswered"
        }
        console.log(this.assessmentData.status,'sss');
        this.getQuestions(false);
        this.countDown = this.assessmentData.duration;
        this.countDownTimer()
    },
    async getQuestions($flag) {
      let $questions = await AssesmentQuestionsService.findAll({
        assessment_id: this.$route.query.id,
        question_id: this.assessmentData.question_ids[this.assessmentData.currentQuestion]
        
      });
      console.log($questions,'sqqqqqq');
      this.totalQuestion = $questions;
      this.totalQuestion.forEach((e) => {
        e.selections = e.selections ? JSON.parse(e.selections) : [];
        e.sequence = this.assessmentData.question_ids.findIndex(k=>k==e.question_id)+1;
      });
      let $data = this.totalQuestion.filter(k=>k.selections && k.selections.length==0);
      if(!$flag){
        if($data && $data.length>0){
          this.getTaskQuestion(this.assessmentData.question_ids.findIndex(e=>e==$data[0].question_id));
        }else{
          this.getTaskQuestion(this.sequence);
        }
      }else{
        this.getTaskQuestion(this.sequence);
      }
      this.skippedQuestions = this.totalQuestion.filter(k=>k.skip==1)
    },
    getByCard($data,$flag){
      if(!$flag){
        this.getTaskQuestion(this.assessmentData.question_ids.findIndex(e=>e==$data.question_id))
      }else{
        this.sequence = $data == "next" ? this.sequence+1 : this.sequence-1;
        this.getTaskQuestion(this.sequence);
      }
      
    },
    async getTaskQuestion($sequene) {
      if($sequene== undefined){
        return 'skipped'
      }
      let $question = await AssesmentService.nextAssessment({
        id: this.$route.query.id,
        sequence: $sequene,
      });
      console.log($question.data[0],'kkaskjdajshdjdh')
      this.currentQuetions = $question.data[0];
      this.currentQuetions.orderSeqeunce = this.assessmentData.question_ids.findIndex(e=>e== $question.data[0].question_id)+1;
      this.currentQuetions.mark_for_review1 = this.currentQuetions.mark_for_review ==1 ? true:false
      this.currentQuetions.selections = this.currentQuetions.selections
        ? JSON.parse(this.currentQuetions.selections)
        : [];
      this.currentQuetions.questionnaire.options = this.currentQuetions
        .questionnaire.options
        ? JSON.parse(this.currentQuetions.questionnaire.options)
        : [];
      this.currentQuetions.questionnaire.options = lodash.orderBy(
        this.currentQuetions.questionnaire.options,
        "sequence",
        "asc"
      );
    },
    async updateQuestion() {
      await AssesmentQuestionsService.update({
        id: this.currentQuetions.id,
        selections:JSON.stringify([]),
        skip: 1,
      });
      this.assessmentData.status[this.assessmentData.currentQuestion]='skipped'
        this.assessmentData.currentQuestion = this.assessmentData.currentQuestion +1
      this.sequence = this.sequence+1;
      console.log(this.sequence,'seeee', this.totalQuestion.length-1);
      if(this.sequence!=this.totalQuestion.length-1){
        this.getTaskQuestion(this.sequence);
        this.getQuestions(true)
      }
      
    },
    async updateAnsweredQuestion($type) {
     
      if(this.currentQuetions.mark_for_review1){
        this.setValues($type)
      }
      else if (
        this.currentQuetions.selections.length <
        this.currentQuetions.questionnaire.min_selection
      ) {
        this.invalidError = true;
        return
      } else {
        this.invalidError = false;
        this.setValues($type)
      }
      this.assessmentData.status[this.assessmentData.currentQuestion] =  this.currentQuetions.selections.length>0 ? 'answered' : this.assessmentData.status[this.assessmentData.currentQuestion]
      console.log(this.assessmentData.status,'sss11');
      if($type=='prev') {
        this.assessmentData.currentQuestion = this.assessmentData.currentQuestion -1
      } else {
        this.assessmentData.currentQuestion = this.assessmentData.currentQuestion +1
      }
    },
    async submit(){
      let $obj = Object.assign({}, this.currentQuetions);
        $obj.selections = JSON.stringify($obj.selections);
        delete $obj.questionnaire;
        delete $obj.mark_for_review1;
        delete $obj.orderSeqeunce;
        await AssesmentQuestionsService.update($obj);
        let $count = JSON.parse(JSON.stringify(this.countDown))
        this.answeredTime = this.assessmentData.duration - $count;
        // this.countDown=0;
       let $data= await AssesmentService.findAll({id:this.$route.query.id})
       console.log($data,'final..');
      this.finaldata = $data[0];
        this.showDialog=true
    },  
    async setValues($type){
      let $obj = Object.assign({}, this.currentQuetions);
        $obj.selections = JSON.stringify($obj.selections);
        console.log($obj,'ooo');
        delete $obj.questionnaire;
        delete $obj.mark_for_review1;
        delete $obj.orderSeqeunce;
        await AssesmentQuestionsService.update($obj);
        this.sequence = $type == "next" ? this.sequence+1 : this.sequence-1;
        this.getTaskQuestion(this.sequence);
    },
    getSelected($data) {
      let $index = this.currentQuetions.selections.findIndex(
        (k) => k.sequence == $data.sequence
      );
      return $index > -1 ? true : false;
    },
    setDefault(){
      this.currentQuetions.selections = [];
      this.assessmentData.status[this.assessmentData.currentQuestion]='markforreview'
      console.log(this.assessmentData.status,'sss111');
      this.totalQuestions.mark_for_review = 1
    },
    setSelected($data) {
  
      this.currentQuetions.mark_for_review1=false;
      let $index = this.currentQuetions.selections.findIndex(
          (k) => k.sequence == $data.sequence
        );
       
      if (
        this.currentQuetions.selections.length ==0
      ) {
        
        if ($index > -1) {
          this.currentQuetions.selections.splice($index, 1);
        } else {
    
          this.currentQuetions.selections.push($data);
        }
      }else{
        if ($index > -1) {
          this.currentQuetions.selections.splice($index, 1);
        }
        
      }
    },
    countDownTimer() {  
      if(this.countDown > 0) {
          setTimeout(() => {
              this.countDown -= 1
              this.countDownTimer()
          }, 1000)
      } else {
          this.submit()
      }
    }
  },
};
</script>

<style>
.countown{
  margin-inline-start: 45%;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}
.countown-text{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.close-icon{
    float: inline-end;
    margin-block-start: -30px;
    cursor: pointer;
}
.success-assessment{
    block-size: 125px;
    display:block;
    margin:auto auto;
    text-align: center;
    padding-block-start: 5%;
    background-color: #f0f6f2;
    /* border: 2px solid #7a996b; */
    border-radius: 6px;
    color: #272727;
    font-size:24px;
}
.left-score{
    block-size: 100%;
    display: flex;
    padding-block-start: 10%;
    background-color: #d2f8e2;
    border-radius: 6px;
    color: #272727;
    justify-content: center;
}
.middle-score{
    block-size: 100%;
    display: flex;
    padding: 5%;
    background-color: #d2f8e2;
    border-radius: 6px;
    color: #272727;
    text-align: center;
}
.right-score{
    block-size: 100%;
    display: flex;
    padding: 6%;
    background-color: #d2f8e2;
    border-radius: 6px;
    color: #272727;
    text-align: center;
}
.second-div {
  padding-inline-end: 20px !important;
}
.status-card {
  padding: 0px 10px 10px 0px;
}
.row-margin {
  margin-block-end: 20px !important;
}
.card {
  inline-size: 100% !important;
}
.headertext {
  font-weight: 600;
  font-size: 24px;
  padding-block-start: 6px;
  padding-inline-start: 25px;
}
.subheadertext {
  font-weight: 400;
  font-size: 14px;
  padding-inline-start: 25px;
}
.row2 {
  text-align: center;
  padding-block-start: 5px;
}

.synopsiscard {
  min-block-size: 58px;
  inline-size: 1000px;
  margin-inline-start: 36px;
  margin-block-start: 30px;
}
.colborders {
  
}

.row3 {
  padding-block-start: 32px;
  padding-inline-start: 38px;
}

.row4 {
  padding-block-start: 22px;
  padding-inline-start: 14px;
}
.avatar {
  position: relative;
  inset-block-start: 10px;
  inset-inline-start: 10px;
}

.text {
  padding-block-start: 10px;
  padding-inline-start: 24px;
}

.div {
  padding-block-start: 40px;
  padding-inline-start: 50px;
}

.chip {
  inline-size: 90%;
  border-radius: 8px;
  padding:5px;
  font-size:14px;
  padding-inline-start:10px;
}

.chosen {
  padding-inline-start: 66px;
  padding-block-start: 26px;
}

.checkbox {
  padding-inline-start: 57px;
  position: relative;
  inset-block-start: 10px;
}
/* .div2 {
  padding-inline-start: 71px;
} */

.time {
  block-size: 100px;
  inline-size: 380px;
  border-radius: 12px;
  position: relative;
  /* inset-inline-end: 50px; */
}
.div3 {
  padding-block-start: 20px;
}
.card2 {
  inline-size: 100%;
  /* block-size: 470px; */
  border-radius: 12px;
  position: relative;
  /* inset-inline-end: 50px; */
}

.Status {
  padding-inline-start: 10px;
  padding-block-start: 10px;
  font-size: 18px;
  font-weight: 700;
}

.avatar2 {
  padding-block-start: 10px;
}

.skipped {
  font-size: 14px;
  padding-inline-start: 4px;
}

.div4 {
  padding-block-start: 100px;
  padding-inline-start: 10px;
}

.text2 {
  padding-block-start: 40px;
}

.timeleft {
  font-size: 14px;
  padding-inline-start: 8px;
}

.buttons {
  block-size: 38px;
  zoom:80%;
  border-radius: 12px;
  background-color: #3C5AAA;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .headertext {
    font-weight: 600;
    font-size: 24px;
    padding-block-start: 6px;
    padding-inline-start: 25px;
  }
  .subheadertext {
    font-weight: 400;
    font-size: 14px;
    padding-inline-start: 26px;
  }
  .row2 {
    text-align: center;
    padding-block-start: 5px;
  }

  .synopsiscard {
    min-block-size: 10px;
    min-inline-size: 600px;
    margin-inline-start: 36px;
    margin-block-start: 30px;
  }
  .colborders {
    border-inline-end: 1px solid #eee;
  }

  .row3 {
    padding-block-start: 32px;
    padding-inline-start: 38px;
  }

  .row4 {
    padding-block-start: 22px;
    padding-inline-start: 14px;
  }
  .avatar {
    position: relative;
    inset-block-start: 10px;
    inset-inline-start: 10px;
  }

  .text {
    padding-block-start: 10px;
    padding-inline-start: 24px;
  }

  .div {
    padding-block-start: 40px;
    padding-inline-start: 50px;
  }

 

  .chosen {
    padding-inline-start: 66px;
    padding-block-start: 26px;
  }

  .checkbox {
    padding-inline-start: 57px;
    position: relative;
    inset-block-start: 10px;
  }
  /* .div2 {
    padding-inline-start: 71px;
  } */

  .time {
    block-size: 100px;
    inline-size: 324px;
    border-radius: 12px;
    position: relative;
    /* inset-inline-start: 160px; */
  }

  .div3 {
    padding-block-start: 20px;
  }
  .card2 {
    inline-size: 100%;
    /* block-size: 470px; */
    border-radius: 12px;
    position: relative;
    /* inset-inline-start: 160px; */
  }

  .Status {
    padding-inline-start: 10px;
    padding-block-start: 10px;
    font-size: 18px;
    font-weight: 700;
  }

  .avatar2 {
    padding-block-start: 10px;
  }

  .skipped {
    font-size: 14px;
    padding-inline-start: 4px;
  }

  .div4 {
    padding-block-start: 100px;
    padding-inline-start: 10px;
  }

  .text2 {
    padding-block-start: 40px;
  }

  .timeleft {
    font-size: 14px;
    padding-inline-start: 8px;
  }

  .buttons {
    background-color:#3C5AAA;
    border-radius: 8px;
    color:#fff;
    font-weight:800;
    text-transform: none; 
  }
}

@media only screen and (min-width: 1440px) {
  .synopsiscard {
    min-block-size: 10px;
    min-inline-size: 100%;
    margin-inline-start: 36px;
    margin-block-start: 30px;
  }

  .time {
    block-size: 100px;
    inline-size: 100%;
    border-radius: 12px;
    position: relative;
    inset-inline-start: 45px;
  }

  .card2 {
    inline-size: 100%;
    /* block-size: 470px; */
    border-radius: 12px;
    position: relative;
    /* inset-inline-start: 45px; */
  }
}
@media only screen and(min-width: 2650px) {
}
.cardBg2 {
  background: url("../../assets/progress-100-svgrepo-com 1.svg") no-repeat #ffff;
  background-position: 24px 100px ;
}
</style>
